import { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import styles from './GenericDraggableList.module.css';

export interface GenericDraggableListProps<T> {
  items: T[];
  moveItem: (newItems: T[]) => void;
  addItem: () => void;
  renderItem: (
    item: T,
    index: number,
    toggleItem: () => void
  ) => React.ReactNode;
  itemType: string;
  addItemText?: string;
}

export interface GenericDraggableItemProps<T> {
  item: T;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  renderItemContent: (item: T) => React.ReactNode;
  itemType: string; // 外部から指定可能にする
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const GenericDraggableList = <T,>({
  items,
  moveItem,
  addItem,
  renderItem,
  itemType,
  addItemText,
}: GenericDraggableListProps<T>) => {
  const changeItemOrder = (dragIndex: number, hoverIndex: number) => {
    const dragItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);
    moveItem(newItems);
  };

  return (
    <div className={styles['p-topPage-box']}>
      <ul className={styles['p-topPage-sortList']}>
        {items.map((item, idx) => (
          <GenericDraggableItem
            key={idx}
            item={item}
            index={idx}
            moveItem={changeItemOrder}
            renderItemContent={() =>
              renderItem(item, idx, () => {
                const newItems = [...items];
                // Assuming "default_display_in_calendar" exists in the generic type
                (newItems[idx] as any).default_display_in_calendar = !(
                  newItems[idx] as any
                ).default_display_in_calendar;
                moveItem(newItems);
              })
            }
            itemType={itemType} // itemType を渡す
          />
        ))}
      </ul>
      {addItemText && (
        <a
          className={styles['p-box__table__add']}
          onClick={() => {
            addItem();
          }}
        >
          <i className="c-icon-outline-general-plus-circle"></i>
          {addItemText}
        </a>
      )}
    </div>
  );
};

export const GenericDraggableItem = <T,>({
  item,
  index,
  moveItem,
  renderItemContent,
  itemType,
}: GenericDraggableItemProps<T>) => {
  const ref = useRef<HTMLLIElement | null>(null);

  const [, drop] = useDrop<DragItem>({
    accept: itemType, // 外部から渡された itemType を使用
    hover(dragItem: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = dragItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset() as XYCoord;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: itemType, // 外部から渡された itemType を使用
    item: { type: itemType, id: String(index), index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li
      ref={ref}
      className={styles['p-topPage-sortList__item']}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      {renderItemContent(item)}
    </li>
  );
};
