import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { ETicketCouponButtonPreview } from 'client/components/ETicketCouponButtonPreview/ETicketCouponButtonPreview';
import { SingleImageInput } from 'client/components/v3/SingleImageInput/SingleImageInput';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from '../../ETicketEdit.module.css';

export const GuidanceButton = () => {
  const { t } = useTranslation();

  const values = useFormState().values;

  return (
    <div className={styles['p-box__table3']}>
      <Box mr={2} width="100%">
        <div className={styles['p-box__table3__item__body__item']}>
          <p className={styles['p-box__table3__item__body__item__ttl']}>
            {t('Text on Button')}
          </p>
          <Field name="guidanceButtonText">
            {({ input }) => <TextField type="text" {...input} />}
          </Field>
        </div>
      </Box>
      <Box mr={2}>
        <div className={styles['p-box__table3__item__body__item']}>
          <p className={styles['p-box__table3__item__body__item__ttl']}>
            {t('Button Image')}
          </p>

          <Field name="guidanceButtonImageUrl">
            {({ input, meta: { touched, error } }) => (
              <SingleImageInput
                initialValue={input.value}
                onUploadFinished={(_, objectUrl) => input.onChange(objectUrl)}
                error={touched && error}
              />
            )}
          </Field>
        </div>
      </Box>
      <Box mr={2}>
        <ETicketCouponButtonPreview
          buttonText={values.guidanceButtonText || t('Coupon')}
          buttonImageUrl={values.guidanceButtonImageUrl}
        />
      </Box>
    </div>
  );
};
