import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_INTERNAL_CHATS_SUCCESS,
  FETCH_INTERNAL_CHATS_FAILURE,
  FETCH_INTERNAL_CHATS_REQUEST,
  CREATE_INTERNAL_CHAT_REQUEST,
  CREATE_INTERNAL_CHAT_FAILURE,
  CREATE_INTERNAL_CHAT_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { InternalChatParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchInternalChatsRequest = () => ({
  type: FETCH_INTERNAL_CHATS_REQUEST,
});

const fetchInternalChatsSuccess = (response: any) => ({
  type: FETCH_INTERNAL_CHATS_SUCCESS,
  response,
});

const fetchInternalChatsFailure = (error: any) => ({
  type: FETCH_INTERNAL_CHATS_FAILURE,
  error,
});

let fetchInternalChatsCancel: () => void | undefined;
export const fetchInternalChats =
  (conversationId: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchInternalChatsCancel?.();
    dispatch(fetchInternalChatsRequest());
    axios
      .get('/api/internalchats', {
        params: { conversation_id: conversationId },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchInternalChatsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchInternalChatsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchInternalChatsFailure('canceled'));
        dispatch(fetchInternalChatsFailure(error));
      });
  };

const createInternalChatRequest = (newChat: InternalChatParams) => ({
  type: CREATE_INTERNAL_CHAT_REQUEST,
  newChat,
});

const createInternalChatSuccess = (response: any) => ({
  type: CREATE_INTERNAL_CHAT_SUCCESS,
  response,
});

const createInternalChatFailure = (error: any) => ({
  type: CREATE_INTERNAL_CHAT_FAILURE,
  error,
});

export const createInternalChat =
  (newChat: InternalChatParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createInternalChatRequest(newChat));
    return axios
      .post('/api/internalchats', newChat, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createInternalChatSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createInternalChatFailure(err.message));
      });
  };
