import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-focus';

import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { Loading } from 'client/pages/Loading';
import { ReduxState } from 'client/reducers';
import { TextArea } from 'client/components/v3/Form/TextArea';
import {
  fetchSupplierSettings,
  putSupplierSettings,
} from 'client/actions/supplierSettings';

import { FormValues } from './FormValues';

const focusOnError: any = createDecorator();

export const ChatDataSourceSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //
  const chatSettings = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.chat
  );
  const loading = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.loading
  );

  React.useEffect(() => {
    dispatch(fetchSupplierSettings('CHAT'));
  }, []);

  const initialValues: FormValues = React.useMemo(() => {
    return {
      urls: (chatSettings?.data_source_settings?.urls || []).join('\n'),
    };
  }, [chatSettings]);

  return (
    <>
      <Form<FormValues>
        onSubmit={async (values: FormValues) => {
          try {
            await dispatch(
              putSupplierSettings('CHAT', {
                chat_settings: {
                  data_source_settings: {
                    urls: values.urls.split('\n').map((url) => url.trim()),
                  },
                },
              })
            );
          } catch (e) {
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        initialValues={initialValues}
        decorators={[focusOnError]}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field name="urls">
              {({ input }) => (
                <Box mt={2} display="flex" alignItems="center">
                  <TextArea
                    value={input.value}
                    onChange={input.onChange}
                    label={t('URLs')}
                    fullContainerWidth={true}
                  />
                </Box>
              )}
            </Field>
            <div className={baseStyles['base-main__box__body__bottomBtns']}>
              <Button
                type="submit"
                color="primary"
                text={t('Save Settings')}
                loading={submitting}
              />
            </div>
          </form>
        )}
      </Form>
      {loading && <Loading />}
    </>
  );
};
