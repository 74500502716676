import { Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import { PresetRedemptionCount } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { GenericDraggableList } from 'client/components/v3/GenericDraggableList/GenericDraggableList';

import styles from '../../ETicketEdit.module.css';

import { PresetRedemptionCountItem } from './PresetRedemptionCountItem';

export const PresetRedemptionCountsForm = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['p-box__table3']}>
      <Field name="presetRedemptionCounts">
        {({ input }) => {
          return (
            <>
              <GenericDraggableList<PresetRedemptionCount>
                items={input.value}
                itemType="PresetRedemptionCount"
                moveItem={(newItems) => input.onChange(newItems)}
                addItem={() => {
                  const newItems = [
                    ...input.value,
                    {
                      key: uuid(),
                      text: '',
                      redemptionCount: 1,
                    },
                  ];
                  input.onChange(newItems);
                }}
                addItemText={t('Add QR Code')}
                renderItem={(item) => {
                  return (
                    <PresetRedemptionCountItem
                      presetRedemptionCount={item}
                      onChange={(newItem) => {
                        const newItems = input.value.map(
                          (i: PresetRedemptionCount) => {
                            if (i.key === item.key) {
                              return newItem;
                            }
                            return i;
                          }
                        );
                        input.onChange(newItems);
                      }}
                      onRemoveClick={() => {
                        const newItems = input.value.filter(
                          (i: PresetRedemptionCount) => i.key !== item.key
                        );
                        input.onChange(newItems);
                      }}
                    />
                  );
                }}
              />
            </>
          );
        }}
      </Field>
    </div>
  );
};
