import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { PaymentMethodEditorModal } from 'client/pages/Settings/PaymentMethodEditorModal';
import { CollapsibleSection } from 'client/pages/v3/SystemFee/SystemFeeSettings/CollapsibleSection/CollapsibleSection';
import { SubscriptionsEditor } from 'client/pages/v3/SystemFee/SystemFeeSettings/Subscription/SubscriptionsEditor';
import { Button } from 'client/components/v3/Common/Button';
import {
  activeUserOrganizationSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { updateActiveUserOrganization } from 'client/actions/organizations';
import { BankAccountEditor } from 'client/pages/v3/SystemFee/SystemFeeSettings/BankAccountEditor';
import { ActivityPlanSettingsEditor } from 'client/pages/v3/SystemFee/SystemFeeSettings/ActivityPlanSettingsEditor';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettings.module.css';
import { Organization } from 'shared/models/swagger';

interface FormValues {
  bankName: string;
  branchName: string;
  accountType: string;
  accountNumber: string;
  accountHolderName: string;
  routingNumber: string;
  accountHolderAddress: string;

  activityPlanSettings: {
    isSubscribed: boolean;
    planStartDate?: string;
    currentPeriodStartDate?: string;
    currentPeriodEndDate?: string;
    isManifestEnabled?: boolean;
  };
}

const getInitialValues = (org: Organization | null): FormValues => {
  return {
    bankName: org?.bank_account_info?.bank_name ?? '',
    branchName: org?.bank_account_info?.branch_name ?? '',
    accountType: org?.bank_account_info?.account_type ?? '',
    accountNumber: org?.bank_account_info?.account_number ?? '',
    accountHolderName: org?.bank_account_info?.account_holder_name ?? '',
    routingNumber: org?.bank_account_info?.routing_number ?? '',
    accountHolderAddress: org?.bank_account_info?.account_holder_address ?? '',

    activityPlanSettings: {
      isSubscribed: org?.activity_plan_settings?.is_subscribed ?? false,
      planStartDate: org?.activity_plan_settings?.plan_start_date ?? '',
      currentPeriodStartDate:
        org?.activity_plan_settings?.current_period_start_date,
      currentPeriodEndDate:
        org?.activity_plan_settings?.current_period_end_date,
      isManifestEnabled:
        org?.activity_plan_settings?.features?.is_manifest_enabled ?? false,
    },
  };
};

export const SystemFeeSettingsBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization = useSelector(activeUserOrganizationSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );

  const apiKey =
    activeUserOrganization?.default_subscriptions_payment_currency === 'JPY'
      ? config.stripeConfig.publicJPYApiKey
      : config.stripeConfig.publicUSDApiKey;

  const initialValues = React.useMemo(
    () => getInitialValues(organization),
    [organization]
  );

  return (
    <div className={baseStyles['l-main__body']}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            updateActiveUserOrganization({
              bank_account_info: {
                bank_name: values.bankName,
                branch_name: values.branchName,
                account_type: values.accountType,
                account_number: values.accountNumber,
                account_holder_name: values.accountHolderName,
                routing_number: values.routingNumber,
                account_holder_address: values.accountHolderAddress,
              },
              activity_plan_settings: {
                is_subscribed: values.activityPlanSettings.isSubscribed,
                plan_start_date: values.activityPlanSettings.planStartDate,
                features: {
                  is_manifest_enabled:
                    values.activityPlanSettings.isManifestEnabled,
                },
              },
            })
          );
        }}
      >
        {({ handleSubmit, submitting, submitSucceeded }) => {
          return (
            <form onSubmit={handleSubmit}>
              {submitSucceeded && (
                <Snackbar
                  text={t('Save Successful')}
                  color="success"
                  shouldShow={submitSucceeded}
                />
              )}
              <section className={styles['g-section']}>
                <CollapsibleSection id="payments" title={t('Payments')}>
                  <ul className={styles['p-general-list']}>
                    <li className={styles['p-general-list__item']}>
                      <div className={styles['p-general-list__item__ttl']}>
                        <div
                          className={styles['p-general-list__item__ttl__txt']}
                        >
                          <div>{t('Credit Card')}</div>
                        </div>
                        <span>
                          {t(
                            'Credit card to be used for monthly payments of system fees and subscription fees'
                          )}
                        </span>
                      </div>
                      <div className={styles['p-general-list__item__body']}>
                        <div
                          className={
                            styles['p-general-products__section__body__item']
                          }
                        >
                          {activeUserOrganization?.payment_profile_card_info && (
                            <Box ml={2} mb={4}>
                              <Box display="flex">
                                <Box mr={2}>
                                  {activeUserOrganization
                                    ?.payment_profile_card_info?.card_brand ??
                                    ''}
                                </Box>
                                <Box ml={2} mr={2}>
                                  XXXXXXXXXXXX
                                  {activeUserOrganization
                                    ?.payment_profile_card_info
                                    ?.last_four_digits ?? ''}
                                </Box>
                                <Box ml={2} mr={2}>
                                  {t('Exp: ')}
                                  {
                                    activeUserOrganization
                                      ?.payment_profile_card_info?.exp_month
                                  }{' '}
                                  /{' '}
                                  {activeUserOrganization
                                    ?.payment_profile_card_info?.exp_year ?? ''}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </div>
                        <div
                          className={
                            styles['p-general-products__section__body__item']
                          }
                        >
                          <StripeProvider key={apiKey} apiKey={apiKey}>
                            <Elements>
                              <PaymentMethodEditorModal />
                            </Elements>
                          </StripeProvider>
                        </div>
                      </div>
                    </li>

                    <li className={styles['p-general-list__item']}>
                      <div className={styles['p-general-list__item__ttl']}>
                        <div
                          className={styles['p-general-list__item__ttl__txt']}
                        >
                          <div>{t('Bank Account')}</div>
                        </div>
                        <span>
                          {t(
                            'Bank account information for Nutmeg to transfer funds for reservations paid in full by credit cards'
                          )}
                        </span>
                      </div>
                      <div className={styles['p-general-list__item__body']}>
                        <BankAccountEditor />
                      </div>
                    </li>
                  </ul>
                </CollapsibleSection>

                <CollapsibleSection
                  id="subscriptions"
                  title={t('Subscriptions')}
                >
                  <SubscriptionsEditor />
                </CollapsibleSection>

                {config.enableActivityPlan &&
                  (loggedInAccountIsNutmegAdmin ||
                    organization?.activity_plan_settings?.is_subscribed) && (
                    <CollapsibleSection
                      id="activityPlan"
                      title={t('Activity Plan')}
                    >
                      <ActivityPlanSettingsEditor />
                    </CollapsibleSection>
                  )}
              </section>
              <div
                className={clsx(
                  styles['p-general-products__fixed'],
                  styles['is-active']
                )}
              >
                <div className={styles['p-general-products__fixed__main']}>
                  <Button
                    text={t('Save')}
                    type="submit"
                    color="primary"
                    size="md"
                    loading={submitting}
                    style={{
                      width: '60px',
                      fontWeight: 'var(--text-semibold)',
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
