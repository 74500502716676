import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TextField } from 'client/components/v3/Form/TextField';
import styles from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettings.module.css';

export const BankAccountEditor = () => {
  const { t } = useTranslation();

  return (
    <>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t('Bank Name')}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="bankName">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t('Branch Name')}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="branchName">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t('Account Type')}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="accountType">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t('Account Number')}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="accountNumber">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t("Account Holder's Name")}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="accountHolderName">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t("Account Holder's Address")}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="accountHolderAddress">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
      <dl className={styles['p-general-products__section__list']}>
        <dt className={styles['p-general-products__section__list__term']}>
          <span>{t('SWIFT Code/Routing Number')}</span>
        </dt>
        <dd className={styles['p-general-products__section__list__desc']}>
          <Field name="routingNumber">
            {({ input }) => <TextField {...input} />}
          </Field>
        </dd>
      </dl>
    </>
  );
};
