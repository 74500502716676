import { useTranslation } from 'react-i18next';

import { Loading } from 'client/components/v3/Common/Loading';

import styles from './InternalChat.module.css';

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['loader']}>
        <Loading size="sm" doNotShowText={true} />
      </div>
      {t('Thinking')}
    </div>
  );
};
