import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Operations.module.css';

export const Operations = () => {
  const { t } = useTranslation();
  return (
    <ul className={clsx(styles['p-primary__select__menu'])}>
      <li className={styles['p-primary__select__menu__item']}>
        {/* TODO: remove v3 from url later */}
        <Link
          to={{
            pathname: `/products/copy-v3`,
          }}
        >
          <i className="c-icon-outline-general-copy-01"></i>
          <div>{t('Copy')}</div>
        </Link>
      </li>
      <li
        className={clsx(styles['p-primary__select__menu__item'], styles['red'])}
      >
        <div>
          <i className="c-icon-outline-general-trash-03"></i>
          <div>{t('Delete')}</div>
        </div>
      </li>
    </ul>
  );
};
