import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';

import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Box } from 'client/components/Box/Box';
import { SingleDropdown } from 'client/pages/v3/Product/ProductPromotions/SingleDropdown';
import { FormValues } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';

import styles from '../../ETicketEdit.module.css';

import { AdditionalBuyPriceForm } from './AdditionalBuyPriceForm';

export const AdditionalBuyForm = () => {
  const { t } = useTranslation();

  const values = useFormState<FormValues>().values;
  const form = useForm<FormValues>();

  return (
    <div className={styles['p-box__table3']}>
      <Box mr={2}>
        <div className={styles['p-box__table3__item__body__item']}>
          <p className={styles['p-box__table3__item__body__item__ttl']}>
            {t('Additional Ticket Type')}
          </p>
          <EnumRadioButtonGroup
            name="additionalBuyType"
            liStyle={{ width: '250px', marginRight: '7px' }}
            options={[
              {
                value: 'SET',
                label: t('Add whole set of ticket'),
                tooltipText: t(
                  'Guest can purchase and add one set of ticket based on the product settings.'
                ),
              },
              {
                value: 'INDIVIDUAL',
                label: t('Select ticket quantity'),
                tooltipText: t('Select the number of tickets and purchase.'),
              },
            ]}
          />
        </div>
      </Box>
      {(values.stubs || []).length > 0 && (
        <Box mr={2}>
          <div className={styles['p-box__table3__item__body__item']}>
            <p className={styles['p-box__table3__item__body__item__ttl']}>
              {t('Ticket Usage')}
            </p>
            <SingleDropdown
              selectedOption={values.additionalBuyStubKey}
              options={values.stubs.map((stub) => ({
                value: stub.key,
                text: stub.text,
              }))}
              onChange={(value) => {
                form.change('additionalBuyStubKey', value);
              }}
            />
          </div>
        </Box>
      )}
      {values.additionalBuyType === 'INDIVIDUAL' && (
        <Box mr={2}>
          <div className={styles['p-box__table3__item__body__item']}>
            <p className={styles['p-box__table3__item__body__item__ttl']}>
              {t('Unit price per ticket')}
            </p>
            <AdditionalBuyPriceForm />
          </div>
        </Box>
      )}
    </div>
  );
};
