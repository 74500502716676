import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'client/components/v3/GenericDraggableList/GenericDraggableList.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { PresetRedemptionCount } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { PresetRedemptionCountQrCodeModal } from 'client/pages/ProductDetails/ProductContentsHeader/DraggablePresetRedemptionCountInputForm/PresetRedemptionCountQrCodeModal';
import { QRCode as QRCodeIcon } from 'client/components/Icons/QRCode';
import { TextField } from 'client/components/v3/Form/TextField';

import privateStyles from './PresetRedemptionCountItem.module.css';

interface Props {
  presetRedemptionCount: PresetRedemptionCount;
  onChange: (newItem: PresetRedemptionCount) => void;
  onRemoveClick: () => void;
}

export const PresetRedemptionCountItem = ({
  presetRedemptionCount,
  onChange,
  onRemoveClick,
}: Props) => {
  const { t } = useTranslation();
  const [showQrCodeModal, setShowQrCodeModal] = React.useState(false);
  const editingProduct = React.useContext(EditingProductContext);
  return (
    <>
      <div className={styles['p-topPage-sortList__item__left']}>
        <div className={styles['p-topPage-sortList__item__ic']}>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <Box display="flex" alignItems="center">
          <Box mr={2} className={privateStyles['item']}>
            <TextField
              label={t('Attraction')}
              name="text"
              type="text"
              value={presetRedemptionCount.text}
              onChange={(value) => {
                const newItem = {
                  ...presetRedemptionCount,
                  text: value,
                };
                onChange(newItem);
              }}
            />
          </Box>
          <Box mr={2} className={privateStyles['item']}>
            <TextField
              label={t('Number of uses')}
              name="number"
              type="number"
              value={String(presetRedemptionCount.redemptionCount)}
              onChange={(value) => {
                const newItem = {
                  ...presetRedemptionCount,
                  redemptionCount: Number(value),
                };
                onChange(newItem);
              }}
            />
          </Box>
        </Box>
      </div>
      <div className={styles['p-topPage-sortList__item__right']}>
        <Box display="flex" alignItems="center">
          <QRCodeIcon onClick={() => setShowQrCodeModal(true)} />
          <Button
            size="icon"
            color="tertiarygray"
            onClick={() => onRemoveClick()}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </Box>
      </div>
      <PresetRedemptionCountQrCodeModal
        productId={editingProduct?.id || ''}
        open={showQrCodeModal}
        onClose={() => setShowQrCodeModal(false)}
        presetRedemptionCountKey={presetRedemptionCount.key}
      />
    </>
  );
};
