import { useTranslation } from 'react-i18next';

import styles from 'client/components/v3/GenericDraggableList/GenericDraggableList.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';

interface Props {
  value: string;
  onChange: (newItem: string) => void;
  onRemoveClick: () => void;
}

export const StubOptionItem = ({ value, onChange, onRemoveClick }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['p-topPage-sortList__item__left']}>
        <div className={styles['p-topPage-sortList__item__ic']}>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <Box width="100%">
          <Box display="flex" alignItems="center" width="100%">
            <Box mr={2} width="100%">
              <TextField
                label={t('Title')}
                name="title"
                value={value}
                type="text"
                onChange={(value) => {
                  onChange(value);
                }}
              />
            </Box>
          </Box>
        </Box>
      </div>
      <div className={styles['p-topPage-sortList__item__right']}>
        <Box display="flex" alignItems="center">
          <Button
            size="icon"
            color="tertiarygray"
            onClick={() => onRemoveClick()}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </Box>
      </div>
    </>
  );
};
