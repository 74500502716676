import { Field } from 'react-final-form';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './CheckinEndRelativeDateTime.module.css';

interface Props {
  name: string;
}

export const CheckinEndRelativeDateTime = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['input-container']}>
        <label
          style={{
            width: '120px',
          }}
        >
          <Field
            name={`${name}.daysLater`}
            parse={(value) =>
              typeof value === 'string' ? parseInt(value) : value
            }
          >
            {({ input }) => (
              <SingleDropdown
                options={_.times(366, (daysLater) => ({
                  value: daysLater.toString(),
                  text:
                    daysLater === 0
                      ? t('On the participation date')
                      : t('{{count}} days after', { count: daysLater }),
                }))}
                selectedOption={input.value.toString()}
                onChange={input.onChange}
              />
            )}
          </Field>
        </label>
        <div style={{ maxWidth: '80px' }}>
          <Field name={`${name}.timeOfDay`}>
            {({ input }) => (
              <TimePicker
                allowEmpty={false}
                value={moment(input.value, 'HH:mm')}
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};
