import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { activeUserSelector } from 'client/reducers/user';
import { fetchReservationByID } from 'client/actions/reservations';
import { ReduxState } from 'client/reducers';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { fetchReservationReceipt } from 'client/actions/reservationReceipts';
import { FreeFormatReservationDetails } from 'client/pages/ReservationDetails/FreeFormatReservationDetails/FreeFormatReservationDetails';
import { ReservationDetails } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetails';
import { fetchSurveyTemplates } from 'client/actions/surveyTemplates';
import { fetchGroupBookingTemplates } from 'client/actions/groupBookingTemplates';
import { fetchReservationInvoice } from 'client/actions/reservationInvoices';

export const ReservationDetailsWrapper = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const reservationLastFetchedDateTimeUtc = useRef<string | null>(null);

  // Reservation
  useEffect(() => {
    if (id) {
      reservationLastFetchedDateTimeUtc.current = moment().format();
      dispatch(fetchReservationByID(id));
    }
  }, [id, t, activeUser, dispatch]);

  useEffect(() => {
    dispatch(fetchGroupBookingTemplates());
  }, [t, activeUser]);

  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[id || '']
  );

  const isFreeFormat = Boolean(reservation?.is_free_format_reservation);

  useEffect(() => {
    if (id && reservation) {
      const lastStatusUpdateDateTimeUtc =
        reservation.status_history[reservation.status_history.length - 1]
          ?.status_date_time_utc;

      if (
        lastStatusUpdateDateTimeUtc &&
        reservationLastFetchedDateTimeUtc.current &&
        moment(lastStatusUpdateDateTimeUtc)
          .add(3, 'seconds')
          .isAfter(moment(reservationLastFetchedDateTimeUtc.current))
      ) {
        reservationLastFetchedDateTimeUtc.current = moment().format();
        dispatch(fetchReservationByID(id));
      }
    }
  }, [id, reservation, dispatch]);

  // Product
  useEffect(() => {
    if (reservation && !isFreeFormat) {
      dispatch(fetchProductByID(reservation.product_id || ''));
    }
  }, [reservation, dispatch]);

  const product = useSelector(
    (state: ReduxState) => state.products.byID[reservation?.product_id || '']
  );

  // Product Instance
  useEffect(() => {
    if (reservation && !isFreeFormat) {
      dispatch(fetchProductInstanceByID(reservation.product_instance_id));
    }
  }, [reservation, dispatch]);

  const productInstance = useSelector(
    (state: ReduxState) =>
      state.productInstances.byID[reservation?.product_instance_id || '']
  );

  // Reservation Receipt
  useEffect(() => {
    if (reservation && !isFreeFormat) {
      dispatch(fetchReservationReceipt(reservation.id));
      if (reservation.booking_source?.group_id) {
        dispatch(fetchReservationInvoice(reservation.id));
      }
    }
  }, [reservation, dispatch]);

  const reservationReceipt = useSelector(
    (state: ReduxState) => state.reservationReceipts.data
  );

  const reservationInvoice = useSelector(
    (state: ReduxState) => state.reservationInvoices.data
  );

  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  // Survey template
  useEffect(() => {
    dispatch(fetchSurveyTemplates());
  }, []);

  return (
    <>
      {isFreeFormat ? (
        <FreeFormatReservationDetails
          reservation={reservation}
          loading={loading}
        />
      ) : (
        <ReservationDetails
          product={product}
          productInstance={productInstance}
          reservation={reservation}
          reservationReceipt={reservationReceipt}
          loading={loading}
          reservationInvoice={reservationInvoice}
        />
      )}
    </>
  );
};
