import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { ReduxState } from 'client/reducers';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';

type FormValues = {
  value: Value;
};

type Value = 'ISSUED' | 'EMAIL_SENT' | 'PAID';

type Props = {
  title: string;
  value: Value;
  onClose: () => void;
  onChange: (status: Value) => Promise<void>;
};

export const InvoiceStatusModal = ({
  title,
  value,
  onClose,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.reservationInvoices.loading
  );

  return (
    <Form
      initialValues={{
        value,
      }}
      onSubmit={(values: FormValues) => {
        onChange(values.value);
        onClose();
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={title}
            open={true}
            onClose={() => {
              onClose();
            }}
            style={{ width: '600px' }}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  color="white"
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                />
                <Button
                  text={t('Change Status')}
                  type="submit"
                  loading={loading}
                />
              </>
            }
          >
            <div className={styles['p-reservationNum']}>
              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('Status')}
                </p>

                <EnumRadioButtonGroup
                  name="value"
                  liStyle={{ width: '160px', marginRight: '7px' }}
                  options={[
                    {
                      value: 'ISSUED',
                      label: t('Issued'),
                    },
                    {
                      value: 'EMAIL_SENT',
                      label: t('Email Sent'),
                    },
                    {
                      value: 'PAID',
                      label: t('Paid'),
                    },
                  ]}
                />
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
