import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { EquipmentSchedule } from 'shared/models/swagger';

import { Schedule } from './Schedule';
import { ProductSelector } from './ProductSelector';
import { StartTimeList } from './StartTimeList';
import { EditButton } from './EditButton';
import styles from './RuleListItem.module.css';

interface Props {
  schedule: EquipmentSchedule;
}

export const RuleListItem = ({ schedule }: Props) => {
  const { t } = useTranslation();
  const [productId, setProductId] = React.useState('');

  const productIdMaps = React.useMemo(() => {
    const productIdMaps: { [index: string]: boolean } = {};

    schedule?.start_time_mappings?.forEach((startTimeMapping) => {
      startTimeMapping?.product_start_times?.forEach((productStartTime) => {
        if (productStartTime.product_id) {
          productIdMaps[productStartTime.product_id] = true;
        }
      });
    });

    return productIdMaps;
  }, [schedule]);

  React.useEffect(() => {
    setProductId(Object.keys(productIdMaps)[0] || '');
  }, [productIdMaps]);

  return (
    <div className={styles['p-seat__equipment-schedule-list__rule-list-item']}>
      <Schedule
        startDate={schedule?.start_date_local || ''}
        endDate={schedule.end_date_local || ''}
      />
      <div
        className={
          styles['p-seat__equipment-schedule-list__rule-list-item__content']
        }
      >
        <div
          className={
            styles[
              'p-seat__equipment-schedule-list__rule-list-item__content__header'
            ]
          }
        >
          <ProductSelector
            productIds={Object.keys(productIdMaps)}
            value={productId}
            onChange={(productId: string) => {
              setProductId(productId);
            }}
          />

          <EditButton
            text={t('Edit Participation Rule')}
            onClick={() => {
              console.log('text');
            }}
          />
        </div>

        <StartTimeList
          productId={productId}
          startTimeMappings={schedule.start_time_mappings || []}
        />
      </div>
    </div>
  );
};
