import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Button, Input } from 'client/components/Form';
import { updateActiveUserOrganization } from 'client/actions/organizations';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { Organization } from 'shared/models/swagger';

interface FormValues {
  bankName: string;
  branchName: string;
  accountType: string;
  accountNumber: string;
  accountHolderName: string;
  routingNumber: string;
  accountHolderAddress: string;
}

const getInitialValues = (org: Organization | null): FormValues => {
  return {
    bankName: org?.bank_account_info?.bank_name ?? '',
    branchName: org?.bank_account_info?.branch_name ?? '',
    accountType: org?.bank_account_info?.account_type ?? '',
    accountNumber: org?.bank_account_info?.account_number ?? '',
    accountHolderName: org?.bank_account_info?.account_holder_name ?? '',
    routingNumber: org?.bank_account_info?.routing_number ?? '',
    accountHolderAddress: org?.bank_account_info?.account_holder_address ?? '',
  };
};

export const BankAccountEditor = () => {
  const organization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialValues = React.useMemo(
    () => getInitialValues(organization),
    [organization]
  );

  let supportMailAddress = 'bill@nutmeglabs.com';
  if (
    organization?.default_currency === 'JPY' &&
    organization?.default_timezone === 'Asia/Tokyo' &&
    organization?.source_language === 'JA_JP'
  ) {
    supportMailAddress = 'admin-jp@nutmeglabs.com';
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateActiveUserOrganization({
            bank_account_info: {
              bank_name: values.bankName,
              branch_name: values.branchName,
              account_type: values.accountType,
              account_number: values.accountNumber,
              account_holder_name: values.accountHolderName,
              routing_number: values.routingNumber,
              account_holder_address: values.accountHolderAddress,
            },
          })
        );
      }}
    >
      {({ handleSubmit, submitting, submitSucceeded, submitError }) => (
        <form onSubmit={handleSubmit}>
          {submitError && (
            <Message error header={t('Update failed')} content={submitError} />
          )}
          {submitSucceeded && (
            <Message success header={t('Update succeeded')} />
          )}

          <Box mb={2}>
            {t(
              'Bank account information for Nutmeg to transfer funds for reservations paid in full by credit cards'
            )}
          </Box>
          <p>
            {t(
              '* When the account information is updated, please kindly let us know at '
            )}
            <a href={`mailto:${supportMailAddress}`}>{supportMailAddress}</a>
          </p>
          <Field name="bankName">
            {({ input }) => <Input label={t('Bank Name')} {...input} />}
          </Field>
          <Box mt={2}>
            <Field name="branchName">
              {({ input }) => <Input label={t('Branch Name')} {...input} />}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="accountType">
              {({ input }) => <Input label={t('Account Type')} {...input} />}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="accountNumber">
              {({ input }) => <Input label={t('Account Number')} {...input} />}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="accountHolderName">
              {({ input }) => (
                <Input label={t("Account Holder's Name")} {...input} />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="accountHolderAddress">
              {({ input }) => (
                <Input label={t("Account Holder's Address")} {...input} />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="routingNumber">
              {({ input }) => (
                <Input label={t('SWIFT Code/Routing Number')} {...input} />
              )}
            </Field>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              loading={submitting}
              type="submit"
              style="blue"
              size="middle"
            >
              {t('Save')}
            </Button>
          </Box>
        </form>
      )}
    </Form>
  );
};
