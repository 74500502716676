import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { Box } from 'client/components/Box/Box';
import {
  activeUserOrganizationSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { isPartnershipAgent } from 'client/libraries/util/partnership';
import { getArrayMutators } from 'client/libraries/util/form';
import { Button, Input, Select, TextArea } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { updateOrganization } from 'client/actions/organizations';
import {
  fetchProducts,
  fetchPassthroughCandidateProducts,
} from 'client/actions/products';
import { getLanguageName } from 'client/libraries/i18n';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
import { DndList } from 'client/components/DndList/DndList';
import { EditTranslationsModal } from 'client/pages/BookingWidget/EditTranslationsModal/EditTranslationsModal';
import { getOrderedProductIds } from 'client/libraries/util/getOrderedProductIds';
import * as Swagger from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

import {
  convertFormValuesToOrganizationPatch,
  getCustomSectionFormValues,
} from './FormValues';
import type { TopPageFormValues } from './FormValues';
import { TopPageSectionsEditor } from './TopPageSectionsEditor/TopPageSectionsEditor';

const focusOnError: any = createDecorator();

export const BookingWidgetTopPage = () => {
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const allProducts = useSelector(bookingWidgetProductSummariesSelector);
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    text: getLanguageName(lang, t),
  }));
  const initialSelectedLanguage =
    (supportedLanguages ?? []).length > 0 ? supportedLanguages[0] : 'JA_JP';
  const initialProductIds = React.useMemo(
    () =>
      getOrderedProductIds(
        activeUserOrganization,
        allProducts,
        initialSelectedLanguage
      ),
    [activeUserOrganization, allProducts, initialSelectedLanguage]
  );
  const initialValues = React.useMemo(() => {
    return {
      bannerImageUrls: [
        activeUserOrganization?.booking_widget_design_params?.banner_image_url,
        ...(activeUserOrganization?.booking_widget_design_params
          ?.additional_banner_image_urls ?? []),
      ].filter((url) => Boolean(url)),
      bannerTitle:
        activeUserOrganization?.booking_widget_design_params?.banner_title,
      bannerDescription:
        activeUserOrganization?.booking_widget_design_params
          ?.banner_description,
      headerDescription:
        activeUserOrganization?.booking_widget_design_params
          ?.header_description_text,
      productListLanguage: initialSelectedLanguage,
      sectionLanguage: initialSelectedLanguage,
      productIds: initialProductIds,
      customSections: getCustomSectionFormValues(
        activeUserOrganization,
        initialSelectedLanguage
      ),
    };
  }, [initialProductIds, activeUserOrganization, initialSelectedLanguage]);
  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
      if (isPartnershipAgent(activeUserOrganization)) {
        dispatch(
          fetchPassthroughCandidateProducts({
            agent_id:
              activeUserOrganization?.corresponding_organization_id ?? '',
          })
        );
      }
    }
  }, [activeUserOrganization]);
  const scrollTo = React.useContext(ScrollToContext);
  const sourceLanguage = activeUserOrganization?.source_language || 'EN_US';
  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== sourceLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([sourceLanguage, ...translationLanguages])].length > 1;
  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="BOOKING_SITE_TOP_PAGE" />
      )}
      <Form
        onSubmit={async (values: TopPageFormValues) => {
          const orgId = activeUserOrganization?.id || '';
          const patch = convertFormValuesToOrganizationPatch(
            activeUserOrganization,
            values
          );

          try {
            await dispatch(updateOrganization(orgId, 'SUPPLIER', patch));
            scrollTo(0, 0);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        mutators={getArrayMutators()}
        decorators={[focusOnError]}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={baseStyles['base-main__body__box']}>
              <div className={baseStyles['base-main__body__box__body']}>
                {saveSucceeded && (
                  <Message success header={t('Settings Saved')} />
                )}
                <div className={styles['page-productsRegist__tableBox']}>
                  <div className={styles['c-table-list']}>
                    <table>
                      <tr>
                        <th>{t('Website Title')}</th>
                        <td>
                          <div>
                            <Field name="headerDescription">
                              {({ input }) => (
                                <Input maxWidth={600} {...input} />
                              )}
                            </Field>
                          </div>
                          {shouldShowMultilingualSettings && (
                            <div
                              className={
                                styles['translations-button-container']
                              }
                            >
                              <EditTranslationsModal
                                sourceLanguage={sourceLanguage}
                                trigger={
                                  <Button style="gray" size="small">
                                    {t('Multilingual Settings')}
                                  </Button>
                                }
                                sourceText={values?.headerDescription || ''}
                                translationLanguages={translationLanguages}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Website Description')}</th>
                        <td>
                          <div>
                            <Field name="bannerDescription">
                              {({ input }) => (
                                <TextArea
                                  height={80}
                                  maxWidth={600}
                                  {...(input as any)}
                                />
                              )}
                            </Field>
                          </div>
                          {shouldShowMultilingualSettings && (
                            <div
                              className={
                                styles['translations-button-container']
                              }
                            >
                              <EditTranslationsModal
                                sourceLanguage={sourceLanguage}
                                trigger={
                                  <Button style="gray" size="small">
                                    {t('Multilingual Settings')}
                                  </Button>
                                }
                                sourceText={values?.bannerDescription || ''}
                                translationLanguages={translationLanguages}
                                useTextArea
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Catchphrase')}</th>
                        <td>
                          <div>
                            <Field name="bannerTitle">
                              {({ input }) => (
                                <Input maxWidth={600} {...input} />
                              )}
                            </Field>
                          </div>
                          {shouldShowMultilingualSettings && (
                            <div
                              className={
                                styles['translations-button-container']
                              }
                            >
                              <EditTranslationsModal
                                sourceLanguage={sourceLanguage}
                                trigger={
                                  <Button style="gray" size="small">
                                    {t('Multilingual Settings')}
                                  </Button>
                                }
                                sourceText={values?.bannerTitle || ''}
                                translationLanguages={translationLanguages}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Key visual')}</th>
                        <td>
                          <div>
                            <Field name="bannerImageUrls">
                              {({ input }) => (
                                <ImageVideoAudioInput
                                  fileUrls={input.value || []}
                                  onChange={(newValue) =>
                                    input.onChange(newValue)
                                  }
                                  disableYoutubeVideos
                                />
                              )}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Customization')}</th>
                        <td>
                          <TopPageSectionsEditor />
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Product List')}</th>
                        <td>
                          <Box
                            ml={-1}
                            mb={2}
                            flexWrap="wrap"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Box ml={1} fontWeight="bold">
                              {t('Content Language')}
                            </Box>
                            <Box ml={1}>
                              <Field name="productListLanguage">
                                {({ input }) => (
                                  <Select
                                    maxWidth={200}
                                    options={languageOptions}
                                    value={input.value}
                                    onChange={(e, { value }) => {
                                      input.onChange(value);
                                      form.change(
                                        'productIds',
                                        getOrderedProductIds(
                                          activeUserOrganization,
                                          allProducts,
                                          value as Swagger.SourceLanguage
                                        )
                                      );
                                    }}
                                  />
                                )}
                              </Field>
                            </Box>
                          </Box>
                          <div>{t('* drag-and-drop to reorder')}</div>
                          <Field name="productIds">
                            {({ input }) => (
                              <DndList
                                items={input.value.map((productId: string) => ({
                                  key: productId,
                                  text:
                                    allProducts?.find(
                                      (product) => product.id === productId
                                    )?.product_name ?? productId,
                                }))}
                                setItems={(newItems) => {
                                  input.onChange(
                                    newItems.map((item) => item.key)
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  <Button
                    type="submit"
                    style="blue"
                    size="small"
                    loading={submitting}
                  >
                    {t('Save Settings')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
