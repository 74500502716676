import type { SourceLanguage } from 'shared/models/swagger';

const bookingWidgetUrlLangCodeMap: Record<string, string> = {
  en: 'en-US',
  ja: 'ja-JP',
  ko: 'ko-KR',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};

export const convertToBookingWidgetUrlLangCode = (lang: string): string => {
  return bookingWidgetUrlLangCodeMap[lang] || 'ja-JP';
};

const sourceLanguageToBookingWidgetUrlLangCodeMap: Record<
  SourceLanguage,
  string
> = {
  EN_US: 'en-US',
  JA_JP: 'ja-JP',
  KO_KR: 'ko-KR',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
};
export const convertSourceLanguageToBookingWidgetUrlLangCode = (
  lang: SourceLanguage
): string => {
  return sourceLanguageToBookingWidgetUrlLangCodeMap[lang] || 'ja-JP';
};

const SourceLanguageToLowerCaseMap: Record<string, string> = {
  'en-US': 'en_us',
  'ja-JP': 'ja_jp',
  'ko-KR': 'ko_kr',
  'zh-CN': 'zh_cn',
  'zh-TW': 'zh_tw',
};
export const convertSourceLanguageToLowerCaseMap = (lang: string): string => {
  return SourceLanguageToLowerCaseMap[lang] || 'ja_jp';
};
