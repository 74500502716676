import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useContext } from 'react';

import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { AgentReferencesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/AgentReferencesEditor';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  name: string;
  language: string;
  currency: string;
  showCustom?: boolean;
  showNet?: boolean;
  showPerGroup?: boolean;
};
export const PerParticipantPriceInput = ({
  currency,
  language,
  name,
  showCustom,
  showNet,
}: Props) => {
  const { t } = useTranslation();
  const translationTargetLanguage = useContext(TranslationLanguageContext);
  const builtInUnits = [
    t('Adult', {
      lng: language,
    }),
    t('Child', {
      lng: language,
    }),
    t('Adult/Child', {
      lng: language,
    }),
    t('Infant', {
      lng: language,
    }),
    t('Spectator', {
      lng: language,
    }),
  ];
  const unitOptions = [
    ...builtInUnits.map((unit) => ({
      value: unit,
      text: unit,
    })),
    ...(showCustom
      ? [
          {
            value: 'custom',
            text: t('Custom'),
          },
        ]
      : []),
  ];
  const translationTargetLanguageName =
    translationTargetLanguage &&
    getLanguageName(uppercaseIsoToLowercaseIso[translationTargetLanguage], t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  return (
    <div className={styles['p-box']}>
      <div className={styles['p-box__header']}>
        <p>{t('Per-Participant Prices')}</p>
      </div>
      <div className={styles['p-box__table3']}>
        <FieldArray name={name}>
          {({ fields }) => (
            <>
              {fields.map((fieldName, idx) => (
                <div key={fieldName} className={styles['p-box__table3__item']}>
                  <div className={styles['p-box__table3__item__card']}>
                    <div>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                        shouldHide={idx == 0}
                        style={{ marginLeft: 'auto' }}
                      />
                    </div>
                    <ul className={styles['p-box__table3__item__body']}>
                      <li className={styles['p-box__table3__item__body__item']}>
                        <p
                          className={
                            styles['p-box__table3__item__body__item__ttl']
                          }
                        >
                          {t('Unit')}
                        </p>
                        <Field name={`${fieldName}.unit`}>
                          {({ input }) => (
                            <SingleDropdown
                              options={unitOptions.map((option) => ({
                                value: option.value,
                                text: option.text,
                              }))}
                              selectedOption={
                                builtInUnits.includes(
                                  t(input.value, {
                                    lng: language,
                                  })
                                )
                                  ? t(input.value, {
                                      lng: language,
                                    })
                                  : 'custom'
                              }
                              onChange={(value) => {
                                if (value === 'custom') {
                                  input.onChange('');
                                } else {
                                  input.onChange(value);
                                }
                              }}
                            />
                          )}
                        </Field>
                      </li>
                      {showCustom && (
                        <li
                          className={styles['p-box__table3__item__body__item']}
                        >
                          <p
                            className={
                              styles['p-box__table3__item__body__item__ttl']
                            }
                          >
                            {t('Displayed As')}
                          </p>
                          <TranslatedField name={`${fieldName}.unit`}>
                            {({ input, translationInput }) => (
                              <>
                                <TextField {...input} />
                                {translationTargetLanguage != null &&
                                  !builtInUnits.includes(input.value) && (
                                    <TextField
                                      {...translationInput}
                                      placeholder={
                                        translationTargetLanguageName ?? ''
                                      }
                                    />
                                  )}
                              </>
                            )}
                          </TranslatedField>
                        </li>
                      )}
                      <li className={styles['p-box__table3__item__body__item']}>
                        <p
                          className={
                            styles['p-box__table3__item__body__item__ttl']
                          }
                        >
                          {t('Age (optional)')}
                        </p>
                        <div
                          className={
                            styles['p-box__table3__item__body__item__flex']
                          }
                        >
                          <Field
                            parse={(value) => parseInt(value, 10)}
                            format={(value) => `${value}`}
                            name={`${fieldName}.ageBandMinimum`}
                          >
                            {({ input }) => (
                              <SingleDropdown
                                options={_.times(100).map((opt) => ({
                                  value: opt.toString(),
                                  text: opt.toString(),
                                }))}
                                selectedOption={input.value}
                                onChange={(value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                          <span>-</span>
                          <Field
                            parse={(value) => parseInt(value, 10)}
                            format={(value) => `${value}`}
                            name={`${fieldName}.ageBandMaximum`}
                          >
                            {({ input }) => (
                              <SingleDropdown
                                options={_.times(100)
                                  .map((opt) => ({
                                    value: (opt + 1).toString(),
                                    text: (opt + 1).toString(),
                                  }))
                                  .concat({
                                    value: '0',
                                    text: t('No Limit'),
                                  })}
                                selectedOption={input.value}
                                onChange={(value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </li>
                    </ul>
                    <ul className={styles['p-box__table3__item__body']}>
                      <li className={styles['p-box__table3__item__body__item']}>
                        <p
                          className={
                            styles['p-box__table3__item__body__item__ttl']
                          }
                        >
                          {t('Amount (tax included {{currency}})', {
                            currency: currency,
                          })}
                        </p>
                        <Field name={`${fieldName}.price`}>
                          {({ input }) => (
                            <TextField
                              value={input.value}
                              onChange={(value) => {
                                if (!currencyInputAllowed(currency, value)) {
                                  return;
                                }
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </li>
                      {showNet && (
                        <li
                          className={styles['p-box__table3__item__body__item']}
                        >
                          <p
                            className={
                              styles['p-box__table3__item__body__item__ttl']
                            }
                          >
                            {t('Net Amount ({{currency}})', {
                              currency: currency,
                            })}
                          </p>
                          <Field name={`${fieldName}.netPrice`}>
                            {({ input }) => (
                              <TextField
                                value={input.value}
                                onChange={(value) => {
                                  console.log('####', value);
                                  if (!currencyInputAllowed(currency, value)) {
                                    return;
                                  }

                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                        </li>
                      )}
                    </ul>
                    {isNutmegAdmin && (
                      <>
                        <ul className={styles['p-box__table3__item__body']}>
                          <li
                            className={
                              styles['p-box__table3__item__body__item']
                            }
                          >
                            <p
                              className={
                                styles['p-box__table3__item__body__item__ttl']
                              }
                            >
                              {t('Agent References')}
                            </p>
                            <AgentReferencesEditor
                              name={`${fieldName}.referencesInAgentSystems`}
                            />
                          </li>
                        </ul>
                        <ul className={styles['p-box__table3__item__body']}>
                          <li
                            className={
                              styles['p-box__table3__item__body__item']
                            }
                          >
                            <p
                              className={
                                styles['p-box__table3__item__body__item__ttl']
                              }
                            >
                              {t('Third Party Reference')}
                            </p>
                            <Field
                              name={`${fieldName}.referenceInSupplierSystem`}
                            >
                              {({ input }) => <TextField {...input} />}
                            </Field>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <a
                className={styles['p-box__table__add']}
                onClick={() => {
                  if (fields.length && fields.length > 0) {
                    (fields as any).insertAt(fields.length + 1, {
                      method: 'PER_PARTICIPANT',
                      unit: t('Adult', {
                        lng: language,
                      }),
                      groupUnits: [],
                      ageBandMinimum: 13,
                      ageBandMaximum: 0,
                      price: '100',
                      packageComponentUnitMappings: [],
                    });
                  }
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Unit')}
              </a>
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};
