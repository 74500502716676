import { useTranslation } from 'react-i18next';

import styles from './StartTimeListItem.module.css';

interface Props {
  startTime: string;
  productStartTimes: string[];
}

export const StartTimeListItem = ({ startTime, productStartTimes }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles['p-seat__equipment-schedule-list__item']}>
        <div className={styles['p-seat__equipment-schedule-list__start-time']}>
          <span
            className={
              styles['p-seat__equipment-schedule-list__start-time__label']
            }
          >
            {startTime}
          </span>
        </div>{' '}
        <div>
          {t('Product start time')}:{productStartTimes.join(',')}
        </div>
      </div>
    </div>
  );
};
