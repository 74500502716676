import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  confirmSystemFeeInvoice,
  fetchSystemFeeInvoices,
} from 'client/actions/systemFeeInvoices';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { Box } from 'client/components/Box/Box';
import { Tabs } from 'client/components/Tabs/Tabs';
import { Button } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { InfoTooltipWithPortal } from 'client/components/InfoTooltipWithPortal/InfoTooltipWithPortal';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import baseStyles from 'client/base.module.css';
import { SystemFeeInvoiceSummary } from 'shared/models/swagger';
import { isPartnershipSupplier } from 'client/libraries/util/partnership';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { fetchPartnershipSystemFeeInvoices } from 'client/actions/partnershipSystemFeeInvoices';
import {
  AgentGrossColumn,
  AgentCancelFeeColumn,
  AgentsCommitionCell,
} from 'client/components/PartnershipSystemFee/PartnershipSystemFee';

import styles from './SystemFeeReservations.module.css';

interface ConfirmCellProps {
  invoice: SystemFeeInvoiceSummary;
}

const ConfirmCell = ({ invoice }: ConfirmCellProps) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const loading = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.loading
  );
  const monthYear = moment(invoice.month)
    .locale(locale)
    .format(monthYearFormat);
  const activeUser = useSelector(activeUserSelector);

  if (invoice.status === 'SYSTEM_FEE_READY_FOR_CONFIRMATION') {
    return (
      <>
        <Button
          width={100}
          style="yellow"
          size="small"
          onClick={() => setShowConfirmModal(true)}
          disabled={
            !hasCustomUserRoleWritePermissions(
              activeUser,
              'SYSTEM_FEE.WEB_BOOKINGS'
            )
          }
        >
          {t('Confirmation Required')}
        </Button>
        {showConfirmModal && (
          <MessageModal
            loading={loading}
            insertRoot
            open={true}
            onClose={() => setShowConfirmModal(false)}
            title={t('Confirm booking fee')}
            message={
              invoice.amount_due_by_nutmeg_final
                ? t(
                    'Nutmeg will make a transfer of {{billingAmount}} to your registered bank account. Please click OK to confirm the booking fee.',
                    {
                      billingAmount: formattedCurrencyAmount(
                        invoice.amount_due_by_nutmeg_final
                      ),
                      month: monthYear,
                    }
                  )
                : t(
                    'Nutmeg will send you an invoice of {{billingAmount}} to be paid by credit card for the reservations fee of {{month}}. Please click OK to confirm the fee.',
                    {
                      billingAmount: formattedCurrencyAmount(
                        invoice.amount_due_by_supplier_final ?? ''
                      ),
                      month: monthYear,
                    }
                  )
            }
            onSubmit={async () => {
              await dispatch(confirmSystemFeeInvoice(invoice.id ?? ''));
              setShowConfirmModal(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <a
      className={clsx(
        baseStyles['base-btn'],
        baseStyles['small'],
        baseStyles['gray']
      )}
      style={{
        width: '100px',
        padding: '2px',
        textAlign: 'center',
        height: 'fit-content',
      }}
      href={invoice.pdf_download_url}
      download
    >
      {t('Download')}
    </a>
  );
};

export type ColumnType<T> = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
};

const useColumns = (
  includeConfirmColumn: boolean
): ColumnType<SystemFeeInvoiceSummary>[] => {
  const { t } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const showAsPartnership =
    config.enablePartnership &&
    isPartnershipSupplier(org) &&
    (!config.enablePartnershipSystemFeeInvoiceTable || partnershipMode);

  return [
    {
      Header: t('Invoice Month'),
      th: true,
      accessor: (row) =>
        moment(row.month).locale(locale).format(monthYearFormat),
      textAlign: 'center',
    },
    ...(includeConfirmColumn
      ? [
          {
            Header: t('Invoice'),
            width: 'middle' as ColumnType<SystemFeeInvoiceSummary>['width'],
            Cell: (cellInfo: { original: SystemFeeInvoiceSummary }) => {
              return (
                <Box width="100%" display="flex" justifyContent="center">
                  <ConfirmCell invoice={cellInfo.original} />
                </Box>
              );
            },
            textAlign:
              'center' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
          },
        ]
      : []),
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Gross')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_gross_amount),
      textAlign: 'right',
    },
    ...(showAsPartnership
      ? ([
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross by agent')}
                </Box>
              </Box>
            ),
            Cell: (cellInfo: { original: SystemFeeInvoiceSummary }) => {
              return (
                <Box width="100%">
                  <AgentGrossColumn invoice={cellInfo.original} />
                </Box>
              );
            },
            width: 'long' as ColumnType<SystemFeeInvoiceSummary>['width'],
            textAlign:
              'left' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
          },
        ] as ColumnType<SystemFeeInvoiceSummary>[])
      : ([] as ColumnType<SystemFeeInvoiceSummary>[])),
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Cancel Fee')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_cancellation_fee_amount),
      textAlign: 'right',
    },
    ...(showAsPartnership
      ? ([
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee by agent')}
                </Box>
              </Box>
            ),
            Cell: (cellInfo: { original: SystemFeeInvoiceSummary }) => {
              return (
                <Box width="100%">
                  <AgentCancelFeeColumn invoice={cellInfo.original} />
                </Box>
              );
            },
            width: 'long' as ColumnType<SystemFeeInvoiceSummary>['width'],
            textAlign:
              'left' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
          },
        ] as ColumnType<SystemFeeInvoiceSummary>[])
      : ([] as ColumnType<SystemFeeInvoiceSummary>[])),
    ...(showAsPartnership
      ? ([] as ColumnType<SystemFeeInvoiceSummary>[])
      : ([
          {
            Header: '',
            HeaderElement: (
              <Box display="flex" alignItems="center">
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('PIF')}
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('Booking Fee')}
                  </Box>
                </Box>
                <InfoTooltipWithPortal
                  tooltipStyle={{
                    width: '210px',
                  }}
                  text={
                    <Box>
                      <div>
                        {t('PIF Booking Fee is total of the following:')}
                      </div>
                      <div>{t(' - PIF Gross * booking fee rate')}</div>
                      <div>{t(' - PIF Cancel Fee * booking fee rate')}</div>
                    </Box>
                  }
                />
              </Box>
            ),
            accessor: (row) => getFormattedAmount(row.pif_booking_fee_amount),
            textAlign: 'right',
          },
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Credit Card Fee')}
                </Box>
              </Box>
            ),
            accessor: (row) =>
              getFormattedAmount(row.pif_credit_card_fee_amount),
            textAlign: 'right',
          },
        ] as ColumnType<SystemFeeInvoiceSummary>[])),
    ...(showAsPartnership
      ? ([
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Commission')}
                </Box>
              </Box>
            ),
            accessor: (row: SystemFeeInvoiceSummary) =>
              getFormattedAmount(row.pif_commission_amount),
            textAlign:
              'right' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
          },
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Commission by agent')}
                </Box>
              </Box>
            ),
            Cell: (cellInfo: { original: SystemFeeInvoiceSummary }) => {
              return (
                <Box width="100%">
                  <AgentsCommitionCell invoice={cellInfo.original} />
                </Box>
              );
            },
            width: 'long' as ColumnType<SystemFeeInvoiceSummary>['width'],
            textAlign:
              'left' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
          },
        ] as ColumnType<SystemFeeInvoiceSummary>[])
      : ([] as ColumnType<SystemFeeInvoiceSummary>[])),
    ...(showAsPartnership
      ? ([] as ColumnType<SystemFeeInvoiceSummary>[])
      : ([
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('POB')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross')}
                </Box>
              </Box>
            ),
            accessor: (row) => getFormattedAmount(row.pob_gross_amount),
            textAlign: 'right',
          },
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('POB')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee')}
                </Box>
              </Box>
            ),
            accessor: (row) =>
              getFormattedAmount(row.pob_cancellation_fee_amount),
            textAlign: 'right',
          },
          {
            Header: '',
            HeaderElement: (
              <Box display="flex" alignItems="center">
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('POB')}
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('Booking Fee')}
                  </Box>
                </Box>
                <Box ml={1}>
                  <InfoTooltipWithPortal
                    tooltipStyle={{
                      width: '210px',
                    }}
                    text={
                      <Box>
                        <div>
                          {t('POB Booking Fee is total of the following:')}
                        </div>
                        <div>{t(' - POB Gross * booking fee rate')}</div>
                        <div>{t(' - POB Cancel Fee * booking fee rate')}</div>
                      </Box>
                    }
                  />
                </Box>
              </Box>
            ),
            accessor: (row) => getFormattedAmount(row.pob_booking_fee_amount),
            textAlign: 'right',
          },
          {
            Header: t('Adjusted Amount'),
            accessor: (row) => getFormattedAmount(row.adjusted_amount),
            textAlign: 'right',
          },
        ] as ColumnType<SystemFeeInvoiceSummary>[])),
    {
      Header: t('Billing Amount'),
      Cell: (cellInfo) => {
        if (cellInfo.original.amount_due_by_nutmeg_final) {
          return getFormattedAmount(
            cellInfo.original.amount_due_by_nutmeg_final
          );
        } else {
          return (
            <div className={styles['red']}>
              {getFormattedAmount(
                cellInfo.original.amount_due_by_supplier_final
              )}
            </div>
          );
        }
      },
      textAlign: 'right',
    },
    {
      Header: t('Due By'),
      Cell: (cellInfo) => {
        if (cellInfo.original.amount_due_by_nutmeg_final) {
          return 'Nutmeg';
        } else if (cellInfo.original.amount_due_by_supplier_final) {
          return <div className={styles['red']}>{org?.name ?? ''}</div>;
        }

        return '-';
      },
      textAlign: 'center',
    },
    {
      Header: t('Reservations'),
      Cell: (cellInfo) => {
        const url = partnershipMode
          ? `/partnership/suppliersystemfeeinvoices/${cellInfo.original.id}`
          : `/systemfee/reservations/${cellInfo.original.id}`;

        return <Link to={url}>{t('List')}</Link>;
      },
      textAlign: 'center',
    },
  ];
};

export const SystemFeeReservations = () => {
  const { partnershipMode } = React.useContext(PartnershipModeContext);
  if (partnershipMode) {
    return <PartnershipSystemFeeReservations />;
  }
  return <NormalSystemFeeReservations />;
};

const NormalSystemFeeReservations = () => {
  const dispatch = useDispatch();
  const systemFeeInvoices = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.summaries
  );
  const org = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (org) {
      dispatch(fetchSystemFeeInvoices());
      dispatch(fetchContractedOrganizations());
    }
  }, [org]);

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="SYSTEM_FEE_WEB_BOOKINGS" />
      )}
      <Content
        systemFeeInvoices={systemFeeInvoices}
        includeConfirmColumnForPastInvoices={true}
      />
    </>
  );
};

const PartnershipSystemFeeReservations = () => {
  const dispatch = useDispatch();
  const systemFeeInvoices = useSelector(
    (state: ReduxState) => state.partnershipSystemFeeInvoices.summaries
  );
  const org = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (org) {
      dispatch(fetchPartnershipSystemFeeInvoices());
      dispatch(fetchContractedOrganizations());
    }
  }, [org]);

  const pastCondition = (invoice: SystemFeeInvoiceSummary) => {
    return Boolean(
      invoice.month &&
        moment(invoice.month).isBefore(moment().format('YYYY-MM'))
    );
  };

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="SYSTEM_FEE_WEB_BOOKINGS" />
      )}
      <Content
        systemFeeInvoices={systemFeeInvoices}
        includeConfirmColumnForPastInvoices={false}
        pastCondition={pastCondition}
        futureCondition={(invoice) => !pastCondition(invoice)}
      />
    </>
  );
};

interface ContentProps {
  systemFeeInvoices: SystemFeeInvoiceSummary[];
  includeConfirmColumnForPastInvoices: boolean;
  pastCondition?: (invoice: SystemFeeInvoiceSummary) => boolean;
  futureCondition?: (invoice: SystemFeeInvoiceSummary) => boolean;
}

const Content = ({
  systemFeeInvoices,
  includeConfirmColumnForPastInvoices,
  pastCondition,
  futureCondition,
}: ContentProps) => {
  const { t } = useTranslation();

  const currency = useSelector(defaultProductCurrencySelector);

  const pastColumns = useColumns(includeConfirmColumnForPastInvoices);
  const futureColumns = useColumns(false);

  const panes = React.useMemo(() => {
    const pastInvoices = _.orderBy(
      systemFeeInvoices.filter((invoice) => {
        if (pastCondition) {
          return pastCondition(invoice);
        }
        return (
          invoice.status === 'SYSTEM_FEE_READY_FOR_CONFIRMATION' ||
          invoice.status === 'SYSTEM_FEE_CONFIRMED'
        );
      }),
      'month',
      ['desc']
    );
    const futureInvoices = _.orderBy(
      systemFeeInvoices.filter((invoice) => {
        if (futureCondition) {
          return futureCondition(invoice);
        }
        return invoice.status === 'SYSTEM_FEE_PENDING';
      }),
      'month',
      ['asc']
    );
    return [
      {
        header: t('Monthly Fees'),
        content: () =>
          pastInvoices.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No System Fee Invoices Found')}
            </div>
          ) : (
            <CustomTable items={pastInvoices} columns={pastColumns} />
          ),
      },
      {
        header: t('Upcoming'),
        content: () =>
          futureInvoices.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No System Fee Invoices Found')}
            </div>
          ) : (
            <CustomTable items={futureInvoices} columns={futureColumns} />
          ),
      },
    ];
  }, [t, systemFeeInvoices, pastColumns, futureColumns]);

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>
      <Tabs panes={panes} />
    </>
  );
};
