import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-focus';

import baseStyles from 'client/base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { Loading } from 'client/pages/Loading';
import { ReduxState } from 'client/reducers';
import {
  fetchSupplierSettings,
  putSupplierSettings,
} from 'client/actions/supplierSettings';
import { Toggle } from 'client/components/v3/Form/Toggle';

import { FormValues } from './FormValues';
import styles from './ChatbotSettings.module.css';

const focusOnError: any = createDecorator();

export const ChatbotSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const chatSettings = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.chat
  );
  const loading = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.loading
  );

  React.useEffect(() => {
    dispatch(fetchSupplierSettings('CHAT'));
  }, []);

  const initialValues: FormValues = React.useMemo(() => {
    return {
      enableChatbotInternal:
        chatSettings?.enable_chatbot_internal?.value ?? false,
    };
  }, [chatSettings]);

  return (
    <section className={styles['p-section']}>
      <Form<FormValues>
        onSubmit={async (values: FormValues) => {
          try {
            await dispatch(
              putSupplierSettings('CHAT', {
                chat_settings: {
                  enable_chatbot_internal: {
                    value: values.enableChatbotInternal,
                    do_not_omit_empty: true,
                  },
                },
              })
            );
          } catch (e) {
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        initialValues={initialValues}
        decorators={[focusOnError]}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles['p-chatbotInternal']}>
              <Field name="enableChatbotInternal">
                {({ input }: FieldRenderProps<boolean>) => {
                  return (
                    <div className={styles['item']}>
                      <Toggle
                        checked={input.value}
                        onChange={() => input.onChange(!input.value)}
                      />
                      <p>{t('Use Chatbot Internal')}</p>
                    </div>
                  );
                }}
              </Field>
            </div>
            <div className={baseStyles['base-main__box__body__bottomBtns']}>
              <Button
                type="submit"
                color="primary"
                text={t('Save Settings')}
                loading={submitting}
              />
            </div>
          </form>
        )}
      </Form>
      {loading && <Loading />}
    </section>
  );
};
