import clsx from 'clsx';

import styles from './InternalChat.module.css';

type TypographyProps = {
  children?: React.ReactNode;
  className?: string;
  dangerouslySetInnerHTML?: { __html: string };
};

export const Typography: React.FC<TypographyProps> = ({
  children,
  className,
  dangerouslySetInnerHTML,
}) => {
  return (
    <p
      className={clsx(styles['typography'], className)}
      {...(dangerouslySetInnerHTML ? { dangerouslySetInnerHTML } : {})}
    >
      {children}
    </p>
  );
};
