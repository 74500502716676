import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { cancelSubscription } from 'client/actions/subscriptions';

interface Props {
  onClose: () => void;
  subscriptionId: string;
}

export const SubscriptionCancelModal = ({ onClose, subscriptionId }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <Modal
      onClose={onClose}
      open={true}
      title={t('Cancel Manual Subscription')}
      rightActionChildren={
        <>
          <Button
            text={t('No')}
            uiType="bg"
            size="md"
            color="white"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            type="submit"
            text={t('Yes')}
            uiType="bg"
            size="md"
            color="primary"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await dispatch(cancelSubscription(subscriptionId));
              setLoading(false);
              onClose();
            }}
          />
        </>
      }
      style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
    >
      <div>{t('Are you sure you want to cancel manual subscription?')}</div>
    </Modal>
  );
};
