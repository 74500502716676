import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import type { LocationType } from 'client/components/NewProductEditor/DetailsStep/CheckinPickupLocationsEditor/FormValues';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';

type CheckinPickupLocationInputProps = {
  name: string;
  type: LocationType;
};

const CheckinPickupLocationInput = ({
  name,
  type,
}: CheckinPickupLocationInputProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguage } = useTranslationTargetLanguage(t);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <>
      <CollapsibleBox title={t('Location')}>
        <ul className={styles['p-list']}>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
            </div>
            <div className={styles['p-list__item__body']}>
              <Field
                name={`${name}.location`}
                validate={(value: any | null | undefined): string => {
                  if (!value?.locationName) return t('Required');
                  return '';
                }}
              >
                {({ input, meta: { touched, error } }) => (
                  <LocationSearchInput
                    location={input.value.locationName}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onSearchChange={() => {}}
                    onLocationSelect={({ title, key }) =>
                      input.onChange({
                        locationName: title,
                        googlePlaceId: key,
                      })
                    }
                    error={touched && error}
                    maxWidth={800}
                  />
                )}
              </Field>
            </div>
          </li>
          {translationTargetLanguage && (
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <p>
                  {getLanguageName(
                    uppercaseIsoToLowercaseIso[translationTargetLanguage],
                    t
                  )}
                </p>
              </div>
              <div className={styles['p-list__item__body']}>
                <TranslatedField name={`${name}.location.locationName`}>
                  {({ translationInput }) => (
                    <TextField {...translationInput} />
                  )}
                </TranslatedField>
              </div>
            </li>
          )}
        </ul>
      </CollapsibleBox>
      <CollapsibleBox title={t('Description')}>
        <ul className={styles['p-list']}>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
            </div>
            <div className={styles['p-list__item__body']}>
              <TranslatedField name={`${name}.description`}>
                {({ input }) => (
                  <>
                    <TextArea
                      value={input.value}
                      onChange={input.onChange}
                      height={80}
                    />
                  </>
                )}
              </TranslatedField>
            </div>
          </li>
          {translationTargetLanguage && (
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <p>
                  {getLanguageName(
                    uppercaseIsoToLowercaseIso[translationTargetLanguage],
                    t
                  )}
                </p>
              </div>
              <div className={styles['p-list__item__body']}>
                <TranslatedField name={`${name}.description`}>
                  {({ translationInput }) => (
                    <TextArea
                      value={translationInput.value}
                      onChange={translationInput.onChange}
                      height={80}
                    />
                  )}
                </TranslatedField>
              </div>
            </li>
          )}
        </ul>
      </CollapsibleBox>
      {(type === 'CHECKIN_CHECKOUT' || type === 'CHECKIN_ONLY') && (
        <CollapsibleBox title={t('Description for Email/Voucher')}>
          <ul className={styles['p-list']}>
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
              </div>
              <div className={styles['p-list__item__body']}>
                <TranslatedField name={`${name}.emailBody`}>
                  {({ input }) => (
                    <>
                      <TextArea
                        value={input.value}
                        onChange={input.onChange}
                        height={80}
                      />
                    </>
                  )}
                </TranslatedField>
              </div>
            </li>
            {translationTargetLanguage && (
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <p>
                    {getLanguageName(
                      uppercaseIsoToLowercaseIso[translationTargetLanguage],
                      t
                    )}
                  </p>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TranslatedField name={`${name}.emailBody`}>
                    {({ translationInput }) => (
                      <TextArea
                        value={translationInput.value}
                        onChange={translationInput.onChange}
                        height={80}
                      />
                    )}
                  </TranslatedField>
                </div>
              </li>
            )}
          </ul>
        </CollapsibleBox>
      )}
    </>
  );
};

export const CheckinPickupLocationsEditor = () => {
  const { t } = useTranslation();
  const { translationTargetLanguage } = useTranslationTargetLanguage(t);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <>
      <CollapsibleBox title={t('Guest Email Pickup Instructions')}>
        <TranslatedField name="guestEmailPickupInstructions">
          {({ input, translationInput }) => (
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TextArea
                    value={input.value}
                    onChange={input.onChange}
                    height={80}
                  />
                </div>
              </li>
              {translationTargetLanguage && (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>
                      {getLanguageName(
                        uppercaseIsoToLowercaseIso[translationTargetLanguage],
                        t
                      )}
                    </p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <TextArea
                      value={translationInput.value}
                      onChange={translationInput.onChange}
                      height={80}
                    />
                  </div>
                </li>
              )}
            </ul>
          )}
        </TranslatedField>
      </CollapsibleBox>
      <CollapsibleBox title={t('Guest Email Checkin Instructions')}>
        <TranslatedField name="guestEmailCheckinInstructions">
          {({ input, translationInput }) => (
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TextArea
                    value={input.value}
                    onChange={input.onChange}
                    height={80}
                  />
                </div>
              </li>
              {translationTargetLanguage && (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>
                      {getLanguageName(
                        uppercaseIsoToLowercaseIso[translationTargetLanguage],
                        t
                      )}
                    </p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <TextArea
                      value={translationInput.value}
                      onChange={translationInput.onChange}
                      height={80}
                    />
                  </div>
                </li>
              )}
            </ul>
          )}
        </TranslatedField>
      </CollapsibleBox>

      <FieldArray name="checkinPickupLocations">
        {({ fields }) => (
          <>
            {fields.length !== 0 &&
              fields.map((name) => (
                <Field key={name} name={name}>
                  {({ input }) => (
                    <div className={styles['p-productsTranslation']}>
                      <p className={styles['p-productsTranslation__ttl']}>
                        {input.value.type === 'PICKUP_DROPOFF'
                          ? t('Pickup/Dropoff')
                          : input.value.type === 'PICKUP_ONLY'
                          ? t('Pickup')
                          : input.value.type === 'DROPOFF_ONLY'
                          ? t('Dropoff')
                          : input.value.type === 'CHECKIN_CHECKOUT'
                          ? t('Checkin/Checkout')
                          : input.value.type === 'CHECKIN_ONLY'
                          ? t('Checkin')
                          : t('Checkout')}
                      </p>

                      <CheckinPickupLocationInput
                        name={name}
                        type={input.value.type}
                      />
                    </div>
                  )}
                </Field>
              ))}
          </>
        )}
      </FieldArray>
    </>
  );
};
