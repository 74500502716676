import { createSelector } from 'reselect';
import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_RESERVATION_VISIBLE_COLUMNS,
  SET_RESERVATION_ROW_COUNT,
  SET_RESERVATION_CURRENT_PAGE,
  SET_LAST_EXECUTED_SEARCH_CONDITION,
  SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import { activeUserSelector } from 'client/reducers/user';
import {
  operationAllowed,
  presetSearchConditionsEnabledForAccount,
} from 'shared/models/access';
import type { SearchReservationsRequest } from 'client/libraries/util/searchReservations';
import type { ReduxState } from 'client/reducers';

// Selectors
export const reservationColumnCandidatesSelector = createSelector(
  activeUserSelector,
  (activeUser) => {
    const isSupplier = activeUser?.organization_type === 'SUPPLIER';
    const isAgent = activeUser?.organization_type !== 'SUPPLIER';
    return [
      'agent_reference',
      'booked_at',
      'updated_at',
      ...(isAgent ? ['agent_guest_last_updated_by_actor_name_only'] : []),
      ...(isSupplier ? ['supplier_last_updated_by_actor_name_only'] : []),
      'status',
      'guest_display_name',
      'product_name',
      'internal_product_name',
      'participates_at',
      'guest_description',
      'guest_count',
      'hotel',
      'pickup_checkin_time',
      'pickup_checkin_location',
      'payment_type',
      'payment_method',
      'transportation',
      'add_ons',
      ...(operationAllowed(activeUser, 'read', 'reservationBookingSource')
        ? ['booking_source']
        : []),
      'agent_notes',
      'supplier_notes',
      ...(activeUser?.organization_type !== 'SUPPLIER'
        ? ['supplier_name']
        : []),
      'supplier_reference',
      'supplier_short_reference',
      'supplier_product_reference',
      'id',
      ...(isSupplier ? ['uses_email_payment'] : []),
      ...(isSupplier ? ['promo_code'] : []),
      ...(presetSearchConditionsEnabledForAccount(activeUser)
        ? ['supplier_internal_notes']
        : []),
      ...(presetSearchConditionsEnabledForAccount(activeUser)
        ? ['supplier_internal_notes_for_dispatch']
        : []),
      ...(presetSearchConditionsEnabledForAccount(activeUser)
        ? ['reservation_language']
        : []),
      'landing_source',
    ];
  }
);

const visibleColumnsSelector = (state: ReduxState) =>
  state.reservationTableControls.visibleColumns;

export const reservationVisibleColumnsSelector = createSelector(
  visibleColumnsSelector,
  reservationColumnCandidatesSelector,
  (visibleColumns, candidateColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);
// Reducers
const defaultVisibleColumns = [
  'edit',
  'agent_reference',
  'booked_at',
  'updated_at',
  'agent_guest_last_updated_by_actor_name_only',
  'supplier_last_updated_by_actor_name_only',
  'status',
  'guest_display_name',
  'product_name',
  'internal_product_name',
  'participates_at',
  'guest_description',
  'guest_count',
  'hotel',
  'pickup_checkin_time',
  'pickup_checkin_location',
  'payment_type',
  'payment_method',
  'transportation',
  'add_ons',
  'booking_source',
  'agent_notes',
  'supplier_notes',
  'supplier_name',
  'supplier_reference',
  'supplier_short_reference',
  'supplier_product_reference',
  'id',
  'promo_code',
  'landing_source',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_RESERVATION_VISIBLE_COLUMNS:
      return action.visibleColumns;
    case SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS:
      return action.defaultVisibleColumns;
    default:
      return state;
  }
};

const defaultVisibleColumnsLoaded = (state = false, action: any) => {
  switch (action.type) {
    case SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS:
      return true;
    default:
      return state;
  }
};

const defaultSearchFilters: SearchReservationsRequest = {
  agentReference: '',
  agentIds: [],
  supplierReference: '',
  id: '',
  statuses: [],
  customerGivenName: '',
  customerFamilyName: '',
  bookingSourceTypes: [],
  supplierIds: [],
  productIds: [],
  bookedDateFrom: '',
  bookedDateTo: '',
  participationDateFrom: '',
  participationDateTo: '',
  lastUpdatedDateFrom: '',
  lastUpdatedDateTo: '',
  dateFilterPreset: 'LAST_UPDATED_7_DAYS',
};

const lastExecutedSearchCondition = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_SEARCH_CONDITION:
      return action.searchFilters;

    default:
      return state;
  }
};

const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_SEARCH_CONDITION:
    case SET_RESERVATION_ROW_COUNT:
      // Reset current page when the search query changes or when the reservation row
      // count changes.
      return 1;

    case SET_RESERVATION_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const rowCount = (state = 10, action: any) => {
  switch (action.type) {
    case SET_RESERVATION_ROW_COUNT:
      return action.rowCount;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  currentPage: number;
  rowCount: number;
  lastExecutedSearchCondition: SearchReservationsRequest;
  defaultVisibleColumnsLoaded: boolean;
};

const reducer = combineReducers<State>({
  visibleColumns,
  currentPage,
  rowCount,
  lastExecutedSearchCondition,
  defaultVisibleColumnsLoaded,
});
export const reservationTableControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
