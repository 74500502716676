import * as React from 'react';
import ColorPicker from 'rc-color-picker';
import clsx from 'clsx';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { ETicketRedemptionButtonPreview } from 'client/components/ETicketRedemptionButtonPreview/ETicketRedemptionButtonPreview';
import { ETicketStubRedemptionButtonPreview } from 'client/components/ETicketStubRedemptionButtonPreview/ETicketStubRedemptionButtonPreview';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { TextField } from 'client/components/v3/Form/TextField';
import { defaultRedemptionButtonColorSets } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';

import styles from '../../ETicketEdit.module.css';

export const RedemptionButton = () => {
  const { t } = useTranslation();

  const values = useFormState().values;
  const form = useForm();

  const colorOptions: { value: string; label: string }[] = React.useMemo(() => {
    const colorLabels: { [index: string]: string } = {
      blue: t('Blue'),
      orange: t('Orange'),
      red: t('Red'),
      pink: t('Pink'),
      gray: t('Gray'),
    };

    const defaultColorSets = defaultRedemptionButtonColorSets.map(
      (colorSet) => {
        return {
          key: colorSet.key,
          colorText: colorLabels[colorSet.key] || colorSet.key,
          backgroundColor: colorSet.backgroundColor,
          textColor: colorSet.textColor,
        };
      }
    );

    const colorOptions: { value: string; label: string }[] = [
      ...defaultColorSets.map((colorSet) => {
        return {
          value: colorSet.key,
          label: colorSet.colorText,
        };
      }),
      {
        value: 'customColor',
        label: t('Custom'),
      },
    ];

    return colorOptions;
  }, [t]);

  React.useEffect(() => {
    if (values && form) {
      const presetColor = defaultRedemptionButtonColorSets.find(
        (option) => option.key === values.presetRedemptionButtonColor
      );

      if (presetColor) {
        form.change('redemptionButtonColor', presetColor.backgroundColor);
        form.change('redemptionButtonTextColor', presetColor.textColor);
      }
    }
  }, [values, form]);

  return (
    <div className={styles['p-box__table3']}>
      <Box mr={2} width="100%">
        <div className={styles['p-box__table3__item__body__item']}>
          <p className={styles['p-box__table3__item__body__item__ttl']}>
            {t('Text on Button')}
          </p>
          <Field name="redemptionButtonText">
            {({ input }) => <TextField type="text" {...input} />}
          </Field>
        </div>
      </Box>
      <Box mr={2}>
        <div className={styles['p-box__table3__item__body__item']}>
          <p className={styles['p-box__table3__item__body__item__ttl']}>
            {t('Button Color')}
          </p>
          <EnumRadioButtonGroup
            name="presetRedemptionButtonColor"
            options={colorOptions}
          />
        </div>
      </Box>
      {values.presetRedemptionButtonColor === 'customColor' && (
        <Box mr={2}>
          <ul className={styles['p-bookingWidgetCustomize-products-list']}>
            <li
              className={styles['p-bookingWidgetCustomize-products-list__item']}
            >
              <p className={styles['p-box__table3__item__body__item__ttl']}>
                {t('Button Color')}
              </p>
              <Field name="redemptionButtonColor">
                {({ input }) => (
                  <ColorPicker
                    placement="bottomRight"
                    color={input.value}
                    onChange={({ color }: any) => input.onChange(color)}
                    className={styles['color_section']}
                  >
                    <a
                      className={clsx(
                        'react-colorpicker-trigger',
                        styles['p-bookingWidgetCustomize-products-list__color']
                      )}
                    >
                      <div
                        className={clsx(
                          styles[
                            'p-bookingWidgetCustomize-products-list__color-box'
                          ]
                        )}
                        style={{
                          backgroundColor: input.value,
                        }}
                      ></div>
                      <span>
                        <i></i>
                        {t('Change')}
                      </span>
                    </a>
                  </ColorPicker>
                )}
              </Field>
            </li>

            <li
              className={styles['p-bookingWidgetCustomize-products-list__item']}
            >
              <p className={styles['p-box__table3__item__body__item__ttl']}>
                {t('Button Text Color')}
              </p>
              <Field name="redemptionButtonTextColor">
                {({ input }) => (
                  <ColorPicker
                    placement="bottomRight"
                    color={input.value}
                    onChange={({ color }: any) => input.onChange(color)}
                    className={styles['color_section']}
                  >
                    <a
                      className={clsx(
                        'react-colorpicker-trigger',
                        styles['p-bookingWidgetCustomize-products-list__color']
                      )}
                    >
                      <div
                        className={clsx(
                          styles[
                            'p-bookingWidgetCustomize-products-list__color-box'
                          ]
                        )}
                        style={{
                          backgroundColor: input.value,
                        }}
                      ></div>
                      <span>
                        <i></i>
                        {t('Change')}
                      </span>
                    </a>
                  </ColorPicker>
                )}
              </Field>
            </li>
          </ul>
        </Box>
      )}
      <Box mr={2}>
        <ul className={styles['p-bookingWidgetCustomize-products-list']}>
          <li
            className={styles['p-bookingWidgetCustomize-products-list__item']}
          >
            <p className={styles['p-box__table3__item__body__item__ttl']}>
              {t('Normal')}
            </p>

            <ETicketRedemptionButtonPreview
              buttonText={values.redemptionButtonText || t('Redeem')}
              buttonColor={values.redemptionButtonColor}
              buttonTextColor={values.redemptionButtonTextColor}
            />
          </li>
          <li
            className={styles['p-bookingWidgetCustomize-products-list__item']}
          >
            <p className={styles['p-box__table3__item__body__item__ttl']}>
              {t('Ticket Usage')}
            </p>

            <ETicketStubRedemptionButtonPreview
              buttonText={values.redemptionButtonText || t('Redeem')}
              buttonColor={values.redemptionButtonColor}
              buttonTextColor={values.redemptionButtonTextColor}
            />
          </li>
        </ul>
      </Box>
    </div>
  );
};
