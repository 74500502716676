import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { ReservationRedemptionCountTableBody } from 'client/components/ReservationRedemptionCountTable/ReservationRedemptionCountTableBody';
import type {
  Reservation,
  Product,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';
import editIcon from 'client/images/ic_edit.svg';

import { EditFixedExpirationDateTimeModal } from '../ReservationCheckinTab/EditFixedExpirationDateTimeModal';

type Props = {
  reservation?: Reservation;
  product?: Product | ProductSummary;
};

export const ReservationRedemptionCountCheckinTab = ({
  reservation,
  product,
}: Props) => {
  const { t } = useTranslation();
  const [
    openFixedExpirationDateTimeModal,
    setOpenFixedExpirationDateTimeModal,
  ] = React.useState(false);

  const expirationDateTime =
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
    reservation?.checkin_info?.expiration_date_time_utc;

  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Ticket Redemptions')}
        </div>
      </div>
      {expirationDateTime && (
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <table className={clsx(baseStyles['base-table'])}>
            <tbody>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Expiration Date')}
                </th>
                <td>
                  <div
                    className={clsx(pageStyles['page-reservations__reference'])}
                  >
                    <div
                      className={clsx(
                        pageStyles['page-reservations__reference_text']
                      )}
                    >
                      {moment
                        .tz(
                          expirationDateTime,
                          reservation?.start_timezone || 'UTC'
                        )
                        .format('lll')}
                    </div>
                    <div
                      className={clsx(
                        pageStyles['page-reservations__reference_btn']
                      )}
                    >
                      <a
                        className={clsx(
                          baseStyles['base-btn icon'],
                          baseStyles['icon']
                        )}
                        onClick={() =>
                          setOpenFixedExpirationDateTimeModal(true)
                        }
                      >
                        <img src={editIcon} />
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {expirationDateTime && (
          <Box mb={2}>
            <div
              className={clsx(baseStyles['base-main__body__box__header__ttl'])}
            >
              {t('Redemptions History')}
            </div>
          </Box>
        )}
        <ReservationRedemptionCountTableBody
          reservation={reservation}
          product={product}
        />
      </div>
      {reservation && openFixedExpirationDateTimeModal && (
        <EditFixedExpirationDateTimeModal
          open={openFixedExpirationDateTimeModal}
          onClose={() => setOpenFixedExpirationDateTimeModal(false)}
          reservation={reservation}
        />
      )}
    </div>
  );
};
