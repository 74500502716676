import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import { config } from 'client/config';
import baseStyles from 'client/base.module.css';
import type { SourceLanguage } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { DraggableProductList } from 'client/pages/ProductDetails/ProductContentsHeader/DraggableProductList/DraggableProductList';
import { Modal } from 'client/components/Modal/Modal';
import { ReduxState } from 'client/reducers';
import { ToggleButton, Select, Input } from 'client/components/Form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { productOptionsSelector } from 'client/reducers/products';

import styles from './AnnualPassModeSettings.module.css';

interface Props {
  languageOptions: {
    value: string;
    text: string;
  }[];
  showTranslationMode: boolean;
  translationTargetLanguage: SourceLanguage | null;
  onTranslationModeChange: (arg0: boolean) => void;
  onTranslationTargetLanguageChange: (arg0: SourceLanguage | null) => void;
}

export const AnnualPassModeSettingsForm = ({
  languageOptions,
  showTranslationMode,
  translationTargetLanguage,
  onTranslationModeChange,
  onTranslationTargetLanguageChange,
}: Props) => {
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);

  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const values = useFormState().values;

  const filteredProductOptions = React.useMemo(() => {
    const annualPassProductIds =
      (productSummaries || [])
        .filter(
          (summary) => summary?.annual_pass_mode_settings?.is_enabled === true
        )
        .map((summary) => summary.id) || [];

    return productOptions.filter((option) =>
      annualPassProductIds.includes(option.value)
    );
  }, [productOptions, productSummaries]);
  return (
    <>
      <Field type="checkbox" name="isEnable">
        {({ input }) => (
          <Modal.Box>
            <ToggleButton
              {...input}
              label={t('Use annual pass mode for this product')}
            />
          </Modal.Box>
        )}
      </Field>
      {values.isEnable && (
        <Modal.Box>
          <Field name="continuingProductId">
            {({ input }) => (
              <Select
                label={t('Continuing product')}
                search
                options={[
                  {
                    text: t('No selected'),
                    value: '',
                  },
                  ...filteredProductOptions,
                ]}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
        </Modal.Box>
      )}
      {values.isEnable && (
        <Modal.Box>
          <Field name="recommendedProductIds">
            {({ input }) => (
              <DraggableProductList
                productIds={input.value}
                setProductIds={input.onChange}
                options={filteredProductOptions}
                label={t(
                  'The annual passport product offered as an upgrade to this item on product detail page.(drag-and-drop to reorder)'
                )}
              />
            )}
          </Field>
        </Modal.Box>
      )}
      {values.isEnable && config.enableMonthlyPass && (
        <Modal.Box>
          <Modal.Box column={'two'}>
            <div className={baseStyles['base-form-toggle']}>
              <label>
                <input
                  type="checkbox"
                  checked={showTranslationMode}
                  onChange={() => onTranslationModeChange(!showTranslationMode)}
                />
                <p></p>
              </label>
              {t('Translation mode')}
            </div>
            {showTranslationMode && (
              <Select
                width={176}
                options={languageOptions}
                onChange={(_, { value }: any) => {
                  onTranslationTargetLanguageChange(value);
                }}
                value={translationTargetLanguage as any}
              />
            )}
          </Modal.Box>
        </Modal.Box>
      )}
      {values.isEnable && config.enableMonthlyPass && (
        <Box mt={2}>
          <Box mb={2}>
            <div>{t('Title for annual pass')}</div>
          </Box>
          <Box mb={2}>
            <div className={styles['c-table-list']}>
              <table>
                <tbody
                  style={{
                    width: '100%',
                  }}
                >
                  {translationTargetLanguage && (
                    <TranslationTableHeader hideThTag={true} />
                  )}
                  <TranslatedField name={`title`}>
                    {({ input, translationInput }) => (
                      <tr>
                        <td>
                          <div className={baseStyles['base-flex']}>
                            <Input {...input} />
                          </div>
                        </td>
                        {translationTargetLanguage && (
                          <td>
                            <Input {...translationInput} />
                          </td>
                        )}
                      </tr>
                    )}
                  </TranslatedField>
                </tbody>
              </table>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};
