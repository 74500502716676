import * as React from 'react';
import clsx from 'clsx';

import { MessageProps } from './types';
import styles from './InternalChat.module.css';
import { Stack } from './Stack';
import { Typography } from './Typography';

export const Message = React.forwardRef<HTMLDivElement, MessageProps>(
  ({ text, isUser }, ref) => {
    return (
      <Stack
        ref={ref}
        direction="row"
        spacing={1}
        className={isUser ? styles.userMessage : styles.botMessage}
        justifyContent={isUser ? 'flex-end' : 'flex-start'}
        alignItems="flex-start"
      >
        <Typography
          className={clsx(
            styles.messageContent,
            isUser ? styles.userMessageContent : styles.botMessageContent
          )}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Stack>
    );
  }
);

Message.displayName = 'Message';
