import { useTranslation } from 'react-i18next';

import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';

import { ChatbotSettings } from './ChatbotSettings';

export const ChatbotInternal = () => {
  const { t } = useTranslation();

  return (
    <V3Page>
      <PageHeader
        title={t('Chatbot Internal')}
        hideNewUIToggle={true}
      ></PageHeader>
      <PageContent>
        <ChatbotSettings />
      </PageContent>
    </V3Page>
  );
};
