import { useState, useRef, useEffect, CSSProperties } from 'react';
import { createPortal } from 'react-dom';

import styles from './Tooltip.module.css';

interface PortalProps {
  children: React.ReactNode;
}

// Use portal to ensure that tooltip won't be hidden when parent element has overflow:hidden property
const Portal = ({ children }: PortalProps) => {
  const elementRef = useRef(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(elementRef.current);

    return () => {
      document.body.removeChild(elementRef.current);
    };
  }, []);

  return createPortal(children, elementRef.current);
};

interface Props {
  text: string | React.ReactNode;
  width?: number; // Width of tooltip (px)
  spacing?: number; // Spacing between the tooltip and the element it is triggered by (px)
  children: JSX.Element;
  mobileStyle?: Record<string, any>; // Mobile style allows customizing style for mobile to allow fine-grained control of individual tooltip positioning
  shouldShow?: boolean; // Use when we want to hide the tooltip, eg. when the element visibility changes according to status/conditionals
  positionLeftOfTriggeringElement?: boolean; // This will show tooltip to the left of the triggering element, useful when the element in question is located in right side of screen
}

export const Tooltip = ({
  text,
  width = 240,
  spacing = 8,
  children,
  mobileStyle,
  shouldShow = true,
  positionLeftOfTriggeringElement = false,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [style, setStyle] = useState<CSSProperties>({});
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const showTooltip = () => {
    if (triggerRef.current) {
      const dimensions = triggerRef.current.getBoundingClientRect();

      // Center the tooltip horizontally relative to the triggering element
      let left = positionLeftOfTriggeringElement
        ? dimensions.right - width
        : dimensions.left + dimensions.width / 2 - width / 2;

      // Adjust left to keep tooltip within the viewport
      if (left + width > window.innerWidth) {
        left = window.innerWidth - width - spacing;
      }
      if (left < spacing) {
        left = spacing;
      }
      const newStyle: CSSProperties = {
        width,
        left,
      };

      // Check if the triggering element is in the top half of the page
      // and position the tooltip accordingly
      if (dimensions.top < window.innerHeight / 2) {
        newStyle.top = dimensions.top + dimensions.height + spacing;
        // Adjust top to keep tooltip within the viewport
        if (newStyle.top + spacing > window.innerHeight) {
          newStyle.top = window.innerHeight - spacing;
        }
      } else {
        newStyle.bottom = window.innerHeight - dimensions.top + spacing;
        // Adjust bottom to keep tooltip within the viewport
        if (newStyle.bottom + spacing > window.innerHeight) {
          newStyle.bottom = window.innerHeight - spacing;
        }
      }
      const mql = window.matchMedia('(max-width: 768px)');
      const customStyle = {
        ...newStyle,
        ...(mql.matches ? mobileStyle : null),
      };

      setStyle(customStyle);
      setVisible(true);
    }
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
      ref={triggerRef}
      className={styles['c-tooltip']}
    >
      {children}

      {visible && shouldShow && (
        <Portal>
          <div className={styles['c-tooltip__body']} style={style}>
            {text}
          </div>
        </Portal>
      )}
    </div>
  );
};
