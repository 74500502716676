import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { currency } from 'shared/libraries/currency';
import {
  CustomTableColumn,
  TableSmall,
} from 'client/components/v3/Table/TableSmall';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './WidgetRankingGadget.module.css';

interface WidgetSummary {
  name: string;
  abTestGroup: string;
  base: {
    sales: number;
    conversions: number;
    clicks: number;
    closes: number;
    conversionRate: number;
    impressions: number;
    clickRate: number;
    targets: number;
  };
  comparison?: {
    sales: number;
    conversions: number;
    clicks: number;
    closes: number;
    conversionRate: number;
    impressions: number;
    clickRate: number;
    targets: number;
  };
}

export const WidgetRankingGadget = () => {
  const { t } = useTranslation();

  const widgets = useSelector(
    (state: ReduxState) => state.siteControlWidgets.all
  );

  const org = useSelector(activeUserOrganizationSelector);

  const reportData = useSelector(
    (state: ReduxState) => state.siteControlWidgets.reportData
  );
  const loading = useSelector(
    (state: ReduxState) => state.siteControlWidgets.reportDataLoading
  );

  const data = React.useMemo(() => {
    if (reportData.length === 0 || widgets.length === 0) {
      return [];
    }

    const baseItemsByCampaign = reportData?.length
      ? reportData[0].items_by_widget
      : [];
    const comparisonItemsByCampaign =
      reportData?.length > 1 ? reportData[1].items_by_widget : [];

    const data = _.orderBy(
      widgets.map((widget) => {
        const baseItem = baseItemsByCampaign?.find(
          (item) => item.widget_id === widget.id
        );
        const comparisonItem = comparisonItemsByCampaign?.find(
          (item) => item.widget_id === widget.id
        );

        const baseGrossSales = baseItem?.gross_sales
          ? currency(baseItem?.gross_sales)?.value
          : 0;
        const baseConversions = baseItem?.conversion_count ?? 0;
        const baseCloses = baseItem?.closed_count ?? 0;
        const baseClicks = baseItem?.clicked_count ?? 0;
        const baseImpressions = baseItem?.impression_count ?? 0;
        const baseTargets = baseItem?.targeted_count ?? 0;
        const baseConversionRate =
          baseImpressions > 0 ? (100 * baseConversions) / baseImpressions : 0;
        const baseClickRate =
          baseImpressions > 0 ? (100 * baseClicks) / baseImpressions : 0;

        const comparisonGrossSales = comparisonItem?.gross_sales
          ? currency(comparisonItem?.gross_sales)?.value
          : 0;
        const comparisonConversions = comparisonItem?.conversion_count ?? 0;
        const comparisonCloses = comparisonItem?.closed_count ?? 0;
        const comparisonClicks = comparisonItem?.clicked_count ?? 0;
        const comparisonImpressions = comparisonItem?.impression_count ?? 0;
        const comparisonTargets = comparisonItem?.targeted_count ?? 0;
        const comparisonConversionRate =
          comparisonImpressions > 0
            ? (100 * comparisonConversions) / comparisonImpressions
            : 0;
        const comparisonClickRate =
          comparisonImpressions > 0
            ? (100 * comparisonClicks) / comparisonImpressions
            : 0;

        return {
          name: widget.name ?? '',
          abTestGroup: widget.ab_test_group?.value ?? '',
          base: {
            sales: baseGrossSales,
            conversions: baseConversions,
            clicks: baseClicks,
            closes: baseCloses,
            conversionRate: baseConversionRate,
            impressions: baseImpressions,
            clickRate: baseClickRate,
            targets: baseTargets,
          },
          comparison: comparisonItem
            ? {
                sales: comparisonGrossSales,
                conversions: comparisonConversions,
                clicks: comparisonClicks,
                closes: comparisonCloses,
                conversionRate: comparisonConversionRate,
                impressions: comparisonImpressions,
                clickRate: comparisonClickRate,
                targets: comparisonTargets,
              }
            : undefined,
        };
      }),
      ['base.sales'],
      ['desc']
    );
    return data;
  }, [widgets, reportData]);

  const columns: CustomTableColumn<WidgetSummary>[] = React.useMemo(() => {
    return [
      {
        Header: t('Widget'),
        accessor: (widget) => (
          <div style={{ whiteSpace: 'pre-line' }}>{widget.name}</div>
        ),
        width: '176',
      },
      {
        Header: t('Targets'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <>
              <div className={styles['cell-base']}>{widget.base.targets}</div>
              {widget.comparison && (
                <div className={styles['cell-comparison']}>
                  {widget.comparison.targets}
                </div>
              )}
            </>
          </div>
        ),
      },
      {
        Header: t('Impressions'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.impressions.toLocaleString()}
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.impressions.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Closes'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.closes.toLocaleString()}
            </div>

            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.closes.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Clicks'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.clicks.toLocaleString()}
            </div>

            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.clicks.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Click Rate'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.clickRate.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              %
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.clickRate.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Bookings'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.conversions.toLocaleString()}
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.conversions.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Conversion Rate'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.conversionRate.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              %
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.conversionRate.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Sales'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {currency(widget.base.sales, org?.default_currency)
                .format()
                .substring(3)}
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {currency(widget.comparison.sales).format().substring(3)}
              </div>
            )}
          </div>
        ),
      },
    ];
  }, [t]);

  if (loading) {
    return (
      <div className={styles['container']}>
        <div className={styles['container-inner']}>
          <div className={styles['header']}>
            <div className={styles['header-inner']}>
              <div>{t('Widget Ranking')}</div>
            </div>
          </div>
          <div className={styles['loading']}>
            <Loading size="md" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Widget Ranking')}</div>
          </div>
        </div>
        <div className={styles['body']}>
          <TableSmall items={data} columns={columns} usePaging />
        </div>
      </div>
    </div>
  );
};
