import { combineReducers } from 'redux';

import {
  SET_IMPERSONATED_USER_ID,
  SET_ALTERNATE_ORGANIZATION_IS_ACTIVE,
  SET_LANGUAGE,
} from 'client/constants/ActionTypes';
import { i18n, current_language, languageOptions } from 'client/libraries/i18n';
import { accounts } from 'client/reducers/accounts';
import { availability } from 'client/reducers/availability';
import { boilerplateSamples } from 'client/reducers/boilerplateSamples';
import { customTopPage } from 'client/reducers/customTopPage';
import { dashTableControls } from 'client/reducers/dashTableControls';
import { dispatch } from 'client/reducers/dispatch';
import { dispatchTableControls } from 'client/reducers/dispatchTableControls';
import { fares } from 'client/reducers/fares';
import { guidanceCoupons } from 'client/reducers/guidanceCoupons';
import { guidanceLocations } from 'client/reducers/guidanceLocations';
import { guidancePages } from 'client/reducers/guidancePages';
import { inquiries } from 'client/reducers/inquiries';
import { inquiryTableControls } from 'client/reducers/inquiryTableControls';
import { manifests } from 'client/reducers/manifests';
import { mapDashboardControls } from 'client/reducers/mapDashboardControls';
import { newsletters } from 'client/reducers/newsletters';
import { newsletterSegments } from 'client/reducers/newsletterSegments';
import { newslettersSearch } from 'client/reducers/newslettersSearch';
import { organizations } from 'client/reducers/organizations';
import { paymentMethods } from 'client/reducers/paymentMethods';
import { privateMarketplace } from 'client/reducers/privateMarketplace';
import { products } from 'client/reducers/products';
import { productCalendarListControls } from 'client/reducers/productCalendarListControls';
import { productAgentReferences } from 'client/reducers/productAgentReferences';
import { productAvailability } from 'client/reducers/productAvailability';
import { productInstances } from 'client/reducers/productInstances';
import { productPrices } from 'client/reducers/productPrices';
import { productTableControls } from 'client/reducers/productTableControls';
import { promotions } from 'client/reducers/promotions';
import { user } from 'client/reducers/user';
import { tasks } from 'client/reducers/tasks';
import { contracts } from 'client/reducers/contracts';
import { reservations } from 'client/reducers/reservations';
import { reservationSearch } from 'client/reducers/reservationSearch';
import { reservationTableControls } from 'client/reducers/reservationTableControls';
import { waiverTableControls } from 'client/reducers/waiverTableControls';
import { reservationVoucher } from 'client/reducers/reservationVoucher';
import { reviewTableControls } from 'client/reducers/reviewTableControls';
import { reviews } from 'client/reducers/reviews';
import { invoices } from 'client/reducers/invoices';
import { subdomains } from 'client/reducers/subdomains';
import { subscriptionInvoices } from 'client/reducers/subscriptionInvoices';
import { subscriptions } from 'client/reducers/subscriptions';
import { systemFeeInvoices } from 'client/reducers/systemFeeInvoices';
import { translations } from 'client/reducers/translations';
import { tutorial } from 'client/reducers/tutorial';
import { customPages } from 'client/reducers/customPages';
import { waiverTemplates } from 'client/reducers/waiverTemplates';
import { waivers } from 'client/reducers/waivers';
import { productTags } from 'client/reducers/productTags';
import { guides } from 'client/reducers/guides';
import { reservationReportSettings } from 'client/reducers/reservationReportSettings';
import { notifications } from 'client/reducers/notifications';
import { accessReportData } from 'client/reducers/accessReportData';
import { accessReportSettings } from 'client/reducers/accessReportSettings';
import { manifestDisplaySettings } from 'client/reducers/manifestDisplaySettings';
import { memos } from 'client/reducers/memos';
import { mediaDownloadOrderTableControls } from 'client/reducers/mediaDownloadOrderTableControls';
import { mediaDownloadOrders } from 'client/reducers/mediaDownloadOrders';
import { mediaDownloadPrices } from 'client/reducers/mediaDownloadPrices';
import { mediaDownloadSalesPages } from 'client/reducers/mediaDownloadSalesPages';
import { dashboardControls } from 'client/reducers/dashboardControls';
import { checkinRecords } from 'client/reducers/checkinRecords';
import { checkinRecordTableControls } from 'client/reducers/checkinRecordTableControls';
import { actimReservations } from 'client/reducers/actimReservations';
import { actimReservationTableControls } from 'client/reducers/actimReservationTableControls';
import { reservationDataCsvExportOrders } from 'client/reducers/reservationDataCsvExportOrders';
import { resourceAssignments } from 'client/reducers/resourceAssignments';
import { resourceAvailabilityControls } from 'client/reducers/resourceAvailabilityControls';
import { reservationDataCsvExportOrderTableControls } from 'client/reducers/reservationDataCsvExportOrderTableControls';
import { resourceQuantities } from 'client/reducers/resourceQuantities';
import { redeemedRecords } from 'client/reducers/redeemedRecords';
import { redeemedRecordTableControls } from 'client/reducers/redeemedRecordTableControls';
import { eTicketLocations } from 'client/reducers/eTicketLocations';
import { eTicketLocationSets } from 'client/reducers/eTicketLocationSets';
import { eTicketFootprints } from 'client/reducers/eTicketFootprints';
import { eTicketFootprintTableControls } from 'client/reducers/eTicketFootprintTableControls';
import { guidanceStampRallies } from 'client/reducers/guidanceStampRallies';
import { surveyTemplates } from 'client/reducers/surveyTemplates';
import { surveySubmissions } from 'client/reducers/surveySubmissions';
import { surveySubmissionResults } from 'client/reducers/surveySubmissionResults';
import { invoiceTableControls } from 'client/reducers/invoiceTableControls';
import { customers } from 'client/reducers/customers';
import { customerListControls } from 'client/reducers/customerListControls';
import { eTicketQrCodes } from 'client/reducers/eTicketQrCodes';
import { eTicketDailyUses } from 'client/reducers/eTicketDailyUses';
import { eTicketDailyUsesSettings } from 'client/reducers/eTicketDailyUsesSettings';
import { equipments } from 'client/reducers/equipments';
import { equipmentAssignments } from 'client/reducers/equipmentAssignments';
import { equipmentInstances } from 'client/reducers/equipmentInstances';
import { seatAssignmentControls } from 'client/reducers/seatAssignmentControls';
import { equipmentBlocks } from 'client/reducers/equipmentBlocks';
import { equipmentAssets } from 'client/reducers/equipmentAssets';
import { salesOffers } from 'client/reducers/salesOffers';
import { salesOfferStats } from 'client/reducers/salesOfferStats';
import { equipmentCalendarListControls } from 'client/reducers/equipmentCalendarListControls';
import { instantWins } from 'client/reducers/instantWins';
import { jaranReservationImportOrders } from 'client/reducers/jaranReservationImportOrders';
import { equipmentNotifications } from 'client/reducers/equipmentNotifications';
import { realtimeNotificationTemplates } from 'client/reducers/realtimeNotificationTemplates';
import { groupBookingTemplates } from 'client/reducers/groupBookingTemplates';
import { digitalMaps } from 'client/reducers/digitalMaps';
import { annualPassReservationTableControls } from 'client/reducers/annualPassReservationTableControls';
import { reservationReceipts } from 'client/reducers/reservationReceipts';
import { marketingAutomationTriggerConditions } from 'client/reducers/marketingAutomationTriggerConditions';
import { instantWinEvents } from 'client/reducers/instantWinEvents';
import { instantWinEventTableControls } from 'client/reducers/instantWinEventTableControls';
import { marketingAutomationContents } from 'client/reducers/marketingAutomationContents';
import { marketingAutomationCampaigns } from 'client/reducers/marketingAutomationCampaigns';
import { analyticsCustomers } from 'client/reducers/analyticsCustomers';
import { dashboardSettings } from 'client/reducers/dashboardSettings';
import { accountSettings } from 'client/reducers/accountSettings';
import { supplierSettings } from 'client/reducers/supplierSettings';
import { agentSystemFeeInvoices } from 'client/reducers/agentSystemFeeInvoices';
import { customerEvents } from 'client/reducers/customerEvents';
import { newUIControls } from 'client/reducers/newUIControls';
import { digitalMapAccessReportData } from 'client/reducers/digitalMapAccessReportData';
import { digitalMapAccessReportSettings } from 'client/reducers/digitalMapAccessReportSettings';
import { restaurants } from 'client/reducers/restaurants';
import { restaurantOrders } from 'client/reducers/restaurantOrders';
import { customerEventControls } from 'client/reducers/customerEventControls';
import { siteControlWidgets } from 'client/reducers/siteControlWidgets';
import { onSiteAnalyticsCustomers } from 'client/reducers/onSiteAnalyticsCustomers';
import { webJourneyCustomerListControls } from 'client/reducers/webJourneyCustomerListControls';
import { partnershipSystemFeeInvoices } from 'client/reducers/partnershipSystemFeeInvoices';
import { restaurantOrderReportData } from 'client/reducers/restaurantOrderReportData';
import { waitTimes } from 'client/reducers/waitTimes';
import { theme } from 'client/reducers/theme';
import { chats } from 'client/reducers/chats';
import { reservationInvoices } from 'client/reducers/reservationInvoices';
import { busRoutes } from 'client/reducers/busRoutes';
import { internalChats } from 'client/reducers/internalChats';

import { webJourneyConversionPathControls } from './webJourneyConversionPathControls';

// I'm not sure if redux was meant to be used this way, but the last action can often
// be used as shorthand for the current state.

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const lastActionType = (state = '', action: any) => action.type;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const error = (state = '', action: any) => action.error || '';

export const localeSelector = (state: ReduxState) =>
  state.language.selected.iso;

const language = (
  state = {
    options: languageOptions,
    selected: current_language,
  },
  action: any
) => {
  switch (action.type) {
    case SET_LANGUAGE: {
      const new_lang = languageOptions.filter(
        (lang) => lang.iso === action.language
      )[0];
      i18n.setLanguage(new_lang.iso);
      return { ...state, selected: new_lang };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userDataInvalidated = (state = false, action: any) => {
  switch (action.type) {
    case SET_IMPERSONATED_USER_ID:
    case SET_ALTERNATE_ORGANIZATION_IS_ACTIVE:
      return true;

    default:
      return false;
  }
};

const reducers = {
  accounts,
  availability,
  boilerplateSamples,
  contracts,
  customTopPage,
  dashTableControls,
  dispatch,
  dispatchTableControls,
  error,
  fares,
  guidanceCoupons,
  guidanceLocations,
  guidancePages,
  inquiries,
  inquiryTableControls,
  lastActionType,
  language,
  manifests,
  mapDashboardControls,
  newsletters,
  newsletterSegments,
  newslettersSearch,
  organizations,
  paymentMethods,
  privateMarketplace,
  products,
  productAgentReferences,
  productAvailability,
  productCalendarListControls,
  productInstances,
  productPrices,
  productTableControls,
  productTags,
  promotions,
  reservations,
  reservationSearch,
  reservationTableControls,
  reservationVoucher,
  reviewTableControls,
  reviews,
  tasks,
  invoices,
  subdomains,
  subscriptionInvoices,
  subscriptions,
  systemFeeInvoices,
  translations,
  tutorial,
  user,
  userDataInvalidated,
  customPages,
  waiverTableControls,
  waiverTemplates,
  waivers,
  guides,
  reservationReportSettings,
  notifications,
  accessReportData,
  accessReportSettings,
  manifestDisplaySettings,
  memos,
  mediaDownloadOrderTableControls,
  mediaDownloadOrders,
  mediaDownloadPrices,
  mediaDownloadSalesPages,
  dashboardControls,
  checkinRecords,
  checkinRecordTableControls,
  actimReservations,
  actimReservationTableControls,
  reservationDataCsvExportOrders,
  resourceAssignments,
  resourceAvailabilityControls,
  reservationDataCsvExportOrderTableControls,
  resourceQuantities,
  redeemedRecords,
  redeemedRecordTableControls,
  eTicketLocations,
  eTicketLocationSets,
  eTicketFootprints,
  eTicketFootprintTableControls,
  guidanceStampRallies,
  surveyTemplates,
  surveySubmissions,
  surveySubmissionResults,
  invoiceTableControls,
  customers,
  customerListControls,
  eTicketQrCodes,
  eTicketDailyUses,
  eTicketDailyUsesSettings,
  equipments,
  equipmentAssignments,
  equipmentInstances,
  seatAssignmentControls,
  equipmentBlocks,
  equipmentAssets,
  salesOffers,
  salesOfferStats,
  equipmentCalendarListControls,
  instantWins,
  jaranReservationImportOrders,
  equipmentNotifications,
  realtimeNotificationTemplates,
  groupBookingTemplates,
  annualPassReservationTableControls,
  digitalMaps,
  reservationReceipts,
  marketingAutomationTriggerConditions,
  instantWinEvents,
  instantWinEventTableControls,
  marketingAutomationContents,
  marketingAutomationCampaigns,
  analyticsCustomers,
  dashboardSettings,
  accountSettings,
  supplierSettings,
  agentSystemFeeInvoices,
  customerEvents,
  newUIControls,
  digitalMapAccessReportData,
  digitalMapAccessReportSettings,
  restaurants,
  restaurantOrders,
  customerEventControls,
  siteControlWidgets,
  onSiteAnalyticsCustomers,
  webJourneyCustomerListControls,
  webJourneyConversionPathControls,
  partnershipSystemFeeInvoices,
  restaurantOrderReportData,
  waitTimes,
  theme,
  chats,
  internalChats,
  reservationInvoices,
  busRoutes,
};

export type ReduxState = {
  [Reducer in keyof typeof reducers]: ReturnType<(typeof reducers)[Reducer]>;
};
export const rootReducer = combineReducers(reducers);
