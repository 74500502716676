import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import type {
  Organization,
  SourceLanguage,
  PrivateMarketplace,
} from 'shared/models/swagger';

export const getBookingWidgetPmpEssentialPageUrlBase = (
  organization: Organization | null,
  contentLanguage: SourceLanguage
): string => {
  let pmp: PrivateMarketplace = {};
  if (organization?.private_marketplaces?.length) {
    organization?.private_marketplaces?.forEach((p) => {
      if (p.language === contentLanguage) {
        pmp = p;
      }
    });
  }

  if (
    Boolean(Object.keys(pmp).length) &&
    !isSubscriptionCancelled(organization, 'standard-plan')
  ) {
    return `https://${pmp?.custom_domain || pmp?.domain || ''}`;
  }

  if (organization?.booking_widget_custom_domain) {
    return `https://${organization?.booking_widget_custom_domain}`;
  }

  if (organization?.booking_widget_subdomain) {
    return getBookingWidgetUrl(organization?.booking_widget_subdomain);
  }

  if (organization?.booking_widget_api_key) {
    return (
      getOldBookingWidgetUrl() +
      '/' +
      (organization.booking_widget_api_key || '')
    );
  }

  return '';
};

const getOldBookingWidgetUrl = (): string => {
  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost:3000/.test(window.location.origin)
  ) {
    return `https://book.dev.ntmg.com`;
  } else if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    return `https://book.stg.ntmg.com`;
  }

  return `https://book.ntmg.com`;
};
