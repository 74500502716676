import _ from 'lodash';
import moment from 'moment-timezone';
import { Field, useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './ExpireLimitInput.module.css';

type Props = {
  name: string;
  activateImmediateExpiration?: boolean;
};

export const ExpireLimitInput = ({
  name,
  activateImmediateExpiration,
}: Props) => {
  const field = useField(name);
  const form = useForm();
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles['input-container']}>
        {field.input.value.deadlineType === 'HOUR' && (
          <label
            style={{
              width: '80px',
            }}
          >
            <Field
              name={`${name}.hoursLater`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <SingleDropdown
                  options={_.times(101, (hoursLater) => ({
                    value: hoursLater.toString(),
                    text: hoursLater.toString(),
                  }))}
                  selectedOption={input.value.toString()}
                  onChange={input.onChange}
                />
              )}
            </Field>
          </label>
        )}
        {field.input.value.deadlineType === 'DAY' && (
          <label
            style={{
              width: '80px',
            }}
          >
            <Field
              name={`${name}.daysLater`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <SingleDropdown
                  options={_.times(366, (daysLater) => ({
                    value: daysLater.toString(),
                    text: daysLater.toString(),
                  }))}
                  selectedOption={input.value.toString()}
                  onChange={input.onChange}
                />
              )}
            </Field>
          </label>
        )}
        <label
          style={{
            width: '200px',
          }}
        >
          <Field name={`${name}.deadlineType`}>
            {({ input }) => (
              <>
                <SingleDropdown
                  options={[
                    {
                      value: 'ETERNAL',
                      text: t('No expiration'),
                    },
                    {
                      value: 'HOUR',
                      text: t('hours'),
                    },
                    {
                      value: 'DAY',
                      text: t('days after'),
                    },
                    ...(activateImmediateExpiration
                      ? [
                          {
                            value: 'INSTANT_EXPIRATION',
                            text: t('Immediately'),
                          },
                        ]
                      : []),
                  ]}
                  selectedOption={input.value.toString()}
                  onChange={(newValue: string) => {
                    if (newValue === 'HOUR') {
                      field.input.onChange({
                        ...field.input.value,
                        deadlineType: 'HOUR',
                        hoursLater: field.input.value.hoursLater ?? 24,
                      });
                    } else if (newValue === 'DAY') {
                      field.input.onChange({
                        ...field.input.value,
                        deadlineType: 'DAY',
                        daysLater: field.input.value.daysLater ?? 1,
                        timeOfDay: field.input.value.timeOfDay ?? '17:00',
                      });
                    } else if (newValue === 'ETERNAL') {
                      form.change('displayType', 'CLOCK');
                      field.input.onChange({
                        ...field.input.value,
                        deadlineType: newValue,
                      });
                    } else {
                      field.input.onChange({
                        ...field.input.value,
                        deadlineType: newValue,
                      });
                    }
                  }}
                />
              </>
            )}
          </Field>
        </label>
        {field.input.value.deadlineType === 'DAY' && (
          <div style={{ maxWidth: '80px' }}>
            <Field name={`${name}.timeOfDay`}>
              {({ input }) => (
                <TimePicker
                  allowEmpty={false}
                  value={moment(input.value, 'HH:mm')}
                  onChange={(newMoment) => {
                    if (!newMoment) {
                      return;
                    }

                    input.onChange(newMoment.format('HH:mm'));
                  }}
                />
              )}
            </Field>
          </div>
        )}
      </div>
    </div>
  );
};
