import * as React from 'react';
import clsx from 'clsx';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-focus';
import { FieldArray } from 'react-final-form-arrays';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateAboutUsSettings } from 'client/actions/essentialPages';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleImageInput } from 'client/components/v3/SingleImageInput/SingleImageInput';
//import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
//import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';
import { Editor } from 'client/components/Editor/Editor';
import type {
  SourceLanguage,
  AboutUsSettings,
  Translation,
} from 'shared/models/swagger';
//import * as Swagger from 'shared/models/swagger';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';

const focusOnError: any = createDecorator();
type TranslationsFormValues = {
  translations: Translation[];
};
type MessageTranslationsFormValues = {
  message_body: string;
  trans_message_body: string;
};

const emptyTranslation = {
  en_us: '',
};

type TransTargetLanguage = 'en_us' | 'ja_jp' | 'ko_kr' | 'zh_cn' | 'zh_tw';

export const AboutUsTab = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const dispatch = useDispatch();
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translation, setTranslation] = React.useState<Translation[]>([
    emptyTranslation,
  ]);
  const [translationEditor, setTranslationEditor] = React.useState<
    {
      body?: string | undefined;
      content_language?: string | undefined;
    }[]
  >([]);

  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== defaultLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([defaultLanguage, ...translationLanguages])].length > 1;

  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);
  //const [transEditorJsLoading, setTransEditorJsLoading] =
  //  React.useState<boolean>(false);

  React.useEffect(() => {
    if (apiTranslations?.length) {
      setTranslation(apiTranslations);
    }
  }, [apiTranslations, activeUserOrganization]);

  React.useEffect(() => {
    if (showTranslationMode) {
      const aboutUsSettings =
        activeUserOrganization?.essential_pages?.about_us_settings;

      if (aboutUsSettings) {
        const texts = [];

        if (aboutUsSettings.title) {
          texts.push(aboutUsSettings.title);
        }

        (aboutUsSettings.other_company_profiles || []).forEach((profile) => {
          if (profile.key) {
            texts.push(profile.key);
          }

          if (profile.value) {
            texts.push(profile.value);
          }
        });
        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeUserOrganization]);

  const initialValuesWithTranslations = React.useMemo(() => {
    const messages =
      activeUserOrganization?.essential_pages?.about_us_settings?.messages ||
      [];
    const defaultLanguageMessage = messages.find(
      (message) => message.content_language === defaultLanguage
    );
    setTranslationEditor(
      messages?.filter(
        (message) => message.content_language !== defaultLanguage
      ) || []
    );
    return {
      ...activeUserOrganization?.essential_pages?.about_us_settings,
      message_body: JSON.parse(defaultLanguageMessage?.body || '{}'),
    };
  }, [apiTranslations, activeUserOrganization, defaultLanguage]);

  const addNewTrans = (
    target: Translation | undefined,
    defaultLanguage: SourceLanguage,
    language: TransTargetLanguage,
    previousValue: string,
    value: string
  ) => {
    if (!target) {
      setTranslation([
        ...translation,
        {
          source_language: defaultLanguage,
          [language]: value,
          [defaultLanguage.toLowerCase()]: previousValue,
        },
      ]);
    } else {
      const newTrans = {
        ...target,
      } as Translation;
      newTrans[language as keyof Translation] = value as any;
      setTranslation(
        translation.map((t2) => {
          if (
            t2[defaultLanguage.toLowerCase() as keyof Translation] ==
            previousValue
          ) {
            return newTrans;
          }
          return t2;
        })
      );
    }
  };

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  return (
    <>
      <Form
        onSubmit={async (
          values: AboutUsSettings &
            TranslationsFormValues &
            MessageTranslationsFormValues
        ) => {
          const previousMessages =
            activeUserOrganization?.essential_pages?.about_us_settings
              ?.messages;
          let messages = previousMessages ? [...previousMessages] : [];
          messages = [
            ...messages.filter(
              (message) => message.content_language !== defaultLanguage
            ),
            {
              content_language: defaultLanguage,
              body: JSON.stringify(values.message_body),
            },
          ];

          if (showTranslationMode) {
            messages = [
              ...translationEditor,
              {
                content_language: defaultLanguage,
                body: JSON.stringify(values.message_body),
              },
            ];
          }

          values.messages = messages;
          const promises = [dispatch(updateAboutUsSettings(values))];

          if (showTranslationMode) {
            promises.push(dispatch(updateTranslations(translation)) as any);
          }

          try {
            await Promise.all(promises);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        mutators={getArrayMutators()}
        initialValues={initialValuesWithTranslations}
        keepDirtyOnReinitialize={true}
      >
        {({
          handleSubmit,
          submitting,
          submitSucceeded,
          submitError,
          modifiedSinceLastSubmit,
          values,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div
              className={clsx(
                styles['p-inquiry-tabs__tab-panel'],
                styles['components_is-active__B15DT']
              )}
              style={{ display: 'block' }}
            >
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Snackbar
                  color={'success'}
                  text={t('Save Successful')}
                  shouldShow={submitSucceeded}
                />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Snackbar
                  color={'error'}
                  text={t('Save Failed')}
                  shouldShow={submitError}
                />
              )}
              <div className={styles['p-inquiry-tabs__content']}>
                <ul className={styles['p-inquiry-list']}>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Visibility')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="visibility" type="checkbox">
                          {({ input }) => (
                            <div>
                              <Toggle
                                label={t(
                                  'Show About Us page on booking website'
                                )}
                                {...input}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Title')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="title">
                          {({ input, meta: { touched, error } }) => {
                            const target = translation.find((tra) => {
                              return (
                                tra[
                                  defaultLanguage.toLowerCase() as keyof Translation
                                ] == values.title
                              );
                            });
                            return (
                              <TextField
                                label={t('Title')}
                                value={input.value}
                                onChange={(value) => {
                                  if (showTranslationMode) {
                                    addNewTrans(
                                      target,
                                      defaultLanguage,
                                      defaultLanguage.toLowerCase() as TransTargetLanguage,
                                      values.title || '',
                                      value
                                    );
                                  }
                                  input.onChange(value);
                                }}
                                error={touched && error}
                              />
                            );
                          }}
                        </Field>
                        {showTranslationMode && (
                          <div
                            className={
                              styles['p-topPage-products__section__body__item']
                            }
                          >
                            <div className={styles['p-topPage-box']}>
                              <div className={styles['p-topPage-box__list']}>
                                {translationLanguages.map((lang, langIdx) => {
                                  const target = translation.find((tra) => {
                                    return (
                                      tra[
                                        defaultLanguage.toLowerCase() as keyof Translation
                                      ] == values.title
                                    );
                                  });
                                  return (
                                    <div
                                      key={langIdx}
                                      className={
                                        styles['p-topPage-box__list__item']
                                      }
                                    >
                                      <TextField
                                        label={getLanguageName(
                                          uppercaseIsoToLowercaseIso[lang],
                                          t
                                        )}
                                        value={
                                          target?.[
                                            lang.toLowerCase() as keyof Translation
                                          ] || ''
                                        }
                                        onChange={(value) => {
                                          if (showTranslationMode) {
                                            addNewTrans(
                                              target,
                                              defaultLanguage,
                                              lang.toLowerCase() as TransTargetLanguage,
                                              values.title || '',
                                              value
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Key visual')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="key_visual">
                          {({ input }) => (
                            <SingleImageInput
                              fileTypes={['image/jpeg', 'image/png']}
                              onUploadFinished={(_, objectUrl) =>
                                input.onChange(objectUrl)
                              }
                              initialValue={input.value}
                              saveFilePath={`essential/supplier/${activeUserOrganization?.id}`}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Message')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                        style={{ display: 'block' }}
                      >
                        <div
                          style={{
                            border: '1px solid var(--gray200)',
                            borderRadius: '12px',
                          }}
                        >
                          <Field name="message_body">
                            {({ input }) => (
                              <Editor
                                imageS3Prefix={`editor/supplier/${
                                  activeUserOrganization?.id || ''
                                }`}
                                data={input.value}
                                onChange={(data: any, loading) => {
                                  setEditorJsLoading(loading);
                                  input.onChange(data);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        {showTranslationMode && (
                          <div
                            className={
                              styles['p-topPage-products__section__body__item']
                            }
                          >
                            {translationLanguages.map((lang, langIdx) => {
                              const translationTargetLanguageMessage =
                                translationEditor.find(
                                  (message) => message.content_language === lang
                                );
                              const messageBody = JSON.parse(
                                translationTargetLanguageMessage?.body || '{}'
                              );
                              return (
                                <div
                                  className={styles['p-topPage-box']}
                                  style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                  }}
                                  key={langIdx}
                                >
                                  <div
                                    className={styles['p-topPage-box__list']}
                                  >
                                    <div
                                      style={{
                                        fontWeight: 'var(--text-semibold)',
                                      }}
                                    >
                                      {getLanguageName(
                                        uppercaseIsoToLowercaseIso[lang],
                                        t
                                      )}
                                    </div>
                                    <div
                                      className={
                                        styles['p-topPage-box__list__item']
                                      }
                                      style={{ backgroundColor: '#fff' }}
                                    >
                                      <Editor
                                        imageS3Prefix={`editor/supplier/${
                                          activeUserOrganization?.id || ''
                                        }`}
                                        data={messageBody}
                                        onChange={(data: any, loading) => {
                                          setEditorJsLoading(loading);
                                          setTranslationEditor([
                                            ...translationEditor.filter(
                                              (m) => m.content_language !== lang
                                            ),
                                            {
                                              content_language: lang,
                                              body: JSON.stringify(data),
                                            },
                                          ]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </li>

                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Other Company Profile')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <FieldArray name={`other_company_profiles`}>
                          {({ fields }) => {
                            const count = fields.length ?? 0;
                            return (
                              <div>
                                {fields.map((name, formIdx) => (
                                  <div
                                    className={styles['company__item']}
                                    style={{ display: 'flex' }}
                                    key={formIdx}
                                  >
                                    <div
                                      className={styles['company__item__box']}
                                    >
                                      <div
                                        style={{
                                          display: 'block',
                                        }}
                                      >
                                        <Field name={`${name}.key`}>
                                          {({
                                            input,
                                            meta: { error, touched },
                                          }) => {
                                            const target = translation.find(
                                              (tra) => {
                                                return (
                                                  tra[
                                                    defaultLanguage.toLowerCase() as keyof Translation
                                                  ] ==
                                                  values
                                                    .other_company_profiles?.[
                                                    formIdx
                                                  ]?.key
                                                );
                                              }
                                            );
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    margin: '10px 10px 10px',
                                                  }}
                                                >
                                                  <p>
                                                    {' '}
                                                    <TextField
                                                      {...input}
                                                      label={t('Title')}
                                                      error={touched && error}
                                                      onChange={(value) => {
                                                        if (
                                                          showTranslationMode
                                                        ) {
                                                          addNewTrans(
                                                            target,
                                                            defaultLanguage,
                                                            defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                            values
                                                              .other_company_profiles?.[
                                                              formIdx
                                                            ]?.key || '',
                                                            value
                                                          );
                                                        }
                                                        input.onChange(value);
                                                      }}
                                                    />{' '}
                                                  </p>
                                                </div>
                                              </>
                                            );
                                          }}
                                        </Field>
                                        {showTranslationMode && (
                                          <div
                                            className={
                                              styles[
                                                'p-topPage-products__section__body__item'
                                              ]
                                            }
                                          >
                                            <div
                                              className={
                                                styles['p-topPage-box']
                                              }
                                              style={{ margin: '10px' }}
                                            >
                                              <div
                                                className={
                                                  styles['p-topPage-box__list']
                                                }
                                              >
                                                {translationLanguages.map(
                                                  (lang, langIdx) => {
                                                    const target =
                                                      translation.find(
                                                        (tra) => {
                                                          return (
                                                            tra[
                                                              defaultLanguage.toLowerCase() as keyof Translation
                                                            ] ==
                                                            values
                                                              .other_company_profiles?.[
                                                              formIdx
                                                            ]?.key
                                                          );
                                                        }
                                                      );
                                                    return (
                                                      <div
                                                        key={langIdx}
                                                        className={
                                                          styles[
                                                            'p-topPage-box__list__item'
                                                          ]
                                                        }
                                                      >
                                                        <TextField
                                                          label={getLanguageName(
                                                            uppercaseIsoToLowercaseIso[
                                                              lang
                                                            ],
                                                            t
                                                          )}
                                                          value={
                                                            target?.[
                                                              lang.toLowerCase() as keyof Translation
                                                            ] || ''
                                                          }
                                                          onChange={(value) => {
                                                            if (
                                                              showTranslationMode
                                                            ) {
                                                              addNewTrans(
                                                                target,
                                                                defaultLanguage,
                                                                lang.toLowerCase() as TransTargetLanguage,
                                                                values
                                                                  .other_company_profiles?.[
                                                                  formIdx
                                                                ]?.key || '',
                                                                value
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                        <Field name={`${name}.value`}>
                                          {({
                                            input,
                                            meta: { error, touched },
                                          }) => {
                                            const target = translation.find(
                                              (tra) => {
                                                return (
                                                  tra[
                                                    defaultLanguage.toLowerCase() as keyof Translation
                                                  ] ==
                                                  values
                                                    .other_company_profiles?.[
                                                    formIdx
                                                  ]?.value
                                                );
                                              }
                                            );
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    margin: '10px 10px 10px',
                                                  }}
                                                >
                                                  <p>
                                                    {' '}
                                                    <TextField
                                                      {...input}
                                                      label={t('Description')}
                                                      error={touched && error}
                                                      onChange={(value) => {
                                                        if (
                                                          showTranslationMode
                                                        ) {
                                                          addNewTrans(
                                                            target,
                                                            defaultLanguage,
                                                            defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                            values
                                                              .other_company_profiles?.[
                                                              formIdx
                                                            ]?.value || '',
                                                            value
                                                          );
                                                        }
                                                        input.onChange(value);
                                                      }}
                                                    />{' '}
                                                  </p>
                                                </div>
                                              </>
                                            );
                                          }}
                                        </Field>
                                        {showTranslationMode && (
                                          <div
                                            className={
                                              styles[
                                                'p-topPage-products__section__body__item'
                                              ]
                                            }
                                          >
                                            <div
                                              className={
                                                styles['p-topPage-box']
                                              }
                                              style={{ margin: '10px' }}
                                            >
                                              <div
                                                className={
                                                  styles['p-topPage-box__list']
                                                }
                                              >
                                                {translationLanguages.map(
                                                  (lang, langIdx) => {
                                                    const target =
                                                      translation.find(
                                                        (tra) => {
                                                          return (
                                                            tra[
                                                              defaultLanguage.toLowerCase() as keyof Translation
                                                            ] ==
                                                            values
                                                              .other_company_profiles?.[
                                                              formIdx
                                                            ]?.value
                                                          );
                                                        }
                                                      );
                                                    return (
                                                      <div
                                                        key={langIdx}
                                                        className={
                                                          styles[
                                                            'p-topPage-box__list__item'
                                                          ]
                                                        }
                                                      >
                                                        <TextField
                                                          label={getLanguageName(
                                                            uppercaseIsoToLowercaseIso[
                                                              lang
                                                            ],
                                                            t
                                                          )}
                                                          value={
                                                            target?.[
                                                              lang.toLowerCase() as keyof Translation
                                                            ] || ''
                                                          }
                                                          onChange={(value) => {
                                                            if (
                                                              showTranslationMode
                                                            ) {
                                                              addNewTrans(
                                                                target,
                                                                defaultLanguage,
                                                                lang.toLowerCase() as TransTargetLanguage,
                                                                values
                                                                  .other_company_profiles?.[
                                                                  formIdx
                                                                ]?.value || '',
                                                                value
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <Button
                                      size="icon"
                                      color="tertiarygray"
                                      iconBeforeText={
                                        <i className="c-icon-outline-general-trash-03"></i>
                                      }
                                      onClick={() => fields.remove(formIdx)}
                                    />
                                  </div>
                                ))}
                                <a
                                  className={styles['add__button']}
                                  onClick={() => {
                                    if (count === 0) {
                                      (fields as any).insertAt(0, '');
                                    } else {
                                      (fields as any).insertAt(count + 1, '');
                                    }
                                  }}
                                >
                                  <i className="c-icon-outline-general-plus-circle"></i>
                                  {t('Add')}
                                </a>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>
                  </li>

                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Google Map Embed Tag')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="google_map_tag">
                          {({ input }) => (
                            <TextField
                              maxWidth={800}
                              height={'150px'}
                              placeholder={
                                "<iframe src='https://www.google.com/maps/embed..."
                              }
                              {...(input as any)}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={clsx(styles['p-products__fixed'], styles['is-active'])}
            >
              <div className={styles['p-products__fixed__actions']}>
                {shouldShowMultilingualSettings && (
                  <Toggle
                    label={t('Translation')}
                    checked={showTranslationMode}
                    onChange={() =>
                      setShowTranslationMode(!showTranslationMode)
                    }
                  />
                )}
                <Button
                  disabled={submitting || editorJsLoading}
                  loading={submitting || editorJsLoading}
                  text={t('Save')}
                  color="primary"
                  size="md"
                  type="submit"
                  style={{
                    width: '60px',
                    fontWeight: 'var(--text-semibold)',
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
