import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';

import styles from './Loading.module.css';

interface Props {
  size: 'lg' | 'md' | 'sm';
  doNotShowText?: boolean;
}

export const Loading = ({ size, doNotShowText = false }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div
      className={clsx(
        size === 'sm' && styles['c-loading-sm'],
        size === 'md' && styles['c-loading-md'],
        size === 'lg' && styles['c-loading-lg']
      )}
    >
      <div>
        <svg viewBox="22 22 44 44">
          <circle
            cx="44"
            cy="44"
            r="18"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
          ></circle>
        </svg>
      </div>
      {!doNotShowText && locale !== 'en' && (
        <p style={{ textAlign: 'center' }}>{t('Loading...')}</p>
      )}
    </div>
  );
};
