import * as React from 'react';

import { InvoiceButton as InvoiceButtonV3 } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/InvoiceButton';
import { Reservation, ReservationInvoice } from 'shared/models/swagger';

interface Props {
  reservation: Reservation;
  reservationInvoice?: ReservationInvoice;
  button: (
    text: string,
    color:
      | 'primary'
      | 'secondary'
      | 'secondarycolor'
      | 'red'
      | 'tertiaryred'
      | 'white'
      | 'gray'
      | 'tertiarygray'
      | 'tertiarycolor',
    loading: boolean,
    onClick: () => void
  ) => React.ReactNode;
  onChangeSendEmailStatus: (
    status: 'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null
  ) => void;
}

export const InvoiceButton = (props: Props) => {
  return <InvoiceButtonV3 {...props} />;
};
