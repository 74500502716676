import type { PriceSchedule } from './FormValues';

export const findOverlappingDateRange = (
  schedule: PriceSchedule,
  comparisonSchedules: PriceSchedule[]
): {
  startDate: string;
  endDate: string;
} | null => {
  for (const comparisonSchedule of comparisonSchedules) {
    const overlappingDateRange = findOverlappingDateRangeForSchedule(
      schedule,
      comparisonSchedule
    );

    if (overlappingDateRange) {
      return overlappingDateRange;
    }
  }

  return null;
};

const findOverlappingDateRangeForSchedule = (
  schedule: PriceSchedule,
  comparisonSchedule: PriceSchedule
): {
  startDate: string;
  endDate: string;
} | null => {
  if (
    !schedule.weekdays.some((weekday) =>
      comparisonSchedule.weekdays.includes(weekday)
    )
  ) {
    return null;
  }

  if (
    (schedule.dateRange.startDate >= comparisonSchedule.dateRange.startDate &&
      schedule.dateRange.startDate <= comparisonSchedule.dateRange.endDate) ||
    (comparisonSchedule.dateRange.startDate >= schedule.dateRange.startDate &&
      comparisonSchedule.dateRange.startDate <= schedule.dateRange.endDate)
  ) {
    let startDate = schedule.dateRange.startDate;

    if (comparisonSchedule.dateRange.startDate > startDate) {
      startDate = comparisonSchedule.dateRange.startDate;
    }

    let endDate = schedule.dateRange.endDate;

    if (comparisonSchedule.dateRange.endDate < endDate) {
      endDate = comparisonSchedule.dateRange.endDate;
    }

    return {
      startDate: comparisonSchedule.dateRange.startDate,
      endDate: comparisonSchedule.dateRange.endDate,
    };
  }

  return null;
};
