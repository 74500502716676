// @flow

import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { config } from 'client/config';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getValidators } from 'shared/libraries/validate/validator';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import {
  FieldWrapper,
  Input,
  ToggleButton,
  FocusableInput,
} from 'client/components/Form';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';
import { PerParticipantPriceInput } from 'client/components/NewProductEditor/PerParticipantPriceInput/PerParticipantPriceInput';
import { PerBookingPriceInput } from 'client/components/NewProductEditor/PerBookingPriceInput/PerBookingPriceInput';
import { PerGroupPriceInput } from 'client/components/NewProductEditor/PerGroupPriceInput/PerGroupPriceInput';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import type { Product } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

import type { PriceSchedule } from './FormValues';
import { findOverlappingDateRange } from './utils';
import { getScheduleText } from '../util';

const getDefaultPerParticipantPrices = (
  product: Product | null,
  t: TranslateFuncType
) => {
  const language = product?.source_language
    ? uppercaseIsoToLowercaseIso[product.source_language]
    : 'ja';

  const units =
    (product?.pricing &&
      product.pricing.length > 0 &&
      product.pricing[0].units &&
      product.pricing[0].units.length > 0 &&
      product.pricing[0].units) ??
    null;

  if (units && units.some((unit) => unit.method === 'PER_PARTICIPANT')) {
    return units
      .filter((unit) => unit.method === 'PER_PARTICIPANT')
      .map((unit) => ({
        method: unit.method,
        unit: unit?.guest_type?.key ?? '',
        ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
        ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
        weight: unit?.guest_type?.minimum_participant_parameters
          ? unit?.guest_type?.minimum_participant_parameters.weight || 0
          : 1,
        price: '100',
        netPrice: '100',
      }));
  }

  return [
    {
      method: 'PER_PARTICIPANT',
      unit: t('Adult', { lng: language }),
      ageBandMinimum: 13,
      ageBandMaximum: 0,
      price: '100',
      netPrice: '100',
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Child', { lng: language }),
      ageBandMinimum: 4,
      ageBandMaximum: 12,
      price: '70',
      netPrice: '70',
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Infant', { lng: language }),
      ageBandMinimum: 0,
      ageBandMaximum: 3,
      price: '0',
      netPrice: '0',
    },
  ];
};

const getDefaultPerBookingPrices = () => {
  return [
    {
      method: 'PER_BOOKING',
      price: '100',
      netPrice: '100',
    },
  ];
};

export const PricesEditor = () => {
  const [activePriceIndex, setActivePriceIndex] = React.useState<number>(0);

  const { t } = useTranslation();

  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  const editingProduct = React.useContext(EditingProductContext);
  const language = editingProduct?.source_language
    ? uppercaseIsoToLowercaseIso[editingProduct.source_language]
    : 'ja';

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const { required } = getValidators(t);

  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);

  const validateSurchargeRate = React.useCallback(
    (value: string | null | typeof undefined) => {
      if (!value) {
        return t('Required');
      }

      if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
        return t('Must be a number between 0 and 100');
      }
      const parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) {
        return t('Must be a number between 0 and 100');
      }
      if (parsedValue < 0 || parsedValue > 100) {
        return t('Must be a number between 0 and 100');
      }
      return undefined;
    },
    []
  );

  const validateNoOverlaps = React.useCallback(
    (schedules: PriceSchedule[]) => {
      for (const [idx, schedule] of schedules.entries()) {
        const overlap = findOverlappingDateRange(
          schedule,
          schedules.slice(idx + 1)
        );

        if (overlap) {
          return t(
            'Duplicate prices for {{startDate}} ~ {{endDate}}. Please edit date range or days of week.',
            {
              startDate: overlap.startDate,
              endDate: overlap.endDate,
            }
          );
        }
      }

      return undefined;
    },
    [t]
  );

  return (
    <FieldArray name="priceSchedules" validate={validateNoOverlaps}>
      {({ fields, meta: { error, touched } }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <label
                className={clsx(
                  baseStyles['base-form-select'],
                  baseStyles['base-maxWidth-800']
                )}
              >
                <select
                  value={activePriceIndex}
                  onChange={(e) => {
                    setActivePriceIndex(parseInt(e.target.value));
                  }}
                >
                  {fields.value.map((priceRule, idx) => (
                    <option value={idx}>{getScheduleText(priceRule, t)}</option>
                  ))}
                </select>
              </label>
              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['small'],
                  baseStyles['green']
                )}
                onClick={() => {
                  fields.push({
                    dateRange: {
                      startDate: defaultStartDate,
                      endDate: defaultEndDate,
                    },
                    weekdays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
                    method: 'PER_PARTICIPANT',
                    prices: getDefaultPerParticipantPrices(editingProduct, t),
                    perGroupPrices: [],
                  });
                  setActivePriceIndex(fields.length);
                }}
              >
                {t('Add Price Schedule')}
              </a>
            </div>
            {error && typeof error === 'string' && (
              <FocusableInput name="priceSchedules" />
            )}
            {touched && error && typeof error === 'string' && (
              <>
                <p className={baseStyles['base-form-box__err']}>{error}</p>
              </>
            )}
          </div>
          <div
            key={activePriceIndex}
            className={baseStyles['base-selectFrame']}
          >
            <div className={baseStyles['base-selectFrame__header']}>
              <p className={baseStyles['base-selectFrame__header__ttl']}>
                {t('Selected Price Schedule')}
              </p>
              {fields.value.length > 1 && (
                <a
                  className={baseStyles['base-selectFrame__header__delete']}
                  onClick={() => {
                    fields.remove(activePriceIndex);
                    setActivePriceIndex(0);
                  }}
                >
                  {t('Remove this price schedule')}
                </a>
              )}
            </div>
            <div className={baseStyles['base-selectFrame__body']}>
              <div>
                <p className={baseStyles['page-productsEdit__select__ttl']}>
                  {t('Title')}
                </p>
                <Field name={`${fields.name}.${activePriceIndex}.title`}>
                  {({ input }) => <Input {...input} maxWidth={800} />}
                </Field>
              </div>
              <div className={styles['page-productsRegist__date']}>
                <p className={styles['page-productsRegist__date__ttl']}>
                  {t('Date Range')}
                </p>
                <div className={styles['page-productsRegist__date__range']}>
                  <div className={baseStyles['base-form-range']}>
                    <label className={baseStyles['base-form-calendar']}>
                      <img src={calendarIcon} />
                      <Field
                        name={`${fields.name}.${activePriceIndex}.dateRange.startDate`}
                      >
                        {({ input }) => <input type="date" {...input} />}
                      </Field>
                    </label>
                    <p>-</p>
                    <label className={baseStyles['base-form-calendar']}>
                      <img src={calendarIcon} />
                      <Field
                        name={`${fields.name}.${activePriceIndex}.dateRange.endDate`}
                      >
                        {({ input }) => <input type="date" {...input} />}
                      </Field>
                    </label>
                  </div>
                </div>
              </div>
              <p>
                {t(
                  '* Both price and "Participation Rules" in Reservation Parameters tab are required to become available on the calendars. Please make sure price and participation rules date ranges are covered.'
                )}
              </p>
              <div className={styles['page-productsRegist__date']}>
                <p className={styles['page-productsRegist__date__ttl']}>
                  {t('Days of Week')}
                </p>
                <Field name={`${fields.name}.${activePriceIndex}.weekdays`}>
                  {({ input }) => (
                    <WeekdaysInput
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>
              <Field name={`${fields.name}.${activePriceIndex}.prices`}>
                {({ input }) => (
                  <EnumRadioButtonGroup
                    name={`${fields.name}.${activePriceIndex}.method`}
                    options={[
                      {
                        value: 'PER_PARTICIPANT',
                        label: t('Per-participant'),
                        tooltipText: t(
                          'Used when setting the price for each participant (Price setting per person)'
                        ),
                      },
                      {
                        value: 'PER_BOOKING',
                        label: t('Per-booking'),
                        tooltipText: t(
                          'Used when setting the price for each reservation (Price setting per reservation)'
                        ),
                      },
                    ]}
                    onChange={(newValue: string) => {
                      switch (newValue) {
                        case 'PER_PARTICIPANT':
                          input.onChange(
                            getDefaultPerParticipantPrices(editingProduct, t)
                          );
                          break;
                        case 'PER_BOOKING':
                          input.onChange(getDefaultPerBookingPrices());
                          break;
                        default:
                          input.onChange([]);
                          break;
                      }
                    }}
                  />
                )}
              </Field>
              <Field name={`${fields.name}.${activePriceIndex}.method`}>
                {({ input: { value } }) => (
                  <>
                    {value === 'PER_PARTICIPANT' && (
                      <>
                        <PerParticipantPriceInput
                          showCustom
                          name={`${fields.name}.${activePriceIndex}.prices`}
                          language={language}
                          currency={defaultCurrency}
                          showNet={true}
                        />
                        <PerGroupPriceInput
                          name={`${fields.name}.${activePriceIndex}.perGroupPrices`}
                          currency={defaultCurrency}
                          showNet={true}
                        />
                      </>
                    )}
                    {value === 'PER_BOOKING' && (
                      <PerBookingPriceInput
                        name={`${fields.name}.${activePriceIndex}.prices`}
                        language={language}
                        currency={defaultCurrency}
                        showNet={true}
                      />
                    )}
                  </>
                )}
              </Field>
              <Field name={`${fields.name}.${activePriceIndex}.applySurcharge`}>
                {({ input: applySurchargeToggleInput }) => (
                  <>
                    <ToggleButton
                      label={t('Apply surcharge for these dates')}
                      checked={applySurchargeToggleInput.value}
                      onChange={() =>
                        applySurchargeToggleInput.onChange(
                          !applySurchargeToggleInput.value
                        )
                      }
                    />

                    {applySurchargeToggleInput.value && (
                      <>
                        <TranslatedField
                          name={`${fields.name}.${activePriceIndex}.surchargeTitle`}
                          validate={required}
                        >
                          {({
                            input,
                            meta: { touched, error },
                            translationInput,
                          }) => (
                            <FieldWrapper label={t('Surcharge Title')}>
                              <input
                                {...input}
                                type="text"
                                className={baseStyles['base-form-text']}
                              />
                              {translationTargetLanguage != null && (
                                <input
                                  {...translationInput}
                                  placeholder={translationTargetLanguageName}
                                  type="text"
                                  className={baseStyles['base-form-text']}
                                />
                              )}
                              {touched && error && (
                                <p className={styles['base-form-box__err']}>
                                  {error}
                                </p>
                              )}
                            </FieldWrapper>
                          )}
                        </TranslatedField>

                        <Field
                          name={`${fields.name}.${activePriceIndex}.surchargeRate`}
                          validate={validateSurchargeRate}
                        >
                          {({ input, meta: { touched, error } }) => (
                            <Input
                              label={t('Surcharge Percent (1-100)')}
                              value={input.value}
                              onChange={input.onChange}
                              error={touched && error}
                            />
                          )}
                        </Field>
                      </>
                    )}
                  </>
                )}
              </Field>
              {config.enableProductMinimumPrice && (
                <Field
                  name={`${fields.name}.${activePriceIndex}.applyMinimumPrice`}
                >
                  {({ input: minimumPriceToggleInput }) => (
                    <>
                      <ToggleButton
                        label={t('Use a minimum price for these dates')}
                        checked={minimumPriceToggleInput.value}
                        onChange={() =>
                          minimumPriceToggleInput.onChange(
                            !minimumPriceToggleInput.value
                          )
                        }
                      />

                      {minimumPriceToggleInput.value && (
                        <div className={styles['c-tableChild']}>
                          <ul>
                            <li className={baseStyles['base-t-240']}>
                              {t('Amount (tax included)')}
                            </li>
                            <li className={baseStyles['base-t-240']}>
                              {t('Net Amount')}
                            </li>
                          </ul>
                          <ul>
                            <li data-title={t('Amount (tax included)')}>
                              <div
                                className={
                                  styles[
                                    'page-productsRegist__priceMain__price'
                                  ]
                                }
                              >
                                <p>{defaultCurrency}</p>
                                <Field
                                  name={`${fields.name}.${activePriceIndex}.minimumGross`}
                                  validate={required}
                                >
                                  {({ input, meta: { touched, error } }) => (
                                    <Input
                                      value={input.value}
                                      onChange={(e) => {
                                        if (
                                          !currencyInputAllowed(
                                            defaultCurrency,
                                            e.target.value
                                          )
                                        ) {
                                          return;
                                        }

                                        input.onChange(e.target.value);
                                      }}
                                      error={touched && error}
                                    />
                                  )}
                                </Field>
                              </div>
                            </li>
                            <li data-title={t('Net Amount')}>
                              <div
                                className={
                                  styles[
                                    'page-productsRegist__priceMain__price'
                                  ]
                                }
                              >
                                <p>{defaultCurrency}</p>
                                <Field
                                  name={`${fields.name}.${activePriceIndex}.minimumNet`}
                                  validate={required}
                                >
                                  {({ input, meta: { touched, error } }) => (
                                    <Input
                                      value={input.value}
                                      onChange={(e) => {
                                        if (
                                          !currencyInputAllowed(
                                            defaultCurrency,
                                            e.target.value
                                          )
                                        ) {
                                          return;
                                        }

                                        input.onChange(e.target.value);
                                      }}
                                      error={touched && error}
                                    />
                                  )}
                                </Field>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              )}
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
