import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  PagedGenericTable,
  ColumnType,
} from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Button } from 'client/components/v3/Common/Button';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/v3/DeleteConfirmModal/DeleteConfirmModal';
import { BusRoute } from 'shared/models/swagger';
import { deleteBusRoute, fetchBusRoutes } from 'client/actions/busRoutes';

import styles from './BusRouteList.module.css';
import { EditBusRouteModal } from './EditBusRouteModal';

const Actions = ({
  busRoute,
  onClickEditButton,
}: {
  busRoute: BusRoute;
  onClickEditButton: (busRoute: BusRoute) => void;
}) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mr={4}
        gap={2}
      >
        <div
          className={styles.actionButton}
          onClick={() => {
            onClickEditButton(busRoute);
          }}
        >
          <i className="c-icon-outline-general-edit-05"></i>
          <p>{t('Edit')}</p>
        </div>
        <div
          className={clsx(styles.actionButton, styles.red)}
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          <i className="c-icon-outline-general-trash-03"></i>
          <p>{t('Delete')}</p>
        </div>
      </Box>
      {showDeleteModal && (
        <DeleteConfirmModal
          insertAtRoot
          header={t('Delete Bus Route')}
          content={t('Are you sure you want to delete this bus route?')}
          onConfirm={async () => {
            await dispatch(deleteBusRoute(busRoute.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export const BusRouteList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditBusRouteModal, setShowEditBusRouteModal] =
    React.useState(false);
  const [editingBusRoute, setEditingBusRoute] = React.useState<BusRoute | null>(
    null
  );
  const busRoutes = useSelector((state: any) => state.busRoutes.all);

  const columns: ColumnType<BusRoute>[] = [
    {
      Header: '',
      width: 80,
      accessor: (busRoute: BusRoute) => (
        <div
          style={{
            width: '24px',
            height: '24px',
            backgroundColor: busRoute.color,
            borderRadius: '4px',
          }}
        />
      ),
      id: 'color',
    },
    {
      Header: t('Name'),
      accessor: (busRoute: BusRoute) => busRoute.name,
      id: 'name',
    },
    {
      Header: '',
      accessor: (busRoute: BusRoute) => (
        <Actions
          busRoute={busRoute}
          onClickEditButton={(busRoute: BusRoute) => {
            setShowEditBusRouteModal(true);
            setEditingBusRoute(busRoute);
          }}
        />
      ),
      id: 'edit',
    },
  ];

  React.useEffect(() => {
    dispatch(fetchBusRoutes());
  }, [dispatch]);

  return (
    <V3Page>
      <PageHeader title={t('Bus Routes')} />
      <PageContent>
        <div className={styles.actions}>
          <Button
            text={t('Create Bus Route')}
            onClick={() => setShowEditBusRouteModal(true)}
          />
        </div>
        <PagedGenericTable allItems={busRoutes} columns={columns} />
      </PageContent>
      {showEditBusRouteModal && (
        <EditBusRouteModal
          existingBusRoute={editingBusRoute}
          onClose={() => {
            setEditingBusRoute(null);
            setShowEditBusRouteModal(false);
          }}
        />
      )}
    </V3Page>
  );
};
