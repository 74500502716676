import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { ReactNode, useEffect } from 'react';
import { setIn } from 'final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import type { Reservation } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { Message } from 'client/components/Message/Message';
import { Modal } from 'client/components/v3/Form/Modal';
import { ReduxState } from 'client/reducers';
import { TextField } from 'client/components/v3/Form/TextField';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getGuestName } from 'client/libraries/util/getGuestName';
import { matchesFormat } from 'shared/libraries/validate/validator';

type FormValues = {
  attention?: string;
  toAddress?: string;
};

type Props = {
  title: string;
  initialToAddress?: string;
  onClose: () => void;
  onSendEmail: (attention: string, toAddress: string) => Promise<void>;
  reservation?: Reservation;
};

export const SendInvoiceEmailModal = ({
  title,
  initialToAddress,
  onClose,
  onSendEmail,
  reservation,
}: Props) => {
  const { t } = useTranslation();
  const status = useSelector(
    (state: ReduxState) => state.reservationInvoices.emailStatus
  );
  const organization = useSelector(activeUserOrganizationSelector);
  const customerName = reservation ? getGuestName(reservation) : '';

  // Close modal when issuance is successful
  useEffect(() => {
    if (status === 'SUCCESS') {
      onClose();
    }
  }, [status]);

  const validateReceiptRelatedSettings = (): ReactNode[] => {
    const validationErrors: ReactNode[] = [];
    if (
      !organization?.supplier_guest_receipt_settings ||
      !organization.supplier_guest_receipt_settings.receipt_business_name
    ) {
      validationErrors.push(
        <>
          {t(
            'Please enter Receipt Business Name in the Booking Site > Settings page. '
          )}
          <Link
            to={`/bookingWidget/general`}
            className={baseStyles['base-link']}
          >
            {t('Click here to set.')}
          </Link>
        </>
      );
    }
    return validationErrors;
  };

  const receiptValidationErrors = validateReceiptRelatedSettings();
  return (
    <Form
      initialValues={{
        attention: customerName,
        toAddress: initialToAddress,
      }}
      onSubmit={(values: FormValues) => {
        let errors = {};
        const setError = (key: string, value: any) => {
          errors = setIn(errors, key, value);
        };

        if (!values.attention) {
          setError('attention', t('Required'));
          return errors;
        }

        if (!values.toAddress) {
          setError('toAddress', t('Required'));
          return errors;
        }

        if (!matchesFormat(values.toAddress, 'email')) {
          setError('toAddress', t('Invalid Email'));
          return errors;
        }
        onSendEmail(values.attention, values.toAddress);
        onClose();
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={title}
            open={true}
            onClose={() => {
              onClose();
            }}
            style={{ width: '600px' }}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  color="white"
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                />
                <Button
                  text={t('Issue receipt')}
                  type="submit"
                  disabled={receiptValidationErrors.length > 0}
                  loading={status === 'IN_FLIGHT'}
                />
              </>
            }
          >
            <div className={styles['p-reservationNum']}>
              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('Attention')}
                </p>
                <Field name="attention">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              </div>

              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('To Address')}
                </p>
                <Field name="toAddress">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              </div>

              {receiptValidationErrors.length > 0 && (
                <Message error>
                  <ol>
                    {receiptValidationErrors.map((e, idx) => (
                      <li key={idx}>{e}</li>
                    ))}
                  </ol>
                </Message>
              )}
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
