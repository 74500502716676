import { Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Stub } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { GenericDraggableList } from 'client/components/v3/GenericDraggableList/GenericDraggableList';

import styles from '../../ETicketEdit.module.css';

import { StubItem } from './StubItem';

export const StubsForm = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['p-box__table3']}>
      <Field name="stubs">
        {({ input }) => {
          return (
            <>
              <GenericDraggableList<Stub>
                items={input.value}
                itemType="stubs"
                moveItem={(newItems) => input.onChange(newItems)}
                addItem={() => {
                  const newItems = [
                    ...input.value,
                    {
                      key: uuid(),
                      text: '',
                      options: [],
                      maxRedemptionCount: 1,
                    },
                  ];
                  input.onChange(newItems);
                }}
                addItemText={t('Add E-ticket Usage')}
                renderItem={(item) => {
                  return (
                    <StubItem
                      stub={item}
                      onChange={(newItem) => {
                        const newItems = input.value.map((i: Stub) => {
                          if (i.key === item.key) {
                            return newItem;
                          }
                          return i;
                        });
                        input.onChange(newItems);
                      }}
                      onRemoveClick={() => {
                        const newItems = input.value.filter(
                          (i: Stub) => i.key !== item.key
                        );
                        input.onChange(newItems);
                      }}
                    />
                  );
                }}
              />
            </>
          );
        }}
      </Field>
    </div>
  );
};
