import {
  Organization,
  Organization$Patch,
  SourceLanguage,
} from 'shared/models/swagger';

export interface FormValues {
  urls: {
    [key in SourceLanguage]: string;
  };
}

export const getInitialValues = (
  org: Organization | null
): FormValues | null => {
  return {
    urls: {
      EN_US:
        org?.journey_analytics_settings?.privacy_policy_urls?.find(
          (url) => url.language === 'EN_US'
        )?.url || '',
      JA_JP:
        org?.journey_analytics_settings?.privacy_policy_urls?.find(
          (url) => url.language === 'JA_JP'
        )?.url || '',
      KO_KR:
        org?.journey_analytics_settings?.privacy_policy_urls?.find(
          (url) => url.language === 'KO_KR'
        )?.url || '',
      ZH_TW:
        org?.journey_analytics_settings?.privacy_policy_urls?.find(
          (url) => url.language === 'ZH_TW'
        )?.url || '',
      ZH_CN:
        org?.journey_analytics_settings?.privacy_policy_urls?.find(
          (url) => url.language === 'ZH_CN'
        )?.url || '',
    },
  };
};

type PrivacyPolicyUrl = {
  url: string;
  language: SourceLanguage;
};

export const convertFormValuesToOrganizationPatch = (
  values: FormValues
): Organization$Patch => {
  const privacyPolicyUrls: PrivacyPolicyUrl[] = [];

  for (const [language, url] of Object.entries(values.urls ?? {})) {
    if (url) {
      privacyPolicyUrls.push({
        url,
        language: language as SourceLanguage,
      });
    }
  }

  return {
    journey_analytics_settings: {
      privacy_policy_urls: privacyPolicyUrls,
    },
  };
};
