import {
  GroupBookingTemplateParams,
  GroupBookingTemplate,
} from 'shared/models/swagger';

interface GroupBookingPaymentDeadlineSettings {
  type:
    | 'END_OF_CURRENT_MONTH'
    | 'END_OF_NEXT_MONTH'
    | 'DAYS_BEFORE_PARTICIPATION';
  count: number;
}

interface GroupBookingPerReservationInvoiceSettings {
  isEnabled: boolean;
  bankTransferDetails: string;
  paymentDeadlineSettings: GroupBookingPaymentDeadlineSettings;
}
interface GroupTemplateProduct {
  productId: string;
  limit: number;
}

export interface FormValues {
  name: string;

  status: 'ON' | 'OFF';

  products: GroupTemplateProduct[];

  startDateLocalFrom: string;
  startDateLocalTo: string;

  groupName: string;
  logoUrl: string;

  perReservationInvoiceSettings: GroupBookingPerReservationInvoiceSettings;

  shouldAllowMultipleBookingsPerEmail: boolean;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): GroupBookingTemplateParams => {
  return {
    name: values.name,
    status: values.status,
    products: values.products.map((product) => ({
      product_id: product.productId,
      limit: product.limit,
    })),
    start_date_local_from: values.startDateLocalFrom,
    start_date_local_to: values.startDateLocalTo,
    logo_url: values.logoUrl,
    group_name: values.groupName,
    per_reservation_invoice_settings: {
      is_enabled: values.perReservationInvoiceSettings.isEnabled,
      bank_transfer_details:
        values.perReservationInvoiceSettings.bankTransferDetails,
      payment_deadline_settings: {
        type: values.perReservationInvoiceSettings.paymentDeadlineSettings.type,
        count:
          values.perReservationInvoiceSettings.paymentDeadlineSettings.count,
      },
    },
    should_allow_multiple_bookings_per_email:
      values.shouldAllowMultipleBookingsPerEmail,
  };
};

export const convertSwaggerToFormValues = (
  groupBookingTemplate: GroupBookingTemplate
): FormValues => {
  return {
    name: groupBookingTemplate.name ?? '',
    products:
      groupBookingTemplate.products?.map((product) => ({
        productId: product.product_id ?? '',
        limit: product.limit ?? 0,
      })) ?? [],
    startDateLocalFrom: groupBookingTemplate.start_date_local_from ?? '',
    startDateLocalTo: groupBookingTemplate.start_date_local_to ?? '',
    status: groupBookingTemplate.status ?? 'OFF',
    logoUrl: groupBookingTemplate.logo_url ?? '',
    groupName: groupBookingTemplate.group_name ?? '',
    perReservationInvoiceSettings: {
      isEnabled:
        groupBookingTemplate.per_reservation_invoice_settings?.is_enabled ??
        false,
      bankTransferDetails:
        groupBookingTemplate.per_reservation_invoice_settings
          ?.bank_transfer_details ?? '',
      paymentDeadlineSettings: {
        type:
          groupBookingTemplate.per_reservation_invoice_settings
            ?.payment_deadline_settings?.type ?? 'DAYS_BEFORE_PARTICIPATION',
        count:
          groupBookingTemplate.per_reservation_invoice_settings
            ?.payment_deadline_settings?.count ?? 1,
      },
    },
    shouldAllowMultipleBookingsPerEmail:
      groupBookingTemplate.should_allow_multiple_bookings_per_email ?? false,
  };
};
