import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { Equipment } from 'shared/models/swagger';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getEquipmentBlockInstanceProperty } from 'client/components/Seat/utils';

import { EditButton } from './EditButton';
import styles from './BookingSource.module.css';

interface Props {
  equipment: Equipment;
}
export const BookingSource = ({ equipment }: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const attributes = React.useMemo(() => {
    const attributeKeyCounts = (
      equipment?.equipment_cell_block_mappings || []
    ).reduce<Record<string, number>>((acc, mapping) => {
      const property = getEquipmentBlockInstanceProperty(
        mapping?.equipment_block_instance_key ?? '',
        equipment,
        undefined,
        activeUserOrganization
      );

      const key = property?.equipment_block_instance_attribute_key;
      if (key) {
        acc[key] = (acc[key] || 0) + 1;
      }

      return acc;
    }, {});

    const attributes = (
      activeUserOrganization?.seat_management_settings
        ?.equipment_block_instance_attributes || []
    )
      .map((attribute) => {
        return {
          title: attribute?.title || '',
          color: attribute?.color || '',
          key: attribute?.key || '',
          count: attributeKeyCounts[attribute?.key || ''] ?? 0,
        };
      })
      .filter((attribute) => {
        return attribute.count > 0;
      });

    return attributes;
  }, [equipment, activeUserOrganization]);

  return (
    <section className={styles.container}>
      {attributes.length === 0 && (
        <div className={styles.allocationRow}>
          <p className={clsx(styles.allocationText, styles.noBookingSource)}>
            {' '}
            {t('The booking source group has not been assigned.')}
          </p>
        </div>
      )}
      {attributes.map((attribute, index) => (
        <div className={styles.allocationRow} key={index}>
          <div
            className={styles.directSalesIndicator}
            style={{ backgroundColor: attribute?.color }}
          />
          <p className={styles.allocationText}>
            {' '}
            {attribute?.title} :{' '}
            {t('{{count}} seat', { count: attribute.count })}
          </p>
        </div>
      ))}
      <EditButton
        text={t('Edit Seat Map')}
        onClick={() => {
          console.log('text');
        }}
      />
    </section>
  );
};
