import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import FileSaver from 'file-saver';

import {
  ISSUE_RESERVATION_INVOICE_REQUEST,
  ISSUE_RESERVATION_INVOICE_SUCCESS,
  ISSUE_RESERVATION_INVOICE_FAILURE,
  DOWNLOAD_RESERVATION_INVOICE_PDF_REQUEST,
  DOWNLOAD_RESERVATION_INVOICE_PDF_SUCCESS,
  DOWNLOAD_RESERVATION_INVOICE_PDF_FAILURE,
  SEND_RESERVATION_INVOICE_EMAIL_FAILURE,
  SEND_RESERVATION_INVOICE_EMAIL_REQUEST,
  SEND_RESERVATION_INVOICE_EMAIL_SUCCESS,
  RESET_SEND_RESERVATION_INVOICE_EMAIL_STATUS,
  FETCH_RESERVATION_INVOICE_FAILURE,
  FETCH_RESERVATION_INVOICE_REQUEST,
  FETCH_RESERVATION_INVOICE_SUCCESS,
  ISSUE_RESERVATION_INVOICE_RESET,
  UPDATE_RESERVATION_INVOICE_REQUEST,
  UPDATE_RESERVATION_INVOICE_SUCCESS,
  UPDATE_RESERVATION_INVOICE_FAILURE,
} from 'client/constants/ActionTypes';
import {
  ReservationInvoiceParams,
  GetReservationInvoiceResponse,
} from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const issueReservationInvoiceRequest = (
  newReservationInvoice: ReservationInvoiceParams
) => ({
  type: ISSUE_RESERVATION_INVOICE_REQUEST,
  newReservationInvoice,
});

const issueReservationInvoiceSuccess = (response: any) => ({
  type: ISSUE_RESERVATION_INVOICE_SUCCESS,
  response,
});

const issueReservationInvoiceFailure = (error: any) => ({
  type: ISSUE_RESERVATION_INVOICE_FAILURE,
  error,
});

const issueReservationInvoiceReset = () => ({
  type: ISSUE_RESERVATION_INVOICE_RESET,
});

export const issueReservationInvoice =
  (newReservationInvoice: ReservationInvoiceParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(issueReservationInvoiceRequest(newReservationInvoice));
    return axios
      .post('/api/reservationinvoices', newReservationInvoice, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(issueReservationInvoiceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(issueReservationInvoiceFailure(err.message));
      });
  };

export const resetIssueReservationInvoiceStatus =
  () => (dispatch: Dispatch) => {
    dispatch(issueReservationInvoiceReset());
  };

const fetchReservationInvoiceRequest = () => ({
  type: FETCH_RESERVATION_INVOICE_REQUEST,
});

const fetchReservationInvoiceSuccess = (
  response: GetReservationInvoiceResponse
) => ({
  type: FETCH_RESERVATION_INVOICE_SUCCESS,
  response,
});

const fetchReservationInvoiceFailure = (error: any) => ({
  type: FETCH_RESERVATION_INVOICE_FAILURE,
  error,
});

export const fetchReservationInvoice =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationInvoiceRequest());
    return axios
      .get(`/api/reservationinvoices/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchReservationInvoiceSuccess(response.data));
      })
      .catch((err) => {
        if (err.response) {
          // Treat 404 as success as reservation may not have reservation invoice
          if (err.response.status === 404) {
            dispatch(fetchReservationInvoiceSuccess({}));
          }
        } else {
          dispatch(fetchReservationInvoiceFailure(err.message));
        }
      });
  };

const downloadReservationInvoicePDFRequest = () => ({
  type: DOWNLOAD_RESERVATION_INVOICE_PDF_REQUEST,
});

const downloadReservationInvoicePDFSuccess = () => ({
  type: DOWNLOAD_RESERVATION_INVOICE_PDF_SUCCESS,
});

const downloadReservationInvoicePDFFailure = (error: any) => ({
  type: DOWNLOAD_RESERVATION_INVOICE_PDF_FAILURE,
  error,
});

export const downloadReservationInvoicePDF =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadReservationInvoicePDFRequest());
    axios
      .post(`/api/reservationinvoices/${id}/pdf`, undefined, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        const filename =
          response.headers?.['content-disposition']
            .split('filename=')[1]
            .split(';')[0] ?? `${id}_invoice.pdf`;
        dispatch(downloadReservationInvoicePDFSuccess());
        FileSaver.saveAs(response.data, filename);
      })
      .catch((err) => dispatch(downloadReservationInvoicePDFFailure(err)));
  };

const sendReservationInvoiceEmailRequest = () => ({
  type: SEND_RESERVATION_INVOICE_EMAIL_REQUEST,
});

const sendReservationInvoiceEmailSuccess = () => ({
  type: SEND_RESERVATION_INVOICE_EMAIL_SUCCESS,
});

const sendReservationInvoiceEmailFailure = (error: any) => ({
  type: SEND_RESERVATION_INVOICE_EMAIL_FAILURE,
  error,
});

export const sendReservationInvoiceEmail =
  (reservationId: string, email?: string, attention?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendReservationInvoiceEmailRequest());
    return axios
      .post(
        `/api/reservationinvoices/${reservationId}/email`,
        {
          to_address: email,
          attention: attention,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendReservationInvoiceEmailSuccess()))
      .catch((error) => dispatch(sendReservationInvoiceEmailFailure(error)));
  };

export const resetReservationInvoiceEmailStatus = () => ({
  type: RESET_SEND_RESERVATION_INVOICE_EMAIL_STATUS,
});

const updateReservationInvoiceRequest = (
  id: string,
  patch: ReservationInvoiceParams
) => ({
  type: UPDATE_RESERVATION_INVOICE_REQUEST,
  id,
  patch,
});

const updateReservationInvoiceSuccess = (response: any) => ({
  type: UPDATE_RESERVATION_INVOICE_SUCCESS,
  response,
});

const updateReservationInvoiceFailure = (error: any) => ({
  type: UPDATE_RESERVATION_INVOICE_FAILURE,
  error,
});

export const updateReservationInvoice =
  (id: string, patch: ReservationInvoiceParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateReservationInvoiceRequest(id, patch));
    return axios
      .patch(`/api/reservationinvoices/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateReservationInvoiceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateReservationInvoiceFailure(err.message));
      });
  };
