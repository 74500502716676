import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { equipmentsSelector } from 'client/reducers/equipments';
import { fetchEquipments } from 'client/actions/equipments';
import { fetchProducts } from 'client/actions/products';

import { CustomTable } from './CustomTable';

export const EquipmentScheduleList: React.FC = () => {
  const dispatch = useDispatch();

  const tableHeaderRef = React.useRef<HTMLDivElement>(null);
  const tableBodyRef = React.useRef<HTMLDivElement>(null);

  const equipments = useSelector(equipmentsSelector);

  React.useEffect(() => {
    dispatch(fetchEquipments());
    dispatch(fetchProducts());
  }, []);

  return (
    <div>
      <CustomTable
        items={equipments}
        tableHeaderRef={tableHeaderRef}
        tableBodyRef={tableBodyRef}
      />
    </div>
  );
};
