import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import { Loading } from 'client/pages/Loading';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import { fetchProductTranslations } from 'client/actions/translations';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import type { ReduxState } from 'client/reducers';
import v3BaseStyles from 'client/v3-base.module.css';
import { PcSidebarMenu } from 'client/pages/v3/Product/ProductEdit/Menu/PcSidebarMenu';
import { Button } from 'client/components/v3/Common/Button';
import { SpMenu } from 'client/pages/v3/Product/ProductEdit/Menu/SpMenu';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { SourceLanguage } from 'shared/models/swagger';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { Section } from 'client/pages/v3/Product/ProductEdit/Menu/menuType';

import { ETicketTranslationEditor } from './ETicketTranslationEditor';
import { ETicketTranslationHeader } from './ETicketTranslationHeader';

type Params = {
  id: string;
};

type Props = {
  isNew?: boolean; // Use this to denote create new product
};

export const ETicketTranslation = ({ isNew = false }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id: editingProductId = '' } = useParams<Params>();

  const [translationTargetLanguage, setTranslationTargetLanguage] =
    useState<SourceLanguage | null>(null);
  const loadingTranslations = useSelector(
    (state: ReduxState) => state.translations.loading
  );

  const editingProduct =
    useSelector(
      (state: ReduxState) => state.products.byID[editingProductId || '']
    ) ?? null;

  const languageOptions = contentLanguageOptions
    .map((option) => ({
      value: lowercaseIsoToUppercaseIso[option.iso],
      text: getLanguageName(option.iso, t),
    }))
    .filter((option) => option.value !== editingProduct?.source_language);

  useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [editingProductId]);

  const defaultTranslationTargetLanguage = languageOptions[0].value;
  useEffect(() => {
    setTranslationTargetLanguage(defaultTranslationTargetLanguage);
  }, [defaultTranslationTargetLanguage]);

  useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchProductTranslations(editingProductId));
    }
  }, [editingProductId]);

  // Only show loading when in edit mode
  if (!isNew && !editingProduct) {
    return <Loading />;
  }

  const sections: Section[] = [
    {
      title: t('Ticket Settings'),
      id: 'ticket',
      hasPermission: true,
      subsections: [
        {
          title: t('Basic Settings'),
          id: 'basics',
          hasPermission: true,
        },
        {
          title: t('Start and Expiry Dates'),
          id: 'startTime',
          hasPermission: true,
        },
        {
          title: t('Display Items'),
          id: 'displayItems',
          hasPermission: true,
        },
        {
          title: t('Preview'),
          id: 'preview',
          hasPermission: true,
        },
      ],
    },
  ];

  return (
    <V3Page>
      {!isNew && (
        <Dimmer active={!editingProduct} page={true} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      )}

      <EditingProductContext.Provider value={editingProduct ?? null}>
        <ETicketTranslationHeader product={editingProduct} />
        <div
          className={clsx(
            v3BaseStyles['l-main__body'],
            styles['p-productEditor']
          )}
        >
          <div className={v3BaseStyles['l-main__body__flex']}>
            <div className={v3BaseStyles['l-main__body__flex__left']}>
              <SpMenu sections={sections} />
              <TranslationLanguageContext.Provider
                value={translationTargetLanguage}
              >
                <ETicketTranslationEditor />
              </TranslationLanguageContext.Provider>
              {/* TODO: make fixed footer show up upon scroll */}
              <div
                className={clsx(
                  styles['p-products__fixed'],
                  styles['is-active']
                )}
              >
                <div className={styles['p-products__fixed__main']}></div>
                <div className={styles['p-products__fixed__actions']}>
                  <SingleDropdown
                    width={200}
                    options={languageOptions}
                    onChange={(value) => {
                      setTranslationTargetLanguage(value as any);
                    }}
                    selectedOption={translationTargetLanguage ?? ''}
                    optionOpensAt="top"
                  />
                  <Button
                    text={t('Save')}
                    type="submit"
                    color="primary"
                    size="md"
                    // Associate button with form id
                    form="translationProductForm"
                    loading={loadingTranslations}
                  />
                </div>
              </div>
            </div>
            <div
              className={clsx(
                v3BaseStyles['l-main__body__flex__right'],
                v3BaseStyles['sticky']
              )}
            >
              <PcSidebarMenu sections={sections} />
            </div>
          </div>
        </div>
      </EditingProductContext.Provider>
    </V3Page>
  );
};
