import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReduxState } from 'client/reducers';
import { config } from 'client/config';

export type Origin =
  | 'PAGE_HEADER'
  | 'SETTINGS'
  | 'RESERVATION_LIST'
  | 'RESERVATION_DETAILS'
  | 'RESERVATION_DATA_DOWNLOAD'
  | 'RESERVATION_REPORTS'
  | 'RESERVATION_DASHBOARD'
  | 'PRODUCT_LIST'
  | 'PRODUCT_DETAILS'
  | 'PRODUCT_EDIT'
  | 'PRODUCT_COPY'
  | 'PRODUCT_CREATE'
  | 'PRODUCT_PROMOTIONS'
  | 'AVAILABILITY_WEEKLY'
  | 'AVAILABILITY_MONTHLY'
  | 'MANIFEST_DAILY'
  | 'MANIFEST_RESOURCE_ASSIGNMENT'
  | 'MANIFEST_CUSTOMIZE'
  | 'MANIFEST_EDIT_RESOURCE'
  | 'BOOKING_SITE_SETTINGS'
  | 'BOOKING_SITE_ACCESS_REPORTS'
  | 'BOOKING_SITE_CUSTOMIZE'
  | 'BOOKING_SITE_TOP_PAGE'
  | 'BOOKING_SITE_WEBSITEINTEGRATION'
  | 'BOOKING_SITE_HOMEPAGES'
  | 'SYSTEM_FEE_WEB_BOOKINGS'
  | 'SYSTEM_FEE_SUBSCRIPTIONS'
  | 'SYSTEM_FEE_SETTINGS'
  | 'CUSTOMER_SETTINGS';

// Hooks to switch between new/old UI when setting is applied
export const useNewUISwitch = (origin: Origin) => {
  const shouldUseNewUI = useSelector(
    (state: ReduxState) => state.newUIControls.useNewUI
  );
  const history = useHistory();

  useEffect(() => {
    // Do not redirect if feature flag is enabled
    if (config.enableUIRevamp) {
      return;
    }

    // Redirect to old UI when setting is disabled
    if (!shouldUseNewUI) {
      const currentUrl = history.location.pathname;
      history.push(currentUrl.replace('-v3', ''));
    }

    // Redirect to new UI when setting is enabled
    if (shouldUseNewUI) {
      const currentUrl = history.location.pathname;

      if (origin === 'SETTINGS') {
        history.push(currentUrl.replace('settings', 'settings-v3'));
      }
      if (origin === 'RESERVATION_LIST') {
        history.push('/reservations-v3');
      }
      if (origin === 'RESERVATION_DETAILS') {
        history.push(currentUrl.replace('reservations', 'reservations-v3'));
      }
      if (origin === 'RESERVATION_DATA_DOWNLOAD') {
        history.push(currentUrl.replace('download', 'download-v3'));
      }
      if (origin === 'RESERVATION_REPORTS') {
        history.push(currentUrl.replace('reports', 'reports-v3'));
      }
      if (origin === 'RESERVATION_DASHBOARD') {
        history.push(currentUrl.replace('dash', 'dash-v3'));
      }
      if (origin === 'PRODUCT_LIST') {
        history.push('/products-v3');
      }
      if (origin === 'PRODUCT_DETAILS') {
        history.push(currentUrl.replace('products', 'products-v3'));
      }
      if (origin === 'PRODUCT_EDIT') {
        history.push(currentUrl.replace('edit', 'edit-v3'));
      }
      if (origin === 'PRODUCT_COPY') {
        history.push(currentUrl.replace('copy', 'copy-v3'));
      }
      if (origin === 'PRODUCT_CREATE') {
        history.push(currentUrl.replace('new', 'new-v3'));
      }
      if (origin === 'PRODUCT_PROMOTIONS') {
        history.push(currentUrl.replace('promotions', 'promotions-v3'));
      }
      if (origin === 'AVAILABILITY_WEEKLY') {
        history.push('/availability/weekly-v3');
      }
      if (origin === 'AVAILABILITY_MONTHLY') {
        history.push('/availability/monthly-v3');
      }
      if (origin === 'MANIFEST_DAILY') {
        history.push('/manifests-v3');
      }
      if (origin === 'MANIFEST_RESOURCE_ASSIGNMENT') {
        history.push('/manifests/assignments-v3');
      }
      if (origin === 'MANIFEST_CUSTOMIZE') {
        history.push('/manifests/customize-v3');
      }
      if (origin === 'MANIFEST_EDIT_RESOURCE') {
        history.push('/manifests/resources-v3');
      }
      if (origin === 'BOOKING_SITE_ACCESS_REPORTS') {
        history.push(currentUrl.replace('reports', 'reports-v3'));
      }
      if (origin === 'BOOKING_SITE_SETTINGS') {
        history.push(currentUrl.replace('general', 'general-v3'));
      }
      if (origin === 'BOOKING_SITE_CUSTOMIZE') {
        history.push(currentUrl.replace('customize', 'customize-v3'));
      }
      if (origin === 'BOOKING_SITE_TOP_PAGE') {
        history.push(currentUrl.replace('topPage', 'topPage-v3'));
      }
      if (origin === 'BOOKING_SITE_WEBSITEINTEGRATION') {
        history.push(
          currentUrl.replace('websiteintegration', 'websiteintegration-v3')
        );
      }
      if (origin === 'BOOKING_SITE_HOMEPAGES') {
        history.push(currentUrl.replace('homepages', 'homepages-v3'));
      }
      if (origin === 'SYSTEM_FEE_WEB_BOOKINGS') {
        history.push(currentUrl.replace('reservations', 'reservations-v3'));
      }
      if (origin === 'SYSTEM_FEE_SUBSCRIPTIONS') {
        history.push(currentUrl.replace('subscriptions', 'subscriptions-v3'));
      }
      if (origin === 'SYSTEM_FEE_SETTINGS') {
        history.push(currentUrl.replace('settings', 'settings-v3'));
      }
      if (origin === 'CUSTOMER_SETTINGS') {
        history.push(currentUrl.replace('settings', 'settings-v3'));
      }
    }
  }, [shouldUseNewUI, history]);

  return { shouldUseNewUI };
};
