import type { Organization, Field$Patch } from 'shared/models/swagger';

export const ntaReservationFormFields: Field$Patch[] = [
  {
    key: 'お客様名（ローマ字）苗字',
    type: 'PER_PARTICIPANT',
    prompt: 'お客様名（ローマ字）苗字',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'お客様名（ローマ字）名',
    type: 'PER_PARTICIPANT',
    prompt: 'お客様名（ローマ字）名',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'gender',
    type: 'PER_PARTICIPANT',
    prompt: '性別',
    format: '',
    choices: ['男性', '女性'],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'パスポート番号',
    type: 'PER_PARTICIPANT',
    prompt: 'パスポート番号',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'date_of_birth',
    type: 'PER_PARTICIPANT',
    prompt: '生年月日',
    format: 'yyyy-mm-dd',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '国籍',
    type: 'PER_PARTICIPANT',
    prompt: '国籍',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'エキストラベッド手配',
    type: 'PER_BOOKING',
    prompt: 'エキストラベッド手配',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '22時以降チェックインの希望',
    type: 'PER_BOOKING',
    prompt: '22時以降チェックインの希望',
    format: '',
    choices: ['希望する', '希望しない'],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'お客様番号（＝部屋割り）',
    type: 'PER_BOOKING',
    prompt: 'お客様番号（＝部屋割り）',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'ベッドタイプ',
    type: 'PER_BOOKING',
    prompt: 'ベッドタイプ',
    format: '',
    choices: ['シングル', 'ツイン', 'ダブル', 'クイーン', 'キング'],
    required: 'WHEN_BOOKING',
  },
  {
    key: '定員',
    type: 'PER_BOOKING',
    prompt: '定員',
    format: 'non-negative-integer',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '取消料課金条件',
    type: 'PER_BOOKING',
    prompt: '取消料課金条件',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'チェックイン日',
    type: 'PER_BOOKING',
    prompt: 'チェックイン日',
    format: 'yyyy-mm-dd',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'チェックアウト日',
    type: 'PER_BOOKING',
    prompt: 'チェックアウト日',
    format: 'yyyy-mm-dd',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '年齢制限',
    type: 'PER_BOOKING',
    prompt: '年齢制限',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '幼児旅客の識別（添い寝）',
    type: 'PER_BOOKING',
    prompt: '幼児旅客の識別（添い寝）',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '手配者のリクエスト',
    type: 'PER_BOOKING',
    prompt: '手配者のリクエスト',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '部屋タイプ',
    type: 'PER_BOOKING',
    prompt: '部屋タイプ',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'フライト情報（in）',
    type: 'PER_BOOKING',
    prompt: 'フライト情報（in）',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'フライト情報（out）',
    type: 'PER_BOOKING',
    prompt: 'フライト情報（out）',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: 'NTA予約番号（および旅客番号）',
    type: 'PER_BOOKING',
    prompt: 'NTA予約番号（および旅客番号）',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '手配箇所',
    type: 'PER_BOOKING',
    prompt: '手配箇所',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
  {
    key: '在庫管理箇所',
    type: 'PER_BOOKING',
    prompt: '在庫管理箇所',
    format: '',
    choices: [],
    required: 'WHEN_BOOKING',
  },
];
const ntaSuppliers = [
  // dev
  // Goro Supplier 1
  'b1a67d33-39d4-517b-ae25-dc8ad0ce3c7e',
  // stg
  // Sunrise Tours & Travel Sdn. Bhd.
  '5e443344-9d5a-5ba6-92f1-860695b80abe',
  // P.T. JABATO INTERNATIONAL
  'c45f1f50-bec1-56c9-a8c5-ac39bad425ee',
  // NTA TRAVEL(SINGAPORE) PTE.LTD.
  'ef98155b-99cb-5fca-a2a3-beddfaaff56b',
  // NTA デモサイト
  //'74cacf57-a844-5477-888b-afb2873d3ad9',
];
export const isNta = (organization: Organization | null) => {
  if (!organization) {
    return false;
  }

  return ntaSuppliers.includes(organization?.id);
};
