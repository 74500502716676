import clsx from 'clsx';
import React from 'react';

import styles from './InternalChat.module.css';

type StackProps = {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  spacing?: number;
  className?: string;
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  ref?: React.Ref<HTMLDivElement>;
};

export const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  (
    {
      children,
      direction = 'row',
      spacing = 0,
      className,
      justifyContent,
      alignItems,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(styles['stack'], className)}
        style={{
          flexDirection: direction,
          gap: `${spacing * 8}px`,
          justifyContent,
          alignItems,
        }}
      >
        {children}
      </div>
    );
  }
);
