import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import {
  checkinReservation,
  undoCheckinReservation,
} from 'client/actions/reservations';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import type { Reservation, Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';
import editIcon from 'client/images/ic_edit.svg';

import { EditFixedExpirationDateTimeModal } from './EditFixedExpirationDateTimeModal';
import styles from './ReservationCheckinTab.module.css';

type Props = {
  reservation?: Reservation;
  product?: Product;
};

export const ReservationCheckinTab = ({ reservation, product }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    openFixedExpirationDateTimeModal,
    setOpenFixedExpirationDateTimeModal,
  ] = React.useState(false);

  const activeUser = useSelector(activeUserSelector);

  const [undoCheckinLoadingStubKey, setUndoCheckinLoadingStubKey] =
    React.useState<string | null>(null);
  const [checkinLoadingStubKey, setCheckinLoadingStubKey] = React.useState<
    string | null
  >(null);

  const checkin = async (stubKey: string) => {
    try {
      setCheckinLoadingStubKey(stubKey);
      await dispatch(
        checkinReservation(reservation?.id ?? '', {
          email: '',
          guest_count: (reservation?.guests ?? []).length,
          package_component_reservation_id: '',
          stub_key: stubKey,
        })
      );
    } finally {
      setCheckinLoadingStubKey(null);
    }
  };

  const undoCheckin = async (stubKey: string) => {
    try {
      setUndoCheckinLoadingStubKey(stubKey);
      await dispatch(
        undoCheckinReservation(reservation?.id ?? '', {
          stub_key: stubKey,
        })
      );
    } finally {
      setUndoCheckinLoadingStubKey(null);
    }
  };

  let records: {
    key: string;
    text: string;
    dateTime: Moment | null;
    stubOptionText: string;
  }[] = [];
  if ((product?.qr_checkin_settings?.stubs ?? []).length > 0) {
    records = (product?.qr_checkin_settings?.stubs || [])
      .map((stub) => {
        const dateTime = getCheckinTime(reservation ?? null, stub.key ?? '');
        return {
          key: stub.key ?? '',
          text:
            reservation?.free_start_date_time_from &&
            reservation?.free_start_date_time_to
              ? `${t('Free Pass')}(${stub.text ?? ''})`
              : stub.text ?? '',
          dateTime: dateTime,
          stubOptionText: getCheckinStubOptionText(
            reservation ?? null,
            product ?? null,
            stub.key ?? ''
          ),
        };
      })
      .sort((a, b) => {
        if (!a.dateTime) {
          return 1;
        }

        if (!b.dateTime) {
          return -1;
        }

        if (a.dateTime.isBefore(b.dateTime)) {
          return -1;
        } else {
          return 1;
        }
      });
  } else {
    const checkinRecords = reservation?.checkin_info?.checkin_records ?? [];
    const isFreeStartDateTime =
      reservation?.free_start_date_time_from &&
      reservation?.free_start_date_time_to;

    records = [
      {
        key: '',
        text: isFreeStartDateTime ? t('Free Pass') : t('Redeem Ticket'),
        dateTime:
          checkinRecords.length === 0
            ? null
            : moment.tz(
                checkinRecords[0].date_time_utc,
                reservation?.start_timezone || 'UTC'
              ),
        stubOptionText: '',
      },
    ];
  }

  const expirationDateTime =
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
    reservation?.checkin_info?.expiration_date_time_utc;

  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Ticket Redemptions')}
        </div>
      </div>

      {expirationDateTime && (
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <table className={clsx(baseStyles['base-table'])}>
            <tbody>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Expiration Date')}
                </th>
                <td>
                  <div
                    className={clsx(pageStyles['page-reservations__reference'])}
                  >
                    <div
                      className={clsx(
                        pageStyles['page-reservations__reference_text']
                      )}
                    >
                      {moment
                        .tz(
                          expirationDateTime,
                          reservation?.start_timezone || 'UTC'
                        )
                        .format('lll')}
                    </div>
                    <div
                      className={clsx(
                        pageStyles['page-reservations__reference_btn']
                      )}
                    >
                      <a
                        className={clsx(
                          baseStyles['base-btn icon'],
                          baseStyles['icon']
                        )}
                        onClick={() =>
                          setOpenFixedExpirationDateTimeModal(true)
                        }
                      >
                        <img src={editIcon} />
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {expirationDateTime && (
          <Box mb={2}>
            <div
              className={clsx(baseStyles['base-main__body__box__header__ttl'])}
            >
              {t('Redemptions History')}
            </div>
          </Box>
        )}
        <div className={clsx(styles['c-table-nowrap'])}>
          <table>
            <tbody>
              <tr>
                {hasCustomUserRoleWritePermissions(
                  activeUser,
                  'RESERVATION.LIST'
                ) && (
                  <>
                    <th className={clsx(baseStyles['base-t-192'])}></th>
                    <th className={clsx(baseStyles['base-t-192'])}></th>
                  </>
                )}
                <th
                  className={clsx(
                    baseStyles['base-t-192'],
                    styles['center-align']
                  )}
                >
                  {t('Date/Time')}
                </th>
                <th>{t('Ticket Usage')}</th>
                <th>{t('Ticket Usage Option')}</th>
              </tr>
              {records.map((record, idx) => {
                return (
                  <tr key={idx}>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'RESERVATION.LIST'
                    ) && (
                      <>
                        <td className={clsx(styles['center-align'])}>
                          <Button
                            size="middle"
                            style="blue"
                            disabled={!!record.dateTime}
                            onClick={() => {
                              checkin(record.key ?? '');
                            }}
                            loading={checkinLoadingStubKey === record.key}
                          >
                            {t('Redeem Ticket')}
                          </Button>
                        </td>
                        <td className={clsx(styles['center-align'])}>
                          <Button
                            size="middle"
                            style="gray"
                            disabled={!record.dateTime}
                            onClick={() => {
                              undoCheckin(record.key ?? '');
                            }}
                            loading={undoCheckinLoadingStubKey === record.key}
                          >
                            {t('Delete record')}
                          </Button>
                        </td>
                      </>
                    )}
                    <td className={clsx(styles['center-align'])}>
                      {record.dateTime?.format('lll') ?? ''}
                    </td>
                    <td>{record.text}</td>
                    <td>{record.stubOptionText}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {reservation && openFixedExpirationDateTimeModal && (
        <EditFixedExpirationDateTimeModal
          open={openFixedExpirationDateTimeModal}
          onClose={() => setOpenFixedExpirationDateTimeModal(false)}
          reservation={reservation}
        />
      )}
    </div>
  );
};

const getCheckinTime = (
  reservation: Reservation | null,
  stub_key: string
): Moment | null => {
  if (!reservation || !stub_key) {
    return null;
  }

  const record = (reservation?.checkin_info?.checkin_records || []).find(
    (record) => {
      return record.stub_key === stub_key;
    }
  );

  if (record) {
    return moment.tz(record.date_time_utc, reservation.start_timezone || 'UTC');
  }

  return null;
};

const getCheckinStubOptionText = (
  reservation: Reservation | null,
  product: Product | null,
  stubKey: string
): string => {
  if (!reservation || !product || !stubKey) {
    return '';
  }

  const record = (reservation?.checkin_info?.checkin_records || []).find(
    (record) => {
      return record.stub_key === stubKey;
    }
  );

  if (!record) {
    return '';
  }

  if (!record.stub_option_key) {
    return '';
  }

  const stub = (product?.qr_checkin_settings?.stubs || []).find((stub) => {
    return stub.key === stubKey;
  });

  if (!stub) {
    return '';
  }

  const option = (stub?.options || []).find((option) => {
    return option.key === record.stub_option_key;
  });

  if (!option) {
    return '';
  }

  return option.text ?? '';
};
