import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import {
  convertToBookingDeadlinePatch,
  convertToCancellationPolicyPatch,
} from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import {
  convertToBookingDeadline,
  convertToCancellationPolicy,
  isDefaultCancellationPolicy,
} from 'client/libraries/util/productShape';
import { getWeekdayText } from 'client/libraries/util/weekdays';
import type {
  StartTime,
  PackageComponentTimeSlot,
} from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import type {
  BookingDeadline,
  CancellationPolicy,
} from 'client/libraries/util/productShape';
import type { TranslateFuncType } from 'client/components/Translate';
import type { Weekday } from 'client/libraries/util/weekdays';
import * as Swagger from 'shared/models/swagger';

import type { Price, PerGroupPrice, Schedule } from '../types';

export type { BookingDeadline, CancellationPolicy, StartTime };
const freesaleSlotLimit = 10000000;
export type ClosedDate = {
  date: string;
  repeatsAnnually: boolean;
};
export type PriceSchedule = Schedule & {
  title: string;
  method: 'PER_PARTICIPANT' | 'PER_BOOKING';
  prices: Price[];
  // Per-participant schedules may also have per-group prices
  perGroupPrices: PerGroupPrice[];
  applySurcharge: boolean;
  surchargeTitle?: string;
  surchargeRate?: string;
  applyMinimumPrice: boolean;
  minimumGross?: string;
  minimumNet?: string;
};
export type AvailabilityAllotmentSchedule = {
  dateRanges: {
    startDate: string;
    endDate: string;
  }[];
  weekdays: Weekday[];
  closedDates: ClosedDate[];
  startTimes: StartTime[];
  key: string;
};
export type ReservationParamsFormValues = {
  availabilityAllotmentSchedules: AvailabilityAllotmentSchedule[];
  requestBookingDeadline?: BookingDeadline;
  instantBookingDeadline?: BookingDeadline;
  cancellationPolicies: CancellationPolicy[];
  timezone: string;
  agentGuestBookingAllowedDaysBefore: number | null;
  allowRequestBookingBeyondCapacity: boolean;
  shouldUseBookingStartDateTime: boolean;
  bookingStartDateTimeUtc: string;
  shouldSendReminderEmail: boolean;
};
export const convertFormValuesToRecurrence = (
  availabilityAllotmentSchedules: AvailabilityAllotmentSchedule[]
): Swagger.Recurrence$Patch[] => {
  return availabilityAllotmentSchedules.map((sched) => ({
    start_date_local:
      sched.dateRanges.length > 0 ? sched.dateRanges[0].startDate : '',
    end_date_local:
      sched.dateRanges.length > 0 ? sched.dateRanges[0].endDate : '',
    additional_date_ranges: sched.dateRanges.slice(1).map((dateRange) => ({
      start_date_local: dateRange.startDate,
      end_date_local: dateRange.endDate,
    })),
    closed_dates: sched.closedDates
      .filter((closedDate) => !closedDate.repeatsAnnually)
      .map((closedDate) => closedDate.date),
    annually_repeating_closed_dates: sched.closedDates
      .filter((closedDate) => closedDate.repeatsAnnually)
      .map((closedDate) => closedDate.date),
    days_of_week: sched.weekdays,
    start_times: sched.startTimes.map((startTime) => ({
      start_time_local: startTime.time,
      duration: startTime.duration,
      description: startTime.description,
      time_slot_key:
        startTime.timeSlotKey || `${startTime.time}-${startTime.duration}`,
      parent_product_time_slot_key: startTime.parentProductTimeSlotKey,
      third_party_reference: startTime.referenceInSupplierSystem,
      package_component_time_slots: startTime.packageComponentTimeSlots.map(
        (packageComponentTimeSlot) => ({
          product_id: packageComponentTimeSlot.productId,
          time_slot_key: packageComponentTimeSlot.timeSlotKey,
          day_offset: packageComponentTimeSlot.dayOffset,
          transportation_key: packageComponentTimeSlot.transportation_key,
          pickup: packageComponentTimeSlot.pickup
            ? {
                id: packageComponentTimeSlot.pickup.id,
                date_time_utc: packageComponentTimeSlot.pickup.dateTimeUtc,
                location_name: packageComponentTimeSlot.pickup.locationName,
                location_description:
                  packageComponentTimeSlot.pickup.locationDescription,
                google_place_id: packageComponentTimeSlot.pickup.googlePlaceId,
              }
            : undefined,
          dropoff: packageComponentTimeSlot.dropoff
            ? {
                id: packageComponentTimeSlot.dropoff.id,
                date_time_utc: packageComponentTimeSlot.dropoff.dateTimeUtc,
                location_name: packageComponentTimeSlot.dropoff.locationName,
                location_description:
                  packageComponentTimeSlot.dropoff.locationDescription,
                google_place_id: packageComponentTimeSlot.dropoff.googlePlaceId,
              }
            : undefined,
        })
      ),
    })),
    key: sched.key || uuidv4(),
  }));
};
export const convertReservationParamsFormValuesToProductPatch = (
  values: ReservationParamsFormValues,
  isPackage: boolean,
  currency: string
): Swagger.Product$Patch => {
  const bookingDeadlines: Swagger.BookingDeadline$Patch[] = [];

  if (values.requestBookingDeadline) {
    bookingDeadlines.push(
      convertToBookingDeadlinePatch(values.requestBookingDeadline, 'REQUEST')
    );
  }

  if (values.instantBookingDeadline) {
    bookingDeadlines.push(
      convertToBookingDeadlinePatch(values.instantBookingDeadline, 'INSTANT')
    );
  }

  const cancellationPolicies: Swagger.CancellationPolicy$Patch[] = [];

  for (const cancellationPolicy of values.cancellationPolicies) {
    cancellationPolicies.push(
      convertToCancellationPolicyPatch(cancellationPolicy, currency)
    );
  }

  if (
    !cancellationPolicies.some(
      (policy) => policy.relative_date?.type === 'ETERNAL'
    )
  ) {
    cancellationPolicies.push({
      relative_date: {
        type: 'HOUR',
        count: 0,
      },
      charge_percent: 100,
    });
  }

  const recurrence = convertFormValuesToRecurrence(
    values.availabilityAllotmentSchedules
  );
  const allotmentRules = !isPackage
    ? values.availabilityAllotmentSchedules.map((sched) => ({
        start_date_local:
          sched.dateRanges.length > 0 ? sched.dateRanges[0].startDate : '',
        end_date_local:
          sched.dateRanges.length > 0 ? sched.dateRanges[0].endDate : '',
        additional_date_ranges: sched.dateRanges.slice(1).map((dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })),
        exception_dates: sched.closedDates
          .filter((closedDate) => !closedDate.repeatsAnnually)
          .map((closedDate) => closedDate.date),
        annually_repeating_closed_dates: sched.closedDates
          .filter((closedDate) => closedDate.repeatsAnnually)
          .map((closedDate) => closedDate.date),
        days_of_week: sched.weekdays,
        start_time_allotments: sched.startTimes.map((startTime) => ({
          time_slot_key:
            startTime.timeSlotKey || `${startTime.time}-${startTime.duration}`,
          common_allotment_slots: parseInt(startTime.allotmentSlots as any),
          agent_allotments: (startTime.perChannelAllotments ?? []).map(
            (perChannelAllotment) => ({
              channel_category: perChannelAllotment.channel,
              agent_id: perChannelAllotment.agentId ?? '',
              slots: parseInt(perChannelAllotment.allotmentSlots as any),
            })
          ),
        })),
      }))
    : [];
  const isRequestOnlyProduct = schedulesHaveRequestOnly(
    values.availabilityAllotmentSchedules
  );
  return {
    booking_deadlines: bookingDeadlines,
    cancellation_policies: cancellationPolicies,
    recurrence,
    allotment_rules: allotmentRules,
    start_timezone: values.timezone,
    agent_guest_booking_period_settings: {
      booking_allowed_days_before_participation:
        values.agentGuestBookingAllowedDaysBefore ?? 0,
      should_use_booking_start_date_time: values.shouldUseBookingStartDateTime,
      booking_start_date_time_utc: values.bookingStartDateTimeUtc,
    },
    request_booking_settings: {
      should_reject_bookings_beyond_capacity:
        !isRequestOnlyProduct && !values.allowRequestBookingBeyondCapacity,
    },
  };
};
export const getInitialAvailabilityAllotmentSchedules = (
  product: Swagger.Product | null,
  componentProducts: Swagger.Product[]
): AvailabilityAllotmentSchedule[] => {
  return (product?.recurrence ?? []).map((recurrenceRule) => {
    const correspondingAllotmentRule = product?.allotment_rules?.find(
      (allotmentRule) =>
        allotmentRule.start_date_local <= recurrenceRule.start_date_local &&
        allotmentRule.end_date_local >= recurrenceRule.end_date_local &&
        (allotmentRule.days_of_week ?? []).some((weekday) =>
          recurrenceRule.days_of_week?.includes(weekday)
        ) &&
        !closeDatesCoversAllRange(
          allotmentRule.exception_dates ?? [],
          recurrenceRule.start_date_local,
          recurrenceRule.end_date_local
        ) &&
        matchAllTimeSlotKeys(
          recurrenceRule?.start_times || [],
          allotmentRule?.start_time_allotments || []
        )
    );

    const closedDates = _.sortBy(
      [
        ...(recurrenceRule.closed_dates ?? []).map((closedDate) => ({
          date: closedDate,
          repeatsAnnually: false,
        })),
        ...(recurrenceRule.annually_repeating_closed_dates ?? []).map(
          (closedDate) => ({
            date: closedDate,
            repeatsAnnually: true,
          })
        ),
      ],
      (closedDate) => closedDate.date
    );

    return {
      dateRanges: [
        {
          startDate: recurrenceRule?.start_date_local,
          endDate: recurrenceRule?.end_date_local,
        },
        ...(recurrenceRule?.additional_date_ranges ?? []).map((dateRange) => ({
          startDate: dateRange?.start_date_local ?? '',
          endDate: dateRange?.end_date_local ?? '',
        })),
      ],
      weekdays: recurrenceRule?.days_of_week ?? [],
      closedDates,
      startTimes: recurrenceRule?.start_times.map((startTime) => {
        let packageComponentTimeSlots: PackageComponentTimeSlot[] = (
          startTime.package_component_time_slots ?? []
        ).map((packageComponentTimeSlot) => ({
          productId: packageComponentTimeSlot.product_id ?? '',
          timeSlotKey: packageComponentTimeSlot.time_slot_key ?? '',
          dayOffset: packageComponentTimeSlot.day_offset ?? 0,
          transportation: packageComponentTimeSlot.transportation_key,
          pickup: packageComponentTimeSlot.pickup
            ? {
                id: packageComponentTimeSlot.pickup.id ?? '',
                dateTimeUtc:
                  packageComponentTimeSlot.pickup.date_time_utc ?? '',
                locationName:
                  packageComponentTimeSlot.pickup.location_name ?? '',
                locationDescription:
                  packageComponentTimeSlot.pickup.location_description ?? '',
                googlePlaceId:
                  packageComponentTimeSlot.pickup.google_place_id ?? '',
              }
            : undefined,
          dropoff: packageComponentTimeSlot.dropoff
            ? {
                id: packageComponentTimeSlot.dropoff.id ?? '',
                dateTimeUtc:
                  packageComponentTimeSlot.dropoff.date_time_utc ?? '',
                locationName:
                  packageComponentTimeSlot.dropoff.location_name ?? '',
                locationDescription:
                  packageComponentTimeSlot.dropoff.location_description ?? '',
                googlePlaceId:
                  packageComponentTimeSlot.dropoff.google_place_id ?? '',
              }
            : undefined,
        }));

        if (packageComponentTimeSlots.length < componentProducts.length) {
          packageComponentTimeSlots = componentProducts.map(
            (componentProduct) => ({
              productId: componentProduct.id,
              timeSlotKey: '',
              dayOffset: 0,
            })
          );
        }

        const startTimeAllotment =
          correspondingAllotmentRule?.start_time_allotments.find(
            (rule) => rule.time_slot_key === startTime.time_slot_key
          );
        return {
          time: startTime.start_time_local,
          duration: startTime.duration,
          timeSlotKey: startTime.time_slot_key ?? '',
          parentProductTimeSlotKey:
            startTime.parent_product_time_slot_key ?? '',
          perChannelAllotments: (
            startTimeAllotment?.agent_allotments || []
          ).map((channelAllotment) => ({
            channel: channelAllotment.channel_category ?? 'AGENT',
            allotmentSlots: channelAllotment.slots ?? 0,
            agentId: channelAllotment.agent_id ?? null,
          })),
          allotmentSlots: startTimeAllotment?.common_allotment_slots ?? 0,
          isFreesale:
            (startTimeAllotment?.common_allotment_slots ?? 0) >=
            freesaleSlotLimit,
          description: startTime.description ?? '',
          referenceInSupplierSystem: startTime.third_party_reference,
          packageComponentTimeSlots,
        };
      }),
      key: recurrenceRule.key ?? '',
    };
  });
};

const matchAllTimeSlotKeys = (
  recurrenceRuleStartTimes: Swagger.StartTime[],
  allotmentRuleStartTimes: Swagger.StartTimeAllotment[]
) => {
  if (recurrenceRuleStartTimes.length !== allotmentRuleStartTimes.length) {
    return false;
  }

  return recurrenceRuleStartTimes.every((startTime) =>
    allotmentRuleStartTimes?.some(
      (startTimeAllotment) =>
        startTime?.time_slot_key === startTimeAllotment?.time_slot_key
    )
  );
};

const closeDatesCoversAllRange = (
  closeDates: string[],
  startDateLocal: string,
  endDateLocal: string
): boolean => {
  if (!startDateLocal || !endDateLocal) {
    return false;
  }

  if (closeDates.length === 0) {
    return false;
  }

  let dateFrom = moment(startDateLocal);
  const dateTo = moment(endDateLocal);

  if (closeDates.length < dateFrom.diff(dateTo, 'days') + 1) {
    return false;
  }

  while (dateFrom.isSameOrBefore(dateTo)) {
    if (!closeDates.includes(dateFrom.format('YYYY-MM-DD'))) {
      return false;
    }

    dateFrom = dateFrom.add(1, 'd');
  }

  return true;
};

export const getInitialValues = (
  product: Swagger.Product | null
): ReservationParamsFormValues => {
  const availabilityAllotmentSchedules =
    getInitialAvailabilityAllotmentSchedules(product, []);
  let instantBookingDeadline: BookingDeadline | undefined = undefined;
  let requestBookingDeadline: BookingDeadline | undefined = undefined;
  const productHasBookingDeadlines =
    product?.booking_deadlines != null && product.booking_deadlines.length > 0;
  const productInstantBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'INSTANT'
  );
  const productRequestBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'REQUEST'
  );

  if (productHasBookingDeadlines) {
    if (productInstantBookingDeadline) {
      instantBookingDeadline = convertToBookingDeadline(
        productInstantBookingDeadline
      );
    }

    if (productRequestBookingDeadline) {
      requestBookingDeadline = convertToBookingDeadline(
        productRequestBookingDeadline
      );
    }
  } else {
    instantBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 2,
      timeOfDay: '17:00',
    };
    requestBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 1,
      timeOfDay: '17:00',
    };
  }

  const cancellationPolicies: CancellationPolicy[] =
    product?.cancellation_policies != null &&
    product.cancellation_policies.length > 0
      ? product.cancellation_policies
          .filter(
            (cancellationPolicy) =>
              !isDefaultCancellationPolicy(cancellationPolicy)
          )
          .map((cancellationPolicy) =>
            convertToCancellationPolicy(cancellationPolicy)
          )
      : [
          {
            deadlineType: 'DAY',
            daysBefore: 2,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '0',
          },
          {
            deadlineType: 'DAY',
            daysBefore: 1,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '50',
          },
        ];
  return {
    availabilityAllotmentSchedules,
    instantBookingDeadline,
    requestBookingDeadline,
    cancellationPolicies,
    timezone: product?.start_timezone ?? '',
    agentGuestBookingAllowedDaysBefore:
      product?.agent_guest_booking_period_settings
        ?.booking_allowed_days_before_participation ?? null,
    shouldUseBookingStartDateTime:
      product?.agent_guest_booking_period_settings
        ?.should_use_booking_start_date_time ?? false,
    bookingStartDateTimeUtc:
      product?.agent_guest_booking_period_settings
        ?.booking_start_date_time_utc ?? moment.utc().format(),
    allowRequestBookingBeyondCapacity:
      !product?.request_booking_settings
        ?.should_reject_bookings_beyond_capacity,
    shouldSendReminderEmail:
      !product?.guest_email_send_settings?.do_not_send_reminder_email,
  };
};
export const getAvailabilityRuleScheduleText = (
  schedule: AvailabilityAllotmentSchedule,
  t: TranslateFuncType
): string => {
  const weekdayText =
    schedule.weekdays.length >= 7
      ? t('Everyday')
      : schedule.weekdays
          .map((weekday) => getWeekdayText(weekday, t))
          .join(',');

  if (schedule.dateRanges && schedule.dateRanges.length > 0) {
    return `${schedule.dateRanges
      .map((dateRange) => {
        if (dateRange.startDate && dateRange.endDate) {
          if (dateRange.startDate === dateRange.endDate) {
            return dateRange.startDate;
          } else {
            return `${dateRange.startDate} ~ ${dateRange.endDate}`;
          }
        }

        if (dateRange.startDate) {
          return `${dateRange.startDate} ~ ${t('Unknown')}`;
        }

        if (dateRange.endDate) {
          return `${t('Unknown')} ~ ${dateRange.endDate}`;
        }
      })
      .join(',')} - ${weekdayText}`;
  }

  return weekdayText;
};
export const schedulesHaveRequestOnly = (
  schedules?: AvailabilityAllotmentSchedule[]
): boolean => {
  return (schedules ?? []).some((schedule) =>
    schedule.startTimes.some(
      (startTime) =>
        !startTime.allotmentSlots &&
        !startTime.perChannelAllotments?.some(
          (allotment) => allotment.allotmentSlots
        )
    )
  );
};
export const schedulesHaveFreesaleOnly = (
  schedules?: AvailabilityAllotmentSchedule[]
): boolean => {
  return !(schedules ?? []).some((schedule) =>
    schedule.startTimes.some(
      (startTime) => (startTime.allotmentSlots ?? 0) < freesaleSlotLimit
    )
  );
};
