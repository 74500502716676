import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from 'client/components/v3/Common/Button';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import type { Equipment } from 'shared/models/swagger';

import { Operations } from './Operations';
import { RuleList } from './RuleList';
import { BookingSource } from './BookingSource';
import { EquipmentTitle } from './EquipmentTitle';
import styles from './EquipmentScheduleList.module.css';

type Props = {
  items: Equipment[];
  tableHeaderRef: React.RefObject<HTMLDivElement>;
  tableBodyRef: React.RefObject<HTMLDivElement>;
};

const equipmentTiltleColumnWidth = '200px';
const bookingSourceColumnWidth = '220px';
const ruleColumnWidth = '50%';

export const CustomTable = ({ items, tableHeaderRef, tableBodyRef }: Props) => {
  const { t } = useTranslation();

  const [showReservationColumns, setShowReservationColumns] = React.useState(
    [...Array(items)].map(() => false)
  );

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const defaultRowCount = 10;
  const [rowCount, setRowCount] = React.useState(defaultRowCount);
  const [currentPage, setCurrentPage] = React.useState(1);

  const rowCountChangeHandler = (count: number) => {
    setRowCount(count);
    setCurrentPage(1);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [items, rowCount]);
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className={styles['p-reservations__body']}>
        <div className={clsx(styles['c-tableFrame'], styles['arrow'])}>
          <div
            className={clsx(
              styles['c-table'],
              styles['thead'],
              styles['sticky-top']
            )}
            ref={tableHeaderRef}
          >
            <table className={styles['c-table__body']}>
              <thead>
                <tr>
                  <th
                    className={clsx(
                      styles['thead__th'],
                      styles['sticky-top'],
                      styles['sticky-left']
                    )}
                    style={{
                      width: equipmentTiltleColumnWidth,
                    }}
                  >
                    {t('Resource Name')}
                  </th>
                  <th
                    className={clsx(styles['thead__th'])}
                    style={{
                      width: bookingSourceColumnWidth,
                    }}
                  >
                    {t('Seat')}
                  </th>
                  <th
                    className={clsx(styles['thead__th'])}
                    style={{
                      width: ruleColumnWidth,
                    }}
                  >
                    {t('Participation Rules')}
                  </th>
                  <th className={clsx(styles['thead__th'])}>{t('Action')}</th>
                </tr>
              </thead>
            </table>
          </div>

          <div
            className={clsx(styles['c-table'], styles['tbody'])}
            onScroll={scrollHandler}
            ref={tableBodyRef}
          >
            <table className={styles['c-table__body']}>
              <tbody>
                {items
                  .filter((_, idx) => {
                    return rowHead <= idx && idx < rowHead + rowCount;
                  })
                  .map((item, ridx) => {
                    return (
                      <tr key={ridx}>
                        <td
                          className={clsx(
                            styles['tbody__td'],
                            styles['sticky-left'],
                            styles['c-table__spHeader']
                          )}
                          style={{
                            width: equipmentTiltleColumnWidth,
                          }}
                        >
                          <EquipmentTitle equipment={item} />
                        </td>
                        <td
                          className={clsx(styles['tbody__td'])}
                          data-text={t('Seat')}
                          style={{
                            width: bookingSourceColumnWidth,
                          }}
                        >
                          <BookingSource equipment={item} />
                        </td>
                        <td
                          className={clsx(styles['tbody__td'])}
                          data-text={t('Participation Rules')}
                          style={{
                            width: ruleColumnWidth,
                          }}
                        >
                          <RuleList equipment={item} />
                        </td>
                        <td
                          className={clsx(styles['tbody__td'])}
                          data-text={t('Action')}
                        >
                          <Operations />
                        </td>

                        {/* This section is only for mobile */}
                        <td
                          className={clsx(
                            styles['c-table__detail'],
                            styles['c-table__sp'],
                            styles['tbody__td']
                          )}
                        >
                          <Button
                            text={
                              showReservationColumns[ridx]
                                ? t('Close')
                                : t('See more')
                            }
                            uiType="bg"
                            size="md"
                            color="tertiarycolor"
                            onClick={() => {
                              const newData = [...showReservationColumns];
                              newData[ridx] = !newData[ridx];
                              setShowReservationColumns(newData);
                            }}
                            iconAfterText={
                              showReservationColumns[ridx] ? (
                                <i className="c-icon-solid-arrows-chevron-up"></i>
                              ) : (
                                <i className="c-icon-solid-arrows-chevron-down"></i>
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {/* Prev and next side buttons */}
          <div
            className={clsx(styles['c-tableBtn'], styles['left'])}
            onClick={() => {
              scrollButtonClickHandler('prev');
            }}
          >
            <a>
              <i className="c-icon-solid-arrows-chevron-left"></i>
            </a>
          </div>
          <div
            className={clsx(styles['c-tableBtn'], styles['right'])}
            onClick={() => {
              scrollButtonClickHandler('next');
            }}
          >
            <a>
              <i className="c-icon-solid-arrows-chevron-left"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Pagination & no of items displayed selection */}
      <div className={styles['p-reservations__bottom']}>
        <Pagination
          totalItems={items.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
        />
      </div>
    </>
  );
};
