import { ActivityPlanSystemFeeInvoiceDetailsBody } from 'client/pages/v3/SystemFee/SystemFeeInvoiceDetails/ActivityPlanSystemFeeInvoiceDetailsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ActivityPlanSystemFeeInvoiceDetails = () => {
  return (
    <V3Page>
      <ActivityPlanSystemFeeInvoiceDetailsBody />
    </V3Page>
  );
};
