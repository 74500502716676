import * as React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

export const ETicketSettingsDisplayBox = () => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  const values = useFormState().values;

  const recurrence = product?.recurrence;

  if (!recurrence || recurrence.length === 0) {
    return null;
  }

  const startDate = recurrence[0].start_date_local;
  const endDate = recurrence[0].end_date_local;
  let startTime = recurrence[0].start_times[0]?.start_time_local;
  const part = startTime.split(':');
  if (part.length === 2 && part[0] === '') {
    startTime = '00:' + part[1];
  }

  const lastStartTimeMoment = moment.tz(
    `${endDate} ${startTime}`,
    product?.start_timezone ?? 'UTC'
  );
  const lastCheckinTimeMoment = moment.tz(
    `${endDate} 23:59`,
    product?.start_timezone ?? 'UTC'
  );
  const firstStartTimeMoment = moment.tz(
    `${startDate} ${startTime}`,
    product?.start_timezone ?? 'UTC'
  );

  let minDeadlineMoment: Moment | null = null;
  (product?.booking_deadlines ?? []).forEach((deadline) => {
    let deadlineMoment: null | Moment = null;
    if (deadline?.relative_date?.type === 'HOUR') {
      deadlineMoment = lastStartTimeMoment
        .clone()
        .subtract(deadline?.relative_date?.count ?? 0, 'hours');
    } else if (deadline?.relative_date?.type === 'DAY') {
      const localTimeParts = deadline?.relative_date?.time_local?.split(':');
      if (!localTimeParts || localTimeParts.length !== 2) {
        return;
      }
      deadlineMoment = lastStartTimeMoment
        .clone()
        .subtract(deadline?.relative_date?.count ?? 0, 'days')
        .set({
          hour: Number(localTimeParts?.[0]) ?? 0,
          minute: Number(localTimeParts?.[1]) ?? 0,
        });
    }
    if (
      !minDeadlineMoment ||
      (deadlineMoment && deadlineMoment.isBefore(minDeadlineMoment))
    ) {
      minDeadlineMoment = deadlineMoment;
    }
  });

  if (!minDeadlineMoment) {
    minDeadlineMoment = lastStartTimeMoment.clone();
  }
  const minDeadlineString = minDeadlineMoment.format('YYYY-MM-DD HH:mm');

  let startSellDateString = t('Current');
  if (moment().isAfter(minDeadlineMoment)) {
    startSellDateString = '';
  } else {
    let isStillNotStarted = false;
    if (
      product?.agent_guest_booking_period_settings
        ?.should_use_booking_start_date_time
    ) {
      const bookingStartDateTime = moment.tz(
        product?.agent_guest_booking_period_settings
          ?.booking_start_date_time_utc,
        product?.start_timezone ?? 'UTC'
      );
      if (bookingStartDateTime.isAfter(moment())) {
        startSellDateString = bookingStartDateTime.format('YYYY-MM-DD HH:mm');
        isStillNotStarted = true;
      }
    }

    if (!isStillNotStarted) {
      const bookingAllowedBefore =
        product?.agent_guest_booking_period_settings
          ?.booking_allowed_days_before_participation ?? null;
      if (bookingAllowedBefore !== null) {
        startSellDateString =
          firstStartTimeMoment
            .clone()
            .subtract(bookingAllowedBefore, 'days')
            .format('YYYY-MM-DD') +
          ' ' +
          '00:00';
      }
    }
  }

  let lastTicketAvailableMoment = lastCheckinTimeMoment.clone();
  if (values?.expirationDate?.deadlineType === 'HOUR') {
    lastTicketAvailableMoment = lastCheckinTimeMoment
      .clone()
      .add(values?.expirationDate?.hoursLater ?? 0, 'hours');
  } else if (values?.expirationDate?.deadlineType === 'DAY') {
    const localTimeParts = values?.expirationDate?.timeOfDay?.split(':');
    if (localTimeParts && localTimeParts.length === 2) {
      lastTicketAvailableMoment = lastCheckinTimeMoment
        .clone()
        .add(values?.expirationDate?.daysLater ?? 0, 'days')
        .set({
          hour: Number(localTimeParts?.[0]) ?? 0,
          minute: Number(localTimeParts?.[1]) ?? 0,
        });
    }
  }

  return (
    <div>
      <table className={tableStyles['c-tableSmall']}>
        <tbody>
          <tr>
            <th>{t('Booking Period')}</th>
            <td>{`${startSellDateString} ~ ${minDeadlineString}`}</td>
          </tr>
          <tr>
            <th>{t('Cancellation Period')}</th>
            <td>{`${startSellDateString} ~ ${minDeadlineString}`}</td>
          </tr>
          <tr>
            <th>{t('E-ticket redeemable period')}</th>
            <td>
              {`${firstStartTimeMoment.format(
                'YYYY-MM-DD'
              )} 00:00 ~ ${lastCheckinTimeMoment.format('YYYY-MM-DD HH:mm')}`}
            </td>
          </tr>
          <tr>
            <th>{t('E-ticket validity period')}</th>
            <td>
              {`${firstStartTimeMoment.format(
                'YYYY-MM-DD'
              )} 00:00 ~ ${lastTicketAvailableMoment.format(
                'YYYY-MM-DD HH:mm'
              )}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
