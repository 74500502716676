import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Button } from 'client/components/v3/Common/Button';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateFaqSettings } from 'client/actions/essentialPages';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import type { ReduxState } from 'client/reducers';
//import * as Swagger from 'shared/models/swagger';
import type {
  SourceLanguage,
  FaqSettings,
  Translation,
} from 'shared/models/swagger';
import { CollapsibleBox } from 'client/pages/v3/BookingWidget/EssentialPages/CollapsibleSection/CollapsibleBox';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';

//export type Translation = {
//  source: string;
//  target: string;
//};
type TranslationsFormValues = {
  translations: Translation[];
};

const emptyTranslation = {
  en_us: '',
};

type TransTargetLanguage = 'en_us' | 'ja_jp' | 'ko_kr' | 'zh_cn' | 'zh_tw';

export const FAQTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translation, setTranslation] = React.useState<Translation[]>([
    emptyTranslation,
  ]);

  const { required } = getValidators(t);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const initialValuesWithTranslations = React.useMemo(
    () => ({
      faqs: activeUserOrganization?.essential_pages?.faq_settings?.faqs || [],
      visibility:
        activeUserOrganization?.essential_pages?.faq_settings?.visibility ||
        false,
    }),
    [apiTranslations, activeUserOrganization]
  );

  React.useEffect(() => {
    if (apiTranslations?.length) {
      setTranslation(apiTranslations);
    }
  }, [apiTranslations, activeUserOrganization]);

  React.useEffect(() => {
    if (showTranslationMode) {
      const faqsSettings =
        activeUserOrganization?.essential_pages?.faq_settings?.faqs || [];

      if (faqsSettings) {
        const texts: string[] = [];

        (faqsSettings || []).forEach((faq) => {
          if (faq.category_title) {
            texts.push(faq.category_title);
          }

          if (faq.question_and_answer?.length) {
            (faq.question_and_answer || []).forEach((qa) => {
              if (qa.question) {
                texts.push(qa.question);
              }
              if (qa.answer) {
                texts.push(qa.answer);
              }
            });
          }
        });

        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeUserOrganization]);

  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== defaultLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([defaultLanguage, ...translationLanguages])].length > 1;

  const addNewTrans = (
    target: Translation | undefined,
    defaultLanguage: SourceLanguage,
    language: TransTargetLanguage,
    previousValue: string,
    value: string
  ) => {
    if (!target) {
      setTranslation([
        ...translation,
        {
          source_language: defaultLanguage,
          [language]: value,
          [defaultLanguage.toLowerCase()]: previousValue,
        },
      ]);
    } else {
      const newTrans = {
        ...target,
      } as Translation;
      newTrans[language as keyof Translation] = value as any;
      setTranslation(
        translation.map((t2) => {
          if (
            t2[defaultLanguage.toLowerCase() as keyof Translation] ==
            previousValue
          ) {
            return newTrans;
          }
          return t2;
        })
      );
    }
  };

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  return (
    <Form
      onSubmit={async (values: FaqSettings & TranslationsFormValues) => {
        try {
          await dispatch(updateFaqSettings(values));
          if (showTranslationMode) {
            await dispatch(updateTranslations(translation));
          }
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={initialValuesWithTranslations}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div
            className={clsx(
              styles['p-inquiry-tabs__tab-panel'],
              styles['components_is-active__B15DT']
            )}
            style={{ display: 'block' }}
          >
            {submitSucceeded && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'success'}
                text={t('Save Successful')}
                shouldShow={submitSucceeded}
              />
            )}
            {submitError && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'error'}
                text={t('Save Failed')}
                shouldShow={submitError}
              />
            )}
            <div className={styles['p-inquiry-tabs__content']}>
              <ul className={styles['p-inquiry-list']}>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Visibility')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="visibility" type="checkbox">
                        {({ input }) => (
                          <div>
                            <Toggle
                              label={t('Show FAQ page on booking website')}
                              {...input}
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </li>

                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Category')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <FieldArray name="faqs">
                        {({ fields }) => {
                          const sectionCount = fields.length ?? 0;
                          return (
                            <>
                              {fields.map((name, idx) => {
                                return (
                                  <>
                                    <CollapsibleBox
                                      key={fields.value?.[idx].key}
                                      id={name}
                                      title={`#${idx + 1} ${
                                        fields.value?.[idx].category_title || ''
                                      }`}
                                      isDeleteable={true}
                                      onDelete={async () => {
                                        fields.remove(idx);
                                      }}
                                      isMovable={false}
                                    >
                                      <Field
                                        name={`${name}.category_title`}
                                        validate={required}
                                      >
                                        {({
                                          input,
                                          meta: { error, touched },
                                        }) => {
                                          const target = translation.find(
                                            (tra) => {
                                              return (
                                                tra[
                                                  defaultLanguage.toLowerCase() as keyof Translation
                                                ] ==
                                                values.faqs?.[idx]
                                                  ?.category_title
                                              );
                                            }
                                          );
                                          return (
                                            <div>
                                              <p> {t('Category Title')} </p>
                                              <p>
                                                <TextField
                                                  {...input}
                                                  error={touched && error}
                                                  onChange={(value) => {
                                                    if (showTranslationMode) {
                                                      addNewTrans(
                                                        target,
                                                        defaultLanguage,
                                                        defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                        values.faqs?.[idx]
                                                          ?.category_title ||
                                                          '',
                                                        value
                                                      );
                                                    }
                                                    input.onChange(value);
                                                  }}
                                                />
                                              </p>
                                            </div>
                                          );
                                        }}
                                      </Field>
                                      <>
                                        {showTranslationMode && (
                                          <div
                                            className={
                                              styles[
                                                'p-topPage-products__section__body__item'
                                              ]
                                            }
                                          >
                                            <div
                                              className={
                                                styles['p-topPage-box']
                                              }
                                            >
                                              <div
                                                className={
                                                  styles['p-topPage-box__list']
                                                }
                                              >
                                                {translationLanguages.map(
                                                  (lang, langIdx) => {
                                                    const target =
                                                      translation.find(
                                                        (tra) => {
                                                          return (
                                                            tra[
                                                              defaultLanguage.toLowerCase() as keyof Translation
                                                            ] ==
                                                            values.faqs?.[idx]
                                                              ?.category_title
                                                          );
                                                        }
                                                      );
                                                    return (
                                                      <div
                                                        key={langIdx}
                                                        className={
                                                          styles[
                                                            'p-topPage-box__list__item'
                                                          ]
                                                        }
                                                      >
                                                        <TextField
                                                          label={getLanguageName(
                                                            uppercaseIsoToLowercaseIso[
                                                              lang
                                                            ],
                                                            t
                                                          )}
                                                          value={
                                                            target?.[
                                                              lang.toLowerCase() as keyof Translation
                                                            ] || ''
                                                          }
                                                          onChange={(value) => {
                                                            if (
                                                              showTranslationMode
                                                            ) {
                                                              addNewTrans(
                                                                target,
                                                                defaultLanguage,
                                                                lang.toLowerCase() as TransTargetLanguage,
                                                                values.faqs?.[
                                                                  idx
                                                                ]
                                                                  ?.category_title ||
                                                                  '',
                                                                value
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                      <FieldArray
                                        name={`${name}.question_and_answer`}
                                      >
                                        {({ fields: innerFields }) => {
                                          const innerSectionCount =
                                            innerFields.length ?? 0;
                                          return (
                                            <>
                                              {innerFields.map(
                                                (name, formIdx) => (
                                                  <>
                                                    <CollapsibleBox
                                                      key={
                                                        innerFields.value?.[
                                                          formIdx
                                                        ].key
                                                      }
                                                      id={name}
                                                      title={`#${formIdx + 1} ${
                                                        innerFields.value?.[
                                                          formIdx
                                                        ].question || ''
                                                      }`}
                                                      isDeleteable={true}
                                                      onDelete={async () => {
                                                        innerFields.remove(
                                                          formIdx
                                                        );
                                                      }}
                                                      isMovable={false}
                                                    >
                                                      <Field
                                                        name={`${name}.question`}
                                                        validate={required}
                                                      >
                                                        {({
                                                          input,
                                                          meta: {
                                                            error,
                                                            touched,
                                                          },
                                                        }) => {
                                                          const target =
                                                            translation.find(
                                                              (tra) => {
                                                                return (
                                                                  tra[
                                                                    defaultLanguage.toLowerCase() as keyof Translation
                                                                  ] ==
                                                                  values.faqs?.[
                                                                    idx
                                                                  ]
                                                                    ?.question_and_answer?.[
                                                                    formIdx
                                                                  ]?.question
                                                                );
                                                              }
                                                            );
                                                          return (
                                                            <div>
                                                              <p>
                                                                {t('Question')}
                                                              </p>
                                                              <p>
                                                                <TextField
                                                                  {...input}
                                                                  error={
                                                                    touched &&
                                                                    error
                                                                  }
                                                                  onChange={(
                                                                    value
                                                                  ) => {
                                                                    if (
                                                                      showTranslationMode
                                                                    ) {
                                                                      addNewTrans(
                                                                        target,
                                                                        defaultLanguage,
                                                                        defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                                        values
                                                                          .faqs?.[
                                                                          idx
                                                                        ]
                                                                          ?.question_and_answer?.[
                                                                          formIdx
                                                                        ]
                                                                          ?.question ||
                                                                          '',
                                                                        value
                                                                      );
                                                                    }
                                                                    input.onChange(
                                                                      value
                                                                    );
                                                                  }}
                                                                />
                                                              </p>
                                                            </div>
                                                          );
                                                        }}
                                                      </Field>
                                                      <>
                                                        {showTranslationMode && (
                                                          <div
                                                            className={
                                                              styles[
                                                                'p-topPage-products__section__body__item'
                                                              ]
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles[
                                                                  'p-topPage-box'
                                                                ]
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  styles[
                                                                    'p-topPage-box__list'
                                                                  ]
                                                                }
                                                              >
                                                                {translationLanguages.map(
                                                                  (
                                                                    lang,
                                                                    langIdx
                                                                  ) => {
                                                                    const target =
                                                                      translation.find(
                                                                        (
                                                                          tra
                                                                        ) => {
                                                                          return (
                                                                            tra[
                                                                              defaultLanguage.toLowerCase() as keyof Translation
                                                                            ] ==
                                                                            values
                                                                              .faqs?.[
                                                                              idx
                                                                            ]
                                                                              ?.question_and_answer?.[
                                                                              formIdx
                                                                            ]
                                                                              ?.question
                                                                          );
                                                                        }
                                                                      );
                                                                    return (
                                                                      <div
                                                                        key={
                                                                          langIdx
                                                                        }
                                                                        className={
                                                                          styles[
                                                                            'p-topPage-box__list__item'
                                                                          ]
                                                                        }
                                                                      >
                                                                        <TextField
                                                                          label={getLanguageName(
                                                                            uppercaseIsoToLowercaseIso[
                                                                              lang
                                                                            ],
                                                                            t
                                                                          )}
                                                                          value={
                                                                            target?.[
                                                                              lang.toLowerCase() as keyof Translation
                                                                            ] ||
                                                                            ''
                                                                          }
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            if (
                                                                              showTranslationMode
                                                                            ) {
                                                                              addNewTrans(
                                                                                target,
                                                                                defaultLanguage,
                                                                                lang.toLowerCase() as TransTargetLanguage,
                                                                                values
                                                                                  .faqs?.[
                                                                                  idx
                                                                                ]
                                                                                  ?.question_and_answer?.[
                                                                                  formIdx
                                                                                ]
                                                                                  ?.question ||
                                                                                  '',
                                                                                value
                                                                              );
                                                                            }
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                      <Field
                                                        name={`${name}.answer`}
                                                        validate={required}
                                                      >
                                                        {({
                                                          input,
                                                          meta: {
                                                            error,
                                                            touched,
                                                          },
                                                        }) => {
                                                          const target =
                                                            translation.find(
                                                              (tra) => {
                                                                return (
                                                                  tra[
                                                                    defaultLanguage.toLowerCase() as keyof Translation
                                                                  ] ==
                                                                  values.faqs?.[
                                                                    idx
                                                                  ]
                                                                    ?.question_and_answer?.[
                                                                    formIdx
                                                                  ]?.answer
                                                                );
                                                              }
                                                            );
                                                          return (
                                                            <div>
                                                              <p>
                                                                {t('Answer')}
                                                              </p>
                                                              <p>
                                                                <TextArea
                                                                  {...(input as any)}
                                                                  error={
                                                                    touched &&
                                                                    error
                                                                  }
                                                                  height={100}
                                                                  onChange={(
                                                                    _,
                                                                    { value }
                                                                  ) => {
                                                                    if (
                                                                      showTranslationMode
                                                                    ) {
                                                                      addNewTrans(
                                                                        target,
                                                                        defaultLanguage,
                                                                        defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                                        values
                                                                          .faqs?.[
                                                                          idx
                                                                        ]
                                                                          ?.question_and_answer?.[
                                                                          formIdx
                                                                        ]
                                                                          ?.answer ||
                                                                          '',
                                                                        value
                                                                      );
                                                                    }
                                                                    input.onChange(
                                                                      value
                                                                    );
                                                                  }}
                                                                />
                                                              </p>
                                                            </div>
                                                          );
                                                        }}
                                                      </Field>
                                                      <>
                                                        {showTranslationMode && (
                                                          <div
                                                            className={
                                                              styles[
                                                                'p-topPage-products__section__body__item'
                                                              ]
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles[
                                                                  'p-topPage-box'
                                                                ]
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  styles[
                                                                    'p-topPage-box__list'
                                                                  ]
                                                                }
                                                              >
                                                                {translationLanguages.map(
                                                                  (
                                                                    lang,
                                                                    langIdx
                                                                  ) => {
                                                                    const target =
                                                                      translation.find(
                                                                        (
                                                                          tra
                                                                        ) => {
                                                                          return (
                                                                            tra[
                                                                              defaultLanguage.toLowerCase() as keyof Translation
                                                                            ] ==
                                                                            values
                                                                              .faqs?.[
                                                                              idx
                                                                            ]
                                                                              ?.question_and_answer?.[
                                                                              formIdx
                                                                            ]
                                                                              ?.answer
                                                                          );
                                                                        }
                                                                      );
                                                                    return (
                                                                      <div
                                                                        key={
                                                                          langIdx
                                                                        }
                                                                        className={
                                                                          styles[
                                                                            'p-topPage-box__list__item'
                                                                          ]
                                                                        }
                                                                      >
                                                                        <TextArea
                                                                          label={getLanguageName(
                                                                            uppercaseIsoToLowercaseIso[
                                                                              lang
                                                                            ],
                                                                            t
                                                                          )}
                                                                          value={
                                                                            target?.[
                                                                              lang.toLowerCase() as keyof Translation
                                                                            ] ||
                                                                            ''
                                                                          }
                                                                          onChange={(
                                                                            _,
                                                                            {
                                                                              value,
                                                                            }
                                                                          ) => {
                                                                            if (
                                                                              showTranslationMode
                                                                            ) {
                                                                              addNewTrans(
                                                                                target,
                                                                                defaultLanguage,
                                                                                lang.toLowerCase() as TransTargetLanguage,
                                                                                values
                                                                                  .faqs?.[
                                                                                  idx
                                                                                ]
                                                                                  ?.question_and_answer?.[
                                                                                  formIdx
                                                                                ]
                                                                                  ?.answer ||
                                                                                  '',
                                                                                value
                                                                              );
                                                                            }
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                    </CollapsibleBox>
                                                  </>
                                                )
                                              )}
                                              <a
                                                className={
                                                  styles['add__button']
                                                }
                                                onClick={() => {
                                                  if (innerSectionCount === 0) {
                                                    (
                                                      innerFields as any
                                                    ).insertAt(
                                                      innerSectionCount,
                                                      ''
                                                    );
                                                  } else {
                                                    (
                                                      innerFields as any
                                                    ).insertAt(
                                                      innerSectionCount + 1,
                                                      ''
                                                    );
                                                  }
                                                }}
                                              >
                                                <i className="c-icon-outline-general-plus-circle"></i>
                                                {t('Add Question and Answer')}
                                              </a>
                                            </>
                                          );
                                        }}
                                      </FieldArray>
                                    </CollapsibleBox>
                                  </>
                                );
                              })}
                              <a
                                className={styles['add__button']}
                                onClick={() => {
                                  if (sectionCount === 0) {
                                    (fields as any).insertAt(sectionCount, '');
                                  } else {
                                    (fields as any).insertAt(
                                      sectionCount + 1,
                                      ''
                                    );
                                  }
                                }}
                              >
                                <i className="c-icon-outline-general-plus-circle"></i>
                                {t('Add Category')}
                              </a>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles['p-products__fixed'], styles['is-active'])}
          >
            <div className={styles['p-products__fixed__actions']}>
              {shouldShowMultilingualSettings && (
                <Toggle
                  label={t('Translation')}
                  checked={showTranslationMode}
                  onChange={() => setShowTranslationMode(!showTranslationMode)}
                />
              )}
              <Button
                disabled={submitting}
                loading={submitting}
                text={t('Save')}
                type="submit"
                color="primary"
                size="md"
                style={{
                  width: '60px',
                  fontWeight: 'var(--text-semibold)',
                }}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
