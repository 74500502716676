import { combineReducers } from 'redux';

import {
  ISSUE_RESERVATION_INVOICE_REQUEST,
  DOWNLOAD_RESERVATION_INVOICE_PDF_REQUEST,
  SEND_RESERVATION_INVOICE_EMAIL_REQUEST,
  ISSUE_RESERVATION_INVOICE_SUCCESS,
  ISSUE_RESERVATION_INVOICE_FAILURE,
  DOWNLOAD_RESERVATION_INVOICE_PDF_SUCCESS,
  DOWNLOAD_RESERVATION_INVOICE_PDF_FAILURE,
  SEND_RESERVATION_INVOICE_EMAIL_SUCCESS,
  SEND_RESERVATION_INVOICE_EMAIL_FAILURE,
  RESET_SEND_RESERVATION_INVOICE_EMAIL_STATUS,
  FETCH_RESERVATION_INVOICE_FAILURE,
  FETCH_RESERVATION_INVOICE_REQUEST,
  FETCH_RESERVATION_INVOICE_SUCCESS,
  ISSUE_RESERVATION_INVOICE_RESET,
  UPDATE_RESERVATION_INVOICE_REQUEST,
  UPDATE_RESERVATION_INVOICE_SUCCESS,
  UPDATE_RESERVATION_INVOICE_FAILURE,
} from 'client/constants/ActionTypes';
import {
  GetReservationInvoiceResponse,
  IssueReservationInvoiceResponse,
  ReservationInvoice,
  UpdateReservationInvoiceResponse,
} from 'shared/models/swagger';

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case ISSUE_RESERVATION_INVOICE_REQUEST:
    case FETCH_RESERVATION_INVOICE_REQUEST:
    case DOWNLOAD_RESERVATION_INVOICE_PDF_REQUEST:
    case SEND_RESERVATION_INVOICE_EMAIL_REQUEST:
    case UPDATE_RESERVATION_INVOICE_REQUEST:
      return true;

    case ISSUE_RESERVATION_INVOICE_SUCCESS:
    case ISSUE_RESERVATION_INVOICE_FAILURE:
    case FETCH_RESERVATION_INVOICE_SUCCESS:
    case FETCH_RESERVATION_INVOICE_FAILURE:
    case DOWNLOAD_RESERVATION_INVOICE_PDF_SUCCESS:
    case DOWNLOAD_RESERVATION_INVOICE_PDF_FAILURE:
    case SEND_RESERVATION_INVOICE_EMAIL_SUCCESS:
    case SEND_RESERVATION_INVOICE_EMAIL_FAILURE:
    case UPDATE_RESERVATION_INVOICE_SUCCESS:
    case UPDATE_RESERVATION_INVOICE_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case ISSUE_RESERVATION_INVOICE_FAILURE:
    case FETCH_RESERVATION_INVOICE_FAILURE:
    case DOWNLOAD_RESERVATION_INVOICE_PDF_FAILURE:
    case SEND_RESERVATION_INVOICE_EMAIL_FAILURE:
    case UPDATE_RESERVATION_INVOICE_FAILURE:
      return action.error;

    case ISSUE_RESERVATION_INVOICE_REQUEST:
    case ISSUE_RESERVATION_INVOICE_SUCCESS:
    case FETCH_RESERVATION_INVOICE_REQUEST:
    case FETCH_RESERVATION_INVOICE_SUCCESS:
    case DOWNLOAD_RESERVATION_INVOICE_PDF_REQUEST:
    case DOWNLOAD_RESERVATION_INVOICE_PDF_SUCCESS:
    case SEND_RESERVATION_INVOICE_EMAIL_REQUEST:
    case SEND_RESERVATION_INVOICE_EMAIL_SUCCESS:
    case UPDATE_RESERVATION_INVOICE_REQUEST:
    case UPDATE_RESERVATION_INVOICE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const data = (
  state: ReservationInvoice = {},
  action: any
): ReservationInvoice => {
  switch (action.type) {
    case ISSUE_RESERVATION_INVOICE_SUCCESS: {
      const response = action.response as IssueReservationInvoiceResponse;
      return response ?? {};
    }

    case FETCH_RESERVATION_INVOICE_SUCCESS: {
      const response = action.response as GetReservationInvoiceResponse;
      return response ?? {};
    }

    case UPDATE_RESERVATION_INVOICE_SUCCESS: {
      const response = action.response as UpdateReservationInvoiceResponse;
      return response ?? {};
    }

    default:
      return state;
  }
};

const issueStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case ISSUE_RESERVATION_INVOICE_REQUEST:
      return 'IN_FLIGHT';

    case ISSUE_RESERVATION_INVOICE_SUCCESS:
      return 'SUCCESS';

    case ISSUE_RESERVATION_INVOICE_FAILURE:
      return 'FAILURE';

    case ISSUE_RESERVATION_INVOICE_RESET:
      return 'IDLE';

    default:
      return state;
  }
};

const pdfStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_RESERVATION_INVOICE_PDF_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_RESERVATION_INVOICE_PDF_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_RESERVATION_INVOICE_PDF_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const emailStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case SEND_RESERVATION_INVOICE_EMAIL_REQUEST:
      return 'IN_FLIGHT';

    case SEND_RESERVATION_INVOICE_EMAIL_SUCCESS:
      return 'SUCCESS';

    case SEND_RESERVATION_INVOICE_EMAIL_FAILURE:
      return 'FAILURE';

    case RESET_SEND_RESERVATION_INVOICE_EMAIL_STATUS:
      return 'IDLE';

    default:
      return state;
  }
};

interface State {
  data: ReservationInvoice;
  loading: boolean;
  error: string;
  issueStatus: string;
  pdfStatus: string;
  emailStatus: string;
}

export const reservationInvoices = combineReducers<State>({
  data,
  loading,
  error,
  issueStatus,
  pdfStatus,
  emailStatus,
});
