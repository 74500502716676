import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { DragHandle } from 'client/components/Icons/DragHandle';

import styles from './DigitalMapBasicsEditor.module.css';

interface SortableBusRouteItemProps {
  id: string;
  value: string;
  options: { value: string; text: string }[];
  onChange: (value: string) => void;
  onDelete: () => void;
}

export const SortableBusRouteItem = ({
  id,
  value,
  options,
  onChange,
  onDelete,
}: SortableBusRouteItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} className={styles.sortableItem}>
      <Box display="flex" alignItems="center" gap={2}>
        <div {...attributes} {...listeners} className={styles.dragHandle}>
          <DragHandle />
        </div>
        <Box style={{ flex: 1 }}>
          <SingleDropdown
            selectedOption={value}
            onChange={onChange}
            options={options}
            placeholder="Select a bus route"
          />
        </Box>
        <a
          className={clsx(styles.button, styles.delete)}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <i className="c-icon-outline-general-trash-03" />
        </a>
      </Box>
    </div>
  );
};
