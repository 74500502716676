import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { CollapsibleBox } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/CollapsibleSection/CollapsibleBox';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';

import type {
  AdditionalImage,
  TopPageFormValues,
  AdditionalImagesSection,
} from '../FormValues';

type Props = {
  name: string;
  parentId: string;
};

export const EditAdditionalImage = ({ name, parentId }: Props) => {
  const { t } = useTranslation();
  const form = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  const initialValues: AdditionalImagesSection = _.get(formState.values, name);
  const initialValuesFromForm: AdditionalImagesSection =
    initialValues.additionalImages?.length &&
    initialValues.additionalImages?.length !== 0
      ? initialValues
      : {
          ...initialValues,
        };

  const [collapsibleBoxOnDrug, setCollapsibleBoxOnDrug] =
    React.useState<boolean>(false);
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = initialValues.additionalImages[dragIndex];
    const newSelectedItems = [...initialValues.additionalImages];
    newSelectedItems.splice(dragIndex, 1);
    newSelectedItems.splice(hoverIndex, 0, dragItem);
    form.change(`${name}.additionalImages` as any, newSelectedItems);
  };

  return (
    <>
      <div className={styles['p-topPage-products__section__body__item']}>
        <Toggle
          label={t('Show additional photos on booking website top page')}
          checked={initialValuesFromForm.isVisible}
          onChange={() => {
            form.change(
              `${name}.isVisible` as any,
              !initialValuesFromForm.isVisible
            );
          }}
        />
      </div>
      {initialValuesFromForm.additionalImages?.map((i, idx) => (
        <CollapsibleBox
          key={`${name}-image-${i.key}`}
          index={idx}
          id={`${name}-image-${i.key}`}
          parentId={parentId}
          title={i.title}
          onDelete={async () => {
            await form.change(
              `${name}.additionalImages` as any,
              initialValuesFromForm.additionalImages.toSpliced(idx, 1)
            );
          }}
          moveItem={moveItem}
          setCollapsibleBoxOnDrug={setCollapsibleBoxOnDrug}
          collapsibleBoxOnDrug={collapsibleBoxOnDrug}
        >
          <dl className={styles['p-topPage-acBox__body__list']}>
            <dt className={styles['p-topPage-acBox__body__list__term']}>
              <span>{t('Title')}</span>
            </dt>
            <dd className={styles['p-topPage-acBox__body__list__desc']}>
              <TextField
                label={t('Title')}
                value={i.title}
                onChange={(value) => {
                  form.change(
                    `${name}.additionalImages.${idx}.title` as any,
                    value
                  );
                }}
              />
            </dd>
            <dt className={styles['p-topPage-acBox__body__list__term']}>
              <span>{t('Photo')}</span>
            </dt>
            <dd className={styles['p-topPage-acBox__body__list__desc']}>
              <ImageVideoAudioInput
                fileUrls={i.imageUrl !== '' ? [i.imageUrl] : []}
                onChange={(value) => {
                  form.change(
                    `${name}.additionalImages.${idx}.imageUrl` as any,
                    value.length === 0 ? '' : value[0]
                  );
                }}
                maxFileCount={1}
                disableYoutubeVideos
              />
            </dd>
            <dt className={styles['p-topPage-acBox__body__list__term']}>
              <span>{t('Description')}</span>
            </dt>
            <dd className={styles['p-topPage-acBox__body__list__desc']}>
              <TextField
                label={t('Description')}
                value={i.description}
                onChange={(value) => {
                  form.change(
                    `${name}.additionalImages.${idx}.description` as any,
                    value
                  );
                }}
              />
            </dd>
            <dt className={styles['p-topPage-acBox__body__list__term']}>
              <span>{t('URL')}</span>
            </dt>
            <dd className={styles['p-topPage-acBox__body__list__desc']}>
              <TextField
                label={t('URL')}
                value={i.linkUrl}
                onChange={(value) => {
                  form.change(
                    `${name}.additionalImages.${idx}.linkUrl` as any,
                    value
                  );
                }}
              />
            </dd>
          </dl>
        </CollapsibleBox>
      ))}
      <a
        className={styles['add__button']}
        onClick={() => {
          const initialValue: AdditionalImage = {
            title: '',
            description: '',
            imageUrl: '',
            linkUrl: '',
            key: uuidv4(),
          };
          const images = [
            ...(initialValuesFromForm.additionalImages || []),
            initialValue,
          ];
          form.change(`${name}.additionalImages` as any, images);
        }}
      >
        <i className="c-icon-outline-general-plus-circle"></i>
        {t('Add Photo')}
      </a>
    </>
  );
};
