import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { useSelector, useDispatch } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Section } from '@nutmeglabs/falcon-ui';

import { config } from 'client/config';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Box } from 'client/components/Box/Box';
import { updateCustomPage, createCustomPage } from 'client/actions/customPage';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  ToggleButton,
  Input,
} from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { ModalLoader } from 'client/components/ModalLoader';
import { getArrayMutators } from 'client/libraries/util/form';
import { convertSourceLanguageToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { Editor } from 'client/components/Editor/Editor';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getBookingWidgetPmpEssentialPageUrlBase } from 'client/pages/EssentialPages/utils';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { FalconUIContextProvider } from 'client/components/FalconUIContextProvider/FalconUIContextProvider';
import baseStyles from 'client/base.module.css';
import type {
  CustomPage,
  CustomPagePatch,
  NewCustomPage,
  SourceLanguage,
} from 'shared/models/swagger';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';

import styles from './EditCustomPageModal.module.css';
import { CustomSectionsEditor } from './CustomSectionsEditor';

type Props = {
  open: boolean;
  title: string;
  onClose: () => void;
  disabled?: boolean;
  loading?: boolean;
  type: 'NORMAL' | 'NEWS';
  editCustomPage?: CustomPage | null;
  contentLanguage: SourceLanguage;
  customPages: CustomPage[] | null;
};
type FormValues = {
  visibility: boolean;
  title: string;
  path: string;
  body: any;
  sections: Section[];
  enableNoIndex: boolean;
};
const focusOnError: any = createDecorator();
export const EditCustomPageModal = ({
  open,
  title,
  onClose,
  disabled,
  loading,
  type,
  editCustomPage,
  contentLanguage,
  customPages,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [selfPath, setSelfPath] = React.useState<string | null>(null);
  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);
  const [copied, setCopied] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (editCustomPage) {
      setSelfPath(editCustomPage.path);
    }
  }, [editCustomPage]);

  const pageUrl = `${getBookingWidgetPmpEssentialPageUrlBase(
    activeUserOrganization,
    contentLanguage
  )}${type === 'NEWS' ? '/news' : '/article'}/`;

  const initialValues = React.useMemo((): FormValues => {
    return editCustomPage
      ? {
          ...editCustomPage,
          visibility: editCustomPage.status === 'ACTIVE',
          body: JSON.parse(editCustomPage?.body || '{}'),
          title: editCustomPage.title,
          sections: !editCustomPage.sections
            ? [
                {
                  type: 'EDITORJS',
                  editorJsContent: editCustomPage?.body || '{}',
                  title: editCustomPage.title,
                  titleColor: '#000000',
                  backgroundColor: '#f9f9f9',
                },
              ]
            : JSON.parse(editCustomPage.sections || '[]'),
          enableNoIndex: editCustomPage.enable_no_index || false,
        }
      : {
          visibility: true,
          path: '',
          title: '',
          body: null,
          sections: [],
          enableNoIndex: false,
        };
  }, [activeUserOrganization]);
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      insertRoot={true}
      width="wide"
    >
      <FalconUIContextProvider language={contentLanguage}>
        <Form
          onSubmit={async (values: FormValues) => {
            const customPage = {
              title: values.title,
              path: values.path,
              body: JSON.stringify(values.body),
              sections: JSON.stringify(values.sections),
              status: values.visibility ? 'ACTIVE' : 'INACTIVE',
              enable_no_index: values.enableNoIndex,
            };
            setSelfPath(values.path);

            try {
              if (editCustomPage) {
                await dispatch(
                  updateCustomPage(
                    editCustomPage.id,
                    customPage as CustomPagePatch
                  )
                );
              } else {
                await dispatch(
                  createCustomPage({
                    ...(customPage as NewCustomPage),
                    type: type,
                    content_language: contentLanguage,
                  })
                );
              }

              setSuccess(true);
            } catch (error) {
              if (editCustomPage) {
                setSelfPath(editCustomPage.path);
              }

              return {
                [FORM_ERROR]: t('Save Failed'),
              };
            }
          }}
          validate={(values) => {
            const errors: Record<string, string> = {};

            if (!values.title) {
              errors.title = t('Required');
            }

            if (selfPath !== values.path) {
              const duplicate = (customPages || []).find(
                (customPage) =>
                  customPage.path === values.path && customPage.type === type
              );

              if (duplicate) {
                errors.path = t('Path Not Available');
              }
            }

            if (!values.path) {
              errors.path = t('Required');
            }

            return errors;
          }}
          decorators={[focusOnError]}
          initialValues={initialValues}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitError, submitting, values, form }) => {
            React.useEffect(() => {
              if (!values.visibility) {
                form.change('enableNoIndex', false);
              }
            }, [values.visibility]);
            return (
              <form onSubmit={handleSubmit}>
                {false && <pre> {JSON.stringify(values, undefined, 2)}</pre>}
                <Modal.Content>
                  {loading && !submitting ? (
                    <ModalLoader />
                  ) : (
                    <>
                      <Modal.Box>
                        <Field name="visibility" type="checkbox">
                          {({ input }) => (
                            <ToggleButton
                              label={t('Show this page on booking site')}
                              {...input}
                            />
                          )}
                        </Field>
                      </Modal.Box>

                      {config.enableNoIndexCustomPage && values.visibility && (
                        <Modal.Box>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Field name="enableNoIndex">
                              {({ input }) => (
                                <ToggleButton
                                  checked={input.value}
                                  onChange={() => input.onChange(!input.value)}
                                  label={
                                    <div>
                                      <div>
                                        {t(
                                          'Hide the page from appearing in Google and other search engine results.'
                                        )}
                                      </div>
                                      <div>
                                        {t(
                                          'Page will be accessible when the URL is accessed directly'
                                        )}
                                      </div>
                                    </div>
                                  }
                                />
                              )}
                            </Field>
                            <Tooltip
                              mobileStyle={{
                                left: '-270px',
                              }}
                              text={t(
                                'Nondex tag will be set on the page to prevent it from appearing in search engine results.'
                              )}
                            />
                          </div>
                        </Modal.Box>
                      )}

                      <Modal.Box>
                        <FieldWrapper label={t('Content Language')}>
                          {getLanguageName(
                            uppercaseIsoToLowercaseIso[contentLanguage],
                            t
                          )}
                        </FieldWrapper>
                      </Modal.Box>

                      <Modal.Box>
                        <FieldWrapper label={t('URL')}>
                          <div className={baseStyles['inline-block']}>
                            <p
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {`${getBookingWidgetPmpEssentialPageUrlBase(
                                activeUserOrganization,
                                contentLanguage
                              )}${type === 'NEWS' ? '/news' : '/article'}/`}
                            </p>
                            <Field name="path">
                              {({ input, meta }) => (
                                <>
                                  <input
                                    className={baseStyles['base-form-text']}
                                    {...input}
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className={
                                        baseStyles['base-form-box__err']
                                      }
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                            <div className={styles['base-info']}>
                              <a
                                className="c-button-link-sm-color"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `${pageUrl}${
                                      values.path
                                    }?lng=${convertSourceLanguageToBookingWidgetUrlLangCode(
                                      contentLanguage
                                    )}`
                                  );
                                  setCopied(true);
                                }}
                                style={{ color: '#3f3f46' }}
                              >
                                <i className="c-icon-outline-general-copy-01"></i>
                              </a>
                              <div className={styles['base-info__body']}>
                                {t('Copy URL to clipboard')}
                              </div>
                            </div>
                            <div
                              className={styles['base-info']}
                              style={{ marginLeft: '0' }}
                            >
                              <a
                                className="c-button-link-sm-color"
                                href={`${pageUrl}${
                                  values.path
                                }?lng=${convertSourceLanguageToBookingWidgetUrlLangCode(
                                  contentLanguage
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: '--primary700' }}
                              >
                                <i className="c-icon-outline-general-link-external-02"></i>
                              </a>
                              <div className={styles['base-info__body']}>
                                {t('Open booking site')}
                              </div>
                            </div>
                          </div>
                        </FieldWrapper>
                      </Modal.Box>
                      <Snackbar
                        color={'success'}
                        text={t('Copied')}
                        shouldShow={copied}
                        onClose={() => setCopied(false)}
                      />
                      <Modal.Box>
                        <Field name="title">
                          {({ input, meta }) => (
                            <Input
                              label={t('Title')}
                              error={meta.error && meta.touched && meta.error}
                              {...input}
                            />
                          )}
                        </Field>
                      </Modal.Box>

                      {hasSubscription(
                        activeUserOrganization,
                        'feature-custom-top-page'
                      ) &&
                      !isSubscriptionCancelled(
                        activeUserOrganization,
                        'feature-custom-top-page'
                      ) ? (
                        <>
                          <Box mt={2} mb={6}>
                            <FieldWrapper label={t('Sections')} />
                          </Box>
                          <CustomSectionsEditor language={contentLanguage} />
                        </>
                      ) : (
                        <>
                          <Modal.Box>
                            <FieldWrapper label={t('Body')} />
                          </Modal.Box>
                          <div className={styles['body-editor']}>
                            <Field name="body">
                              {({ input }) => (
                                <Editor
                                  imageS3Prefix={`editor/supplier/${
                                    activeUserOrganization?.id || ''
                                  }`}
                                  data={input.value}
                                  onChange={(data: any, loading) => {
                                    setEditorJsLoading(loading);
                                    input.onChange(data);
                                  }}
                                  contentLanguage={contentLanguage}
                                />
                              )}
                            </Field>
                          </div>
                          {t(
                            '* Copy and paste URL of YouTube videos to show YouTube videos in the massage.'
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div>
                    {success && (
                      <Message success header={t('Save Successful')} />
                    )}
                    {submitError && <Message error header={t('Save Failed')} />}
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    disabled={disabled || loading || submitting}
                    style="gray"
                    size="middle"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t('Close')}
                  </Button>
                  <Button
                    disabled={
                      disabled ||
                      loading ||
                      submitting ||
                      editorJsLoading ||
                      (!editCustomPage && success)
                    }
                    loading={submitting || editorJsLoading}
                    style="green"
                    size="middle"
                    type="submit"
                  >
                    {t('Save')}
                  </Button>
                </Modal.Actions>
              </form>
            );
          }}
        </Form>
      </FalconUIContextProvider>
    </Modal>
  );
};
