import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import type { SourceLanguage } from 'shared/models/swagger';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { fetchProductTranslations } from 'client/actions/translations';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { getTranslationSourceLanguageText } from 'client/libraries/getTranslationSourceLanguageText';
import { isNullTranslation } from 'client/libraries/isNullTranslation';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';

import { AnnualPassModeSettingsForm } from './AnnualPassModeSettingsForm';

type FormValues = {
  isEnable: boolean;
  continuingProductId: string;
  recommendedProductIds: string[];
  title: string;
};

const getInitialValues = (product: Swagger.Product | null): FormValues => {
  return {
    isEnable: product?.annual_pass_mode_settings?.is_enabled || false,
    continuingProductId:
      product?.annual_pass_mode_settings?.continuing_product_id || '',
    recommendedProductIds:
      product?.annual_pass_mode_settings?.recommended_product_ids || [],
    title: product?.annual_pass_mode_settings?.title || '',
  };
};
const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    annual_pass_mode_settings: {
      is_enabled: values.isEnable,
      continuing_product_id: values.continuingProductId,
      recommended_product_ids: values.recommendedProductIds,
      title: values.title,
    },
  };
};

type Props = {
  onClose: () => void;
};
export const AnnualPassModeSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  const dispatch = useDispatch();
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage | null>(null);
  const productTranslationsLoading = useSelector(
    (state: ReduxState) => state.translations.loading
  );
  const productTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const languageOptions = contentLanguageOptions
    .map((option) => ({
      value: lowercaseIsoToUppercaseIso[option.iso],
      text: getLanguageName(option.iso, t),
    }))
    .filter((option) => option.value !== product?.source_language);
  const defaultTranslationTargetLanguage = languageOptions[0].value;
  React.useEffect(() => {
    if (showTranslationMode) {
      setTranslationTargetLanguage(defaultTranslationTargetLanguage as any);
    } else {
      setTranslationTargetLanguage(null);
    }
  }, [defaultTranslationTargetLanguage, showTranslationMode]);
  React.useEffect(() => {
    if (product != null) {
      dispatch(fetchProductTranslations(product.id));
    }
  }, [product]);
  React.useEffect(() => {
    // Default translation mode to "ON" if product name is translated.
    // We assume that if product name is translated, user has input translations for the product.
    if (
      product &&
      productTranslations.some(
        (translation) =>
          !isNullTranslation(translation) &&
          getTranslationSourceLanguageText(translation) === product.product_name
      )
    ) {
      setShowTranslationMode(true);
    }
  }, [productTranslations, productTranslationsLoading, product]);

  return (
    <TranslationLanguageContext.Provider value={translationTargetLanguage}>
      <ProductEditorModal
        open={true}
        onClose={onClose}
        title={t('Annual Pass Mode Settings')}
        initialValues={getInitialValues(product)}
        convertFormValuesToProductPatch={convertFormValuesToProductPatch}
      >
        <AnnualPassModeSettingsForm
          languageOptions={languageOptions}
          showTranslationMode={showTranslationMode}
          translationTargetLanguage={translationTargetLanguage}
          onTranslationModeChange={setShowTranslationMode}
          onTranslationTargetLanguageChange={setTranslationTargetLanguage}
        />
      </ProductEditorModal>
    </TranslationLanguageContext.Provider>
  );
};
