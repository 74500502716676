import clsx from 'clsx';
import { Dispatch, SetStateAction, useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import baseStyles from 'client/v3-base.module.css';
import { Badge } from 'client/components/v3/Common/Badge';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorForm } from 'client/pages/v3/Product/ProductEdit/ProductEditorForm';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { getBadgeColorForProductStatus } from 'client/libraries/util/getBadgeColorForProductStatus';
import {
  getInitialValues,
  getETicketConvertFormValuesToProductPatch,
} from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { getProductEditorDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import {
  getSalesStatusText,
  getProductSalesStatus,
} from 'client/libraries/util/getProductSalesStatus';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';

import styles from './ETicketEdit.module.css';
import { BasicEditor } from './SectionEditor/BasicEditor/BasicEditor';
import { CustomEditor } from './SectionEditor/CustomEditor/CustomEditor';
import { ScheduleEditor } from './SectionEditor/ScheduleEditor/ScheduleEditor';

interface Props {
  setCurrentSectionId: Dispatch<SetStateAction<string>>;
}

export const ETicketEditor = ({ setCurrentSectionId }: Props) => {
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const [success, setSuccess] = useState<boolean>(false);
  const initialValues = useMemo(
    () => getInitialValues(editingProduct),
    [editingProduct]
  );

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const currency = defaultProductCurrency ?? defaultSupplierCurrency;

  const productSalesStatus = getProductSalesStatus(editingProduct);
  return (
    <ProductEditorForm
      id="editProductForm"
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={getETicketConvertFormValuesToProductPatch(
        editingProduct,
        currency
      )}
      subscription={{
        submitError: true,
        submitting: true,
      }}
    >
      {({ submitError }) => (
        <>
          <Snackbar
            text={t('Save Successful')}
            color="success"
            shouldShow={success}
          />
          <Snackbar
            text={t('Save Failed')}
            color="error"
            shouldShow={submitError}
          />
          {submitError && <></>}

          {/* Basic editor */}
          <section className={styles['g-section']}>
            <div className={styles['p-products__info']}>
              {/* Find the first image in media list and use it for display pic, if there is none do no display */}
              {editingProduct?.media &&
                editingProduct?.media.length > 0 &&
                editingProduct?.media.find((item) => item.type === 'IMAGE') && (
                  <div className={styles['p-products__info__pic']}>
                    <img
                      src={
                        editingProduct?.media.find(
                          (item) => item.type === 'IMAGE'
                        )?.url
                      }
                    />
                  </div>
                )}
              <div className={styles['p-products__info_body']}>
                <div className={styles['p-products__info_body__label status1']}>
                  <Badge
                    label={getSalesStatusText(productSalesStatus, t)}
                    color={getBadgeColorForProductStatus(productSalesStatus)}
                    size="md"
                  />
                </div>
                <p className={styles['p-products__info_body__ttl']}>
                  {getProductEditorDisplayProductName(editingProduct)}
                </p>
              </div>
            </div>
          </section>

          <section
            id="ticket"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>{t('Ticket Settings')}</p>
            <CollapsibleSection
              id="basics"
              title={t('Basic Settings')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <BasicEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="startTime"
              title={t('Start and Expiry Dates')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <ScheduleEditor />
            </CollapsibleSection>

            <CollapsibleSection
              id="custom"
              title={t('Display Items')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <CustomEditor />
            </CollapsibleSection>
          </section>
        </>
      )}
    </ProductEditorForm>
  );
};
