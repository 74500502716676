import type { Equipment } from 'shared/models/swagger';

import { RuleListItem } from './RuleListItem';
import { NoSchedule } from './NoSchedule';

interface Props {
  equipment: Equipment;
}

export const RuleList = ({ equipment }: Props) => {
  if ((equipment.equipment_schedules || []).length === 0) {
    return <NoSchedule />;
  }

  return (
    <div>
      {(equipment?.equipment_schedules || []).map((schedule, index) => (
        <RuleListItem schedule={schedule} key={index} />
      ))}
    </div>
  );
};
