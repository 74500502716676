import useSWR from 'swr';
import axios from 'axios';
import moment from 'moment-timezone';
import { Field, useFormState } from 'react-final-form';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettings.module.css';
import { Box } from 'client/components/Box/Box';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import {
  activeUserOrganizationSelector,
  impersonatedAccountSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { getIDToken } from 'client/libraries/cognito';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { Account } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  startDate: string,
  endDate: string,
  impersonatedAccount?: Account
) =>
  axios
    .get(url, {
      params: {
        page_size: 10,
        offset: 0,
        is_ascending: false,
        order_by: 'booked_date_time_utc',
        booked_date_time_utc_from: startDate,
        booked_date_time_utc_to: endDate,
        statuses: ['CONFIRMED'],
        booking_sources: ['DIRECT_WEB'],
      },
      headers: {
        authorization: `Bearer ${token}`,
        'accept-language': lang,
        impersonate: impersonatedAccount
          ? buildImpersonateToken(impersonatedAccount)
          : '',
      },
    })
    .then((result) => result.data);

export const ActivityPlanSettingsEditor = () => {
  const { t, i18n } = useTranslation();

  const organization = useSelector(activeUserOrganizationSelector);
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );

  const startDateTimeUtc = moment
    .tz(
      organization?.activity_plan_settings?.current_period_start_date,
      organization?.default_timezone ?? 'UTC'
    )
    .utc()
    .format();

  const endDateTimeUtc = moment
    .tz(
      organization?.activity_plan_settings?.current_period_end_date,
      organization?.default_timezone ?? 'UTC'
    )
    .utc()
    .format();

  const { data, isValidating } = useSWR(
    organization?.activity_plan_settings?.is_subscribed
      ? [
          '/api/reservations/search',
          token,
          i18n.language,
          startDateTimeUtc,
          endDateTimeUtc,
          impersonatedAccount,
        ]
      : null,
    fetcher
  );

  const values = useFormState().values;
  return (
    <>
      <ul className={styles['p-general-list']}>
        {loggedInAccountIsNutmegAdmin && (
          <li className={styles['p-general-list__item']}>
            <div className={styles['p-general-list__item__ttl']}>
              <div className={styles['p-general-list__item__ttl__txt']}>
                <div>{t('Activity Plan')}</div>
              </div>
            </div>
            <div className={styles['p-general-list__item__body']}>
              <div
                className={styles['p-general-products__section__body__item']}
              >
                <Box mb={2}>
                  <Field
                    name="activityPlanSettings.isSubscribed"
                    type="checkbox"
                  >
                    {({ input }) => (
                      <>
                        <Toggle
                          label={t('Subscribe Activity Plan')}
                          checked={input.checked}
                          onChange={() => {
                            input.onChange(!input.checked);
                          }}
                          disabled={
                            organization?.activity_plan_settings?.is_subscribed
                          }
                        />
                      </>
                    )}
                  </Field>
                </Box>
                <Box mb={2}>
                  <dl className={styles['p-general-products__section__list']}>
                    <dt
                      className={
                        styles['p-general-products__section__list__term']
                      }
                    >
                      <span>{t('Plan Start Date')}</span>
                    </dt>
                    <dd
                      className={
                        styles['p-general-products__section__list__desc']
                      }
                    >
                      <Field name="activityPlanSettings.planStartDate">
                        {({ input }) => (
                          <TextField
                            {...input}
                            type={'date'}
                            disabled={
                              !values.activityPlanSettings?.isSubscribed
                            }
                          />
                        )}
                      </Field>
                    </dd>
                  </dl>
                </Box>
              </div>
            </div>
          </li>
        )}

        <li className={styles['p-general-list__item']}>
          <div className={styles['p-general-list__item__ttl']}>
            <div className={styles['p-general-list__item__ttl__txt']}>
              <div>{t('Number of Reservations')}</div>
            </div>
          </div>
          <div className={styles['p-general-list__item__body']}>
            <div className={styles['p-general-products__section__body__item']}>
              <Box mb={2}>
                <dl className={styles['p-general-products__section__list']}>
                  <dt
                    className={
                      styles['p-general-products__section__list__term']
                    }
                  >
                    <span>{t('Period')}</span>
                  </dt>
                  <dd
                    className={
                      styles['p-general-products__section__list__desc']
                    }
                  >
                    {values.activityPlanSettings?.isSubscribed ? (
                      <>
                        {values.activityPlanSettings?.currentPeriodStartDate} ~{' '}
                        {values.activityPlanSettings?.currentPeriodEndDate}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </dd>
                </dl>
              </Box>
              <Box mb={2}>
                <dl className={styles['p-general-products__section__list']}>
                  <dt
                    className={
                      styles['p-general-products__section__list__term']
                    }
                  >
                    <span>{t('Number of Reservations')}</span>
                  </dt>
                  <dd
                    className={
                      styles['p-general-products__section__list__desc']
                    }
                  >
                    {values.activityPlanSettings?.isSubscribed ? (
                      <>
                        {isValidating && <Icon name="circle notched" loading />}
                        {data?.total ?? 0}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </dd>
                </dl>
              </Box>
            </div>
          </div>
        </li>

        {loggedInAccountIsNutmegAdmin && (
          <li className={styles['p-general-list__item']}>
            <div className={styles['p-general-list__item__ttl']}>
              <div className={styles['p-general-list__item__ttl__txt']}>
                <div>{t('Menu Settings')}</div>
              </div>
            </div>
            <div className={styles['p-general-list__item__body']}>
              <div
                className={styles['p-general-products__section__body__item']}
              >
                <Box display="flex" flexDirection="column">
                  <Box mb={2}>
                    <Field
                      name="activityPlanSettings.isManifestEnabled"
                      type="checkbox"
                    >
                      {({ input }) => (
                        <>
                          <Toggle
                            label={`${t('Manifest/Dispatch')}`}
                            checked={input.checked}
                            onChange={() => {
                              input.onChange(!input.checked);
                            }}
                            disabled={
                              !values.activityPlanSettings?.isSubscribed
                            }
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                </Box>
              </div>
            </div>
          </li>
        )}
      </ul>
    </>
  );
};
