import * as React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import styles from 'client/components/v3/GenericDraggableList/GenericDraggableList.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { Stub } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';

import { StubOptionsForm } from './StubOptionsForm';

interface Props {
  stub: Stub;
  onChange: (newItem: Stub) => void;
  onRemoveClick: () => void;
  useRedemptionCount?: boolean;
}

export const StubItem = ({ stub, onChange, onRemoveClick }: Props) => {
  const { t } = useTranslation();
  const values = useFormState().values;
  const [useStubOption, setUseStubOption] = React.useState(
    (stub.options || []).length > 0
  );

  return (
    <>
      <div className={styles['p-topPage-sortList__item__left']}>
        <div className={styles['p-topPage-sortList__item__ic']}>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <Box width="100%">
          <Box display="flex" alignItems="center" width="100%">
            <Box mr={2} width="100%">
              <TextField
                label={t('Title')}
                name="title"
                value={stub.text}
                type="text"
                onChange={(value) => {
                  const newItem = {
                    ...stub,
                    text: value,
                  };
                  onChange(newItem);
                }}
              />
            </Box>
            {values?.shouldUseRedemptionCount && (
              <Box mr={2} width="100%">
                <TextField
                  label={t('Number of uses')}
                  name="numberOfUses"
                  value={String(stub.maxRedemptionCount)}
                  type="number"
                  onChange={(value) => {
                    const newItem = {
                      ...stub,
                      maxRedemptionCount: Number(value),
                    };
                    onChange(newItem);
                  }}
                />
              </Box>
            )}
          </Box>
          <Toggle
            label={t('Use Ticket Usage Options')}
            checked={useStubOption}
            onChange={() => {
              setUseStubOption(!useStubOption);
            }}
          />
          {useStubOption && (
            <StubOptionsForm
              stubKey={stub.key}
              value={stub.options || []}
              onChange={(newItem: any) => {
                const newStub = {
                  ...stub,
                  options: newItem,
                };
                onChange(newStub);
              }}
            />
          )}
        </Box>
      </div>
      <div className={styles['p-topPage-sortList__item__right']}>
        <Box display="flex" alignItems="center">
          <Button
            size="icon"
            color="tertiarygray"
            onClick={() => onRemoveClick()}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </Box>
      </div>
    </>
  );
};
