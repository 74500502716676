import * as React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { config } from 'client/config';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { Loading } from 'client/components/v3/Common/Loading';
import { ReduxState } from 'client/reducers';
import { fetchSystemFeeInvoice } from 'client/actions/systemFeeInvoices';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { isPartnershipSupplier } from 'client/libraries/util/partnership';
import { fetchPartnershipSystemFeeInvoice } from 'client/actions/partnershipSystemFeeInvoices';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import {
  ReservationStatus,
  SystemFeeInvoice,
  SystemFeeLineItem,
} from 'shared/models/swagger';
import pageHeaderStyle from 'client/components/v3/Page/PageHeader.module.css';

import { CustomTable } from './CustomTable';
import { SystemFeeInvoiceDownloadCSVModal } from './SystemFeeInvoiceDownloadCSVModal/SystemFeeInvoiceDownloadCSVModal';
import { CollapsibleSection } from './CollapsibleSection/CollapsibleSection';
import { FilterBlock } from './FilterBlock';
import { SystemFeeInvoiceSummary } from './SystemFeeInvoiceSummary';
import { FilterReservationsRequest } from './util';
import styles from './SystemFeeInvoiceDetails.module.css';
import { getSystemFeeInvoiceCSVColumns } from './SystemFeeInvoiceDownloadCSVModal/util';

export const SystemFeeInvoiceDetailsBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const org = useSelector(activeUserOrganizationSelector);
  const currency = useSelector(defaultProductCurrencySelector);
  const systemFeeInvoicesById = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.byId
  );
  const activeUser = useSelector(activeUserSelector);

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const csvLoading = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.csvLoading
  );

  const { id } = useParams<QueryParams>();
  const { partnershipMode } = React.useContext(PartnershipModeContext);
  const { setHeaderOverride } = React.useContext(PageHeaderOverrideContext);

  const [filterCondition, setFilterCondition] =
    React.useState<FilterReservationsRequest>(defaultFilterCondition);
  const [lastExecutedFilterCondition, setLastExecutedFilterCondition] =
    React.useState<FilterReservationsRequest>(filterCondition);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<'NUTMEG' | 'DIRECT'>(
    'NUTMEG'
  );

  const allColumns = getSystemFeeInvoiceCSVColumns(t, partnershipMode);
  const systemFeeInvoice = systemFeeInvoicesById[id];

  const formattedYearMonth = systemFeeInvoice
    ? moment(systemFeeInvoice.month).locale(locale).format(monthYearFormat)
    : '';

  React.useEffect(() => {
    if (formattedYearMonth) {
      if (partnershipMode) {
        setHeaderOverride(
          t('Fee - {{month}}', {
            month: formattedYearMonth,
          })
        );
      } else {
        setHeaderOverride(
          t('Web Bookings - {{month}}', {
            month: formattedYearMonth,
          })
        );
      }
    }

    return () => setHeaderOverride('');
  }, [formattedYearMonth, t]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, []);

  React.useEffect(() => {
    if (partnershipMode) {
      dispatch(fetchPartnershipSystemFeeInvoice(id));
    } else {
      dispatch(fetchSystemFeeInvoice(id));
    }
  }, [id]);

  const filteredLineItems = React.useMemo(
    () => getFilteredLineItems(systemFeeInvoice, lastExecutedFilterCondition),
    [systemFeeInvoice, lastExecutedFilterCondition]
  );

  const showNutmegContactNote = Boolean(
    org?.system_fee_settings?.show_nutmeg_contact_note
  );
  const showStripeConnectExpressNote =
    (org?.stripe_accounts ?? []).length > 0 &&
    !org?.stripe_accounts?.some(
      (acct) =>
        acct.account_type === 'JAPAN_NTMGJP_NO_CONNECT' ||
        acct.account_type === 'HIS_3DS'
    );

  const showDepositDate = org?.stripe_accounts?.some(
    (acct) => acct.account_type === 'JAPAN_EXPRESS'
  );

  const useGmo =
    org?.reservation_payment_gateway_settings?.payment_gateway === 'GMO';

  const useTab = useGmo && showDepositDate;

  return (
    <>
      <div className={baseStyles['l-main__header']}>
        {!partnershipMode && <ToggleNewUI origin="PAGE_HEADER" />}
        {showNutmegContactNote ? (
          <div className={styles['p-list__msg']}>
            <div className={styles['p-list__msg__ttl']}>
              <i className="c-icon-outline-alerts-alert-circle"></i>
              <p>
                {t(
                  'Nutmeg will contact you for details of the booking fees and billing amount'
                )}
              </p>
            </div>
          </div>
        ) : showStripeConnectExpressNote ? (
          <div className={styles['p-list__msg']}>
            <div className={styles['p-list__msg__ttl']}>
              <i className="c-icon-outline-alerts-alert-circle"></i>
              <p>
                {t(
                  'For bookings in Direct Billing, PIF Booking Fee and Credit Card Fee are deducted by Stripe and net amount will be transferred to your bank account. Billing amount from Nutmeg will not include those PIF transactions.'
                )}
              </p>
            </div>
          </div>
        ) : null}
        <div className={pageHeaderStyle['c-pageHeader']}>
          <div className={pageHeaderStyle['c-pageHeader__title']}>
            <h1>{t('Web Bookings')}</h1>
            <span>{t('Currency Code: {{currency}}', { currency })}</span>
          </div>
          {!useTab && (
            <div className={pageHeaderStyle['c-pageHeader__actions']}>
              <a
                className="c-button-bg-md-white"
                id="modal-btn-1"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <i className="c-icon-outline-general-download-02"></i>
                {csvLoading ? (
                  <Loading size="sm" />
                ) : (
                  <p
                    style={{
                      fontWeight: 'var(--text-semibold)',
                      fontSize: 'var(--text-sm-size)',
                    }}
                  >
                    {t('Download CSV')}
                  </p>
                )}
              </a>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <SystemFeeInvoiceDownloadCSVModal
          systemFeeInvoiceId={id}
          filters={filterCondition}
          setShowModal={setShowModal}
          showDepositDate={showDepositDate ?? false}
          settlementType={!useTab ? undefined : activeTab}
          allColumns={allColumns}
        />
      )}
      <div className={baseStyles['l-main__body']}>
        <section className={styles['g-section']}>
          <div className={styles['p-topPage-products__section']}>
            <div className={styles['p-topPage-products__section__header']}>
              <p className={styles['p-topPage-products__section__ttl']}>
                {t('Filter')}
              </p>
              <div className={styles['p-topPage-products__section__ic']}>
                <Button
                  text={isActive ? t('Hide') : t('Show')}
                  uiType="bg"
                  size="md"
                  color="tertiarygray"
                  iconBeforeText={
                    isActive ? (
                      <i className="c-icon-outline-general-minus"></i>
                    ) : (
                      <i className="c-icon-solid-general-plus"></i>
                    )
                  }
                  onClick={() => {
                    setIsActive(!isActive);
                  }}
                />
              </div>
            </div>

            <div
              className={clsx(
                styles['p-topPage-products__section__body'],
                isActive && styles['is-active']
              )}
            >
              <FilterBlock
                onReset={() => {
                  setFilterCondition(defaultFilterCondition);
                }}
                onSearch={() => {
                  setLastExecutedFilterCondition(filterCondition);
                }}
                filterCondition={filterCondition}
                setFilterCondition={(newCondition: FilterReservationsRequest) =>
                  setFilterCondition(newCondition)
                }
              />
            </div>
          </div>
        </section>
        <section className={clsx(styles['g-section'], baseStyles['u-mt-6'])}>
          <CollapsibleSection title={t('Summary')}>
            <SystemFeeInvoiceSummary invoice={systemFeeInvoice} />
          </CollapsibleSection>
        </section>
        {!useTab ? (
          <section
            className={clsx(styles['p-list-section'], baseStyles['u-mt-6'])}
          >
            <div className={styles['p-list-section__table']}>
              <CustomTable
                items={filteredLineItems}
                columns={useColumns(showDepositDate || false)}
                usePaging={true}
              />
            </div>
          </section>
        ) : (
          <section className={styles['g-section']}>
            <div className={clsx(styles['p-reservations'])}>
              <div className={styles['p-reservations__header']}>
                <ul className={styles['p-reservations__tab']}>
                  <li
                    className={clsx(
                      activeTab === 'NUTMEG' ? styles['is-active'] : ''
                    )}
                    onClick={() => {
                      setActiveTab('NUTMEG');
                    }}
                  >
                    <a className={styles['table--link']}>
                      <span className={styles['table--tab']}>
                        {t('Nutmeg Billing')}
                      </span>
                    </a>
                  </li>
                  <li
                    className={clsx(
                      activeTab === 'DIRECT' ? styles['is-active'] : ''
                    )}
                    onClick={() => {
                      setActiveTab('DIRECT');
                    }}
                  >
                    <a className={styles['table--link']}>
                      <span className={styles['table--tab']}>
                        {t('Direct Billing')}
                      </span>
                    </a>
                  </li>
                </ul>
                <div className={pageHeaderStyle['c-pageHeader__actions']}>
                  <a
                    className="c-button-bg-md-white"
                    id="modal-btn-1"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <i className="c-icon-outline-general-download-02"></i>
                    {csvLoading ? (
                      <Loading size="sm" />
                    ) : (
                      <p
                        style={{
                          fontWeight: 'var(--text-semibold)',
                          fontSize: 'var(--text-sm-size)',
                        }}
                      >
                        {t('Download CSV')}
                      </p>
                    )}
                  </a>
                </div>
              </div>
              <CustomTable
                items={
                  !useTab
                    ? filteredLineItems
                    : filteredLineItems.filter(
                        (i) => i?.settlement_type === activeTab
                      )
                }
                columns={useColumns(activeTab === 'NUTMEG' ? false : true)}
                usePaging={true}
              />
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign: 'right' | 'center' | 'left';
};

const getGuestName = (lineItem: SystemFeeLineItem): string => {
  const givenName = lineItem.given_name;
  const lastName = lineItem.family_name;
  const kanaGivenName = lineItem.kana_given_name;
  const kanaLastName = lineItem.kana_family_name;

  let customerName = '';
  if (givenName || lastName) {
    customerName = `${givenName} ${lastName}`;

    if (kanaGivenName || kanaLastName) {
      customerName =
        customerName + ' ' + `（${kanaLastName} ${kanaGivenName}）`;
    }
  } else {
    customerName = `${kanaLastName} ${kanaGivenName}`;
  }

  return customerName;
};

const getGuestDescription = (
  guestCounts: SystemFeeLineItem['unit_counts']
): string => {
  return (
    guestCounts
      ?.map(
        (guestCount) => `${guestCount.guest_type_title} x ${guestCount.count}`
      )
      .join(', ') ?? ''
  );
};

const getTotalPax = (guestCounts: SystemFeeLineItem['unit_counts']): number => {
  let count = 0;

  for (const guestCount of guestCounts ?? []) {
    count += guestCount.count ?? 0;
  }

  return count;
};

const useColumns = (
  showDepositDate: boolean
): ColumnType<SystemFeeLineItem>[] => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const organization = useSelector(activeUserOrganizationSelector);

  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return [
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (row) => row.agent_reference ?? '-',
      width: 'middle',
      Cell: (cellInfo) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/reservations/${cellInfo.original.reservation_id}`}
        >
          {cellInfo.original.agent_reference}
        </a>
      ),
      textAlign: 'left',
    },
    ...(isPartnershipSupplier(organization) && partnershipMode
      ? ([
          {
            Header: t('Agent'),
            id: 'agent',
            accessor: (row) => {
              const agent = contracted.find(
                (org) => org.id === row.pif_agent_id
              );
              return agent?.name ?? '-';
            },
            textAlign: 'left',
            width: 'middle',
          },
        ] as ColumnType<SystemFeeLineItem>[])
      : ([] as ColumnType<SystemFeeLineItem>[])),
    {
      Header: t('Booked Date'),
      id: 'booked_at',
      accessor: (row) =>
        moment(row.booked_date_time_utc).locale(locale).format('lll'),
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Participation'),
      id: 'participation',
      accessor: (row) => {
        if (row.is_free_start_date_time && row.first_checkin_date_time_utc) {
          const m = moment
            .tz(row.first_checkin_date_time_utc, row.start_timezone ?? '')
            .locale(locale);
          return `${m.format('lll')} (GMT${m.format('Z')})`;
        }

        const m = moment
          .tz(row.start_date_time_utc, row.start_timezone ?? '')
          .locale(locale);

        return `${m.format('lll')} (GMT${m.format('Z')})`;
      },
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Customer'),
      id: 'customer',
      accessor: (row) => getGuestName(row),
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (row) => t(row.reservation_status as any),
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Product'),
      id: 'product',
      Cell: (cellInfo) => (
        <Link to={`/products/${cellInfo.original.product_id}`}>
          {cellInfo.original.internal_product_name ??
            cellInfo.original.product_name}
        </Link>
      ),
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Units'),
      id: 'units',
      accessor: (row) => getGuestDescription(row.unit_counts),
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Total Pax'),
      id: 'totalPax',
      accessor: (row) => `${getTotalPax(row.unit_counts)}`,
      textAlign: 'right',
      width: 'middle',
    },
    {
      Header: t('Payment Type'),
      id: 'paymentType',
      accessor: (row) => (row.payment_type && t(row.payment_type as any)) || '',
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Credit Card Brand'),
      id: 'creditCardBrand',
      accessor: (row) =>
        row.payments?.find((payment) => payment.credit_card_brand)
          ?.credit_card_brand ?? '-',
      textAlign: 'left',
      width: 'middle',
    },
    {
      Header: t('Gross'),
      id: 'gross',
      accessor: (row) => getFormattedAmount(row.amount_gross),
      textAlign: 'right',
      width: 'middle',
    },
    {
      Header: t('Cancel Fee'),
      id: 'cancelFee',
      accessor: (row) => getFormattedAmount(row.amount_cancellation_fee_gross),
      textAlign: 'right',
      width: 'middle',
    },
    {
      Header: t('Email Payment'),
      id: 'emailPayment',
      accessor: (row) => (row.is_email_payment ? t('yes') : t('no')),
      textAlign: 'left',
      width: 'middle',
    },
    ...(config.enablePartnership &&
    isPartnershipSupplier(organization) &&
    (!config.enablePartnershipSystemFeeInvoiceTable || partnershipMode)
      ? [
          {
            Header: t('Commission'),
            id: 'commission',
            accessor: (row: SystemFeeLineItem) =>
              getFormattedAmount(row.amount_commission),
            textAlign: 'right' as const,
            width: 'middle' as ColumnType<SystemFeeLineItem>['width'],
          },
        ]
      : []),
    {
      Header: t('Booking Fee'),
      id: 'bookingFee',
      accessor: (row) => getFormattedAmount(row.amount_booking_fee),
      textAlign: 'right',
      width: 'middle',
    },
    {
      Header: t('Credit Card Fee'),
      id: 'creditCardFee',
      accessor: (row) => getFormattedAmount(row.amount_credit_card_fee),
      textAlign: 'right',
      width: 'middle',
    },
    ...(showDepositDate
      ? [
          {
            Header: t('Deposit Date'),
            id: 'depositDate',
            Cell: (cellInfo: { original: SystemFeeLineItem }) =>
              (cellInfo.original.payout_date_times_utc ?? []).length > 0 ? (
                <ul>
                  {cellInfo.original.payout_date_times_utc?.map(
                    (dateTime, idx) => (
                      <li key={idx}>
                        {moment(dateTime).locale(locale).format('ll')}
                      </li>
                    )
                  )}
                </ul>
              ) : null,
            textAlign: 'left' as const,
            width: 'middle' as ColumnType<SystemFeeLineItem>['width'],
          },
          {
            Header: t('Deposit Amount'),
            id: 'depositAmount',
            Cell: (cellInfo: { original: SystemFeeLineItem }) =>
              (cellInfo.original.payments ?? []).length > 0 ? (
                <ul>
                  {cellInfo.original.payments?.map((payment, idx) =>
                    payment.amount_stripe_transfer ? (
                      <li key={idx}>
                        {payment.type === 'CHARGE'
                          ? getFormattedAmount(payment.amount_stripe_transfer)
                          : `-${getFormattedAmount(
                              payment.amount_stripe_transfer
                            )}`}
                      </li>
                    ) : null
                  )}
                </ul>
              ) : null,
            textAlign: 'right' as const,
            width: 'middle' as ColumnType<SystemFeeLineItem>['width'],
          },
          {
            Header: t('Transaction Date (UTC)'),
            id: 'transactionDate',
            width: 'middle' as ColumnType<SystemFeeLineItem>['width'],
            Cell: (cellInfo: { original: SystemFeeLineItem }) =>
              (cellInfo.original.payments ?? []).length > 0 ? (
                <ul>
                  {cellInfo.original.payments?.map((payment, idx) =>
                    payment.stripe_transfer_date_time_utc ? (
                      <li key={idx}>
                        {moment(payment.stripe_transfer_date_time_utc)
                          .utc()
                          .format('YYYY/MM/DD HH:mm')}
                      </li>
                    ) : null
                  )}
                </ul>
              ) : null,
            textAlign: 'right' as const,
          },
        ]
      : []),
  ];
};

const getFilteredLineItems = (
  invoice: SystemFeeInvoice | undefined,
  filterCondition: FilterReservationsRequest
): SystemFeeLineItem[] => {
  let results: SystemFeeLineItem[] = invoice?.line_items ?? [];

  const exemptedReservationIds =
    invoice?.fare_adjustment?.exempted_reservation_ids ?? [];
  if (exemptedReservationIds.length > 0) {
    results = results.filter(
      (lineItem) =>
        !exemptedReservationIds.includes(lineItem.reservation_id ?? '')
    );
  }

  if (filterCondition.reservationStatuses.length > 0) {
    results = results.filter((lineItem) =>
      filterCondition.reservationStatuses.includes(
        lineItem.reservation_status as ReservationStatus
      )
    );
  }

  if (filterCondition.productIds.length > 0) {
    results = results.filter((lineItem) =>
      filterCondition.productIds.includes(lineItem.product_id ?? '')
    );
  }

  if (filterCondition.paymentTypes.length > 0) {
    results = results.filter(
      (lineItem) =>
        filterCondition.paymentTypes.includes(lineItem.payment_type ?? '') ||
        (lineItem.payment_type === 'PAID_PARTIALLY' &&
          filterCondition.paymentTypes.length > 0)
    );
  }

  if (filterCondition.transferDateFrom) {
    results = results.filter((lineItem) =>
      lineItem.payout_date_times_utc?.some((payoutDateTimeUtc) => {
        if (payoutDateTimeUtc) {
          const localTransferDate =
            moment(payoutDateTimeUtc).format('YYYY-MM-DD');

          return localTransferDate >= filterCondition.transferDateFrom;
        }

        return false;
      })
    );
  }

  if (filterCondition.transferDateTo) {
    results = results.filter((lineItem) =>
      lineItem.payout_date_times_utc?.some((payoutDateTimeUtc) => {
        if (payoutDateTimeUtc) {
          const localTransferDate =
            moment(payoutDateTimeUtc).format('YYYY-MM-DD');

          return localTransferDate <= filterCondition.transferDateTo;
        }

        return false;
      })
    );
  }

  return results;
};

interface QueryParams {
  id: string;
}

const defaultFilterCondition: FilterReservationsRequest = {
  productIds: [],
  reservationStatuses: [],
  paymentTypes: [],
  transferDateFrom: '',
  transferDateTo: '',
  agentIds: [],
};
