//import type { LanguageISO } from 'shared/libraries/i18n';

const FemaleLangCodeMap: Record<string, string> = {
  'en-US': 'Female',
  'ja-JP': '女性',
  'ko-KR': '여성',
  'zh-CN': '女性',
  'zh-TW': '女性',
};
const FemaleResponses: string[] = ['Female', '女性', '여성'];

const MaleLangCodeMap: Record<string, string> = {
  'en-US': 'Male',
  'ja-JP': '男性',
  'ko-KR': '남성',
  'zh-CN': '男性',
  'zh-TW': '男性',
};
const MaleResponses: string[] = ['Male', '男性', '남성'];

export const getGenderMaleFieldResponse = (lang: string): string => {
  return MaleLangCodeMap[lang] || '男性';
};

export const getGenderFemaleFieldResponse = (lang: string): string => {
  return FemaleLangCodeMap[lang] || '女性';
};

export const getGenderFieldResponse = (
  lang: string,
  response: string
): string => {
  if (MaleResponses.includes(response)) {
    return getGenderMaleFieldResponse(lang);
  }
  if (FemaleResponses.includes(response)) {
    return getGenderFemaleFieldResponse(lang);
  }
  return '';
};
