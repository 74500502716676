import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';
import { getBookingWidgetProductGroupCalendarUrl } from 'client/libraries/util/getBookingWidgetProductGroupCalendarUrl';
import { EmbeddedProductGroupCalendar } from 'client/pages/v3/BookingWidget/WebSiteIntegration/EmbeddedProductGroupCalendar/EmbeddedProductGroupCalendar';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

type Props = {
  bookingWidgetApiKey: string;
  widgetLanguage: string;
};

export const BookingWidgetEmbeddedProductGroupCalendar = ({
  widgetLanguage,
  bookingWidgetApiKey,
}: Props) => {
  const { t } = useTranslation();

  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);

  const calendarUrl = getBookingWidgetProductGroupCalendarUrl(
    bookingWidgetApiKey,
    widgetLanguage
  );

  const embedCode = `<script type="text/javascript">
        /*<![CDATA[*/
        (function () {
            loadScriptsAsync();
            function loadScriptsAsync() {
                if (window.buildMultiCalendarEmbed) {
                    NutmegMultiCalendarInit();
                } else {
                    var script = document.createElement('script');
                    script.src = 'https://assets.ntmg.com/scripts/falcon-embed-multi-product/v1/index.js';
                    script.async = true;
                    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                    script.onload = NutmegMultiCalendarInit
                }
            
                if (!window.NutmegCalendar) {
                  script = document.createElement('script');
                  script.src = 'https://assets.ntmg.com/scripts/falcon-embed/v1/index.js';
                  script.async = true;
                  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                }
            }
            function NutmegMultiCalendarInit() {
                window.buildMultiCalendarEmbed('${calendarUrl}');
            }
        })();
        /*]]>*/
    </script>
    <div id="nutmeg-multi-calendar"></div>`;

  return (
    <div className={styles['p-inquiry-list__item__body__item']}>
      {copySuccess && (
        <Snackbar
          color="success"
          text={t('Copied')}
          shouldShow={copySuccess}
          onClose={() => setCopySuccess(false)}
        />
      )}
      <div className={styles['p-bookingWidgetCustomize-box']}>
        <div className={styles['p-bookingWidgetCustomize-box__list']}>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Product Group')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <EmbeddedProductGroupCalendar
                  apiKey={bookingWidgetApiKey}
                  language={widgetLanguage}
                />
              </dd>
            </dl>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Code')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <Button
                  uiType="bg"
                  size="icon"
                  color="white"
                  text={t('Copy embed code')}
                  iconBeforeText={
                    <i className="c-icon-outline-general-copy-02"></i>
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(embedCode);
                    setCopySuccess(true);
                  }}
                />
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
