import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { Product } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';

import styles from './HeaderButtons.module.css';

interface Props {
  product: Product;
}
export const HeaderButtons = ({ product }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['base-single__header']}>
        <div className={styles['base-single__buttons']}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className={styles['language-select']}
          ></div>
          {/* TODO: remove v3 from url later */}
          <Link to={`/products/${product.id}/translate/eticket`}>
            <Button
              text={t('ETicket Translation')}
              uiType="bg"
              size="md"
              color="white"
              iconBeforeText={
                <i className="c-icon-solid-general-translate-01"></i>
              }
            />
          </Link>
        </div>
      </div>
    </>
  );
};
