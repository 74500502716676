import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';

import {
  Button,
  FieldWrapper,
  Input,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateDigitalMap } from 'client/actions/digitalMaps';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Delete } from 'client/components/Icons/Delete';
import { Add } from 'client/components/Icons/Add';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
  defaultInitialValues,
} from './formValues';

export const DigitalMapPinCategoriesEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );

  const initialValues = React.useMemo(() => {
    return existingDigitalMap
      ? convertSwaggerToFormValues(existingDigitalMap)
      : defaultInitialValues;
  }, [existingDigitalMap]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateDigitalMap(id, convertFormValuesToSwagger(values))
        );
      }}
      initialValues={initialValues}
      debug={console.log}
      mutators={getArrayMutators()}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
      }) => (
        <div className={baseStyles['base-main__body__box']}>
          <div className={baseStyles['base-main__body__box__body']}>
            <form onSubmit={handleSubmit}>
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <FieldArray name="pinCategories">
                {({ fields }) => (
                  <>
                    <Add
                      onClick={() =>
                        (fields as any).insertAt(0, {
                          iconUrl: '',
                          label: '',
                        })
                      }
                    />
                    <table className={baseStyles['base-table']}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>{t('Category Name')}</th>
                          <th>{t('Icon')}</th>
                          <th>{t('Initial State')}</th>
                          <th style={{ width: '112px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map((pinCategory, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid #cfdae1' }}>
                              <Box ml={2} mr={2}>
                                {t('Category #{{index}}', { index: index + 1 })}
                              </Box>
                            </td>
                            <td style={{ border: '1px solid #cfdae1' }}>
                              {fields.value[index].label === 'TRANSPORT' ? (
                                <div>{t('Special Category (Transport)')}</div>
                              ) : (
                                <Field name={`${pinCategory}.label`}>
                                  {({ input }) => (
                                    <Box ml={4} mr={4} mt={2}>
                                      <Input
                                        label={t('Category Name')}
                                        {...input}
                                      />
                                    </Box>
                                  )}
                                </Field>
                              )}
                            </td>
                            <td style={{ border: '1px solid #cfdae1' }}>
                              <Field name={`${pinCategory}.iconUrl`}>
                                {({ input }) => (
                                  <Box ml={4} mt={2}>
                                    <FieldWrapper
                                      label={t('Map Pin Icon Image')}
                                    />
                                    <ImageVideoAudioInput
                                      fileUrls={
                                        input.value ? [input.value] : []
                                      }
                                      onChange={(newValue) =>
                                        newValue.length > 0
                                          ? input.onChange(newValue[0])
                                          : input.onChange('')
                                      }
                                      maxFileCount={1}
                                      disableYoutubeVideos
                                    />
                                  </Box>
                                )}
                              </Field>
                            </td>
                            <td style={{ border: '1px solid #cfdae1' }}>
                              <Field
                                name={`${pinCategory}.initiallySelected`}
                                type="checkbox"
                              >
                                {({ input }) => (
                                  <Box ml={4} mt={2}>
                                    <ToggleButton
                                      checked={input.checked ?? false}
                                      onChange={() =>
                                        input.onChange(!input.checked)
                                      }
                                      label={t(
                                        'Set to be selected when the map is opened'
                                      )}
                                    />
                                  </Box>
                                )}
                              </Field>
                            </td>
                            <td style={{ border: '1px solid #cfdae1' }}>
                              <Box
                                ml={2}
                                mr={2}
                                display="flex"
                                justifyContent="center"
                              >
                                {fields.value[index].label !== 'TRANSPORT' && (
                                  <Delete
                                    onClick={() => {
                                      fields.remove(index);
                                    }}
                                  />
                                )}
                                <Add
                                  onClick={() => {
                                    (fields as any).insertAt(index + 1, {
                                      iconUrl: '',
                                      label: '',
                                    });
                                  }}
                                />
                              </Box>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </FieldArray>

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Form>
  );
};
