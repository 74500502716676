import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';

import { Box } from 'client/components/Box/Box';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { Button } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateDigitalMap } from 'client/actions/digitalMaps';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
  defaultInitialValues,
} from './formValues';

export const DigitalMapPwaSettingsEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );

  const initialValues = React.useMemo(() => {
    return existingDigitalMap
      ? convertSwaggerToFormValues(existingDigitalMap)
      : defaultInitialValues;
  }, [existingDigitalMap]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateDigitalMap(id, convertFormValuesToSwagger(values))
        );
      }}
      initialValues={initialValues}
      debug={console.log}
      mutators={getArrayMutators()}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
        values,
      }) => (
        <div className={baseStyles['base-main__body__box']}>
          <div className={baseStyles['base-main__body__box__body']}>
            <form onSubmit={handleSubmit}>
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <Box display="flex" flexDirection="column" gap={2}>
                <Field type="checkbox" name="pwaSettings.enablePwaInstallation">
                  {({ input }) => (
                    <Box mt={1} mb={2} display="flex" gap={1}>
                      <Toggle
                        {...input}
                        label={t('Enable Progressive Web App')}
                      />
                      <Tooltip
                        text={t(
                          'If the user elects to install the PWA, they will be able to access the map offline, receive push notifications on iOS, and view the map in full screen.'
                        )}
                      >
                        <i className="c-icon-outline-general-info-circle"></i>
                      </Tooltip>
                    </Box>
                  )}
                </Field>

                {values.pwaSettings.enablePwaInstallation && (
                  <>
                    <Field name="pwaSettings.title">
                      {({ input }) => (
                        <TextField {...input} label={t('PWA Title')} />
                      )}
                    </Field>

                    <Field name="pwaSettings.description">
                      {({ input }) => (
                        <TextArea {...input} label={t('PWA Description')} />
                      )}
                    </Field>

                    <Box>
                      <Message info>
                        {t('Need help generating icons? Try ')}
                        <a
                          href="https://www.pwabuilder.com/imageGenerator"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'underline' }}
                        >
                          PWA Builder Image Generator
                        </a>
                        {t(' to create icons in all required sizes.')}
                      </Message>
                    </Box>

                    <Field name="pwaSettings.iconUrl192">
                      {({ input }) => (
                        <>
                          <FieldWrapper
                            label={t('Icon URL (192x192)')}
                            tooltipText={t(
                              'The 192x192 icon URL for the PWA. This size is used for Android devices.'
                            )}
                          />
                          <ImageVideoAudioInput
                            fileUrls={
                              input.value.length > 0 ? [input.value] : []
                            }
                            onChange={(newValue) => input.onChange(newValue[0])}
                            disableYoutubeVideos={true}
                            maxFileCount={1}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="pwaSettings.iconUrl512">
                      {({ input }) => (
                        <>
                          <FieldWrapper
                            label={t('Icon URL (512x512)')}
                            tooltipText={t(
                              'The 512x512 icon URL for the PWA. This size is used for Android devices.'
                            )}
                          />
                          <ImageVideoAudioInput
                            fileUrls={
                              input.value.length > 0 ? [input.value] : []
                            }
                            onChange={(newValue) => input.onChange(newValue[0])}
                            disableYoutubeVideos={true}
                            maxFileCount={1}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="pwaSettings.iconUrl180">
                      {({ input }) => (
                        <>
                          <FieldWrapper
                            label={t('Icon URL (180x180)')}
                            tooltipText={t(
                              'The 180x180 icon URL for the PWA. This size is used for iPhones.'
                            )}
                          />
                          <ImageVideoAudioInput
                            fileUrls={
                              input.value.length > 0 ? [input.value] : []
                            }
                            onChange={(newValue) => input.onChange(newValue[0])}
                            disableYoutubeVideos={true}
                            maxFileCount={1}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="pwaSettings.themeColor">
                      {({ input }) => (
                        <>
                          <FieldWrapper label={t('Theme Color')} />
                          <ColorPicker
                            color={input.value}
                            onChange={({ color }: any) => input.onChange(color)}
                          />
                        </>
                      )}
                    </Field>
                  </>
                )}
              </Box>

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Form>
  );
};
