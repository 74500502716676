import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

import styles from './JourneyAnalyticsPrivacyPolicies.module.css';
import {
  FormValues,
  getInitialValues,
  convertFormValuesToOrganizationPatch,
} from './formValues';

export const JourneyAnalyticsPrivacyPolicies = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const org = useSelector(activeUserOrganizationSelector);
  const initialValues = React.useMemo(() => getInitialValues(org), [org]);

  const onSubmit = async (values: FormValues) => {
    const patch = convertFormValuesToOrganizationPatch(values);
    await dispatch(updateOrganization(org?.id ?? '', 'SUPPLIER', patch));
  };

  const languages = getBookingWidgetPmpSupportLanguages(org);

  return (
    <V3Page>
      <PageHeader
        title={t('Privacy Policies')}
        description={t(
          'Manage custom privacy policy URLs for Journey Analytics'
        )}
        hideNewUIToggle={true}
      />
      <PageContent>
        <div className={styles['section']}>
          <div className={styles['container']}>
            <div className={styles['header']}>
              <h2>{t('Privacy Policy URLs')}</h2>
            </div>
            <Form onSubmit={onSubmit} initialValues={initialValues}>
              {({
                handleSubmit,
                submitting,
                submitSucceeded,
                submitError,
                modifiedSinceLastSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  {submitSucceeded && !modifiedSinceLastSubmit && (
                    <Snackbar
                      color={'success'}
                      text={t('Save Successful')}
                      shouldShow={submitSucceeded}
                    />
                  )}
                  {submitError && !modifiedSinceLastSubmit && (
                    <Snackbar
                      color={'error'}
                      text={t('Save Failed')}
                      shouldShow={submitError}
                    />
                  )}
                  {languages.includes('EN_US') && (
                    <Field name="urls.EN_US">
                      {({ input }) => (
                        <TextField {...input} label={t('English')} />
                      )}
                    </Field>
                  )}
                  {languages.includes('JA_JP') && (
                    <Box mt={3}>
                      <Field name="urls.JA_JP">
                        {({ input }) => (
                          <TextField {...input} label={t('Japanese')} />
                        )}
                      </Field>
                    </Box>
                  )}
                  {languages.includes('KO_KR') && (
                    <Box mt={3}>
                      <Field name="urls.KO_KR">
                        {({ input }) => (
                          <TextField {...input} label={t('Korean')} />
                        )}
                      </Field>
                    </Box>
                  )}
                  {languages.includes('ZH_TW') && (
                    <Box mt={3}>
                      <Field name="urls.ZH_TW">
                        {({ input }) => (
                          <TextField
                            {...input}
                            label={t('Chinese (Traditional)')}
                          />
                        )}
                      </Field>
                    </Box>
                  )}
                  {languages.includes('ZH_CN') && (
                    <Box mt={3}>
                      <Field name="urls.ZH_CN">
                        {({ input }) => (
                          <TextField
                            {...input}
                            label={t('Chinese (Simplified)')}
                          />
                        )}
                      </Field>
                    </Box>
                  )}
                  <Box mt={4} display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      text={t('Save')}
                      loading={submitting}
                    />
                  </Box>
                </form>
              )}
            </Form>
          </div>
        </div>
      </PageContent>
    </V3Page>
  );
};
