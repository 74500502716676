import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import {
  activeUserOrganizationSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { hideEmbedCalendar } from 'client/components/ESaas/Keihan/utils';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { CustomDomainTab } from './CustomDomainTab';
import { EmbedCalendarTab } from './EmbedCalendarTab/EmbedCalendarTab';
import { FloatingButtonTab } from './FloatingButtonTab';
import { FareharborTab } from './FareharborTab';

type TabType = 'CUSTOM_DOMAIN' | 'EMBED' | 'FLOAT_BUTTON' | 'FAREHARBOR';

export const BookingWidgetWebSiteIntegration = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );

  const [activeTab, setActiveTab] = React.useState<TabType>('CUSTOM_DOMAIN');

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="BOOKING_SITE_WEBSITEINTEGRATION" />
      )}
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'CUSTOM_DOMAIN' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('CUSTOM_DOMAIN');
            }}
          >
            <a>{t('Custom Domain')}</a>
          </li>
          {!hideEmbedCalendar(activeUserOrganization) && (
            <>
              <li
                className={clsx(
                  activeTab === 'EMBED' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('EMBED');
                }}
              >
                <a>{t('Embed')}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'FLOAT_BUTTON'
                    ? componentStyles['is-active']
                    : ''
                )}
                onClick={() => {
                  setActiveTab('FLOAT_BUTTON');
                }}
              >
                <a>{t('Floating Button')}</a>
              </li>
            </>
          )}
          {loggedInAccountIsNutmegAdmin && (
            <li
              className={clsx(
                activeTab === 'FAREHARBOR' ? componentStyles['is-active'] : ''
              )}
              onClick={() => {
                setActiveTab('FAREHARBOR');
              }}
            >
              <a>{t('FareHarbor')}</a>
            </li>
          )}
        </ul>
      </div>

      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          componentStyles['no-margin-top'],
          componentStyles['is-active']
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <div className={baseStyles['acBody' as keyof typeof baseStyles]}>
            {activeTab === 'CUSTOM_DOMAIN' && <CustomDomainTab />}
            {activeTab === 'EMBED' && <EmbedCalendarTab />}
            {activeTab === 'FLOAT_BUTTON' && <FloatingButtonTab />}
            {activeTab === 'FAREHARBOR' && <FareharborTab />}
          </div>
        </div>
      </div>
    </>
  );
};
