import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'client/components/v3/GenericDraggableList/GenericDraggableList.module.css';
import type { Product } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { FieldResponseItemType } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { SingleDropdown } from 'client/pages/v3/Product/ProductPromotions/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';

interface Props {
  item: FieldResponseItemType;
  onChange: (newItem: FieldResponseItemType) => void;
  onRemoveClick: () => void;
  includeConfirmationNumber?: boolean;
  includeAddOns?: boolean;
  excludeHotelInfomationCheckinCheckout?: boolean;
  formFieldType?: 'PER_BOOKING' | 'PER_PARTICIPANT';
}

export const FieldResponseItem = ({
  item,
  onChange,
  onRemoveClick,
  includeConfirmationNumber,
  includeAddOns,
  excludeHotelInfomationCheckinCheckout,
  formFieldType,
}: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  console.log('### product', product);

  return (
    <>
      <div className={styles['p-topPage-sortList__item__left']}>
        <div className={styles['p-topPage-sortList__item__ic']}>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <Box width="100%">
          <Box display="flex" alignItems="center" width="100%">
            <Box mr={2} width="100%">
              <SingleDropdown
                label={t('Display Item')}
                selectedOption={item.key || ''}
                options={getItemFieldOptions(
                  product,
                  formFieldType,
                  includeConfirmationNumber,
                  includeAddOns,
                  excludeHotelInfomationCheckinCheckout,
                  t
                )}
                onChange={(value) => {
                  const newItem = {
                    ...item,
                    key: value,
                  };
                  onChange(newItem);
                }}
              />
            </Box>
            <Box mr={2} width="100%">
              <TextField
                label={t('Display Name')}
                name="title"
                value={item.title || ''}
                type="text"
                onChange={(value) => {
                  const newItem = {
                    ...item,
                    title: value,
                  };
                  onChange(newItem);
                }}
              />
            </Box>
          </Box>
        </Box>
      </div>
      <div className={styles['p-topPage-sortList__item__right']}>
        <Box display="flex" alignItems="center">
          <Button
            size="icon"
            color="tertiarygray"
            onClick={() => onRemoveClick()}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </Box>
      </div>
    </>
  );
};

const getItemFieldOptions = (
  product: Product | null,
  formFieldType: 'PER_BOOKING' | 'PER_PARTICIPANT' | undefined,
  includeConfirmationNumber: boolean | undefined,
  includeAddOns: boolean | undefined,
  excludeHotelInfomationCheckinCheckout: boolean | undefined,
  t: TranslateFuncType
) => {
  const options =
    product?.reservation_form_fields
      ?.filter((field) => {
        if (formFieldType) {
          return field.type === formFieldType;
        }
        return true;
      })
      .filter((field) => {
        if (excludeHotelInfomationCheckinCheckout) {
          return field.key !== 'hotel_information_checkin_checkout';
        }

        return true;
      })
      .map((field) => ({
        value: field.key || '',
        text: field.prompt || '',
      })) ?? [];

  return [
    ...options,
    ...(includeConfirmationNumber
      ? [
          {
            value: 'supplier_reference',
            text: t('Confirmation Number'),
          },
        ]
      : []),
    ...(includeAddOns
      ? [
          {
            value: 'add_ons',
            text: t('Add-Ons'),
          },
        ]
      : []),
  ];
};
