import React, { useState, useRef, useEffect } from 'react';

import sendIcon from '../../images/chat/mail-send.svg';

import styles from './InternalChat.module.css';
import { ChatInputProps } from './types';

export const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  const handleSubmit = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
      // Reset height after sending
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={styles.inputContainer}>
      <label htmlFor="messageInput" className={styles.visuallyHidden}>
        Type a message
      </label>
      <textarea
        ref={textareaRef}
        id="messageInput"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        className={styles.messageInput}
        placeholder="Message"
        aria-label="Type a message"
        rows={1}
      />
      <button
        className={styles.sendButton}
        aria-label="Send message"
        onClick={handleSubmit}
      >
        <img src={sendIcon} />
      </button>
    </div>
  );
};
