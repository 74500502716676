import { BusRoute } from 'shared/models/swagger';

export interface BusRouteFormValues {
  name: string;
  color: string;
  path: string;
  busTrackerType: BusRoute['bus_tracker_type'];
  gpsDeviceIMEIs: string[];
  simulatedBusCount: number;
}

export const getInitialBusRouteFormValues = (): BusRouteFormValues => ({
  name: '',
  color: '#000000',
  path: '',
  busTrackerType: 'NONE',
  gpsDeviceIMEIs: [],
  simulatedBusCount: 0,
});

export const convertSwaggerBusRouteToFormValues = (
  busRoute: BusRoute
): BusRouteFormValues => ({
  name: busRoute.name ?? '',
  color: busRoute.color ?? '',
  path:
    busRoute.route?.map((point) => `${point.lng},${point.lat}`).join('\n') ??
    '',
  busTrackerType: busRoute.bus_tracker_type ?? 'NONE',
  gpsDeviceIMEIs: busRoute.gps_device_imeis ?? [],
  simulatedBusCount: busRoute.simulated_bus_count ?? 0,
});

export const convertFormValuesToSwaggerBusRoute = (
  values: BusRouteFormValues
): BusRoute => ({
  name: values.name,
  color: values.color,
  route: values.path
    .trim()
    .split('\n')
    .map((point) => {
      const [lng, lat] = point.trim().split(',');
      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    }),
  simulated_bus_count:
    values.busTrackerType === 'SIMULATED' ? values.simulatedBusCount : 0,
  gps_device_imeis:
    values.busTrackerType === 'IMEI' ? values.gpsDeviceIMEIs : [],
  bus_tracker_type: values.busTrackerType,
});
