import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  PointerSensor,
  useSensor,
  DndContext,
  useSensors,
  KeyboardSensor,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { fetchBusRoutes } from 'client/actions/busRoutes';

import { FormValues } from './formValues';
import { SortableBusRouteItem } from './SortableBusRouteItem';
import styles from './DigitalMapBasicsEditor.module.css';

interface Props {
  name: string;
}

export const PinBusRouteEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const { values } = form.getState();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchBusRoutes());
  }, []);

  const busRoutes = useSelector((state: ReduxState) => state.busRoutes.all);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const busRouteOptions = busRoutes.map((route) => ({
    value: route.id ?? '',
    text: route.name ?? '',
  }));

  return (
    <Box mt={3} mb={4}>
      <FieldWrapper label={t('Bus Routes')} />
      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={({ active, over }: DragEndEvent) => {
            if (over && active.id !== over.id) {
              const oldIndex = values.busRouteIds.findIndex(
                (id) => id === active.id
              );
              const newIndex = values.busRouteIds.findIndex(
                (id) => id === over.id
              );

              if (oldIndex !== -1 && newIndex !== -1) {
                const newIds = arrayMove(
                  values.busRouteIds,
                  oldIndex,
                  newIndex
                );
                form.change(name as keyof FormValues, newIds);
              }
            }
          }}
        >
          <SortableContext
            items={_.get(values, name)}
            strategy={verticalListSortingStrategy}
          >
            {_.get(values, name).map((routeId: string, index: number) => (
              <div key={routeId} className={styles.routeItem}>
                <SortableBusRouteItem
                  id={routeId}
                  value={routeId}
                  options={busRouteOptions}
                  onChange={(newValue: string) => {
                    const newIds = [..._.get(values, name)];
                    newIds[index] = newValue;
                    form.change(name as keyof FormValues, newIds);
                  }}
                  onDelete={() => {
                    const newIds = _.get(values, name).filter(
                      (id: any) => id !== routeId
                    );
                    form.change(name as keyof FormValues, newIds);
                  }}
                />
              </div>
            ))}
          </SortableContext>
        </DndContext>
      </Box>
      <Box mt={2}>
        <Button
          type="button"
          color="secondary"
          text={t('Add Bus Route')}
          onClick={() => {
            form.change(name as keyof FormValues, [..._.get(values, name), '']);
          }}
          iconBeforeText={<i className="c-icon-outline-general-plus-circle" />}
        />
      </Box>
    </Box>
  );
};
