import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import clsx from 'clsx';
import _ from 'lodash';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import type { TranslateFuncType } from 'client/components/Translate';
import { EditPromotionModal } from 'client/pages/PromotionList/EditPromotionModal';
import { fetchPromotions } from 'client/actions/promotions';
import {
  getPromotionConditionDescription,
  getPromotionDiscountDescription,
  getPromotionTypeText,
  getPromotionStatusText,
} from 'client/libraries/util/promotionTextHelpers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchProducts } from 'client/actions/products';
import { toPromotionShape } from 'client/libraries/util/promotionShape';
import type { PromotionShape } from 'client/libraries/util/promotionShape';
import type { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import 'react-table/react-table.css';
import editIcon from 'client/images/ic_edit.svg';
import copyIcon from 'client/images/ic_copy.svg';
import type { ProductSummary } from 'shared/models/swagger';
import { getDaysOfWeekText } from 'client/libraries/util/getDaysOfWeekText';

type TabType = 'ON' | 'OFF' | 'DISCONTINUED';
const promotionsSelector = createSelector(
  (state: ReduxState) => state.promotions.all,
  (promotions) => promotions.map(toPromotionShape)
);

const filterPromotions = (
  promotions: PromotionShape[],
  products: ProductSummary[],
  filter: string,
  t: TranslateFuncType
): PromotionShape[] => {
  if (!filter) {
    return promotions;
  }

  return promotions.filter((promo) => {
    const internalProductName =
      products.find((p) => p.id == promo.productId)?.internal_product_name ??
      '';
    return filter.split(/\s+/).every((word) => {
      const productName = promo.productName || t('All Products');
      const promoCode = promo.promoCode ?? '';
      return (
        productName.toLowerCase().includes(word.toLowerCase()) ||
        promoCode.toLowerCase().includes(word.toLowerCase()) ||
        internalProductName.toLowerCase().includes(word.toLowerCase())
      );
    });
  });
};

export const PromotionList = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState('');
  const [activeTab, setActiveTab] = React.useState<TabType>('ON');
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [inputPromotion, setInputPromotion] =
    React.useState<PromotionShape | null>(null);
  const apiPromotions = useSelector(promotionsSelector);
  const allProducts = useSelector(summariesSelector);
  const activeUser = useSelector(activeUserSelector);
  const promotions = _.sortBy(
    filterPromotions(apiPromotions, allProducts, filter, t),
    (p) => p.productName
  );
  const onPromotions = promotions.filter((promo) => promo.status === 'ON');
  const offPromotions = promotions.filter((promo) => promo.status === 'OFF');
  const discontinuedPromotions = promotions.filter(
    (promo) => promo.status === 'DISCONTINUED'
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchPromotions());
  }, [dispatch, invalidated]);

  const getColumns = () => {
    return [
      ...(hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.PROMOTIONS')
        ? [
            {
              Header: '',
              id: 'productName',
              accessor: 'productName',
              Cell: (cellInfo: any) => (
                <a
                  className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  data-text={t('Edit')}
                  onClick={() => {
                    setInputPromotion(cellInfo.original);
                    setShowEditModal(true);
                  }}
                >
                  <img src={editIcon} />
                </a>
              ),
              th: true,
              width: 'minimal',
            },
          ]
        : []),
      {
        Header: t('Product'),
        id: 'productName',
        accessor: 'productName',
        Cell: (cellInfo: any) => (
          <div className={clsx(baseStyles['inline-block'])}>
            {hasCustomUserRoleWritePermissions(
              activeUser,
              'PRODUCT.PROMOTIONS'
            ) && (
              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['icon'],
                  baseStyles['narrow']
                )}
                data-text={t('Edit')}
                onClick={() => {
                  setInputPromotion({ ...cellInfo.original, id: '' });
                  setShowEditModal(true);
                }}
              >
                <img src={copyIcon} />
              </a>
            )}
            {cellInfo.value ? (
              <Link to={`/products/${cellInfo.original.productId}`}>
                {getDisplayProductName(
                  allProducts.find((p) => p.id == cellInfo.original.productId)
                )}
              </Link>
            ) : (
              t('All Products')
            )}
          </div>
        ),
        width: 'longx2',
      },
      {
        Header: t('Promotion Type'),
        id: 'type',
        accessor: (row: PromotionShape) => getPromotionTypeText(row.type, t),
        width: 'middle',
      },
      {
        Header: t('Status'),
        id: 'status',
        accessor: (row: PromotionShape) =>
          getPromotionStatusText(row.status, t),
        width: 'middle',
      },
      {
        Header: t('Condition'),
        id: 'condition',
        accessor: (row: PromotionShape) =>
          getPromotionConditionDescription(row, t, locale),
        width: '300px',
      },
      {
        Header: t('Charge/Discount'),
        id: 'chargeDiscount',
        accessor: (row: PromotionShape) =>
          getPromotionDiscountDescription(row, t),
        width: '160px',
      },
      {
        Header: t('Participation Date Range'),
        id: 'startDateRange',
        accessor: (row: PromotionShape) => {
          const weekdayText = getDaysOfWeekText(row.participationDaysOfWeek, t);
          return `${row.participationDateRanges
            .map((dateRange) => `${dateRange.startDate} ~ ${dateRange.endDate}`)
            .join('; ')}${weekdayText ? ` (${weekdayText})` : ''}`;
        },
        width: 'long',
      },
      {
        Header: t('Booking Date Range'),
        id: 'bookedDateRange',
        accessor: (row: PromotionShape) =>
          row.bookedDateLocalFrom || row.bookedDateLocalTo
            ? `${row.bookedDateLocalFrom} ~ ${row.bookedDateLocalTo}`
            : '',
        width: 'long',
      },
      {
        Header: t('Channels'),
        id: 'channels',
        accessor: (row: PromotionShape) => {
          if (!row.channels || row.channels.length === 0) {
            return t('All');
          }

          return row.channels
            .map((channel) => {
              switch (channel.channel_category) {
                case 'DIRECT_ONLINE':
                  return t('Direct (Online)');

                case 'DIRECT_OFFLINE':
                  return t('Direct (Offline)');

                default:
                  return channel.agent_name;
              }
            })
            .join(',');
        },
        width: 'middle',
      },
      {
        Header: t('Promo Code'),
        id: 'promoCode',
        accessor: 'promoCode',
        width: 'middle',
      },
    ];
  };

  const columns = getColumns();
  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="PRODUCT_PROMOTIONS" />
      )}
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__middle'],
            baseStyles['base-f-320'],
            baseStyles['spOrder-2']
          )}
        >
          <input
            value={filter}
            placeholder={'Filter'}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            type="text"
            className={baseStyles['base-form-text']}
          />
        </div>
        {hasCustomUserRoleWritePermissions(
          activeUser,
          'PRODUCT.PROMOTIONS'
        ) && (
          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spSpacebetween'],
              baseStyles['spOrder-1']
            )}
          >
            <button
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['middle'],
                baseStyles['flex'],
                baseStyles['green']
              )}
              onClick={() => {
                setInputPromotion(null);
                setShowEditModal(true);
              }}
            >
              {t('Create new promotion')}
            </button>
          </div>
        )}
      </div>

      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <EditPromotionModal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          inputPromotion={inputPromotion}
        />
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'ON' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('ON');
            }}
          >
            <a>{t('On')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'OFF' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('OFF');
            }}
          >
            <a>{t('Off')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'DISCONTINUED' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('DISCONTINUED');
            }}
          >
            <a>{t('Discontinued')}</a>
          </li>
        </ul>

        {activeTab === 'ON' && (
          <CustomTable
            items={_.sortBy(onPromotions, (p) => p.productName)}
            columns={columns}
            useScrollButton={true}
          />
        )}

        {activeTab === 'OFF' && (
          <CustomTable
            items={_.sortBy(offPromotions, (p) => p.productName)}
            columns={columns}
            useScrollButton={true}
          />
        )}

        {activeTab === 'DISCONTINUED' && (
          <CustomTable
            items={_.sortBy(discontinuedPromotions, (d) => d.productName)}
            columns={columns}
            useScrollButton={true}
          />
        )}
      </div>
    </>
  );
};
