// @flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { fetchReservationByID } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { fetchReservationReceipt } from 'client/actions/reservationReceipts';
import { fetchReservationInvoice } from 'client/actions/reservationInvoices';
import { activeUserSelector } from 'client/reducers/user';
import { ReservationDetails } from 'client/pages/ReservationDetails/ReservationDetails';
import { FreeFormatReservationDetails } from 'client/pages/ReservationDetails/FreeFormatReservationDetails/FreeFormatReservationDetails';
import type { ReduxState } from 'client/reducers';
import { fetchGroupBookingTemplates } from 'client/actions/groupBookingTemplates';

export const Reservation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const reservationLastFetchedDateTimeUtc = React.useRef<string | null>(null);

  // Reservation
  React.useEffect(() => {
    if (typeof id === 'string') {
      reservationLastFetchedDateTimeUtc.current = moment().format();
      dispatch(fetchReservationByID(id));
    }
  }, [id, t, activeUser]);

  React.useEffect(() => {
    dispatch(fetchGroupBookingTemplates());
  }, [t, activeUser]);

  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[id || '']
  );

  const isFreeFormat = Boolean(reservation?.is_free_format_reservation);

  // Refresh reservation data if reservation was last fetched near the time of the last reservation status change.
  // We do this because billing info is stored asynchronously separate from the main reservation record in the backend.
  // Reservation API responses may not reflect billing info immediately. Refreshing the reservation 3 seconds after the status
  // change makes it very likely that billing data will show up correctly.
  // From the user's point of view, the reservation page will show with a loading mark until we have loaded the reservation 3
  // seconds after the status change.
  React.useEffect(() => {
    if (typeof id === 'string' && reservation) {
      const lastStatusUpdateDateTimeUtc =
        reservation.status_history[reservation.status_history.length - 1]
          ?.status_date_time_utc;

      if (
        lastStatusUpdateDateTimeUtc &&
        reservationLastFetchedDateTimeUtc.current &&
        moment(lastStatusUpdateDateTimeUtc)
          .add(3, 'seconds')
          .isAfter(moment(reservationLastFetchedDateTimeUtc.current))
      ) {
        reservationLastFetchedDateTimeUtc.current = moment().format();
        dispatch(fetchReservationByID(id));
      }
    }
  }, [id, reservation]);

  // Product
  React.useEffect(() => {
    if (reservation && !isFreeFormat) {
      dispatch(fetchProductByID(reservation.product_id || ''));
    }
  }, [reservation]);

  const product = useSelector(
    (state: ReduxState) => state.products.byID[reservation?.product_id || '']
  );

  // Product Instance
  React.useEffect(() => {
    if (reservation && !isFreeFormat) {
      dispatch(fetchProductInstanceByID(reservation.product_instance_id));
    }
  }, [reservation]);

  const productInstance = useSelector(
    (state: ReduxState) =>
      state.productInstances.byID[reservation?.product_instance_id || '']
  );

  // Reservation Receipt
  React.useEffect(() => {
    if (reservation && !isFreeFormat) {
      dispatch(fetchReservationReceipt(reservation.id));
      dispatch(fetchReservationInvoice(reservation.id));
    }
  }, [reservation]);

  const reservationReceipt = useSelector(
    (state: ReduxState) => state.reservationReceipts.data
  );

  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const reservationInvoice = useSelector(
    (state: ReduxState) => state.reservationInvoices.data
  );

  return (
    <>
      {isFreeFormat ? (
        <FreeFormatReservationDetails
          reservation={reservation}
          loading={loading}
        />
      ) : (
        <ReservationDetails
          product={product}
          productInstance={productInstance}
          reservation={reservation}
          reservationReceipt={reservationReceipt}
          loading={loading}
          reservationInvoice={reservationInvoice}
        />
      )}
    </>
  );
};
