import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { InvoiceModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/InvoiceModal';
import { InvoiceStatusModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/InvoiceStatusModal';
import { ReduxState } from 'client/reducers';
import { Reservation, ReservationInvoice } from 'shared/models/swagger';
import { SendInvoiceEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/SendInvoiceEmailModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  downloadReservationInvoicePDF,
  fetchReservationInvoice,
  issueReservationInvoice,
  resetIssueReservationInvoiceStatus,
  resetReservationInvoiceEmailStatus,
  sendReservationInvoiceEmail,
  updateReservationInvoice,
} from 'client/actions/reservationInvoices';

import styles from './ReservationDetailsHeader.module.css';

interface Props {
  reservation: Reservation;
  reservationInvoice?: ReservationInvoice;
  button: (
    text: string,
    color:
      | 'primary'
      | 'secondary'
      | 'secondarycolor'
      | 'red'
      | 'tertiaryred'
      | 'white'
      | 'gray'
      | 'tertiarygray'
      | 'tertiarycolor',
    loading: boolean,
    onClick: () => void
  ) => React.ReactNode;
  onChangeSendEmailStatus: (
    status: 'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null
  ) => void;
}

export const InvoiceButton = ({
  reservation,
  reservationInvoice,
  button,
  onChangeSendEmailStatus,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fieldSetRef = React.useRef<HTMLFieldSetElement | null>(null);

  const reservationInvoicePdfStatus = useSelector(
    (state: ReduxState) => state.reservationInvoices.pdfStatus
  );

  const reservationInvoiceEmailStatus = useSelector(
    (state: ReduxState) => state.reservationInvoices.emailStatus
  );

  const groupBookingTemplates = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  );
  const organization = useSelector(activeUserOrganizationSelector);

  const [showInvoiceMenu, setShowInvoiceMenu] = React.useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = React.useState(false);
  const [showInvoiceStatusModal, setShowInvoiceStatusModal] =
    React.useState(false);
  const [showSendInvoiceEmailModal, setShowSendInvoiceEmailModal] =
    React.useState(false);

  const groupBookingTemplate = React.useMemo(() => {
    return groupBookingTemplates.find(
      (template) => template.id === reservation?.booking_source?.group_id
    );
  }, [groupBookingTemplates, reservation?.booking_source?.group_id]);

  const reservationInvoiceStatusText = React.useMemo(() => {
    const options = [
      {
        value: 'ISSUED',
        label: t('Issued'),
      },
      {
        value: 'EMAIL_SENT',
        label: t('Email Sent'),
      },
      {
        value: 'PAID',
        label: t('Paid'),
      },
    ];

    const currentOption = options.find(
      (option) => option.value === reservationInvoice?.status
    );

    if (!currentOption) {
      return t('Unknown');
    }

    return currentOption.label;
  }, [reservationInvoice, t]);

  React.useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showInvoiceMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowInvoiceMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showInvoiceMenu]);

  const loading =
    reservationInvoicePdfStatus === 'IN_FLIGHT' ||
    reservationInvoiceEmailStatus === 'IN_FLIGHT';

  const reservationLanguage = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
  )?.response;

  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  return (
    <>
      {!reservationInvoice?.status ? (
        <>
          {button(t('Issue Invoice'), 'primary', loading, () => {
            dispatch(resetIssueReservationInvoiceStatus());
            setShowInvoiceModal(true);
          })}
        </>
      ) : (
        <fieldset ref={fieldSetRef} className={styles['p-manifests__select']}>
          {button(
            `${t('Invoice')}(${reservationInvoiceStatusText})`,
            'white',
            loading,
            () => {
              setShowInvoiceMenu(!showInvoiceMenu);
            }
          )}
          <ul
            className={clsx(
              styles['p-manifests__select__menu'],
              showInvoiceMenu && styles['is-active']
            )}
          >
            <li className={styles['p-manifests__select__menu__item']}>
              <p
                onClick={() => {
                  setShowInvoiceMenu(false);

                  dispatch(
                    downloadReservationInvoicePDF(reservation?.id ?? '')
                  );
                }}
              >
                <i className="c-icon-outline-general-download-02"></i>
                {t('Download as PDF')}
              </p>
            </li>
            <li className={styles['p-manifests__select__menu__item']}>
              <p
                onClick={() => {
                  setShowInvoiceMenu(false);
                  setShowSendInvoiceEmailModal(true);
                }}
              >
                {t('Send Email')}
              </p>
            </li>
            <li className={styles['p-manifests__select__menu__item']}>
              <p
                onClick={() => {
                  setShowInvoiceMenu(false);
                  setShowInvoiceStatusModal(true);
                }}
              >
                <i className="c-icon-outline-general-edit-05"></i>
                {t('Change Status')}
              </p>
            </li>
          </ul>
        </fieldset>
      )}
      {showInvoiceModal && groupBookingTemplate && (
        <InvoiceModal
          title={t('Invoice')}
          groupBookingTemplate={groupBookingTemplate}
          onClose={() => setShowInvoiceModal(false)}
          reservation={reservation}
          onIssue={async (
            attention: string,
            payemntDaedlineDate: string,
            bankTransferDetails: string
          ) => {
            await dispatch(
              issueReservationInvoice({
                reservation_id: reservation?.id ?? '',
                supplier_id: organization?.id ?? '',
                attention: attention,
                payment_deadline_date: payemntDaedlineDate,
                bank_transfer_details: bankTransferDetails,
                language: reservationLanguage,
                supplier_invoice_business_name:
                  organization?.supplier_guest_receipt_settings
                    ?.receipt_business_name,
                supplier_business_registration_number:
                  organization?.supplier_guest_receipt_settings
                    ?.business_registration_number,
                supplier_consumption_tax_calculation:
                  organization?.supplier_guest_receipt_settings
                    ?.consumption_tax_calculation,
                supplier_address: organization?.office_mailing_address,
                supplier_phone_number: organization?.office_phone,
              })
            );
            await dispatch(fetchReservationInvoice(reservation?.id ?? ''));
          }}
        />
      )}
      {groupBookingTemplate && showSendInvoiceEmailModal && (
        <SendInvoiceEmailModal
          title={t('Invoice')}
          initialToAddress={email}
          onClose={() => setShowSendInvoiceEmailModal(false)}
          reservation={reservation}
          onSendEmail={async (attention: string, toAddress: string) => {
            try {
              onChangeSendEmailStatus('REQUESTED');
              await dispatch(
                sendReservationInvoiceEmail(
                  reservation?.id ?? '',
                  toAddress,
                  attention
                )
              );
              onChangeSendEmailStatus('SUCCEEDED');
            } catch (e) {
              onChangeSendEmailStatus('FAILED');
            } finally {
              await dispatch(resetReservationInvoiceEmailStatus());
            }
          }}
        />
      )}
      {groupBookingTemplate && showInvoiceStatusModal && (
        <InvoiceStatusModal
          title={t('Invoice')}
          value={reservationInvoice?.status || 'ISSUED'}
          onClose={() => setShowInvoiceStatusModal(false)}
          onChange={async (status: 'ISSUED' | 'EMAIL_SENT' | 'PAID') => {
            try {
              await dispatch(
                updateReservationInvoice(reservation?.id ?? '', {
                  status,
                })
              );
            } catch (e) {
              console.error(e);
            }
          }}
        />
      )}
    </>
  );
};
