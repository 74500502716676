import clsx from 'clsx';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import { TextField } from 'client/components/v3/Form/TextField';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { ProductEditorForm } from 'client/pages/v3/Product/ProductEdit/ProductEditorForm';
import {
  getInitialValues,
  getETicketConvertFormValuesToProductPatch,
} from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';

export const ETicketTranslationEditor = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  const translationTargetLanguage = useContext(TranslationLanguageContext);

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const currency = defaultProductCurrency ?? defaultSupplierCurrency;

  const initialValues = useMemo(
    () => getInitialValues(editingProduct),
    [editingProduct]
  );
  // Guard to ensure product is not null
  if (!editingProduct) {
    return <></>;
  }

  return (
    <ProductEditorForm
      id="translationProductForm"
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={getETicketConvertFormValuesToProductPatch(
        editingProduct,
        currency
      )}
      subscription={{
        submitError: true,
        submitting: true,
      }}
    >
      {({ submitError }) => (
        <>
          <Snackbar
            text={t('Save Successful')}
            color="success"
            shouldShow={success}
          />
          <Snackbar
            text={t('Save Failed')}
            color="error"
            shouldShow={submitError}
          />

          {/* Basic editor */}
          <section id="basic" className={styles['g-section']}>
            <p className={styles['p-products__ttl']}>{t('Ticket Settings')}</p>
            <CollapsibleSection title={t('Basic Settings')}>
              <CollapsibleBox title={t('Ticket Usage')}>
                <FieldArray name="stubs">
                  {({ fields }) => (
                    <>
                      <div className={styles['p-list__item__body__list']}>
                        {fields.length !== 0 &&
                          fields.map((name, index) => (
                            <CollapsibleBox
                              key={index}
                              title={`${t('Ticket Usage')} #${index + 1}`}
                            >
                              <TranslatedField
                                key={`${name}.key`}
                                name={`${name}.text`}
                              >
                                {({ input, translationInput }) => (
                                  <ul className={styles['p-list']}>
                                    <li className={styles['p-list__item']}>
                                      <div
                                        className={styles['p-list__item__ttl']}
                                      >
                                        <p>
                                          {getLanguageName(
                                            lowerCaseIsoSourceLanguage,
                                            t
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className={styles['p-list__item__body']}
                                      >
                                        <TextField {...input} />
                                      </div>
                                    </li>
                                    {translationTargetLanguage && (
                                      <li className={styles['p-list__item']}>
                                        <div
                                          className={
                                            styles['p-list__item__ttl']
                                          }
                                        >
                                          <p>
                                            {getLanguageName(
                                              uppercaseIsoToLowercaseIso[
                                                translationTargetLanguage
                                              ],
                                              t
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className={
                                            styles['p-list__item__body']
                                          }
                                        >
                                          <TextField {...translationInput} />
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </TranslatedField>

                              <FieldArray name={`${name}.options`}>
                                {({ fields }) => (
                                  <>
                                    <div
                                      className={
                                        styles['p-list__item__body__list']
                                      }
                                    >
                                      {fields.length !== 0 &&
                                        fields.map((name, optionIndex) => (
                                          <CollapsibleBox
                                            key={`${index}-${optionIndex}`}
                                            title={`${t('Ticket Usage')} #${
                                              index + 1
                                            } ${t('Option')} #${optionIndex}`}
                                          >
                                            <TranslatedField
                                              key={`${name}`}
                                              name={`${name}`}
                                            >
                                              {({
                                                input,
                                                translationInput,
                                              }) => (
                                                <ul
                                                  className={styles['p-list']}
                                                >
                                                  <li
                                                    className={
                                                      styles['p-list__item']
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles[
                                                          'p-list__item__ttl'
                                                        ]
                                                      }
                                                    >
                                                      <p>
                                                        {getLanguageName(
                                                          lowerCaseIsoSourceLanguage,
                                                          t
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div
                                                      className={
                                                        styles[
                                                          'p-list__item__body'
                                                        ]
                                                      }
                                                    >
                                                      <TextField {...input} />
                                                    </div>
                                                  </li>
                                                  {translationTargetLanguage && (
                                                    <li
                                                      className={
                                                        styles['p-list__item']
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          styles[
                                                            'p-list__item__ttl'
                                                          ]
                                                        }
                                                      >
                                                        <p>
                                                          {getLanguageName(
                                                            uppercaseIsoToLowercaseIso[
                                                              translationTargetLanguage
                                                            ],
                                                            t
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div
                                                        className={
                                                          styles[
                                                            'p-list__item__body'
                                                          ]
                                                        }
                                                      >
                                                        <TextField
                                                          {...translationInput}
                                                        />
                                                      </div>
                                                    </li>
                                                  )}
                                                </ul>
                                              )}
                                            </TranslatedField>
                                          </CollapsibleBox>
                                        ))}
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </CollapsibleBox>
                          ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </CollapsibleBox>

              <CollapsibleBox title={t('Redeem Ticket')}>
                <FieldArray name="presetRedemptionCounts">
                  {({ fields }) => (
                    <>
                      <div className={styles['p-list__item__body__list']}>
                        {fields.length !== 0 &&
                          fields.map((name, index) => (
                            <CollapsibleBox
                              key={index}
                              title={`${t('Attraction')} #${index + 1}`}
                            >
                              <TranslatedField
                                key={`${name}.key`}
                                name={`${name}.text`}
                              >
                                {({ input, translationInput }) => (
                                  <ul className={styles['p-list']}>
                                    <li className={styles['p-list__item']}>
                                      <div
                                        className={styles['p-list__item__ttl']}
                                      >
                                        <p>
                                          {getLanguageName(
                                            lowerCaseIsoSourceLanguage,
                                            t
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className={styles['p-list__item__body']}
                                      >
                                        <TextField {...input} />
                                      </div>
                                    </li>
                                    {translationTargetLanguage && (
                                      <li className={styles['p-list__item']}>
                                        <div
                                          className={
                                            styles['p-list__item__ttl']
                                          }
                                        >
                                          <p>
                                            {getLanguageName(
                                              uppercaseIsoToLowercaseIso[
                                                translationTargetLanguage
                                              ],
                                              t
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className={
                                            styles['p-list__item__body']
                                          }
                                        >
                                          <TextField {...translationInput} />
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </TranslatedField>
                            </CollapsibleBox>
                          ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </CollapsibleBox>

              <CollapsibleBox title={t('Ticket Usage Precautions')}>
                <TranslatedField name="noteForRedemptionModal">
                  {({ input, meta: { touched, error }, translationInput }) => {
                    return (
                      <ul className={styles['p-list']}>
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField
                              {...input}
                              error={Boolean(touched && error)}
                              helperText={error}
                            />
                          </div>
                        </li>
                        {translationTargetLanguage && (
                          <li className={clsx(styles['p-list__item'])}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...translationInput} />
                            </div>
                          </li>
                        )}
                      </ul>
                    );
                  }}
                </TranslatedField>
              </CollapsibleBox>

              <CollapsibleBox title={t('Items to Display for Active Tickets')}>
                <FieldArray name="footprintedModalDisplayItems">
                  {({ fields }) => (
                    <>
                      <div className={styles['p-list__item__body__list']}>
                        {fields.length !== 0 &&
                          fields.map((name, index) => (
                            <CollapsibleBox
                              key={index}
                              title={`${t('Display Item')} #${index + 1}`}
                            >
                              <TranslatedField
                                key={`${name}.key`}
                                name={`${name}.title`}
                              >
                                {({ input, translationInput }) => (
                                  <ul className={styles['p-list']}>
                                    <li className={styles['p-list__item']}>
                                      <div
                                        className={styles['p-list__item__ttl']}
                                      >
                                        <p>
                                          {getLanguageName(
                                            lowerCaseIsoSourceLanguage,
                                            t
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className={styles['p-list__item__body']}
                                      >
                                        <TextField {...input} />
                                      </div>
                                    </li>
                                    {translationTargetLanguage && (
                                      <li className={styles['p-list__item']}>
                                        <div
                                          className={
                                            styles['p-list__item__ttl']
                                          }
                                        >
                                          <p>
                                            {getLanguageName(
                                              uppercaseIsoToLowercaseIso[
                                                translationTargetLanguage
                                              ],
                                              t
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className={
                                            styles['p-list__item__body']
                                          }
                                        >
                                          <TextField {...translationInput} />
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </TranslatedField>
                            </CollapsibleBox>
                          ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </CollapsibleBox>
            </CollapsibleSection>

            <CollapsibleSection
              id="startTime"
              title={t('Start and Expiry Dates')}
            >
              <CollapsibleBox
                title={t('Precautions before the start of ticket validity')}
              >
                <TranslatedField name="noteForRedemptionModalBeforeCheckinStartTime">
                  {({ input, meta: { touched, error }, translationInput }) => {
                    return (
                      <ul className={styles['p-list']}>
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField
                              {...input}
                              error={Boolean(touched && error)}
                              helperText={error}
                            />
                          </div>
                        </li>
                        {translationTargetLanguage && (
                          <li className={clsx(styles['p-list__item'])}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...translationInput} />
                            </div>
                          </li>
                        )}
                      </ul>
                    );
                  }}
                </TranslatedField>
              </CollapsibleBox>
            </CollapsibleSection>

            <CollapsibleSection id="custom" title={t('Display Items')}>
              <CollapsibleBox title={t('Redeem Button')}>
                <TranslatedField name="redemptionButtonText">
                  {({ input, meta: { touched, error }, translationInput }) => {
                    return (
                      <ul className={styles['p-list']}>
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField
                              {...input}
                              error={Boolean(touched && error)}
                              helperText={error}
                            />
                          </div>
                        </li>
                        {translationTargetLanguage && (
                          <li className={clsx(styles['p-list__item'])}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...translationInput} />
                            </div>
                          </li>
                        )}
                      </ul>
                    );
                  }}
                </TranslatedField>
              </CollapsibleBox>

              <CollapsibleBox title={t('Button to Online Guide')}>
                <TranslatedField name="guidanceButtonText">
                  {({ input, meta: { touched, error }, translationInput }) => {
                    return (
                      <ul className={styles['p-list']}>
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField
                              {...input}
                              error={Boolean(touched && error)}
                              helperText={error}
                            />
                          </div>
                        </li>
                        {translationTargetLanguage && (
                          <li className={clsx(styles['p-list__item'])}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...translationInput} />
                            </div>
                          </li>
                        )}
                      </ul>
                    );
                  }}
                </TranslatedField>
              </CollapsibleBox>
            </CollapsibleSection>
          </section>
        </>
      )}
    </ProductEditorForm>
  );
};
