import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ProductEditorForm } from 'client/components/ProductEditorForm/ProductEditorForm';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { AvailabilityAllotmentEditor } from 'client/pages/ProductEditor/ReservationParametersEditor/AvailabilityAllotmentEditor';
import { BookingDeadlinesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/BookingDeadlinesInput';
import { CancellationPoliciesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/CancellationPoliciesInput';
import {
  convertReservationParamsFormValuesToProductPatch,
  getInitialValues,
} from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import type { ReservationParamsFormValues } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import baseStyles from 'client/base.module.css';

type Props = {
  active: boolean;
};
export const ReservationParametersEditor = ({ active }: Props) => {
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState<boolean>(false);
  React.useEffect(() => {
    setSuccess(false);
  }, [active]);
  const editingProduct = React.useContext(EditingProductContext);
  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;

  const initialValues = React.useMemo(
    () => getInitialValues(editingProduct),
    [editingProduct]
  );
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  return (
    <ProductEditorForm
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={(values: ReservationParamsFormValues) =>
        convertReservationParamsFormValuesToProductPatch(
          values,
          isPackage,
          defaultCurrency
        )
      }
    >
      {({ submitError, submitting }) => {
        return (
          <>
            <FormTableBox>
              <table>
                <tr>
                  <th>{t('Participation Rules')}</th>
                  <td>
                    <AvailabilityAllotmentEditor />
                  </td>
                </tr>
                <tr>
                  <th>{t('Booking Period')}</th>
                  <td>
                    <BookingDeadlinesInput />
                  </td>
                </tr>
                <tr>
                  <th>{t('Cancellation Policies')}</th>
                  <td>
                    <CancellationPoliciesInput />
                  </td>
                </tr>
              </table>
            </FormTableBox>

            <div className={baseStyles['base-main__box__body__bottomBtns']}>
              <Button
                loading={submitting}
                style="blue"
                size="small"
                type="submit"
              >
                {t('Save')}
              </Button>
            </div>
            {success && <Message success header={t('Save Successful')} />}
            {submitError && <Message error header={t('Save Failed')} />}
          </>
        );
      }}
    </ProductEditorForm>
  );
};
