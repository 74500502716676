import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { ButtonStyleEditor } from 'client/components/v3/ButtonStyleEditor/ButtonStyleEditor';

import styles from './ButtonListEditor.module.css';

type ButtonListEditorProps = {
  name: string;
};

export const ButtonListEditor = ({ name }: ButtonListEditorProps) => {
  const { t } = useTranslation();

  const validateUrl = useCallback(
    (url?: string): string | undefined => {
      if (!url) {
        return undefined;
      }

      // Check for proper protocol format
      if (!url.match(/^(http|https):\/\/.+/)) {
        return t('URL is incorrect');
      }

      try {
        const urlObject = new URL(url);
        if (!urlObject.hostname) {
          return t('URL is incorrect');
        }
      } catch {
        return t('URL is incorrect');
      }
    },
    [t]
  );

  return (
    <div className={styles['c-listEditor']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={styles['c-listEditor__body']}>
            <div className={styles['c-listEditor__body__list']}>
              {fields.length !== 0 &&
                fields.map((fieldName, idx) => (
                  <div
                    key={fieldName}
                    className={styles['c-listEditor__body__list__item']}
                  >
                    <div>
                      <div
                        className={
                          styles['c-listEditor__body__list__item__group']
                        }
                        style={{ flexDirection: 'column' }}
                      >
                        <div
                          className={
                            styles['c-listEditor__body__list__item__group']
                          }
                        >
                          <div style={{ marginRight: '8px', flex: '0 0 30%' }}>
                            <p
                              className={
                                styles['c-listEditor__body__list__item__text']
                              }
                            >
                              {t('Text')}
                            </p>
                          </div>
                          <Field name={`${fieldName}.text`}>
                            {({ input }) => {
                              return (
                                <TextField
                                  value={input.value}
                                  onChange={input.onChange}
                                />
                              );
                            }}
                          </Field>
                        </div>
                        <div
                          className={clsx(
                            styles['c-listEditor__body__list__item__group'],
                            styles['sp']
                          )}
                        >
                          <div
                            className={styles['url']}
                            style={{ marginRight: '8px', flex: '0 0 30%' }}
                          >
                            <p>{t('URL')}</p>
                          </div>
                          <Field
                            // Use this key to properly reference the field within the array structure that Final Form expects for validation
                            name={`${name}[${idx}].url`}
                            validate={validateUrl}
                          >
                            {({ input, meta: { error } }) => {
                              return (
                                <TextField
                                  value={input.value}
                                  onChange={input.onChange}
                                  error={error}
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          styles['c-listEditor__body__list__item__group'],
                          styles['custom'],
                          styles['sp']
                        )}
                      >
                        <ButtonStyleEditor
                          name={`${fieldName}.style`}
                          expanderLabel={t('Customize Button Style')}
                          useFontPicker={false}
                        />
                      </div>
                    </div>
                    <div className={styles['c-listEditor__body__actions']}>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div
              className={clsx(
                fields.length && fields.length > 0 && baseStyles['u-mt-2']
              )}
            >
              <a
                className={styles['c-listEditor__body__add']}
                onClick={() => {
                  if (fields.length && fields.length > 0) {
                    (fields as any).insertAt(fields.length + 1, '');
                  } else {
                    (fields as any).insertAt(0, '');
                  }
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Button')}
              </a>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};
