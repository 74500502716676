import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Button } from 'client/components/v3/Common/Button';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Modal } from 'client/components/v3/Form/Modal';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

import { FilterReservationsRequest } from '../util';

import {
  downloadSystemFeeInvoiceCSV,
  downloadPartnershipSystemFeeInvoiceCSV,
  ColumnType as SystemFeeColumnType,
} from './util';

interface Props {
  systemFeeInvoiceId: string;
  filters: FilterReservationsRequest;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDepositDate?: boolean;
  settlementType?: 'NUTMEG' | 'DIRECT';
  allColumns: SystemFeeColumnType[];
}

export const SystemFeeInvoiceDownloadCSVModal = ({
  systemFeeInvoiceId,
  filters,
  setShowModal,
  showDepositDate,
  settlementType,
  allColumns,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const organization = useSelector(activeUserOrganizationSelector);

  const shouldIncludeDepositDateColumn =
    showDepositDate !== undefined
      ? showDepositDate
      : organization?.stripe_accounts?.some(
          (acct) => acct.account_type === 'JAPAN_EXPRESS'
        );

  if (shouldIncludeDepositDateColumn) {
    allColumns.push(
      {
        Header: t('Deposit Date'),
        id: 'PAYOUT_DATE',
      },
      {
        Header: t('Deposit Amount'),
        id: 'TRANSFER_AMOUNT',
      },
      {
        Header: t('Transaction Date (UTC)'),
        id: 'TRANSFER_DATE',
      }
    );
  }

  const [selectedColumns, setSelectedColumns] = React.useState<string[]>(
    allColumns.map((c) => c.id)
  );

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask.map(
      (c) => allColumns.find((col) => col.id === c) as ColumnType
    );
  };

  const columnsToShow = getColumns(selectedColumns);

  return (
    <Modal
      title={t('Download CSV')}
      open={true}
      onClose={() => setShowModal(false)}
      useCloseButton={true}
      rightActionChildren={
        <>
          <Button
            text={t('Download')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={async () => {
              if (partnershipMode) {
                await dispatch(
                  downloadPartnershipSystemFeeInvoiceCSV(systemFeeInvoiceId, {
                    timezone: moment.tz.guess(),
                    columns: selectedColumns.map((col) =>
                      col.toUpperCase()
                    ) as any,
                    productIds: filters.productIds,
                    paymentTypes: filters.paymentTypes,
                    reservationStatuses: filters.reservationStatuses,
                    transferDateFrom: filters.transferDateFrom,
                    transferDateTo: filters.transferDateTo,
                    settlementType,
                  })
                );
              } else {
                await dispatch(
                  downloadSystemFeeInvoiceCSV(systemFeeInvoiceId, {
                    timezone: moment.tz.guess(),
                    columns: selectedColumns.map((col) =>
                      col.toUpperCase()
                    ) as any,
                    productIds: filters.productIds,
                    paymentTypes: filters.paymentTypes,
                    reservationStatuses: filters.reservationStatuses,
                    transferDateFrom: filters.transferDateFrom,
                    transferDateTo: filters.transferDateTo,
                    settlementType,
                  })
                );
              }

              setShowModal(false);
            }}
          />
        </>
      }
      style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
    >
      <ToggleableDragAndDropList
        selectedItems={columnsToShow.map((col) => ({
          key: col.id,
          text: col.Header,
        }))}
        candidateItems={allColumns.map((col) => ({
          key: col.id,
          text: col.Header,
        }))}
        setSelectedItems={(items) =>
          setSelectedColumns(items.map((item) => item.key))
        }
        doNotUseOuterBox={true}
      />
    </Modal>
  );
};
