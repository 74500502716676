import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { fetchCustomPages } from 'client/actions/customPage';
import { fetchProducts } from 'client/actions/products';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { getArrayMutators } from 'client/libraries/util/form';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { EmbeddedCalendar } from 'client/pages/BookingWidget/EmbeddedCalendar/EmbeddedCalendar';
import { Button, Checkbox, ToggleButton } from 'client/components/Form';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { updateOrganization } from 'client/actions/organizations';
import { getBookingWebsiteUrlWithParams } from 'client/libraries/util/getBookingWebsiteUrl';
import newWindowIcon from 'client/images/ic_newwindow.svg';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

import {
  convertFormValuesToOrganizationPatch,
  getInitialValues,
} from './FormValues';
import type { CustomizeFormValues } from './FormValues';
import { FooterLinksLayoutEditor } from './FooterLinksLayoutEditor';
import { PopupMessagesEditor } from './PopupMessagesEditor';
import 'rc-color-picker/assets/index.css';

const focusOnError: any = createDecorator();
export const BookingWidgetCustomize = () => {
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const scrollTo = React.useContext(ScrollToContext);
  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchCustomPages());
  }, [activeUserOrganization]);
  const themeOptions = [
    {
      value: 'DEFAULT',
      label: t('Default'),
    },
    {
      value: 'PACIFIC',
      label: t('Fun'),
    },
    {
      value: 'VIBRANT',
      label: t('Vibrant'),
    },
    {
      value: 'COOL_PROFESSIONAL',
      label: t('Cool & Professional'),
    },
    {
      value: 'FAMILY',
      label: t('Family'),
    },
  ];

  const initialValues = getInitialValues(activeUserOrganization);
  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="BOOKING_SITE_CUSTOMIZE" />
      )}
      <Form
        onSubmit={async (values: CustomizeFormValues) => {
          const orgId = activeUserOrganization?.id || '';

          try {
            await dispatch(
              updateOrganization(
                orgId,
                'SUPPLIER',
                convertFormValuesToOrganizationPatch(
                  activeUserOrganization,
                  values
                )
              )
            );
            scrollTo(0, 0);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={baseStyles['base-main__body__box']}>
              <div className={baseStyles['base-main__body__box__body']}>
                {saveSucceeded && (
                  <Message success header={t('Settings Saved')} />
                )}
                <div className={styles['page-productsRegist__tableBox']}>
                  <div className={styles['c-table-list']}>
                    <table>
                      <tr>
                        <th>{t('Theme')}</th>
                        <td>
                          <Box mt={2}>
                            <div className={styles['c-tableChild']}>
                              <ul>
                                <li
                                  style={{
                                    width: 160,
                                  }}
                                >
                                  {t('Theme')}
                                </li>
                              </ul>
                              <ul>
                                <li data-title={t('Theme')}>
                                  <EnumRadioButtonGroup
                                    name="theme"
                                    options={themeOptions}
                                  />
                                </li>
                              </ul>
                            </div>
                          </Box>
                          <Box mb={2}>
                            <Field name="useThemeDefaultColors" type="checkbox">
                              {({ input }) => (
                                <Checkbox
                                  label={t('Use default colors for theme')}
                                  checked={input.checked ?? false}
                                  onChange={input.onChange}
                                />
                              )}
                            </Field>
                          </Box>
                          {!values?.useThemeDefaultColors && (
                            <>
                              <div className={styles['c-tableChild']}>
                                <ul>
                                  <li
                                    style={{
                                      width: 160,
                                    }}
                                  >
                                    {t('Primary Color')}
                                  </li>
                                  <li>{t('Secondary Color')}</li>
                                </ul>
                                <ul>
                                  <li data-title={t('Primary Color')}>
                                    <Field name="primaryColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                  <li data-title={t('Secondary Color')}>
                                    <Field name="secondaryColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                </ul>
                              </div>
                              <div className={styles['c-tableChild']}>
                                <ul>
                                  <li
                                    style={{
                                      width: 160,
                                    }}
                                  >
                                    {t('Background Color')}
                                  </li>
                                  <li>{t('Key Texts Color')}</li>
                                </ul>
                                <ul>
                                  <li data-title={t('Background Color')}>
                                    <Field name="backgroundColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                  <li data-title={t('Key Texts Color')}>
                                    <Field name="subheaderColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                </ul>
                              </div>
                              <div className={styles['c-tableChild']}>
                                <ul>
                                  <li
                                    style={{
                                      width: 160,
                                    }}
                                  >
                                    {t('Header Background Color')}
                                  </li>
                                  <li>{t('Footer Background Color')}</li>
                                </ul>
                                <ul>
                                  <li data-title={t('Header Background Color')}>
                                    <Field name="headerBackgroundColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                  <li data-title={t('Footer Background Color')}>
                                    <Field name="footerBackgroundColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                </ul>
                              </div>
                              <div className={styles['c-tableChild']}>
                                <ul>
                                  <li
                                    style={{
                                      width: 160,
                                    }}
                                  >
                                    {t('Content Section Background Color')}
                                  </li>
                                </ul>
                                <ul>
                                  <li
                                    data-title={t(
                                      'Content Section Background Color'
                                    )}
                                  >
                                    <Field name="contentSectionBackgroundColor">
                                      {({ input }) => (
                                        <ColorPicker
                                          placement="bottomRight"
                                          color={input.value}
                                          onChange={({ color }: any) =>
                                            input.onChange(color)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                          <Box>
                            <a
                              href={getBookingWebsiteUrlWithParams(
                                activeUserOrganization,
                                {
                                  bg: values?.backgroundColor,
                                  sh: values?.subheaderColor,
                                  thm: values?.theme,
                                  primary: values?.primaryColor,
                                  secondary: values?.secondaryColor,
                                  hbg: values?.headerBackgroundColor,
                                  fbg: values?.footerBackgroundColor,
                                  cbg: values?.contentSectionBackgroundColor,
                                  defCol: values?.useThemeDefaultColors
                                    ? 'true'
                                    : undefined,
                                }
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button size="middle" style="blue">
                                <p>{t('Preview Website')}</p>
                                <img src={newWindowIcon} />
                              </Button>
                            </a>
                          </Box>
                          <div className={styles['color-preview-box']}>
                            <p>{t('Calendar Preview')}</p>
                            {activeUserOrganization?.booking_widget_api_key &&
                              activeUserOrganization?.tutorial_product_id && (
                                <EmbeddedCalendar
                                  apiKey={
                                    activeUserOrganization?.booking_widget_api_key
                                  }
                                  productId={
                                    activeUserOrganization?.tutorial_product_id
                                  }
                                  designType="BASIC"
                                  primaryColor={values?.primaryColor}
                                  secondaryColor={values?.secondaryColor}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Frequently Viewed Products')}</th>
                        <td>
                          <Field name="hideFrequentlyViewedProducts">
                            {({ input }) => (
                              <>
                                <Box display="flex" width="fit-content">
                                  <ToggleButton
                                    label={t(
                                      'Show list of frequently viewed products on product detail pages'
                                    )}
                                    checked={!input.value}
                                    onChange={() =>
                                      input.onChange(!input.value)
                                    }
                                  />
                                  <Tooltip
                                    text={t(
                                      'Display up to 5 products with the most views on the booking website at the bottom of each product detail page so that more products can be viewed on the booking website.'
                                    )}
                                  />
                                </Box>
                              </>
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Customization: Footer')}</th>
                        <td>
                          <FooterLinksLayoutEditor />
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Customization: Pop-up message')}</th>
                        <td>
                          <PopupMessagesEditor />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  <Button
                    type="submit"
                    style="blue"
                    size="small"
                    loading={submitting}
                  >
                    {t('Save Settings')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
