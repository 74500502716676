import * as React from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { config } from 'client/config';
import type { ReduxState } from 'client/reducers';
import { DateTimeInput, Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import {
  setWeeklyEquipmentAvailabilityStartDate,
  setEquipmentAvailabilityStartDate,
  setEquipmentAvailabilityEndDate,
} from 'client/actions/equipmentCalendarListControls';
import { equipmentCalendarListDatesSelector } from 'client/reducers/equipmentCalendarListControls';
import pageStyles from 'client/pages/pages.module.css';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';

import { EquipmentInstanceCloseCalendarModal } from './EquipmentInstanceCloseCalendarModal';
import { SeatAvailabilityTable } from './SeatAvailabilityTable';
import { SeatCalendarEditContext } from './SeatCalendarEditContext';
import { SeatCalendarEditButtonRow } from './SeatCalendarEditButtonRow';
import styles from './WeeklySeatAvailability.module.css';

export const WeeklySeatAvailability = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const activeUser = useSelector(activeUserSelector);
  const dates = useSelector(equipmentCalendarListDatesSelector);
  const weeklyAvailabilityStartDate = useSelector(
    (state: ReduxState) =>
      state.equipmentCalendarListControls.weeklyAvailabilityStartDate
  );

  const [selectedEquipmentInstanceIds, setSelectedEquipmentInstanceIds] =
    React.useState<string[]>([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    React.useState(false);
  const [showCloseCalendarModal, setShowCloseCalendarModal] =
    React.useState(false);
  React.useEffect(() => {
    dispatch(
      setEquipmentAvailabilityStartDate(
        moment(weeklyAvailabilityStartDate).format('YYYY-MM-DD')
      )
    );
    dispatch(
      setEquipmentAvailabilityEndDate(
        moment(weeklyAvailabilityStartDate).add(7, 'days').format('YYYY-MM-DD')
      )
    );
  }, [weeklyAvailabilityStartDate]);

  return (
    <SeatCalendarEditContext.Provider
      value={{
        selectedEquipmentInstanceIds,
        selectEquipmentInstanceIds: (...ids: any[]) => {
          const idsToAdd = ids.filter(
            (id) => !selectedEquipmentInstanceIds.includes(id)
          );
          if (idsToAdd.length) {
            setSelectedEquipmentInstanceIds([
              ...selectedEquipmentInstanceIds,
              ...idsToAdd,
            ]);
          }
        },
        deselectEquipmentInstanceIds: (...ids: any) => {
          setSelectedEquipmentInstanceIds(
            selectedEquipmentInstanceIds.filter((id) => !ids.includes(id))
          );
        },
        calendarEditModeIsActive,
        setCalendarEditModeIsActive,
      }}
    >
      <div className={styles['header']}>
        <Box mt={2}>
          <div className={clsx(pageStyles['page-availability__datepick'])}>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['prev']
              )}
              onClick={() => {
                dispatch(
                  setWeeklyEquipmentAvailabilityStartDate(
                    moment(weeklyAvailabilityStartDate)
                      .add(-7, 'days')
                      .format('YYYY-MM-DD')
                  )
                );
              }}
            />
            <div
              className={clsx(pageStyles['page-availability__datepick__main'])}
              style={{ zIndex: 9 }}
            >
              <DateTimeInput
                value={moment(dates[0])}
                locale={locale}
                onChange={(date) => {
                  dispatch(
                    setWeeklyEquipmentAvailabilityStartDate(
                      moment(date).format('YYYY-MM-DD')
                    )
                  );
                }}
              />
            </div>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['next']
              )}
              onClick={() => {
                dispatch(
                  setWeeklyEquipmentAvailabilityStartDate(
                    moment(weeklyAvailabilityStartDate)
                      .add(7, 'days')
                      .format('YYYY-MM-DD')
                  )
                );
              }}
            />
          </div>
        </Box>

        {hasCustomUserRoleWritePermissions(
          activeUser,
          'SEAT_MANAGEMENT.WEEKLY_AVAILABILITY'
        ) && (
          <>
            {config.enableBatchEditEquipmentInstanceAvailability ? (
              <SeatCalendarEditButtonRow />
            ) : (
              <div
                className={styles['tag-select']}
                style={{
                  marginLeft: 'auto',
                }}
              >
                <div className={styles['button-row']}>
                  <div className={styles['calendar-edit-btns-pc']}>
                    <Box ml={-2} mt={-2} display="flex" flexWrap="wrap">
                      <Box mt={2} ml={2}>
                        <Button
                          style="yellow"
                          size="middle"
                          width={120}
                          onClick={() => {
                            setShowCloseCalendarModal(true);
                          }}
                        >
                          {t('Close calendar')}
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </div>
                <EquipmentInstanceCloseCalendarModal
                  open={showCloseCalendarModal}
                  onClose={() => setShowCloseCalendarModal(false)}
                />
              </div>
            )}
          </>
        )}
      </div>

      <SeatAvailabilityTable />
    </SeatCalendarEditContext.Provider>
  );
};
