import { Organization } from 'shared/models/swagger';

export const isNonManifestPermittedActivityPlanUser = (
  organization: Organization | null
) => {
  if (!organization?.activity_plan_settings?.is_subscribed) {
    return false;
  }

  if (!organization?.activity_plan_settings?.features?.is_manifest_enabled) {
    return true;
  }

  return false;
};
