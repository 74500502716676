import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  PointerSensor,
  useSensor,
  DndContext,
  useSensors,
  KeyboardSensor,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';

import { FieldWrapper } from 'client/components/Form/FieldWrapper';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';

import { FormValues } from './formValues';
import { SortableItineraryItem } from './SortableItineraryItem';
import styles from './RecommendedItineraryEditor.module.css';

export const RecommendedItineraryEditor = () => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const { values } = form.getState();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <Box mt={2} mb={2}>
      <FieldWrapper label={t('Recommended Itinerary URLs')} />
      <Box display="flex" flexDirection="column" gap={2}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={({ active, over }: DragEndEvent) => {
            if (over && active.id !== over.id) {
              const oldIndex = values.recommendedItineraryUrls.findIndex(
                (_, idx) => `item-${idx}` === active.id
              );
              const newIndex = values.recommendedItineraryUrls.findIndex(
                (_, idx) => `item-${idx}` === over.id
              );

              if (oldIndex !== -1 && newIndex !== -1) {
                const newUrls = arrayMove(
                  values.recommendedItineraryUrls,
                  oldIndex,
                  newIndex
                );
                form.change('recommendedItineraryUrls', newUrls);
              }
            }
          }}
        >
          <SortableContext
            items={values.recommendedItineraryUrls.map(
              (_, idx) => `item-${idx}`
            )}
            strategy={verticalListSortingStrategy}
          >
            {values.recommendedItineraryUrls.map((url, index) => (
              <div key={`item-${index}`} className={styles.itineraryItem}>
                <SortableItineraryItem
                  id={`item-${index}`}
                  value={url}
                  onChange={(newValue) => {
                    const newUrls = [...values.recommendedItineraryUrls];
                    newUrls[index] = newValue;
                    form.change('recommendedItineraryUrls', newUrls);
                  }}
                  onDelete={() => {
                    const newUrls = values.recommendedItineraryUrls.filter(
                      (_, idx) => idx !== index
                    );
                    form.change('recommendedItineraryUrls', newUrls);
                  }}
                />
              </div>
            ))}
          </SortableContext>
        </DndContext>
      </Box>
      <Box mt={2}>
        <Button
          type="button"
          color="secondary"
          text={t('Add URL')}
          onClick={() => {
            form.change('recommendedItineraryUrls', [
              ...values.recommendedItineraryUrls,
              '',
            ]);
          }}
          iconBeforeText={<i className="c-icon-outline-general-plus-circle" />}
        />
      </Box>
    </Box>
  );
};
