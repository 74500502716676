import styles from './Schedule.module.css';

interface Props {
  startDate: string;
  endDate: string;
}

export const Schedule = ({ startDate, endDate }: Props) => {
  if (!startDate || !endDate) {
    return <div className={styles.container}>-</div>;
  }

  return (
    <div className={styles.container}>
      {startDate} ~ {endDate}
    </div>
  );
};
