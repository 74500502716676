import { SystemFeeReservationsHeader } from 'client/pages/v3/SystemFee/SystemFeeReservations/SystemFeeReservationsHeader';
import { ActivityPlanSystemFeeReservationsBody } from 'client/pages/v3/SystemFee/SystemFeeReservations/ActivityPlanSystemFeeReservationsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ActivityPlanSystemFeeReservations = () => {
  return (
    <V3Page>
      <SystemFeeReservationsHeader />
      <ActivityPlanSystemFeeReservationsBody />
    </V3Page>
  );
};
