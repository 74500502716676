import { v4 as uuid } from 'uuid';

import * as Swagger from 'shared/models/swagger';

export const defaultRedemptionButtonColorSets = [
  {
    key: 'blue',
    backgroundColor: '#006ABB',
    textColor: '#FFFFFF',
  },
  {
    key: 'orange',
    backgroundColor: '#FF5A26',
    textColor: '#FFFFFF',
  },
  {
    key: 'red',
    backgroundColor: '#BB0000',
    textColor: '#FFFFFF',
  },
  {
    key: 'pink',
    backgroundColor: '#FF9298',
    textColor: '#FFFFFF',
  },
  {
    key: 'gray',
    backgroundColor: '#EEEEEE',
    textColor: '#888888',
  },
];

export type FormValues = {
  shouldUseEasyCheckin: boolean;
  shouldCountGuests: boolean;
  stubs: Stub[];
  displayType: string;
  expirationDate: CheckinRelativeDateTime;
  shouldUseETicket: boolean;
  termsOfServiceUrl: string;
  isFreeStartDateTime: boolean;
  availableStubCount: string;
  showStubSettings: boolean;
  shouldUseFootPrint: boolean;
  noteForRedemptionModal: string;
  shouldUseCountGuestsWithGuestType: boolean;
  guidanceButtonText: string;
  guidanceButtonImageUrl: string;
  shouldUseCheckinStartTimeRelative: boolean;
  shouldUseCheckinEndTimeRelative: boolean;
  checkinStartTimeRelative: string;
  noteForRedemptionModalBeforeCheckinStartTime: string;

  redemptionButtonText: string;
  redemptionButtonColor: string;
  redemptionButtonTextColor: string;
  footprintedModalDisplayItems: FieldResponseItemType[];
  shouldShowCustomizedDisplayItemsOnFootprintedModal: boolean;
  barCode: string;
  checkinEndRelativeDateTime: CheckinRelativeDateTime;
  shouldUseRedemptionCount: boolean;
  maxRedemptionCount: number;
  presetRedemptionCounts: PresetRedemptionCount[];
  useQrScanOnly: boolean;

  participantFieldResponseItems: FieldResponseItemType[];
  bookingFieldResponseItems: FieldResponseItemType[];

  shouldUseAdditionalBuy: boolean;
  additionalBuyType: 'SET' | 'INDIVIDUAL';
  additionalBuyStubKey: string;
  individualGross: string;
  individualNet: string;

  // Used for ETicket Editor
  eTicketType?: 'NORMAL' | 'FREEPASS';
  consumptionType?: 'NORMAL' | 'SPLIT' | 'MULTI-RIDE';
  checkinStartTimeType?: 'NORMAL' | 'RELATIVE';
  checkinEndTimeType?: 'NORMAL' | 'RELATIVE';
  presetRedemptionButtonColor?: string;
};

export type FieldResponseItemType = {
  title?: string;
  key?: string;
  _key?: string;
};

export type PresetRedemptionCount = {
  key: string;
  text: string;
  redemptionCount: number;
};

export type Stub = {
  key: string;
  text: string;
  options: string[];
  maxRedemptionCount?: number;
};

type CheckinRelativeDateTime = {
  deadlineType?: string;
  hoursLater?: number;
  daysLater?: number;
  timeOfDay?: string;
};

export const getInitialValues = (
  product: Swagger.Product | null
): FormValues => {
  let expirationDate = null;

  if (
    product?.qr_checkin_settings?.expiration_date?.relative_date?.type ===
    'HOUR'
  ) {
    expirationDate = {
      deadlineType: 'HOUR',
      hoursLater:
        product?.qr_checkin_settings?.expiration_date?.relative_date?.count,
    };
  } else if (
    product?.qr_checkin_settings?.expiration_date?.relative_date?.type === 'DAY'
  ) {
    expirationDate = {
      deadlineType: 'DAY',
      daysLater:
        product?.qr_checkin_settings?.expiration_date?.relative_date?.count,
      timeOfDay:
        product?.qr_checkin_settings?.expiration_date?.relative_date
          ?.time_local ?? '0:00',
    };
  } else if (
    product?.qr_checkin_settings?.expiration_date?.instant_expiration
  ) {
    expirationDate = {
      deadlineType: 'INSTANT_EXPIRATION',
    };
  } else {
    expirationDate = {
      deadlineType: 'ETERNAL',
    };
  }

  const isFreeStartDateTime =
    product?.booking_widget_settings?.is_free_start_date_time ?? false;

  let checkinEndRelativeDateTime: CheckinRelativeDateTime = {
    deadlineType: 'DAY',
    daysLater: 0,
    timeOfDay: '23:59',
  };
  if (product?.qr_checkin_settings?.first_checkin_deadline_relative_date_time) {
    checkinEndRelativeDateTime = {
      deadlineType: 'DAY',
      daysLater:
        product?.qr_checkin_settings?.first_checkin_deadline_relative_date_time
          ?.count,
      timeOfDay:
        product?.qr_checkin_settings?.first_checkin_deadline_relative_date_time
          ?.time_local ?? '23:59',
    };
  }

  let presetRedemptionButtonColor = 'customColor';
  for (const colorSet of defaultRedemptionButtonColorSets) {
    if (
      colorSet.backgroundColor ===
        product?.qr_checkin_settings?.redemption_button_color &&
      colorSet.textColor ===
        product?.qr_checkin_settings?.redemption_button_text_color
    ) {
      presetRedemptionButtonColor = colorSet.key;
      break;
    }
  }

  return {
    shouldUseEasyCheckin:
      product?.qr_checkin_settings?.should_use_simple_checkin ?? false,
    shouldCountGuests:
      product?.qr_checkin_settings?.should_count_guests_for_checkin ?? false,
    stubs: (product?.qr_checkin_settings?.stubs || []).map((stub) => {
      return {
        key: stub?.key || '',
        text: stub?.text || stub?.key || '',
        options: (stub?.options || []).map((option) => option?.key || ''),
        maxRedemptionCount: stub?.max_redemption_count ?? 1,
      };
    }),
    displayType:
      product?.qr_checkin_settings?.expiration_date?.display_type ?? 'CLOCK',
    expirationDate: expirationDate,
    shouldUseETicket:
      product?.qr_checkin_settings?.should_use_e_ticket ?? false,
    termsOfServiceUrl: product?.qr_checkin_settings?.terms_of_service_url ?? '',
    isFreeStartDateTime,
    availableStubCount: String(
      product?.qr_checkin_settings?.available_stub_count ?? '0'
    ),
    showStubSettings: (product?.qr_checkin_settings?.stubs ?? [])?.length > 0,
    shouldUseFootPrint:
      product?.qr_checkin_settings?.should_use_foot_print ?? false,
    noteForRedemptionModal:
      product?.qr_checkin_settings?.note_for_redemption_modal ?? '',
    shouldUseCountGuestsWithGuestType:
      product?.qr_checkin_settings
        ?.should_count_guests_for_checkin_with_guest_type ?? false,
    guidanceButtonText:
      product?.qr_checkin_settings?.guidance_button_text ?? '',
    guidanceButtonImageUrl:
      product?.qr_checkin_settings?.guidance_button_image_url ?? '',
    shouldUseCheckinStartTimeRelative: Boolean(
      product?.qr_checkin_settings?.checkin_start_time_relative
    ),
    shouldUseCheckinEndTimeRelative: Boolean(
      product?.qr_checkin_settings?.first_checkin_deadline_relative_date_time
    ),
    checkinStartTimeRelative:
      product?.qr_checkin_settings?.checkin_start_time_relative ?? '00:00',
    noteForRedemptionModalBeforeCheckinStartTime:
      product?.qr_checkin_settings
        ?.note_for_redemption_modal_before_checkin_start_time ?? '',
    redemptionButtonText:
      product?.qr_checkin_settings?.redemption_button_text ?? '',
    redemptionButtonColor:
      product?.qr_checkin_settings?.redemption_button_color || '#006ABB',
    redemptionButtonTextColor:
      product?.qr_checkin_settings?.redemption_button_text_color || '#FFFFFF',
    footprintedModalDisplayItems: (
      product?.qr_checkin_settings?.footprinted_modal_display_items ?? []
    ).map((item) => {
      return {
        title: item?.title || '',
        key: item?.key || '',
        _key: uuid(),
      };
    }),
    shouldShowCustomizedDisplayItemsOnFootprintedModal:
      product?.qr_checkin_settings
        ?.should_show_customized_display_items_on_footprinted_modal ?? false,
    barCode: product?.qr_checkin_settings?.bar_code ?? '',
    checkinEndRelativeDateTime: checkinEndRelativeDateTime,
    shouldUseRedemptionCount:
      product?.qr_checkin_settings?.should_use_redemption_count ?? false,
    maxRedemptionCount: product?.qr_checkin_settings?.max_redemption_count ?? 1,
    presetRedemptionCounts: (
      product?.qr_checkin_settings?.preset_redemption_counts || []
    ).map((presetRedemptionCount) => {
      return {
        key: presetRedemptionCount?.key || '',
        text: presetRedemptionCount?.text || '',
        redemptionCount: presetRedemptionCount?.redemption_count || 0,
      };
    }),
    useQrScanOnly: product?.qr_checkin_settings?.use_qr_scan_only ?? false,

    participantFieldResponseItems: (
      product?.qr_checkin_settings?.participant_field_response_items ?? []
    ).map((participantFieldResponseItem) => {
      return {
        key: participantFieldResponseItem?.key || '',
        title: participantFieldResponseItem?.title || '',
        _key: uuid(),
      };
    }),
    bookingFieldResponseItems: (
      product?.qr_checkin_settings?.booking_field_response_items ?? []
    ).map((bookingFieldResponseItem) => {
      return {
        key: bookingFieldResponseItem?.key || '',
        title: bookingFieldResponseItem?.title || '',
        _key: uuid(),
      };
    }),

    shouldUseAdditionalBuy:
      product?.qr_checkin_settings?.additional_buy_settings?.is_enabled ??
      false,
    additionalBuyType:
      product?.qr_checkin_settings?.additional_buy_settings?.type ?? 'SET',
    additionalBuyStubKey:
      product?.qr_checkin_settings?.additional_buy_settings?.stub_key ?? '',
    individualGross:
      product?.qr_checkin_settings?.additional_buy_settings?.individual_price?.gross?.substring(
        3
      ) || '0',
    individualNet:
      product?.qr_checkin_settings?.additional_buy_settings?.individual_price?.net?.substring(
        3
      ) || '0',

    eTicketType: isFreeStartDateTime ? 'FREEPASS' : 'NORMAL',
    consumptionType: product?.qr_checkin_settings
      ?.should_count_guests_for_checkin_with_guest_type
      ? 'SPLIT'
      : product?.qr_checkin_settings?.should_use_redemption_count
      ? 'MULTI-RIDE'
      : 'NORMAL',
    checkinStartTimeType: product?.qr_checkin_settings
      ?.checkin_start_time_relative
      ? 'RELATIVE'
      : 'NORMAL',
    checkinEndTimeType: product?.qr_checkin_settings
      ?.first_checkin_deadline_relative_date_time
      ? 'RELATIVE'
      : 'NORMAL',
    presetRedemptionButtonColor,
  };
};

export const convertFormValuesToProductPatch = (
  values: FormValues,
  defaultCurrency: string
): Swagger.Product$Patch => {
  let expirationDateRelativeTime = null;

  if (values.expirationDate.deadlineType === 'HOUR') {
    expirationDateRelativeTime = {
      type: 'HOUR',
      count: values.expirationDate.hoursLater,
    };
  } else if (values.expirationDate.deadlineType === 'DAY') {
    expirationDateRelativeTime = {
      type: 'DAY',
      count: values.expirationDate.daysLater,
      time_local: values.expirationDate.timeOfDay,
    };
  } else if (values.expirationDate.deadlineType === 'ETERNAL') {
    expirationDateRelativeTime = {
      type: 'ETERNAL',
    };
  } else {
    expirationDateRelativeTime = null;
  }

  let checkinEndRelativeTime: Swagger.RelativeDateTime | undefined = undefined;
  if (values.shouldUseCheckinEndTimeRelative && !values.isFreeStartDateTime) {
    checkinEndRelativeTime = {
      type: 'DAY',
      count: values.checkinEndRelativeDateTime.daysLater,
      time_local: values.checkinEndRelativeDateTime.timeOfDay,
    };
  }

  return {
    qr_checkin_settings: {
      should_use_simple_checkin: values.shouldUseEasyCheckin,
      should_count_guests_for_checkin: values.shouldCountGuests,
      stubs: values.showStubSettings
        ? values.stubs.map((stub) => {
            return {
              key: stub?.key || stub?.text || '',
              text: stub?.text || '',
              options: stub?.options.map((option) => {
                return {
                  key: option ?? '',
                  text: option ?? '',
                };
              }),
              max_redemption_count: values.shouldUseRedemptionCount
                ? stub?.maxRedemptionCount
                : 0,
            };
          })
        : [],
      expiration_date: expirationDateRelativeTime
        ? {
            relative_date: expirationDateRelativeTime as any,
            display_type: values.displayType,
            instant_expiration: false,
          }
        : {
            instant_expiration: true,
          },
      should_use_e_ticket: values.shouldUseETicket,
      terms_of_service_url: values.termsOfServiceUrl,
      available_stub_count: Number(values.availableStubCount),
      should_use_foot_print: values.shouldUseFootPrint,
      note_for_redemption_modal: values.noteForRedemptionModal,
      should_count_guests_for_checkin_with_guest_type:
        values.shouldUseCountGuestsWithGuestType,
      guidance_button_text: values.guidanceButtonText,
      guidance_button_image_url: values.guidanceButtonImageUrl,
      checkin_start_time_relative:
        !values.isFreeStartDateTime && values.shouldUseCheckinStartTimeRelative
          ? values.checkinStartTimeRelative
          : '',
      note_for_redemption_modal_before_checkin_start_time:
        values.noteForRedemptionModalBeforeCheckinStartTime,
      redemption_button_text: values.redemptionButtonText,
      redemption_button_color: values.redemptionButtonColor,
      redemption_button_text_color: values.redemptionButtonTextColor,
      footprinted_modal_display_items: values.footprintedModalDisplayItems.map(
        (item) => {
          return {
            title: item.title,
            key: item.key,
          };
        }
      ),
      should_show_customized_display_items_on_footprinted_modal:
        values.shouldShowCustomizedDisplayItemsOnFootprintedModal,
      bar_code: values.barCode,
      first_checkin_deadline_relative_date_time: checkinEndRelativeTime,
      should_use_redemption_count: values.shouldUseRedemptionCount,
      max_redemption_count:
        values.shouldUseRedemptionCount && !values.showStubSettings
          ? values.maxRedemptionCount
          : 0,
      preset_redemption_counts:
        values.shouldUseRedemptionCount && !values.showStubSettings
          ? values.presetRedemptionCounts.map((presetRedemptionCount) => {
              return {
                key: presetRedemptionCount?.key || '',
                text: presetRedemptionCount?.text || '',
                redemption_count: Number(
                  presetRedemptionCount?.redemptionCount
                ),
              };
            })
          : [],
      use_qr_scan_only:
        values.shouldUseRedemptionCount && !values.showStubSettings
          ? values.useQrScanOnly
          : false,
      participant_field_response_items:
        values.participantFieldResponseItems.map((item) => {
          return {
            key: item.key,
            title: item.title,
          };
        }),

      booking_field_response_items: values.bookingFieldResponseItems.map(
        (item) => {
          return {
            key: item.key,
            title: item.title,
          };
        }
      ),

      additional_buy_settings: values.shouldUseAdditionalBuy
        ? {
            is_enabled: values.shouldUseAdditionalBuy,
            type: values.additionalBuyType,
            stub_key: values.additionalBuyStubKey,
            individual_price:
              values.additionalBuyType === 'INDIVIDUAL'
                ? {
                    net: `${defaultCurrency}${values.individualNet}`,
                    gross: `${defaultCurrency}${values.individualGross}`,
                  }
                : undefined,
          }
        : undefined,
    },
  };
};
export const getETicketConvertFormValuesToProductPatch =
  (
    product: Swagger.Product | null,
    defaultCurrency: string
  ): ((values: FormValues) => Swagger.Product$Patch) =>
  (values: FormValues): Swagger.Product$Patch => {
    const productPatch = convertFormValuesToProductPatch(
      values,
      defaultCurrency
    );
    return {
      ...productPatch,
      booking_widget_settings: {
        ...product?.booking_widget_settings,
        is_free_start_date_time: values.isFreeStartDateTime,
      },
    };
  };
