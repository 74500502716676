import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import { getArrayMutators } from 'client/libraries/util/form';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { Loading } from 'client/pages/Loading';
import type { ReduxState } from 'client/reducers';
import { productOptionsSelector } from 'client/reducers/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import type { LanguageISO } from 'client/libraries/i18n';
import { FieldWrapper } from 'client/components/Form';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Radio } from 'client/components/v3/Form/Radio';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Box } from 'client/components/Box/Box';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { updateOrganization } from 'client/actions/organizations';
import { fetchProducts } from 'client/actions/products';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

import { getFloatingButtonEmbedCode } from './util';
import type { ButtonStyle, DisplayPosition } from './util';
import {
  convertSettingsFormValuesToOrganizationPatch,
  getSettingsInitialValues,
} from './FormValues';
import type { FloatingButtonSettingsFormValues } from './FormValues';
import 'rc-color-picker/assets/index.css';

const focusOnError: any = createDecorator();

export const FloatingButtonTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollTo = React.useContext(ScrollToContext);
  const productOptions = useSelector(productOptionsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const bookingWidgetApiKey =
    activeUserOrganization?.booking_widget_api_key ?? '';
  const baseUrl = getBookingWebsiteUrl(activeUserOrganization);

  const existFloatingButtonSettings = Boolean(
    activeUserOrganization?.floating_button_settings
  );

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);

  const [displayPosition, setDisplayPosition] =
    React.useState<DisplayPosition>('LOWER_LEFT');
  const [buttonText, setButtonText] = React.useState<string>(t('Book Now'));
  const [buttonTextColor, setButtonTextColor] =
    React.useState<string>('#ffffff');
  const [buttonColor, setButtonColor] = React.useState<string>('#008ec9');
  const [buttonStyle, setButtonStyle] = React.useState<ButtonStyle>(
    'ROUNDED_CORNERS_LARGE'
  );
  const [buttonWidth, setButtonWidth] = React.useState<string>('160');
  const [buttonHeight, setButtonHeight] = React.useState<string>('40');
  const [language, setLanguage] = React.useState<LanguageISO>('ja');

  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');

  const languageOptions = (
    (activeUserOrganization && activeUserOrganization.supported_languages) ||
    []
  ).map((language) => ({
    label: getLanguageName(uppercaseIsoToLowercaseIso[language], t),
    value: uppercaseIsoToLowercaseIso[language],
  }));

  const getBorderRadiusValue = React.useMemo(() => {
    if (buttonStyle === 'ROUND') {
      return '50%';
    } else if (buttonStyle === 'ROUNDED_CORNERS_LARGE') {
      return '24px';
    } else if (buttonStyle === 'ROUNDED_CORNERS_SMALL') {
      return '12px';
    }
    return '';
  }, [buttonStyle]);

  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
    }
  }, [activeUserOrganization]);

  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);

  React.useEffect(() => {
    if (!(window as any).openCalendarByModal) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed-floating-button/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).bookingWidgetApiKey) {
      (window as any).bookingWidgetApiKey = bookingWidgetApiKey;
    }
  }, [bookingWidgetApiKey]);
  React.useEffect(() => {
    if (!(window as any).lang) {
      (window as any).lang = language;
    }
  }, [language]);

  const embedCode = getFloatingButtonEmbedCode(
    bookingWidgetApiKey,
    buttonText,
    buttonColor,
    displayPosition,
    buttonTextColor,
    buttonStyle,
    buttonWidth,
    buttonHeight,
    language
  );

  const initialValues = getSettingsInitialValues(activeUserOrganization);

  return (
    <>
      <div
        style={{
          borderBottom: '1px solid var(--gray200)',
          marginLeft: '24px',
        }}
      >
        <div
          style={{
            marginTop: '24px',
          }}
        >
          <p style={{ fontWeight: 'var(--text-semibold)' }}>
            {t('What is floating button?')}
          </p>
          <p>
            {t(
              'You can add a button to your website that is displayed at the bottom right/left corner even when you scroll the page.'
            )}
          </p>
          <div
            style={{
              marginBottom: '24px',
            }}
          >
            <a
              className="c-button-link-sm-color"
              href="/helps/website/floating-button"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: '0' }}
            >
              <p style={{ fontWeight: 'var(--text-semibold)' }}>
                {t('More about floating button')}
              </p>
              <i className="c-icon-outline-general-link-external-02"></i>
            </a>
          </div>
        </div>
      </div>
      <Form
        onSubmit={async (values: FloatingButtonSettingsFormValues) => {
          const orgId = activeUserOrganization?.id || '';

          try {
            await dispatch(
              updateOrganization(
                orgId,
                'SUPPLIER',
                convertSettingsFormValuesToOrganizationPatch(values)
              )
            );
            scrollTo(0, 0);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values }) => {
          const navigateToPageUrl = React.useMemo(() => {
            if (!baseUrl) {
              return '';
            }
            const url = new URL(baseUrl ?? '');
            if (values.onClickBehavior === 'NAVIGATE_TO_PAGE') {
              if (values.onClickTarget === 'TOP_PAGE') {
                return url.origin;
              }
              return `${url.origin}/products/${
                values.floatingButtonUrlMap?.[0]?.targetProduct || ''
              }/?lng=${language}`;
            } else {
              if (values.onClickTarget === 'TOP_PAGE') {
                return `${url.origin}/embed/${bookingWidgetApiKey}/multiProductCalendar?lng=${language}&isModal=true`;
              }
              return `${url.origin}/embed/${bookingWidgetApiKey}/products/${
                values.floatingButtonUrlMap?.[0]?.targetProduct || ''
              }/sideBarCalendarWithPrices?lng=${language}&isModal=true`;
            }
          }, [
            values.onClickTarget,
            values.onClickBehavior,
            values.floatingButtonUrlMap,
            language,
            baseUrl,
          ]);
          const buttonDisable = React.useMemo(() => {
            return (
              values.onClickTarget === 'PRODUCT_PAGE' &&
              values.floatingButtonUrlMap?.length === 0
            );
          }, [values.onClickTarget, values.floatingButtonUrlMap]);
          return (
            <form onSubmit={handleSubmit}>
              {saveSucceeded && (
                <Snackbar
                  color="success"
                  text={t('Settings Saved')}
                  shouldShow={saveSucceeded}
                />
              )}
              <div
                className={clsx(
                  styles['p-inquiry-tabs__tab-panel'],
                  styles['components_is-active__B15DT']
                )}
                style={{ display: 'block' }}
              >
                <div className={styles['p-inquiry-tabs__content']}>
                  <ul className={styles['p-inquiry-list']}>
                    <li className={styles['p-inquiry-list__item']}>
                      <div className={styles['p-inquiry-list__item__ttl']}>
                        <div
                          className={styles['p-inquiry-list__item__ttl__txt']}
                        >
                          <div>{t('Behaviour')}</div>
                        </div>
                      </div>
                      <div className={styles['p-inquiry-list__item__body']}>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <div style={{ display: 'block' }}>
                            <div
                              className={styles['form-title']}
                              style={{ width: '100%', marginBottom: '5px' }}
                            >
                              {t('Click Action')}
                            </div>
                            <Field name="onClickBehavior">
                              {({ input }) => (
                                <>
                                  <Box display="flex" alignItems="center">
                                    <div className={styles['form-body']}>
                                      <Radio
                                        label={t('Go to booking website')}
                                        checked={
                                          input.value === 'NAVIGATE_TO_PAGE'
                                        }
                                        onChange={() =>
                                          input.onChange('NAVIGATE_TO_PAGE')
                                        }
                                        size="sm"
                                      />
                                    </div>
                                    <div className={styles['form-body']}>
                                      <Radio
                                        label={t(
                                          'Open product availability calendar'
                                        )}
                                        checked={
                                          input.value === 'SHOW_CALENDAR'
                                        }
                                        onChange={() =>
                                          input.onChange('SHOW_CALENDAR')
                                        }
                                        size="sm"
                                      />
                                    </div>
                                    <Tooltip
                                      text={
                                        <div>
                                          {t(
                                            'Similar to "Book Now" button for embed calendar, guest can see the availability calendar without redirected to booking website.'
                                          )}
                                        </div>
                                      }
                                    >
                                      <i className="c-icon-outline-general-info-circle"></i>
                                    </Tooltip>
                                  </Box>
                                </>
                              )}
                            </Field>
                            <Box mt={5}>
                              <div className={styles['form-title']}>
                                {t('Targets')}
                              </div>
                              <div style={{ marginBottom: '5px' }}>
                                {t(
                                  'Select a page to go to or type of calendar when the button is clicked.'
                                )}
                              </div>
                              <Field name="onClickTarget">
                                {({ input }) => {
                                  const formState = useFormState();
                                  const isNavigateToPage = Boolean(
                                    formState.values?.onClickBehavior ===
                                      'NAVIGATE_TO_PAGE'
                                  );
                                  return (
                                    <>
                                      <Box display="flex" width="fit-content">
                                        <div className={styles['form-body']}>
                                          <Radio
                                            label={
                                              isNavigateToPage
                                                ? t('Top Page')
                                                : t('All Products Calendar')
                                            }
                                            checked={input.value === 'TOP_PAGE'}
                                            onChange={() =>
                                              input.onChange('TOP_PAGE')
                                            }
                                            size="sm"
                                          />
                                        </div>
                                        <div className={styles['form-body']}>
                                          <Radio
                                            label={
                                              isNavigateToPage
                                                ? t('Product Page')
                                                : t('Single Product Calendar')
                                            }
                                            checked={
                                              input.value === 'PRODUCT_PAGE'
                                            }
                                            onChange={() =>
                                              input.onChange('PRODUCT_PAGE')
                                            }
                                            size="sm"
                                          />
                                        </div>
                                      </Box>
                                    </>
                                  );
                                }}
                              </Field>
                            </Box>
                            <div style={{ marginTop: '20px' }}>
                              <FieldArray name="floatingButtonUrlMap">
                                {({ fields: urlMap }) => {
                                  const formState = useFormState();
                                  if (
                                    formState.values?.onClickTarget !==
                                    'PRODUCT_PAGE'
                                  ) {
                                    return null;
                                  }
                                  const count = urlMap.length ?? 0;

                                  return (
                                    <>
                                      <FieldWrapper label={t('URL Mapping')}>
                                        <Box mb={2} display="block">
                                          <p>
                                            {t(
                                              "Please enter URL to show the floating button and select a product for the URL. When the button is clicked on the registered URL, guest will be redirected to the target product's page or product's availability calendar will be displayed. Floating button will not be displayed on non-registered URL."
                                            )}
                                          </p>
                                          <Box mt={2}>
                                            <div
                                              className={styles['c-table-list']}
                                            >
                                              {urlMap.map((url, idx) => (
                                                <>
                                                  <div
                                                    className={
                                                      styles['company__item']
                                                    }
                                                    style={{ display: 'flex' }}
                                                    key={idx}
                                                  >
                                                    <div
                                                      className={
                                                        styles[
                                                          'company__item__box'
                                                        ]
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'block',
                                                        }}
                                                      >
                                                        <Field
                                                          key={`origin.${idx}`}
                                                          name={`${url}.origin`}
                                                        >
                                                          {({
                                                            input,
                                                            meta: {
                                                              error,
                                                              touched,
                                                            },
                                                          }) => (
                                                            <>
                                                              <div
                                                                style={{
                                                                  margin:
                                                                    '10px 10px 10px',
                                                                }}
                                                              >
                                                                <p>
                                                                  {' '}
                                                                  <TextField
                                                                    {...input}
                                                                    label={t(
                                                                      'Source URL'
                                                                    )}
                                                                    error={
                                                                      touched &&
                                                                      error
                                                                    }
                                                                  />{' '}
                                                                </p>
                                                              </div>
                                                            </>
                                                          )}
                                                        </Field>
                                                        <Field
                                                          key={`targetProduct.${idx}`}
                                                          name={`${url}.targetProduct`}
                                                        >
                                                          {({ input }) => (
                                                            <>
                                                              <div
                                                                style={{
                                                                  margin:
                                                                    '10px 10px 10px',
                                                                }}
                                                              >
                                                                <SingleDropdown
                                                                  searchable={
                                                                    true
                                                                  }
                                                                  placeholder={t(
                                                                    'Target Product'
                                                                  )}
                                                                  selectedOption={
                                                                    input.value
                                                                  }
                                                                  options={
                                                                    productOptions
                                                                  }
                                                                  onChange={(
                                                                    value
                                                                  ) => {
                                                                    input.onChange(
                                                                      value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </Field>
                                                      </div>
                                                    </div>
                                                    <Button
                                                      size="icon"
                                                      color="tertiarygray"
                                                      iconBeforeText={
                                                        <i className="c-icon-outline-general-trash-03"></i>
                                                      }
                                                      onClick={() =>
                                                        urlMap.remove(idx)
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              ))}
                                            </div>
                                          </Box>
                                          <a
                                            className={styles['add__button']}
                                            onClick={() => {
                                              if (count === 0) {
                                                (urlMap as any).insertAt(0, '');
                                              } else {
                                                (urlMap as any).insertAt(
                                                  count + 1,
                                                  ''
                                                );
                                              }
                                            }}
                                          >
                                            <i className="c-icon-outline-general-plus-circle"></i>
                                            {t('Add')}
                                          </a>
                                        </Box>
                                      </FieldWrapper>
                                    </>
                                  );
                                }}
                              </FieldArray>
                              <FieldArray name="excludeUrl">
                                {({ fields: urls }) => {
                                  const formState = useFormState();
                                  if (
                                    formState.values?.onClickTarget !==
                                    'TOP_PAGE'
                                  ) {
                                    return null;
                                  }
                                  const count = urls.length ?? 0;

                                  return (
                                    <>
                                      <div>
                                        <div className={styles['form-title']}>
                                          {t('Excluded URL')}
                                        </div>
                                        <p>
                                          {t(
                                            'Please enter URL that should not show the button.'
                                          )}
                                        </p>
                                        {urls.map((url, idx) => (
                                          <Field key={idx} name={url}>
                                            {({ input }) => (
                                              <>
                                                <Box
                                                  display="flex"
                                                  alignItems="center"
                                                  mt={2}
                                                  mr={3}
                                                  mb={2}
                                                >
                                                  <TextField {...input} />
                                                  <Box ml={2}>
                                                    <Button
                                                      size="icon"
                                                      color="tertiarygray"
                                                      iconBeforeText={
                                                        <i className="c-icon-outline-general-trash-03"></i>
                                                      }
                                                      onClick={() =>
                                                        urls.remove(idx)
                                                      }
                                                    />
                                                  </Box>
                                                </Box>
                                              </>
                                            )}
                                          </Field>
                                        ))}
                                        <div>
                                          <a
                                            className={styles['add__button']}
                                            onClick={() => {
                                              if (count === 0) {
                                                (urls as any).insertAt(0, '');
                                              } else {
                                                (urls as any).insertAt(
                                                  count + 1,
                                                  ''
                                                );
                                              }
                                            }}
                                          >
                                            <i className="c-icon-outline-general-plus-circle"></i>
                                            {t('Add')}
                                          </a>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className={styles['p-inquiry-list__item']}>
                      <div className={styles['p-inquiry-list__item__ttl']}>
                        <div
                          className={styles['p-inquiry-list__item__ttl__txt']}
                        >
                          <div>{t('Display')}</div>
                        </div>
                      </div>
                      <div className={styles['p-inquiry-list__item__body']}>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <div style={{ display: 'block' }}>
                            <div
                              className={styles['form-title']}
                              style={{ marginBottom: '10px' }}
                            >
                              {t('Position')}
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div className={styles['form-body']}>
                                <Radio
                                  label={t('Left Bottom')}
                                  checked={displayPosition === 'LOWER_LEFT'}
                                  onChange={() =>
                                    setDisplayPosition('LOWER_LEFT')
                                  }
                                  size="sm"
                                />
                              </div>
                              <div className={styles['form-body']}>
                                <Radio
                                  label={t('Right Bottom')}
                                  checked={displayPosition === 'LOWER_RIGHT'}
                                  onChange={() =>
                                    setDisplayPosition('LOWER_RIGHT')
                                  }
                                  size="sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <div style={{ width: '100%', marginTop: '5px' }}>
                            <div className={styles['form-title']}>
                              {t('Button Text')}
                            </div>
                            <Box display="block">
                              <Box display="flex" alignItems="center" mt={2}>
                                <TextField
                                  value={buttonText}
                                  onChange={(value) => setButtonText(value)}
                                />
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <Box mt={2}>
                            <div className={styles['form-title']}>
                              {t('Text Color')}
                            </div>
                            <div style={{ width: '142px', marginTop: '8px' }}>
                              <ColorPicker
                                placement="bottomRight"
                                color={buttonTextColor}
                                onChange={({ color }: any) =>
                                  setButtonTextColor(color)
                                }
                                className={styles['color_section']}
                              >
                                <a
                                  className={clsx(
                                    'react-colorpicker-trigger',
                                    styles[
                                      'p-bookingWidgetCustomize-products-list__color'
                                    ]
                                  )}
                                >
                                  <div
                                    className={clsx(
                                      styles[
                                        'p-bookingWidgetCustomize-products-list__color-box'
                                      ]
                                    )}
                                    style={{
                                      backgroundColor: buttonTextColor,
                                    }}
                                  ></div>
                                  <span>
                                    <i></i>
                                    {t('Change')}
                                  </span>
                                </a>
                              </ColorPicker>
                            </div>
                          </Box>
                        </div>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <Box mt={2}>
                            <div className={styles['form-title']}>
                              {t('Button Color')}
                            </div>
                            <div style={{ width: '142px', marginTop: '8px' }}>
                              <ColorPicker
                                placement="bottomRight"
                                color={buttonColor}
                                onChange={({ color }: any) =>
                                  setButtonColor(color)
                                }
                                className={styles['color_section']}
                              >
                                <a
                                  className={clsx(
                                    'react-colorpicker-trigger',
                                    styles[
                                      'p-bookingWidgetCustomize-products-list__color'
                                    ]
                                  )}
                                >
                                  <div
                                    className={clsx(
                                      styles[
                                        'p-bookingWidgetCustomize-products-list__color-box'
                                      ]
                                    )}
                                    style={{
                                      backgroundColor: buttonColor,
                                    }}
                                  ></div>
                                  <span>
                                    <i></i>
                                    {t('Change')}
                                  </span>
                                </a>
                              </ColorPicker>
                            </div>
                          </Box>
                        </div>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <div
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                          >
                            <div className={styles['form-title']}>
                              {t('Button Style')}
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                              <div className={styles['form-body']}>
                                <Radio
                                  label={t('Square Corners')}
                                  checked={buttonStyle === 'SQUARE_CORNERS'}
                                  onChange={() => {
                                    setButtonStyle('SQUARE_CORNERS');
                                    setButtonWidth('160');
                                    setButtonHeight('40');
                                  }}
                                  size="sm"
                                />
                              </div>
                              <div className={styles['form-body']}>
                                <Radio
                                  label={t('Rounded Corners (small)')}
                                  checked={
                                    buttonStyle === 'ROUNDED_CORNERS_SMALL'
                                  }
                                  onChange={() => {
                                    setButtonStyle('ROUNDED_CORNERS_SMALL');
                                    setButtonWidth('160');
                                    setButtonHeight('40');
                                  }}
                                  size="sm"
                                />
                              </div>
                              <div className={styles['form-body']}>
                                <Radio
                                  label={t('Rounded Corners (large)')}
                                  checked={
                                    buttonStyle === 'ROUNDED_CORNERS_LARGE'
                                  }
                                  onChange={() => {
                                    setButtonStyle('ROUNDED_CORNERS_LARGE');
                                    setButtonWidth('160');
                                    setButtonHeight('40');
                                  }}
                                  size="sm"
                                />
                              </div>
                              <div className={styles['form-body']}>
                                <Radio
                                  label={t('Round')}
                                  checked={buttonStyle === 'ROUND'}
                                  onChange={() => {
                                    setButtonStyle('ROUND');
                                    setButtonWidth('50');
                                    setButtonHeight('50');
                                  }}
                                  size="sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              className={styles['form-title']}
                              style={{ marginBottom: '10px' }}
                            >
                              {t('Button Size')}
                            </div>
                            <div
                              className={styles['button__setting__item__box']}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  margin: '8px',
                                  alignItems: 'center',
                                }}
                              >
                                <span style={{ width: '50px' }}>
                                  {t('width')}
                                </span>
                                <TextField
                                  value={buttonWidth}
                                  onChange={(value) => {
                                    setButtonWidth(value);
                                    if (buttonStyle === 'ROUND') {
                                      setButtonHeight(value);
                                    }
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  {t('px')}
                                </span>
                              </div>
                              {buttonStyle !== 'ROUND' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    margin: '8px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span style={{ width: '50px' }}>
                                    {t('height')}
                                  </span>
                                  <TextField
                                    value={buttonHeight}
                                    onChange={(value) => setButtonHeight(value)}
                                  />
                                  <span style={{ marginLeft: '5px' }}>
                                    {t('px')}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <Box mt={2}>
                            <div
                              className={styles['form-title']}
                              style={{ marginBottom: '10px' }}
                            >
                              {t('Select language')}
                            </div>
                            <div style={{ display: 'flex' }}>
                              {languageOptions.map((l) => (
                                <>
                                  <div className={styles['form-body']}>
                                    <Radio
                                      label={l.label}
                                      checked={language === l.value}
                                      onChange={() => {
                                        setLanguage(l.value as LanguageISO);
                                      }}
                                      size="sm"
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </Box>
                        </div>
                      </div>
                    </li>

                    <li className={styles['p-inquiry-list__item']}>
                      <div className={styles['p-inquiry-list__item__ttl']}>
                        <div
                          className={styles['p-inquiry-list__item__ttl__txt']}
                        >
                          <div>{t('Sample, Embed code')}</div>
                        </div>
                      </div>
                      <div className={styles['p-inquiry-list__item__body']}>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                        >
                          <div style={{ display: 'block', width: '100%' }}>
                            <div
                              className={styles['form-title']}
                              style={{ width: '100%' }}
                            >
                              {t('Preview')}
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                              {t(
                                'Button can be added to your website by copy and pasting the HTML code right before </body> tag on each page.'
                              )}
                            </div>
                            {!existFloatingButtonSettings && (
                              <div
                                style={{
                                  color: '#dc3e15',
                                  marginBottom: '10px',
                                }}
                              >
                                {t(
                                  'Please confirm behavior and display settings and click "Save Settings".'
                                )}
                              </div>
                            )}
                            {copySuccess && (
                              <Snackbar
                                color="success"
                                text={t('Copied')}
                                shouldShow={copySuccess}
                              />
                            )}
                            <div className={styles['button__preview__box']}>
                              <button
                                type="button"
                                disabled={buttonDisable}
                                style={{
                                  height: `${buttonHeight}px`,
                                  width: `${buttonWidth}px`,
                                  fontWeight: 'bold',
                                  color: `${buttonTextColor}`,
                                  fontSize: '18px',
                                  backgroundColor: `${buttonColor}`,
                                  borderRadius: `${getBorderRadiusValue}`,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (baseUrl) {
                                    if (
                                      values.onClickBehavior ===
                                      'NAVIGATE_TO_PAGE'
                                    ) {
                                      window.open(
                                        `${navigateToPageUrl}`,
                                        '_blank'
                                      );
                                    } else {
                                      if (values.onClickTarget === 'TOP_PAGE') {
                                        (window as any).openCalendarByModal(
                                          bookingWidgetBaseUrl,
                                          '',
                                          'MULTI'
                                        );
                                      } else {
                                        (window as any).openCalendarByModal(
                                          bookingWidgetBaseUrl,
                                          values.floatingButtonUrlMap?.[0]
                                            ?.targetProduct,
                                          'SINGLE'
                                        );
                                      }
                                    }
                                  }
                                }}
                              >
                                {buttonText}
                              </button>
                            </div>
                            <Box display="flex" alignItems="center">
                              <div style={{ marginRight: '5px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(embedCode);
                                    setCopySuccess(true);
                                  }}
                                >
                                  <Button
                                    type="button"
                                    text={t('Copy HTML code')}
                                    size="md"
                                    color="white"
                                    iconBeforeText={
                                      <i className="c-icon-outline-general-copy-01"></i>
                                    }
                                  />
                                </div>
                              </div>
                              <Tooltip
                                text={t(
                                  'Copy HTML code for the button and paste it to each page of your website.'
                                )}
                              >
                                <i className="c-icon-outline-general-info-circle"></i>
                              </Tooltip>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className={clsx(
                    styles['p-products__fixed'],
                    styles['is-active']
                  )}
                >
                  <div className={styles['p-products__fixed__actions']}>
                    <Button
                      text={t('Save')}
                      type="submit"
                      color="primary"
                      size="md"
                      loading={submitting}
                      style={{
                        width: '60px',
                        fontWeight: 'var(--text-semibold)',
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
      {loading && <Loading />}
    </>
  );
};
