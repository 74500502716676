import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import clsx from 'clsx';

import { hasSubscription } from 'client/libraries/util/subscriptions';
import { MainInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/MainInformation';
import { DetailedInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/DetailedInformation';
import { DynamicPackageDetailedInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/DynamicPackageDetaildInfomation/DynamicPackageDetailedInformation';
import { GuestInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/GuestInformation';
import { BillingInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/BillingInformation';
import { RelatedInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/RelatedInformation';
import { SupplementaryInformation } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/SupplementaryInformation';
import { ActivityLog } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ActivityLog';
import { Review } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Review';
import { Waiver } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Waiver';
import { Inquiry } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry';
import { EmailLog } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/EmailLog';
import { Footprint } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Footprint/Footprint';
import { Coupon } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Coupon/Coupon';
import { Checkin } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/TicketRedemption/Checkin/Checkin';
import { DynamicPackageCheckin } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/DynamicPackageTicketRedemption/DynamicPacakgeCheckin/DynamicPackageCheckin';
import { GuestTypeCountCheckin } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/TicketRedemption/GuestTypeCountCheckin/GuestTypeCountCheckin';
import { RedemptionCountCheckin } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/TicketRedemption/RedemptionCountCheckin/RedemptionCountCheckin';
import { ReservationSurveys } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationSurveys/ReservationSurveys';
import { ReservationMediaDownloads } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationMediaDownloads/ReservationMediaDownloads';
import { Loading } from 'client/pages/Loading';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import type {
  Reservation,
  ReservationReceipt,
  Product,
  ProductInstance,
  ReservationInvoice,
} from 'shared/models/swagger';
import { ReservationContext } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationContext';
import { PcSidebarMenu } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/Menu/PcSidebarMenu';
import { ReservationDetailsHeader } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/ReservationDetailsHeader';
import baseStyles from 'client/v3-base.module.css';
import { SpMenu } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/Menu/SpMenu';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { ReduxState } from 'client/reducers';
import { useMediaDownloadOrders } from 'client/hooks/useMediaDownloadOrders';
import { useMediaDownloadSalesPages } from 'client/hooks/useMediaDownloadSalesPages';
import { getWaiverStatus } from 'client/libraries/util/getWaiverStatusText';
import { LanguageISO, lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';

type Props = {
  reservation?: Reservation;
  reservationReceipt?: ReservationReceipt;
  product?: Product;
  productInstance?: ProductInstance;
  loading?: boolean;
  reservationInvoice?: ReservationInvoice;
};

export const ReservationDetails = ({
  reservation,
  reservationReceipt,
  product,
  productInstance,
  loading,
  reservationInvoice,
}: Props) => {
  const { t, i18n } = useTranslation();

  const activeUser = useSelector(activeUserSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const relatedDivRef = useRef<HTMLDivElement | null>(null);
  const supplementaryDivRef = useRef<HTMLDivElement | null>(null);
  const redeemedRecords = useSelector(
    (state: ReduxState) => state.redeemedRecords.all
  );
  const { orders } = useMediaDownloadOrders({
    reservationId: reservation?.id ?? '',
  });
  const { salesPages } = useMediaDownloadSalesPages({
    reservationId: reservation?.id ?? '',
  });

  const surveyTemplates = useSelector(
    (state: ReduxState) => state.surveyTemplates.all
  );

  if (!reservation || !product || !productInstance) {
    return <Loading />;
  }

  const waiverStatus = getWaiverStatus(reservation);

  const reservationLanguage =
    reservation?.field_responses?.find(
      (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
    )?.response ?? 'en';
  const surveyTemplate = surveyTemplates.find((survey) =>
    survey.product_ids?.includes(reservation.product_id ?? '')
  );
  const shouldShowSurvey =
    // Reservation product is tied to a survey template
    surveyTemplate !== undefined &&
    // Reservation survey template is published
    surveyTemplate.status !== undefined &&
    surveyTemplate.status === 'SURVEY_TEMPLATE_PUBLISHED' &&
    // Reservation language matches survey template language or translated language
    surveyTemplate.source_language !== undefined &&
    surveyTemplate.translated_languages !== undefined &&
    (surveyTemplate.source_language ===
      lowercaseIsoToUppercaseIso[reservationLanguage as LanguageISO] ||
      surveyTemplate.translated_languages?.includes(
        lowercaseIsoToUppercaseIso[reservationLanguage as LanguageISO]
      ));

  // Sections & permission to show for sidebar
  const sections = [
    {
      title: t('Main Information'),
      id: 'main',
      hasPermission: true,
    },
    {
      title: t('Detail Information'),
      id: 'detail',
      hasPermission: true,
    },
    {
      title: t('Guest Information'),
      id: 'guest',
      hasPermission: true,
    },
    {
      title: t('Billing Info'),
      id: 'billing',
      hasPermission: true,
    },
    {
      title: t('Related Information'),
      id: 'related',
      hasPermission: true,
    },
    {
      title: t('Supplementary Information'),
      id: 'supplementary',
      hasPermission: true,
    },
    {
      title: t('Reviews'),
      id: 'review',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-review') &&
        reservation.status === 'CONFIRMED',
    },
    {
      title: t('Waiver'),
      id: 'waiver',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-digital-waiver') &&
        reservation.status === 'CONFIRMED' &&
        waiverStatus !== 'NOT_APPLICABLE',
    },
    {
      title: t('Image/Video Downloads'),
      id: 'mediaDownload',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-image-download') &&
        reservation.status === 'CONFIRMED' &&
        (orders.length !== 0 || salesPages.length !== 0),
    },
    {
      title: t('Inquiries'),
      id: 'inquiry',
      hasPermission: hasSubscription(activeOrganization, 'feature-inquiry'),
    },
    {
      title: t('Digital Guidance'),
      id: 'digitalGuidance',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-digital-guidance') &&
        redeemedRecords.length > 0,
    },
    {
      title: t('Ticket Redemptions'),
      id: 'ticketRedemptions',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-e-ticket') &&
        product.qr_checkin_settings?.should_use_e_ticket !== undefined &&
        product.qr_checkin_settings?.should_use_e_ticket &&
        reservation.status === 'CONFIRMED',
    },

    {
      title: t('E-ticket View history'),
      id: 'footprint',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-e-ticket') &&
        product.qr_checkin_settings?.should_use_e_ticket !== undefined &&
        product.qr_checkin_settings?.should_use_e_ticket &&
        reservation.status === 'CONFIRMED',
    },
    {
      title: t('Survey'),
      id: 'survey',
      hasPermission:
        hasSubscription(activeOrganization, 'feature-survey') &&
        reservation.status === 'CONFIRMED' &&
        shouldShowSurvey,
    },
    {
      title: t('Activity Log'),
      id: 'activityLog',
      hasPermission: true,
    },
    {
      title: t('Emails Sent to Guest'),
      id: 'emailLog',
      hasPermission: operationAllowed(
        activeUser,
        'write',
        'reservationConfirmation'
      ),
    },
  ];

  return (
    <V3Page>
      <ReservationDetailsHeader
        reservation={reservation}
        reservationReceipt={reservationReceipt}
        product={product}
        productInstance={productInstance}
        activeUser={activeUser}
        reservationInvoice={reservationInvoice}
      />
      <div className={baseStyles['l-main__body']}>
        <div className={baseStyles['l-main__body__flex']}>
          <div className={baseStyles['l-main__body__flex__left']}>
            <SpMenu sections={sections} />
            <ReservationContext.Provider value={reservation}>
              {loading && <Loading />}

              <MainInformation
                reservation={reservation}
                product={product}
                productInstance={productInstance}
                locale={i18n.language}
                activeUser={activeUser}
              />

              {reservation.is_dynamic_package_reservation ? (
                <DynamicPackageDetailedInformation
                  reservation={reservation}
                  product={product}
                  locale={i18n.language}
                  activeUser={activeUser}
                  readOnly={false}
                />
              ) : (
                <DetailedInformation
                  reservation={reservation}
                  product={product}
                  locale={i18n.language}
                  activeUser={activeUser}
                  readOnly={false}
                />
              )}
              <GuestInformation
                reservation={reservation}
                product={product}
                activeUser={activeUser}
                readOnly={false}
              />

              <BillingInformation
                reservation={reservation}
                activeUser={activeUser}
              />

              <RelatedInformation
                reservation={reservation}
                product={product}
                activeUser={activeUser}
                ref={relatedDivRef}
              />

              <SupplementaryInformation
                reservation={reservation}
                product={product}
                activeUser={activeUser}
                ref={supplementaryDivRef}
              />

              {hasSubscription(activeOrganization, 'feature-review') &&
                reservation.status === 'CONFIRMED' && (
                  <Review reservation={reservation} />
                )}
              {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
                reservation.status === 'CONFIRMED' && (
                  <Waiver reservation={reservation} />
                )}
              {hasSubscription(activeOrganization, 'feature-image-download') &&
                reservation.status === 'CONFIRMED' && (
                  <ReservationMediaDownloads reservation={reservation} />
                )}
              {hasSubscription(activeOrganization, 'feature-inquiry') && (
                <Inquiry reservation={reservation} />
              )}
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
                product.qr_checkin_settings?.should_use_e_ticket &&
                reservation.status === 'CONFIRMED' && (
                  <>
                    {reservation.is_dynamic_package_reservation ? (
                      <DynamicPackageCheckin reservation={reservation} />
                    ) : (
                      <>
                        {product?.qr_checkin_settings
                          ?.should_count_guests_for_checkin_with_guest_type ? (
                          <GuestTypeCountCheckin
                            reservation={reservation}
                            product={product}
                          />
                        ) : product?.qr_checkin_settings
                            ?.should_use_redemption_count ? (
                          <RedemptionCountCheckin
                            reservation={reservation}
                            product={product}
                          />
                        ) : (
                          <Checkin
                            reservation={reservation}
                            product={product}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
                product.qr_checkin_settings?.should_use_e_ticket &&
                reservation.status === 'CONFIRMED' && (
                  <Footprint reservation={reservation} />
                )}
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) && <Coupon reservation={reservation} />}
              {hasSubscription(activeOrganization, 'feature-survey') &&
                reservation.status === 'CONFIRMED' &&
                shouldShowSurvey && (
                  <ReservationSurveys reservation={reservation} />
                )}

              <ActivityLog reservation={reservation} locale={i18n.language} />

              {operationAllowed(
                activeUser,
                'write',
                'reservationConfirmation'
              ) && <EmailLog reservation={reservation} />}
            </ReservationContext.Provider>
          </div>
          <div
            className={clsx(
              baseStyles['l-main__body__flex__right'],
              baseStyles['sticky']
            )}
          >
            <PcSidebarMenu sections={sections} />
          </div>
        </div>
      </div>
    </V3Page>
  );
};
