import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { currency as currencyJs } from 'shared/libraries/currency';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import {
  loggedInAccountIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { ConfirmModal } from 'client/components/ConfirmModal/ConfirmModal';
import {
  markSystemFeeInvoicePaid,
  reReadyForConfirmationSystemFeeInvoice,
} from 'client/actions/systemFeeInvoices';
import { SystemFeeInvoice } from 'shared/models/swagger';

import { SystemFeeFareAdjustmentModal } from './SystemFeeFareAdjustmentModal/SystemFeeFareAdjustmentModal';
import { SystemFeeFareAdjustmentModalForSupplier } from './SystemFeeFareAdjustmentModal/SystemFeeFareAdjustmentModalForSupplier';
import styles from './TableSmall.module.css';
import summaryStyles from './SystemFeeInvoiceDetails.module.css';

interface Props {
  invoice: SystemFeeInvoice;
}

export const ActivityPlanSystemFeeInvoiceSummary = ({ invoice }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.loading
  );
  const isNutmegAdmin = useSelector(loggedInAccountIsNutmegAdminSelector);
  const organization = useSelector(activeUserOrganizationSelector);

  const [
    showSystemFeeFareAdjustmentModal,
    setShowSystemFeeFareAdjustmentModal,
  ] = React.useState(false);
  const [
    showSystemFeeFareAdjustmentModalForSupplier,
    setShowSystemFeeFareAdjustmentModalForSupplier,
  ] = React.useState(false);
  const [showPaymentCompleteModal, setShowPaymentCompleteModal] =
    React.useState(false);
  const [showStatusRevertModal, setShowStatusRevertModal] =
    React.useState(false);

  const showPaymentCompleteButton =
    (invoice?.amount_due_by_nutmeg_final &&
      currencyJs(invoice?.amount_due_by_nutmeg_final).value > 0) ||
    (invoice?.amount_due_by_supplier_final &&
      currencyJs(invoice?.amount_due_by_supplier_final).value > 0);

  return (
    <>
      <table className={clsx(styles['c-tableSmall'], styles['row'])}>
        <thead>
          <tr>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              <div>{t('PIF')}</div>
              <div>{t('Gross')}</div>
            </th>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              <div>{t('PIF')}</div>
              <div>{t('Cancel Fee')}</div>
            </th>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              <div>{t('PIF')}</div>
              <div>{t('Credit Card Fee')}</div>
            </th>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              <div>{t('POB')}</div>
              <div>{t('Gross')}</div>
            </th>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              <div>{t('POB')}</div>
              <div>{t('Cancel Fee')}</div>
            </th>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              {t('Adjusted Amount')}
            </th>
            <th className={summaryStyles['p-invoide-details__summary__th']}>
              {t('Billing Amount')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('PIF')} ${t('Gross')}`}
            >
              {getFormattedAmount(invoice?.pif_gross_amount)}
            </td>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('PIF')} ${t('Cancel Fee')}`}
            >
              {getFormattedAmount(invoice?.pif_cancellation_fee_amount)}
            </td>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('PIF')} ${t('Credit Card Fee')}`}
            >
              {getFormattedAmount(invoice?.pif_credit_card_fee_amount)}
            </td>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('POB')} ${t('Gross')}`}
            >
              {getFormattedAmount(invoice?.pob_gross_amount)}
            </td>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('POB')} ${t('Cancel Fee')}`}
            >
              {getFormattedAmount(invoice?.pob_cancellation_fee_amount)}
            </td>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('Adjusted Amount')}`}
            >
              <div>{getFormattedAmount(invoice?.adjusted_amount)}</div>
              {invoice?.adjusted_amount &&
                organization?.type === 'SUPPLIER' && (
                  <div
                    style={{ color: '#008ec9', cursor: 'pointer' }}
                    onClick={() =>
                      setShowSystemFeeFareAdjustmentModalForSupplier(true)
                    }
                  >
                    {t('Detail')}
                  </div>
                )}
            </td>
            <td
              className={summaryStyles['p-invoide-details__summary__td']}
              data-text={`${t('Billing Amount')}`}
            >
              {invoice?.amount_due_by_nutmeg_final ? (
                getFormattedAmount(invoice?.amount_due_by_nutmeg_final)
              ) : (
                <div className={clsx(styles['red'], styles['right-align'])}>
                  {getFormattedAmount(invoice?.amount_due_by_supplier_final)}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {isNutmegAdmin && (
        <>
          <Box mt={2} display="flex" justifyContent="flex-end">
            {invoice?.status !== 'SYSTEM_FEE_CONFIRMED' ? (
              <Button
                style="yellow"
                size="small"
                onClick={() => setShowSystemFeeFareAdjustmentModal(true)}
              >
                {t('Fare Adjustment')}
              </Button>
            ) : (
              <>
                {!invoice?.payment_has_been_processed && (
                  <Button
                    style="yellow"
                    size="small"
                    onClick={() => setShowStatusRevertModal(true)}
                    loading={loading}
                  >
                    {t('Revert Status')}
                  </Button>
                )}
              </>
            )}
            {showPaymentCompleteButton && (
              <Box ml={1}>
                <Button
                  style="yellow"
                  size="small"
                  disabled={
                    invoice?.status !== 'SYSTEM_FEE_CONFIRMED' ||
                    invoice?.payment_has_been_processed
                  }
                  onClick={() => setShowPaymentCompleteModal(true)}
                >
                  {t('Payment Complete')}
                </Button>
              </Box>
            )}
          </Box>
          {showStatusRevertModal && (
            <ConfirmModal
              header={t('Change Status')}
              content={t(
                'Are you sure you want change the invoice status back to "Confirmation Required"?'
              )}
              onConfirm={async () => {
                await dispatch(
                  reReadyForConfirmationSystemFeeInvoice(invoice?.id ?? '')
                );
              }}
              onClose={() => setShowStatusRevertModal(false)}
              open={showStatusRevertModal}
              insertRoot={true}
            />
          )}
          {showPaymentCompleteModal && (
            <ConfirmModal
              header={t('Process Payment')}
              content={t(
                'Notification email will be sent to the supplier. Are you sure you want to complete the payment process?'
              )}
              onConfirm={async () => {
                await dispatch(markSystemFeeInvoicePaid(invoice?.id ?? ''));
              }}
              onClose={() => setShowPaymentCompleteModal(false)}
              open={showPaymentCompleteModal}
              insertRoot={true}
            />
          )}
          {showSystemFeeFareAdjustmentModal && (
            <SystemFeeFareAdjustmentModal
              systemFeeInvoice={invoice}
              onClose={() => setShowSystemFeeFareAdjustmentModal(false)}
            />
          )}
        </>
      )}
      {showSystemFeeFareAdjustmentModalForSupplier && (
        <SystemFeeFareAdjustmentModalForSupplier
          systemFeeInvoice={invoice}
          onClose={() => setShowSystemFeeFareAdjustmentModalForSupplier(false)}
        />
      )}
    </>
  );
};
