import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';

import { Button } from 'client/components/v3/Common/Button';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { Box } from 'client/components/Box/Box';
import { TextField } from 'client/components/v3/Form/TextField';

import { BusRouteFormValues } from './formValues';
import styles from './EditBusRouteModal.module.css';

export const GpsDevicesEditor = () => {
  const { t } = useTranslation();
  const form = useForm<BusRouteFormValues>();
  const { values } = form.getState();

  return (
    <Box mt={2} mb={2}>
      <FieldWrapper label={t('GPS Device IMEIs')} />
      <Box display="flex" flexDirection="column" gap={2}>
        {values.gpsDeviceIMEIs.map((imei: string, index: number) => (
          <div key={index} className={styles.imeiItem}>
            <Box display="flex" gap={2} alignItems="center">
              <TextField
                value={imei}
                onChange={(value: string) => {
                  const newIMEIs = [...values.gpsDeviceIMEIs];
                  newIMEIs[index] = value;
                  form.change('gpsDeviceIMEIs', newIMEIs);
                }}
                placeholder="Enter IMEI"
              />
              <Button
                type="button"
                size="icon"
                color="tertiarygray"
                onClick={() => {
                  const newIMEIs = values.gpsDeviceIMEIs.filter(
                    (_, idx: number) => idx !== index
                  );
                  form.change('gpsDeviceIMEIs', newIMEIs);
                }}
                iconBeforeText={
                  <i className="c-icon-outline-general-trash-03" />
                }
              />
            </Box>
          </div>
        ))}
      </Box>
      <Box mt={2}>
        <Button
          type="button"
          color="secondary"
          text={t('Add GPS Device')}
          onClick={() => {
            form.change('gpsDeviceIMEIs', [...values.gpsDeviceIMEIs, '']);
          }}
          iconBeforeText={<i className="c-icon-outline-general-plus-circle" />}
        />
      </Box>
    </Box>
  );
};
