import { useTranslation } from 'react-i18next';

import { Schedule } from './Schedule';
import { EditButton } from './EditButton';
import styles from './RuleListItem.module.css';

export const NoSchedule = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['p-seat__equipment-schedule-list__rule-list-item']}>
      <Schedule startDate="" endDate="" />
      <div
        className={
          styles['p-seat__equipment-schedule-list__rule-list-item__content']
        }
      >
        <div>
          <span>{t('No participation rules have been registered.')}</span>

          <EditButton
            text={t('Create Participation Rule')}
            onClick={() => {
              console.log('text');
            }}
          />
        </div>
      </div>
    </div>
  );
};
