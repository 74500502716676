import { useSelector } from 'react-redux';

import { summariesSelector } from 'client/reducers/products';
import type { EquipmentStartTimeMapping } from 'shared/models/swagger';

import { StartTimeListItem } from './StartTimeListItem';
import styles from './StartTimeList.module.css';

interface Props {
  productId: string;
  startTimeMappings: EquipmentStartTimeMapping[];
}

export const StartTimeList = ({ productId, startTimeMappings }: Props) => {
  const productSummaries = useSelector(summariesSelector);

  const product = productSummaries.find((product) => product.id === productId);

  const mappings = startTimeMappings
    .filter((mapping) => {
      const productIds =
        mapping.product_start_times?.map(
          (productStartTime) => productStartTime.product_id
        ) ?? [];

      return productIds.includes(productId);
    })
    .map((mapping) => {
      return {
        startTime: mapping.start_time_local ?? '',
        productStartTimes: mapping.product_start_times
          ?.map((productStartTime) => {
            const recurrence = product?.recurrence?.find(
              (recurrence) => recurrence.key === productStartTime.recurrence_key
            );

            const startTime = recurrence?.start_times?.find(
              (startTime) =>
                startTime.time_slot_key === productStartTime.time_slot_key
            );

            return startTime?.start_time_local ?? '';
          })
          .sort((startTimeA, startTimeB) => {
            return startTimeA.localeCompare(startTimeB);
          }),
      };
    })
    .sort((mappingA, mappingB) => {
      return mappingA.startTime.localeCompare(mappingB.startTime);
    });

  return (
    <div className={styles['p-seat__equipment-schedule-list']}>
      {mappings.map((mapping, index) => (
        <StartTimeListItem
          key={index}
          startTime={mapping.startTime}
          productStartTimes={mapping.productStartTimes ?? []}
        />
      ))}
    </div>
  );
};
