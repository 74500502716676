import React, { CSSProperties } from 'react';

import styles from './Fab.module.css';

type FabProps = {
  icon: React.ReactNode;
  onClick: () => void;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'default';
  className?: string;
  style?: CSSProperties;
};

const Fab: React.FC<FabProps> = ({
  icon,
  onClick,
  size = 'medium',
  color = 'default',
  className,
  style,
}) => {
  return (
    <button
      className={`${styles.fab} ${styles[size]} ${styles[color]} ${
        className || ''
      }`}
      onClick={onClick}
      style={style}
    >
      {icon}
    </button>
  );
};

export default Fab;
