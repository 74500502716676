import moment from 'moment-timezone';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { ReactNode, useEffect, useMemo } from 'react';
import { setIn } from 'final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import type { Reservation, GroupBookingTemplate } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { Message } from 'client/components/Message/Message';
import { Modal } from 'client/components/v3/Form/Modal';
import { ReduxState } from 'client/reducers';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getGuestName } from 'client/libraries/util/getGuestName';

type FormValues = {
  attention?: string;
  paymentDeadlineDate?: string;
  bankTransferDetails?: string;
};

type Props = {
  title: string;
  groupBookingTemplate: GroupBookingTemplate;
  onClose: () => void;
  onIssue: (
    attention: string,
    paymentDeadlineDate: string,
    bankTransferDetails: string
  ) => Promise<void>;
  reservation?: Reservation;
};

export const InvoiceModal = ({
  title,
  groupBookingTemplate,
  onClose,
  onIssue,
  reservation,
}: Props) => {
  const { t } = useTranslation();
  const reservationInvoiceIssueStatus = useSelector(
    (state: ReduxState) => state.reservationInvoices.issueStatus
  );
  const organization = useSelector(activeUserOrganizationSelector);
  const customerName = reservation ? getGuestName(reservation) : '';
  const reservationLanguage = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
  )?.response;
  const isReservationLanguageJapanese = reservationLanguage?.startsWith('ja');

  const customerNameWithPrefixOrSuffix = isReservationLanguageJapanese
    ? `${customerName} 様`
    : t('Invoice to: {{customerName}}', { customerName: customerName });

  // Close modal when issuance is successful
  useEffect(() => {
    if (reservationInvoiceIssueStatus === 'SUCCESS') {
      onClose();
    }
  }, [reservationInvoiceIssueStatus]);

  const paymentDeadlineDate = useMemo(() => {
    if (!groupBookingTemplate || !reservation) return null;

    switch (
      groupBookingTemplate?.per_reservation_invoice_settings
        ?.payment_deadline_settings?.type
    ) {
      case 'END_OF_CURRENT_MONTH':
        return moment
          .tz(reservation.start_timezone || 'UTC')
          .endOf('month')
          .format('YYYY-MM-DD');
      case 'END_OF_NEXT_MONTH': {
        return moment
          .tz(reservation.start_timezone || 'UTC')
          .add(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      case 'DAYS_BEFORE_PARTICIPATION': {
        const participationDate = moment.tz(
          reservation.start_date_time_utc,
          reservation.start_timezone || 'UTC'
        );
        const count =
          groupBookingTemplate?.per_reservation_invoice_settings
            ?.payment_deadline_settings?.count || 7;
        return moment(participationDate)
          .subtract(count, 'days')
          .format('YYYY-MM-DD');
      }
    }
  }, [groupBookingTemplate, reservation]);

  const validateReceiptRelatedSettings = (): ReactNode[] => {
    const validationErrors: ReactNode[] = [];
    if (
      !organization?.supplier_guest_receipt_settings ||
      !organization.supplier_guest_receipt_settings.receipt_business_name
    ) {
      validationErrors.push(
        <>
          {t(
            'Please enter Receipt Business Name in the Booking Site > Settings page. '
          )}
          <Link
            to={`/bookingWidget/general`}
            className={baseStyles['base-link']}
          >
            {t('Click here to set.')}
          </Link>
        </>
      );
    }
    return validationErrors;
  };

  const receiptValidationErrors = validateReceiptRelatedSettings();
  return (
    <Form
      initialValues={{
        attention: customerNameWithPrefixOrSuffix,
        paymentDeadlineDate: paymentDeadlineDate,
        bankTransferDetails:
          groupBookingTemplate?.per_reservation_invoice_settings
            ?.bank_transfer_details || '',
      }}
      onSubmit={(values: FormValues) => {
        let errors = {};
        const setError = (key: string, value: any) => {
          errors = setIn(errors, key, value);
        };

        if (!values.attention) {
          setError('attention', t('Required'));
          return errors;
        }

        if (!values.paymentDeadlineDate) {
          setError('paymentDeadlineDate', t('Required'));
          return errors;
        }

        if (!values.bankTransferDetails) {
          setError('bankTransferDetails', t('Required'));
          return errors;
        }

        onIssue(
          values.attention,
          values.paymentDeadlineDate,
          values.bankTransferDetails
        );
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={title}
            open={true}
            onClose={() => {
              onClose();
            }}
            style={{ width: '600px' }}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  color="white"
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                />
                <Button
                  text={t('Issue receipt')}
                  type="submit"
                  disabled={receiptValidationErrors.length > 0}
                  loading={reservationInvoiceIssueStatus === 'IN_FLIGHT'}
                />
              </>
            }
          >
            <div className={styles['p-reservationNum']}>
              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('Attention')}
                </p>
                <Field name="attention">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              </div>

              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('Payment Deadline')}
                </p>
                <Field name="paymentDeadlineDate">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      type="date"
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              </div>

              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('Bank Transfer Details')}
                </p>
                <Field name="bankTransferDetails">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextArea
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              </div>
              {receiptValidationErrors.length > 0 && (
                <Message error>
                  <ol>
                    {receiptValidationErrors.map((e, idx) => (
                      <li key={idx}>{e}</li>
                    ))}
                  </ol>
                </Message>
              )}
              {/* Show error message when receipt has already been issued when user tries to issue */}
              {reservationInvoiceIssueStatus === 'FAILURE' && (
                <Message error>
                  <ol>
                    <li>{t('Invoice had already been issued.')}</li>
                  </ol>
                </Message>
              )}
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
