import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { activeUserSelector } from 'client/reducers/user';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { RestaurantOrder } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';

import styles from './OrderManagement.module.css';
import { Tabs } from './Tabs';
import { ActiveOrderContext } from './ActiveOrderContext';

type TabType = 'today' | 'past' | 'all';

type RestaurantOrderLineItem = Exclude<
  Exclude<RestaurantOrder['billing_info'], undefined>['line_items'],
  undefined
>[number];

const getItemName = (item: RestaurantOrderLineItem) => {
  const name = item.name;

  const optionNames: string[] = [];
  for (const optionGroup of item.option_groups ?? []) {
    optionNames.push(...(optionGroup.option_names ?? []));
  }

  if (optionNames.length > 0) {
    return `${name} (${optionNames.join(', ')})`;
  }
  return name;
};

const useColumns = (): ColumnType<RestaurantOrder>[] => {
  const { t } = useTranslation();

  const { setActiveOrderId } = React.useContext(ActiveOrderContext);

  return [
    {
      id: 'id',
      Header: t('Order ID'),
      accessor: (order: RestaurantOrder) => (
        <div
          className={styles.orderListItemId}
          onClick={() => setActiveOrderId(order.id ?? '')}
        >
          {order.id?.substring(0, 8)}
        </div>
      ),
    },
    {
      id: 'customer',
      Header: t('Customer'),
      accessor: (order: RestaurantOrder) =>
        `${order.given_name} ${order.family_name}`,
    },
    {
      id: 'restaurant',
      Header: t('Restaurant'),
      accessor: (order) => order.restaurant_name,
    },
    {
      id: 'items',
      Header: 'Items',
      accessor: (order) =>
        order.billing_info?.line_items?.map((item) => (
          <div
            className={styles.orderListItemColumnContent}
            key={item.name}
          >{`${item.quantity}x ${getItemName(item)}`}</div>
        )),
    },
    {
      id: 'quantity',
      Header: t('Quantity'),
      accessor: (order: RestaurantOrder) => order.items?.length,
    },
    {
      id: 'price',
      Header: 'Price',
      accessor: () => (order: RestaurantOrder) => order.billing_info?.total,
    },
    {
      id: 'notes',
      Header: 'Notes',
      accessor: () => '-',
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: () => '-',
    },
  ];
};

export const OrderList: React.FC = () => {
  const { t } = useTranslation();

  const activeUser = useSelector(activeUserSelector);
  const [activeTab, setActiveTab] = React.useState<TabType>('today');
  const orders = useSelector((state: ReduxState) => state.restaurantOrders.all);
  const columns = useColumns();

  const tabs: { id: TabType; title: string }[] = [
    {
      id: 'today',
      title: t("Today's Orders"),
    },
    {
      id: 'past',
      title: t('Past Orders'),
    },
    {
      id: 'all',
      title: t('All Orders'),
    },
  ];

  const newOrders = orders.filter((order) => order.status === 'RECEIVED');
  const preparingOrders = orders.filter(
    (order) => order.status === 'IN_PROGRESS'
  );
  const readyOrders = orders.filter((order) => order.status === 'READY');
  const completedOrders = orders.filter((order) => order.status === 'COMPLETE');

  return (
    <div className={styles.orderList}>
      <div className={styles.orderListHeader}>
        <Tabs
          tabs={tabs}
          activeTabId={activeTab}
          onChange={(tabId: TabType) => setActiveTab(tabId)}
        />
        {hasCustomUserRoleWritePermissions(
          activeUser,
          'MOBILE_ORDER.ORDERS'
        ) && (
          <button className={styles.addOrderButton}>
            <img src="add-icon.svg" alt="Add" className={styles.addOrderIcon} />
            <span className={styles.addOrderText}>
              {t('Add order manually')}
            </span>
          </button>
        )}
      </div>

      {newOrders.length > 0 && (
        <>
          <div className={styles.orderListSectionTitle}>
            {t('New Orders ({{orderCount}})', {
              orderCount: newOrders.length,
            })}
          </div>
          <PagedGenericTable allItems={newOrders} columns={columns} />
        </>
      )}

      {preparingOrders.length > 0 && (
        <>
          <div className={styles.orderListSectionTitle}>
            {t('Preparing ({{orderCount}})', {
              orderCount: preparingOrders.length,
            })}
          </div>
          <PagedGenericTable allItems={preparingOrders} columns={columns} />
        </>
      )}

      {readyOrders.length > 0 && (
        <>
          <div className={styles.orderListSectionTitle}>
            {t('Ready ({{orderCount}})', {
              orderCount: readyOrders.length,
            })}
          </div>
          <PagedGenericTable allItems={readyOrders} columns={columns} />
        </>
      )}

      {completedOrders.length > 0 && (
        <>
          <div className={styles.orderListSectionTitle}>
            {t('Completed Orders ({{orderCount}})', {
              orderCount: completedOrders.length,
            })}
          </div>
          <PagedGenericTable allItems={completedOrders} columns={columns} />
        </>
      )}
    </div>
  );
};
