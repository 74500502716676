import type { LanguageISO } from 'client/libraries/i18n';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';

export type ButtonStyle =
  | 'SQUARE_CORNERS'
  | 'ROUNDED_CORNERS_LARGE'
  | 'ROUNDED_CORNERS_SMALL'
  | 'ROUND';

export type DisplayPosition = 'LOWER_LEFT' | 'LOWER_RIGHT';

const getFloatingButtonEnv = () => {
  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost:3000/.test(window.location.origin)
  ) {
    return `\n        window.env = 'DEV';`;
  } else if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    return `\n        window.env = 'STG';`;
  }

  return ``;
};

const getBorderRadius = (buttonStyle: ButtonStyle) => {
  if (buttonStyle === 'ROUND') {
    return 'border-radius: 50%; ';
  } else if (buttonStyle === 'ROUNDED_CORNERS_LARGE') {
    return 'border-radius: 24px; ';
  } else if (buttonStyle === 'ROUNDED_CORNERS_SMALL') {
    return 'border-radius: 12px; ';
  }
  return '';
};

const getDisplayPosition = (displayPosition: DisplayPosition) => {
  if (displayPosition === 'LOWER_LEFT') {
    return 'left: 15px; ';
  }
  return 'right: 15px; ';
};

export const getFloatingButtonEmbedCode = (
  apiKey: string,
  buttonText: string,
  buttonColor: string,
  displayPosition: DisplayPosition,
  buttonTextColor: string,
  buttonStyle: ButtonStyle,
  buttonWidth: string,
  buttonHeight: string,
  language: LanguageISO
) => {
  const position = getDisplayPosition(displayPosition);
  const borderRadius = getBorderRadius(buttonStyle);
  const lang = convertToBookingWidgetUrlLangCode(language);

  return `<script type="text/javascript">
    /*<![CDATA[*/
    (function () {
        if (!window.openCalendarByModal) {
            loadScriptAsync('https://assets.ntmg.com/scripts/falcon-embed-floating-button/v1/index.js');
        }
        if (!window.NutmegCalendar) {
            loadScriptAsync('https://assets.ntmg.com/scripts/falcon-embed/v1/index.js');
        }
        function loadScriptAsync(url) {
            var script = document.createElement('script');
            script.src = url;
            script.async = true;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        }
        window.lang = '${lang}';
        window.bookingWidgetApiKey = '${apiKey}';${getFloatingButtonEnv()}
    })();
    /*]]>*/
</script>
<button id="nutmeg-floating-button" style="font-size: 18px; font-weight: bold; background: ${buttonColor}; ${position}color: ${buttonTextColor}; ${borderRadius}width: ${buttonWidth}px; height: ${buttonHeight}px; position: fixed; border: none; cursor: pointer; bottom: 40px; display: none;">${buttonText}</button>`;
};

export const getBookNowButtonEmbedCode = (
  cssStyleString: string,
  calendarUrl: string,
  calendarType: string,
  buttonText: string
) => {
  return `<script type="text/javascript">
    /*<![CDATA[*/
    (function () {
        if (!window.openCalendarModal) {
          loadScriptAsync('https://assets.ntmg.com/scripts/falcon-embed-book-now/v1/index.js');
        }
        if (!window.NutmegCalendar) {
          loadScriptAsync('https://assets.ntmg.com/scripts/falcon-embed/v1/index.js');
        }
        function loadScriptAsync(url) {
            var script = document.createElement('script');
            script.src = url;
            script.async = true;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        }
    })();
    /*]]>*/
</script>   
<div style="width: 100%; display: flex; justify-content: center"><button type="button" style="${cssStyleString}" onclick="openCalendarModal('${calendarUrl}', '${calendarType}')">${buttonText}</button></div>`;
};
