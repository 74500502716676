import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AboutUsTab } from 'client/pages/v3/BookingWidget/EssentialPages/Tab/AboutUsTab';
import { FAQTab } from 'client/pages/v3/BookingWidget/EssentialPages/Tab/FAQTab';
import { Covid19Tab } from 'client/pages/v3/BookingWidget/EssentialPages/Tab/Covid19Tab';
import { CustomPagesTab } from 'client/pages/v3/BookingWidget/EssentialPages/Tab/CustomPagesTab';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';

type TabType = 'ABOUT_US' | 'FAQ' | 'COVID19' | 'CUSTOM';

export const EssentialPagesBody = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState<TabType>('ABOUT_US');

  return (
    <div className={baseStyles['l-main__body']}>
      <section className={styles['g-section']}>
        <div
          className={clsx(styles['p-inquiry-tabs'], styles['js-tab-scope'])}
          style={{ marginBottom: '70px' }}
        >
          <div className={styles['p-inquiry-tabs__head']}>
            <div className={styles['p-inquiry-tabs__tab-scroll']}>
              <ul className={styles['p-inquiry-tabs__tab-list']}>
                <li
                  className={clsx(
                    styles['p-inquiry-tabs__tab'],
                    activeTab === 'ABOUT_US'
                      ? styles['components_is-active__B15DT']
                      : ''
                  )}
                  style={{ display: 'block' }}
                  onClick={() => {
                    setActiveTab('ABOUT_US');
                  }}
                >
                  <a>{t('About Us')}</a>
                </li>
                <li
                  className={clsx(
                    styles['p-inquiry-tabs__tab'],
                    activeTab === 'FAQ'
                      ? styles['components_is-active__B15DT']
                      : ''
                  )}
                  style={{ display: 'block' }}
                  onClick={() => {
                    setActiveTab('FAQ');
                  }}
                >
                  <a>{t('FAQ')}</a>
                </li>
                <li
                  className={clsx(
                    styles['p-inquiry-tabs__tab'],
                    activeTab === 'COVID19'
                      ? styles['components_is-active__B15DT']
                      : ''
                  )}
                  style={{ display: 'block' }}
                  onClick={() => {
                    setActiveTab('COVID19');
                  }}
                >
                  <a>{t('COVID-19 Guidelines')}</a>
                </li>
                <li
                  className={clsx(
                    styles['p-inquiry-tabs__tab'],
                    activeTab === 'CUSTOM'
                      ? styles['components_is-active__B15DT']
                      : ''
                  )}
                  onClick={() => {
                    setActiveTab('CUSTOM');
                  }}
                >
                  <a>{t('Custom Page')}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles['p-inquiry-tabs__body']}>
            {activeTab === 'ABOUT_US' && <AboutUsTab />}
            {activeTab === 'FAQ' && <FAQTab />}
            {activeTab === 'COVID19' && <Covid19Tab />}
            {activeTab === 'CUSTOM' && <CustomPagesTab />}
          </div>
        </div>
      </section>
    </div>
  );
};
