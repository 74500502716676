import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Product } from 'shared/models/swagger';

import { HeaderButtons } from './HeaderButtons';

type Props = {
  product: Product;
};

export const ETicketEditHeader = ({ product }: Props) => {
  const { t } = useTranslation();

  if (!product) {
    return null;
  }

  const paths = [
    { text: t('Product List'), url: '/products-v3' },
    {
      text: product.internal_product_name ?? '',
      url: `/products-v3/${product.id}`,
    },
    { text: t('ETicket Edit'), url: '' },
  ];

  return (
    <PageHeader
      title={t('ETicket Edit')}
      breadcrumbPaths={paths}
      hideNewUIToggle={true}
    >
      <HeaderButtons product={product} />
    </PageHeader>
  );
};
