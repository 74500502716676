import { useTranslation } from 'react-i18next';

import { config } from 'client/config';

import styles from '../../ETicketEdit.module.css';
import { FieldResponsesForm } from '../../Components/FieldResponsesForm/FieldResponsesForm';

import { RedemptionButton } from './RedemptionButton';
import { GuidanceButton } from './GuidanceButton';

export const CustomEditor = () => {
  const { t } = useTranslation();
  return (
    <ul className={styles['p-list']}>
      {config.enableETicketFieldResponse && (
        <>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                <div>{t('Participation Details')}</div>
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <FieldResponsesForm
                name="bookingFieldResponseItems"
                formFieldType="PER_BOOKING"
                includeAddOns={true}
                excludeHotelInfomationCheckinCheckout={true}
                addItemText={t('Add Per Booking Reservation Form to Display')}
              />
            </div>
          </li>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                <div>{t('Guest Information')}</div>
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <FieldResponsesForm
                name="participantFieldResponseItems"
                formFieldType="PER_PARTICIPANT"
                addItemText={t(
                  'Add Per Participant Reservation Form to Display'
                )}
              />
            </div>
          </li>
        </>
      )}
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Redeem Button')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <RedemptionButton />
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Digital Guidance')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <GuidanceButton />
        </div>
      </li>
    </ul>
  );
};

// Guest Information
