import clsx from 'clsx';
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchCustomPages } from 'client/actions/customPage';
import { fetchProducts } from 'client/actions/products';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { getArrayMutators } from 'client/libraries/util/form';
import { PcSidebarMenu } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/Menu/PcSidebarMenu';
import { CollapsibleSection } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/CollapsibleSection/CollapsibleSection';
import { FooterEditor } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/FooterEditor';
import { PopUpMessageEditor } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/PopUpMessageEditor';
import {
  convertFormValuesToOrganizationPatch,
  getInitialValues,
} from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';
import { getBookingWebsiteUrlWithParams } from 'client/libraries/util/getBookingWebsiteUrl';
import { Section } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/Menu/menuType';
import { Button } from 'client/components/v3/Common/Button';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';
import type { CustomizeFormValues } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';

const focusOnError: any = createDecorator();
export const BookingWidgetCustomizeSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchCustomPages());
  }, [activeUserOrganization]);

  const [currentSectionId, setCurrentSectionId] = useState('basic');
  const [saveSucceeded, setSaveSucceeded] = useState<boolean>(false);

  const sections: Section[] = [
    {
      title: t('Theme'),
      id: 'theme',
      hasPermission: true,
    },
    {
      title: t('Footer'),
      id: 'footer',
      hasPermission: true,
    },
    {
      title: t('Contents'),
      id: 'contents',
      hasPermission: true,
    },
  ];
  const themeOptions = [
    {
      value: 'DEFAULT',
      label: t('Default'),
    },
    {
      value: 'PACIFIC',
      label: t('Fun'),
    },
    {
      value: 'VIBRANT',
      label: t('Vibrant'),
    },
    {
      value: 'COOL_PROFESSIONAL',
      label: t('Cool & Professional'),
    },
    {
      value: 'FAMILY',
      label: t('Family'),
    },
  ];

  const initialValues = getInitialValues(activeUserOrganization);
  return (
    <div className={baseStyles['l-main__body']}>
      <div className={baseStyles['l-main__body__flex']}>
        <div className={baseStyles['l-main__body__flex__left']}>
          <Form
            onSubmit={async (values: CustomizeFormValues) => {
              const orgId = activeUserOrganization?.id || '';

              try {
                await dispatch(
                  updateOrganization(
                    orgId,
                    'SUPPLIER',
                    convertFormValuesToOrganizationPatch(
                      activeUserOrganization,
                      values
                    )
                  )
                );
                scrollTo(0, 0);
                setSaveSucceeded(true);
              } catch (err) {
                return {
                  [FORM_ERROR]: t('Save Failed'),
                };
              }
            }}
            decorators={[focusOnError]}
            initialValues={initialValues}
            mutators={getArrayMutators()}
            keepDirtyOnReinitialize
            //debug={console.log}
          >
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                {saveSucceeded && (
                  <Snackbar
                    text={t('Save Successful')}
                    color="success"
                    shouldShow={saveSucceeded}
                    onClose={() => setSaveSucceeded(false)}
                  />
                )}
                <section className={styles['g-section']}>
                  <CollapsibleSection
                    id="theme"
                    title={t('Theme')}
                    setCurrentSectionId={setCurrentSectionId}
                  >
                    <ul className={styles['p-bookingWidgetCustomize-list']}>
                      <li
                        className={
                          styles['p-bookingWidgetCustomize-list__item']
                        }
                      >
                        <div
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__body__item'
                            ]
                          }
                        >
                          <div style={{ fontWeight: 'var(--text-semibold)' }}>
                            {t('Theme')}
                          </div>
                          <EnumRadioButtonGroup
                            name="theme"
                            options={themeOptions}
                          />
                        </div>
                        <div
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__body__item'
                            ]
                          }
                        >
                          <Field name="useThemeDefaultColors" type="checkbox">
                            {({ input }) => (
                              <Toggle
                                label={t('Use default colors for theme')}
                                checked={input.checked ?? false}
                                onChange={input.onChange}
                              />
                            )}
                          </Field>
                        </div>
                        {!values?.useThemeDefaultColors && (
                          <>
                            <div
                              className={
                                styles[
                                  'p-bookingWidgetCustomize-products__section__body__item'
                                ]
                              }
                            >
                              <div
                                className={
                                  styles['p-bookingWidgetCustomize-box']
                                }
                              >
                                <dl
                                  className={
                                    styles[
                                      'p-bookingWidgetCustomize-products__section__list'
                                    ]
                                  }
                                >
                                  <dt
                                    className={
                                      styles[
                                        'p-bookingWidgetCustomize-products__section__list__term'
                                      ]
                                    }
                                  >
                                    <span>{t('Customize')}</span>
                                  </dt>
                                  <dd
                                    className={
                                      styles[
                                        'p-bookingWidgetCustomize-products__section__list__desc'
                                      ]
                                    }
                                  >
                                    <ul
                                      className={
                                        styles[
                                          'p-bookingWidgetCustomize-products-list'
                                        ]
                                      }
                                    >
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>{t('Primary Color')}</span>
                                        <Field name="primaryColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>{t('Secondary Color')}</span>
                                        <Field name="secondaryColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>{t('Background Color')}</span>
                                        <Field name="backgroundColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>{t('Key Texts Color')}</span>
                                        <Field name="subheaderColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>
                                          {t('Header Background Color')}
                                        </span>
                                        <Field name="headerBackgroundColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>
                                          {t('Footer Background Color')}
                                        </span>
                                        <Field name="footerBackgroundColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                      <li
                                        className={
                                          styles[
                                            'p-bookingWidgetCustomize-products-list__item'
                                          ]
                                        }
                                      >
                                        <span>
                                          {t(
                                            'Content Section Background Color'
                                          )}
                                        </span>
                                        <Field name="contentSectionBackgroundColor">
                                          {({ input }) => (
                                            <ColorPicker
                                              placement="bottomRight"
                                              color={input.value}
                                              onChange={({ color }: any) =>
                                                input.onChange(color)
                                              }
                                              className={
                                                styles['color_section']
                                              }
                                            >
                                              <a
                                                className={clsx(
                                                  'react-colorpicker-trigger',
                                                  styles[
                                                    'p-bookingWidgetCustomize-products-list__color'
                                                  ]
                                                )}
                                              >
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-bookingWidgetCustomize-products-list__color-box'
                                                    ]
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      input.value,
                                                  }}
                                                ></div>
                                                <span>
                                                  <i></i>
                                                  {t('Change')}
                                                </span>
                                              </a>
                                            </ColorPicker>
                                          )}
                                        </Field>
                                      </li>
                                    </ul>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </>
                        )}
                        <div
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__body__item'
                            ]
                          }
                        >
                          <a
                            href={getBookingWebsiteUrlWithParams(
                              activeUserOrganization,
                              {
                                bg: values?.backgroundColor,
                                sh: values?.subheaderColor,
                                thm: values?.theme,
                                primary: values?.primaryColor,
                                secondary: values?.secondaryColor,
                                hbg: values?.headerBackgroundColor,
                                fbg: values?.footerBackgroundColor,
                                cbg: values?.contentSectionBackgroundColor,
                                defCol: values?.useThemeDefaultColors
                                  ? 'true'
                                  : undefined,
                              }
                            )}
                            target="_blank"
                            rel="noreferrer"
                            style={{ display: 'inline-block', width: 'auto' }}
                          >
                            <Button
                              text={t('Preview Website')}
                              color="white"
                              size="md"
                              loading={submitting}
                              style={{
                                fontWeight: 'var(--text-semibold)',
                              }}
                              iconBeforeText={
                                <i className="c-icon-outline-general-link-external-02"></i>
                              }
                            />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </CollapsibleSection>
                  <CollapsibleSection
                    id="footer"
                    title={t('Footer')}
                    setCurrentSectionId={setCurrentSectionId}
                  >
                    <FooterEditor />
                  </CollapsibleSection>
                  <CollapsibleSection
                    id="contents"
                    title={t('Contents')}
                    setCurrentSectionId={setCurrentSectionId}
                  >
                    <PopUpMessageEditor />
                  </CollapsibleSection>
                </section>
                <div
                  className={clsx(
                    styles['p-bookingWidgetCustomize-products__fixed'],
                    styles['is-active']
                  )}
                >
                  <div
                    className={
                      styles['p-bookingWidgetCustomize-products__fixed__main']
                    }
                  >
                    <Button
                      text={t('Save')}
                      type="submit"
                      color="primary"
                      size="md"
                      loading={submitting}
                      style={{
                        width: '60px',
                        fontWeight: 'var(--text-semibold)',
                      }}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
        <div
          className={clsx(
            baseStyles['l-main__body__flex__right'],
            baseStyles['sticky']
          )}
        >
          <PcSidebarMenu
            sections={sections}
            currentSectionId={currentSectionId}
          />
        </div>
      </div>
    </div>
  );
};
