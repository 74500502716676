import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Button } from 'client/components/v3/Common/Button';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateCovid19Settings } from 'client/actions/essentialPages';
import type { ReduxState } from 'client/reducers';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import type {
  SourceLanguage,
  Covid19Settings,
  Translation,
} from 'shared/models/swagger';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';
import baseStyles from 'client/v3-base.module.css';

type TranslationsFormValues = {
  translations: Translation[];
};

const emptyTranslation = {
  en_us: '',
};

type TransTargetLanguage = 'en_us' | 'ja_jp' | 'ko_kr' | 'zh_cn' | 'zh_tw';

const defaultPrecautions = [
  'Guides & drivers wear masks',
  'Staff required to wash hands regularly',
  'Staff required to check temperature regularly',
  'Regularly sanitize high-traffic area',
  'Regularly sanitize equipments, vehicles',
  'Hand sanitizer available to travelers and staff',
  'Reduced group sizes',
  'Keep social distance throughout the activity',
];
const defaultToGuests = [
  'Guests are required to check temperature upon arrival',
  'Guests are required to bring and wear masks',
  'Hand sanitizer available to travelers',
];

export const Covid19Tab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translation, setTranslation] = React.useState<Translation[]>([
    emptyTranslation,
  ]);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const initialValuesWithTranslations = React.useMemo(
    () => ({
      ...activeUserOrganization?.essential_pages?.covid19_settings,
    }),
    [apiTranslations, activeUserOrganization]
  );

  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== defaultLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([defaultLanguage, ...translationLanguages])].length > 1;

  React.useEffect(() => {
    if (apiTranslations?.length) {
      setTranslation(apiTranslations);
    }
  }, [apiTranslations, activeUserOrganization]);

  React.useEffect(() => {
    if (showTranslationMode) {
      const covidSettings =
        activeUserOrganization?.essential_pages?.covid19_settings;

      if (covidSettings) {
        const texts = [];

        if (covidSettings.introduction) {
          texts.push(covidSettings.introduction);
        }
        if (covidSettings.other_message) {
          texts.push(covidSettings.other_message);
        }

        if (covidSettings.custom_precautions?.length) {
          (covidSettings.custom_precautions || []).forEach((c) => {
            if (c) {
              texts.push(c);
            }
          });
        }

        if (covidSettings.custom_to_guests?.length) {
          (covidSettings.custom_to_guests || []).forEach((c) => {
            if (c) {
              texts.push(c);
            }
          });
        }

        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeUserOrganization]);

  const addNewTrans = (
    target: Translation | undefined,
    defaultLanguage: SourceLanguage,
    language: TransTargetLanguage,
    previousValue: string,
    value: string
  ) => {
    if (!target) {
      setTranslation([
        ...translation,
        {
          source_language: defaultLanguage,
          [language]: value,
          [defaultLanguage.toLowerCase()]: previousValue,
        },
      ]);
    } else {
      const newTrans = {
        ...target,
      } as Translation;
      newTrans[language as keyof Translation] = value as any;
      setTranslation(
        translation.map((t2) => {
          if (
            t2[defaultLanguage.toLowerCase() as keyof Translation] ==
            previousValue
          ) {
            return newTrans;
          }
          return t2;
        })
      );
    }
  };

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  return (
    <Form
      onSubmit={async (values: Covid19Settings & TranslationsFormValues) => {
        const newCovid19Settings = {
          visibility: values.visibility,
          introduction: values.introduction,
          precautions: defaultPrecautions.filter((precaution) =>
            (values.precautions || []).includes(precaution)
          ),
          custom_precautions: values.custom_precautions,
          to_guests: defaultToGuests.filter((toGuest) =>
            (values.to_guests || []).includes(toGuest)
          ),
          custom_to_guests: values.custom_to_guests,
          other_message: values.other_message,
        };

        try {
          await dispatch(updateCovid19Settings(newCovid19Settings));
          if (showTranslationMode) {
            await dispatch(updateTranslations(translation));
          }
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={initialValuesWithTranslations}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div
            className={clsx(
              styles['p-inquiry-tabs__tab-panel'],
              styles['components_is-active__B15DT']
            )}
            style={{ display: 'block' }}
          >
            {submitSucceeded && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'success'}
                text={t('Save Successful')}
                shouldShow={submitSucceeded}
              />
            )}
            {submitError && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'error'}
                text={t('Save Failed')}
                shouldShow={submitError}
              />
            )}
            <div className={styles['p-inquiry-tabs__content']}>
              <ul className={styles['p-inquiry-list']}>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Visibility')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="visibility" type="checkbox">
                        {({ input }) => (
                          <div>
                            <Toggle
                              label={t(
                                'Show COVID-19 Safety Measures page on booking website'
                              )}
                              {...input}
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </li>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Introduction')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="introduction">
                        {({ input, meta: { touched, error } }) => {
                          const target = translation.find((tra) => {
                            return (
                              tra[
                                defaultLanguage.toLowerCase() as keyof Translation
                              ] == values.introduction
                            );
                          });
                          return (
                            <TextArea
                              value={input.value}
                              error={touched && error}
                              onChange={(_, { value }) => {
                                if (showTranslationMode) {
                                  addNewTrans(
                                    target,
                                    defaultLanguage,
                                    defaultLanguage.toLowerCase() as TransTargetLanguage,
                                    values.introduction || '',
                                    value
                                  );
                                }
                                input.onChange(value);
                              }}
                            />
                          );
                        }}
                      </Field>
                      {showTranslationMode && (
                        <div
                          className={
                            styles['p-topPage-products__section__body__item']
                          }
                        >
                          <div className={styles['p-topPage-box']}>
                            <div className={styles['p-topPage-box__list']}>
                              {translationLanguages.map((lang, langIdx) => {
                                const target = translation.find((tra) => {
                                  return (
                                    tra[
                                      defaultLanguage.toLowerCase() as keyof Translation
                                    ] == values.introduction
                                  );
                                });
                                return (
                                  <div
                                    key={langIdx}
                                    className={
                                      styles['p-topPage-box__list__item']
                                    }
                                  >
                                    <TextArea
                                      label={getLanguageName(
                                        uppercaseIsoToLowercaseIso[lang],
                                        t
                                      )}
                                      value={
                                        target?.[
                                          lang.toLowerCase() as keyof Translation
                                        ] || ''
                                      }
                                      onChange={(_, { value }) => {
                                        if (showTranslationMode) {
                                          addNewTrans(
                                            target,
                                            defaultLanguage,
                                            lang.toLowerCase() as TransTargetLanguage,
                                            values.introduction || '',
                                            value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Precautions')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      {defaultPrecautions.map((precaution, idx) => {
                        return (
                          <Field
                            key={idx}
                            name="precautions"
                            type="checkbox"
                            value={t(precaution, {
                              lng: defaultLanguage,
                            })}
                          >
                            {({ input }) => (
                              <div key={idx} style={{ marginBottom: '7px' }}>
                                <Toggle label={t(precaution)} {...input} />
                              </div>
                            )}
                          </Field>
                        );
                      })}
                    </div>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <FieldArray name="custom_precautions">
                        {({ fields }) => {
                          const count = fields.length ?? 0;
                          return (
                            <>
                              {fields.map((name, idx) => (
                                <>
                                  <Field key={name} name={name}>
                                    {({ input }) => {
                                      const target = translation.find((tra) => {
                                        return (
                                          tra[
                                            defaultLanguage.toLowerCase() as keyof Translation
                                          ] == values.custom_precautions?.[idx]
                                        );
                                      });
                                      return (
                                        <div
                                          className={styles['company__item']}
                                          style={{ display: 'flex' }}
                                          key={idx}
                                        >
                                          <TextField
                                            {...input}
                                            onChange={(value) => {
                                              if (showTranslationMode) {
                                                addNewTrans(
                                                  target,
                                                  defaultLanguage,
                                                  defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                  values.custom_precautions?.[
                                                    idx
                                                  ] || '',
                                                  value
                                                );
                                              }
                                              input.onChange(value);
                                            }}
                                          />
                                          <div
                                            className={baseStyles['base-flex']}
                                          >
                                            <Button
                                              size="icon"
                                              color="tertiarygray"
                                              iconBeforeText={
                                                <i className="c-icon-outline-general-trash-03"></i>
                                              }
                                              onClick={() => fields.remove(idx)}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Field>
                                  {showTranslationMode && (
                                    <div
                                      className={
                                        styles[
                                          'p-topPage-products__section__body__item'
                                        ]
                                      }
                                    >
                                      <div className={styles['p-topPage-box']}>
                                        <div
                                          className={
                                            styles['p-topPage-box__list']
                                          }
                                        >
                                          {translationLanguages.map(
                                            (lang, langIdx) => {
                                              const target = translation.find(
                                                (tra) => {
                                                  return (
                                                    tra[
                                                      defaultLanguage.toLowerCase() as keyof Translation
                                                    ] ==
                                                    values.custom_precautions?.[
                                                      idx
                                                    ]
                                                  );
                                                }
                                              );
                                              return (
                                                <div
                                                  key={langIdx}
                                                  className={
                                                    styles[
                                                      'p-topPage-box__list__item'
                                                    ]
                                                  }
                                                >
                                                  <TextField
                                                    label={getLanguageName(
                                                      uppercaseIsoToLowercaseIso[
                                                        lang
                                                      ],
                                                      t
                                                    )}
                                                    value={
                                                      target?.[
                                                        lang.toLowerCase() as keyof Translation
                                                      ] || ''
                                                    }
                                                    onChange={(value) => {
                                                      if (showTranslationMode) {
                                                        addNewTrans(
                                                          target,
                                                          defaultLanguage,
                                                          lang.toLowerCase() as TransTargetLanguage,
                                                          values
                                                            .custom_precautions?.[
                                                            idx
                                                          ] || '',
                                                          value
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                              <a
                                className={styles['add__button']}
                                onClick={() => {
                                  if (count === 0) {
                                    (fields as any).insertAt(0, '');
                                  } else {
                                    (fields as any).insertAt(count + 1, '');
                                  }
                                }}
                              >
                                <i className="c-icon-outline-general-plus-circle"></i>
                                {t('Add')}
                              </a>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </li>

                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('To Guests')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      {defaultToGuests.map((toGuest, idx) => {
                        return (
                          <Field
                            key={idx}
                            name="to_guests"
                            value={t(toGuest, {
                              lng: defaultLanguage,
                            })}
                          >
                            {({ input }) => (
                              <div key={idx} style={{ marginBottom: '7px' }}>
                                <Toggle label={t(toGuest)} {...input} />
                              </div>
                            )}
                          </Field>
                        );
                      })}
                    </div>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <FieldArray name="custom_to_guests">
                        {({ fields }) => {
                          const count = fields.length ?? 0;
                          return (
                            <>
                              {fields.map((name, idx) => (
                                <>
                                  <Field key={name} name={name}>
                                    {({ input }) => {
                                      const target = translation.find((tra) => {
                                        return (
                                          tra[
                                            defaultLanguage.toLowerCase() as keyof Translation
                                          ] == values.custom_to_guests?.[idx]
                                        );
                                      });
                                      return (
                                        <div
                                          className={styles['company__item']}
                                          style={{ display: 'flex' }}
                                          key={idx}
                                        >
                                          <TextField
                                            {...input}
                                            onChange={(value) => {
                                              if (showTranslationMode) {
                                                addNewTrans(
                                                  target,
                                                  defaultLanguage,
                                                  defaultLanguage.toLowerCase() as TransTargetLanguage,
                                                  values.custom_to_guests?.[
                                                    idx
                                                  ] || '',
                                                  value
                                                );
                                              }
                                              input.onChange(value);
                                            }}
                                          />
                                          <div
                                            className={baseStyles['base-flex']}
                                          >
                                            <Button
                                              size="icon"
                                              color="tertiarygray"
                                              iconBeforeText={
                                                <i className="c-icon-outline-general-trash-03"></i>
                                              }
                                              onClick={() => fields.remove(idx)}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Field>
                                  {showTranslationMode && (
                                    <div
                                      className={
                                        styles[
                                          'p-topPage-products__section__body__item'
                                        ]
                                      }
                                    >
                                      <div className={styles['p-topPage-box']}>
                                        <div
                                          className={
                                            styles['p-topPage-box__list']
                                          }
                                        >
                                          {translationLanguages.map(
                                            (lang, langIdx) => {
                                              const target = translation.find(
                                                (tra) => {
                                                  return (
                                                    tra[
                                                      defaultLanguage.toLowerCase() as keyof Translation
                                                    ] ==
                                                    values.custom_to_guests?.[
                                                      idx
                                                    ]
                                                  );
                                                }
                                              );
                                              return (
                                                <div
                                                  key={langIdx}
                                                  className={
                                                    styles[
                                                      'p-topPage-box__list__item'
                                                    ]
                                                  }
                                                >
                                                  <TextField
                                                    label={getLanguageName(
                                                      uppercaseIsoToLowercaseIso[
                                                        lang
                                                      ],
                                                      t
                                                    )}
                                                    value={
                                                      target?.[
                                                        lang.toLowerCase() as keyof Translation
                                                      ] || ''
                                                    }
                                                    onChange={(value) => {
                                                      if (showTranslationMode) {
                                                        addNewTrans(
                                                          target,
                                                          defaultLanguage,
                                                          lang.toLowerCase() as TransTargetLanguage,
                                                          values
                                                            .custom_to_guests?.[
                                                            idx
                                                          ] || '',
                                                          value
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                              <a
                                className={styles['add__button']}
                                onClick={() => {
                                  if (count === 0) {
                                    (fields as any).insertAt(0, '');
                                  } else {
                                    (fields as any).insertAt(count + 1, '');
                                  }
                                }}
                              >
                                <i className="c-icon-outline-general-plus-circle"></i>
                                {t('Add')}
                              </a>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </li>

                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Other Message')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="other_message">
                        {({ input }) => {
                          const target = translation.find((tra) => {
                            return (
                              tra[
                                defaultLanguage.toLowerCase() as keyof Translation
                              ] == values.other_message
                            );
                          });
                          return (
                            <TextArea
                              style={{ height: '150px' }}
                              {...(input as any)}
                              onChange={(_, { value }) => {
                                if (showTranslationMode) {
                                  addNewTrans(
                                    target,
                                    defaultLanguage,
                                    defaultLanguage.toLowerCase() as TransTargetLanguage,
                                    values.other_message || '',
                                    value
                                  );
                                }
                                input.onChange(value);
                              }}
                            />
                          );
                        }}
                      </Field>
                      {showTranslationMode && (
                        <div
                          className={
                            styles['p-topPage-products__section__body__item']
                          }
                        >
                          <div className={styles['p-topPage-box']}>
                            <div className={styles['p-topPage-box__list']}>
                              {translationLanguages.map((lang, langIdx) => {
                                const target = translation.find((tra) => {
                                  return (
                                    tra[
                                      defaultLanguage.toLowerCase() as keyof Translation
                                    ] == values.other_message
                                  );
                                });
                                return (
                                  <div
                                    key={langIdx}
                                    className={
                                      styles['p-topPage-box__list__item']
                                    }
                                  >
                                    <TextArea
                                      label={getLanguageName(
                                        uppercaseIsoToLowercaseIso[lang],
                                        t
                                      )}
                                      value={
                                        target?.[
                                          lang.toLowerCase() as keyof Translation
                                        ] || ''
                                      }
                                      onChange={(_, { value }) => {
                                        if (showTranslationMode) {
                                          addNewTrans(
                                            target,
                                            defaultLanguage,
                                            lang.toLowerCase() as TransTargetLanguage,
                                            values.other_message || '',
                                            value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles['p-products__fixed'], styles['is-active'])}
          >
            <div className={styles['p-products__fixed__actions']}>
              {shouldShowMultilingualSettings && (
                <Toggle
                  label={t('Translation')}
                  checked={showTranslationMode}
                  onChange={() => setShowTranslationMode(!showTranslationMode)}
                />
              )}
              <Button
                disabled={submitting}
                loading={submitting}
                text={t('Save')}
                type="submit"
                color="primary"
                size="md"
                style={{
                  width: '60px',
                  fontWeight: 'var(--text-semibold)',
                }}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
