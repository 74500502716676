import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import ColorPicker from 'rc-color-picker';

import { updateBusRoute, createBusRoute } from 'client/actions/busRoutes';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { TextField } from 'client/components/v3/Form/TextField';
import { BusRoute } from 'shared/models/swagger';
import { getValidators } from 'shared/libraries/validate/validator';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';

import {
  BusRouteFormValues,
  convertFormValuesToSwaggerBusRoute,
  convertSwaggerBusRouteToFormValues,
  getInitialBusRouteFormValues,
} from './formValues';
import { GpsDevicesEditor } from './GpsDevicesEditor';
import styles from './EditBusRouteModal.module.css';

interface Props {
  onClose: () => void;
  existingBusRoute: BusRoute | null;
}

export const EditBusRouteModal = ({ existingBusRoute, onClose }: Props) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const dispatch = useDispatch();

  const initialBusRouteFormValues = existingBusRoute
    ? convertSwaggerBusRouteToFormValues(existingBusRoute)
    : getInitialBusRouteFormValues();

  return (
    <Modal
      open={true}
      title={existingBusRoute ? t('Edit Bus Route') : t('Create Bus Route')}
      insertAtRoot
      onClose={() => {
        // Set as empty function to disable close when clicking outside of modal
      }}
      rightActionChildren={
        <Box display="flex" gap={2}>
          <Button
            color="gray"
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
          />
          <Button form="edit-bus-route-form" text={t('Save')} type="submit" />
        </Box>
      }
      allowContentOverflow
    >
      <Form<BusRouteFormValues>
        onSubmit={(values) => {
          const busRoute = convertFormValuesToSwaggerBusRoute(values);
          if (existingBusRoute) {
            busRoute.id = existingBusRoute.id;
            dispatch(updateBusRoute(busRoute.id ?? '', busRoute));
          } else {
            dispatch(createBusRoute(busRoute));
          }
          onClose();
        }}
        initialValues={initialBusRouteFormValues}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} id="edit-bus-route-form">
            <div className={styles['input-container']}>
              <Field name="name" validate={required}>
                {({ input, meta: { error, touched } }) => (
                  <TextField
                    label={t('Name')}
                    value={input.value}
                    onChange={input.onChange}
                    error={touched && error}
                  />
                )}
              </Field>
            </div>
            <div className={styles['input-container']}>
              <Field name="color">
                {({ input }) => (
                  <FieldWrapper label={t('Color')}>
                    <ColorPicker
                      color={input.value}
                      onChange={({ color }: any) => input.onChange(color)}
                      onClick={(e: any) => e.stopPropagation()}
                    />
                  </FieldWrapper>
                )}
              </Field>
            </div>
            <div className={styles['input-container']}>
              <Field name="path" validate={required}>
                {({ input, meta: { error, touched } }) => (
                  <TextArea
                    label={t('Path')}
                    placeholder={
                      '-122.41888,37.7899\n-122.41891,37.79001\n-122.41893,37.79011\n...'
                    }
                    value={input.value}
                    onChange={input.onChange}
                    error={touched && error}
                    height={120}
                  />
                )}
              </Field>
            </div>
            <div className={styles['input-container']}>
              <FieldWrapper label={t('Bus Tracker Type')} />
              <EnumRadioButtonGroup
                name="busTrackerType"
                options={[
                  { label: t('None'), value: 'NONE' },
                  { label: t('Simulated'), value: 'SIMULATED' },
                  { label: t('GPS Device (IMEI)'), value: 'IMEI' },
                ]}
              />
            </div>
            {values.busTrackerType === 'SIMULATED' && (
              <div className={styles['input-container']}>
                <Field name="simulatedBusCount">
                  {({ input }) => (
                    <SingleDropdown
                      label={t('Number of Simulated Buses')}
                      options={[1, 2, 3, 4, 5].map((num) => ({
                        value: num.toString(),
                        text: num.toString(),
                      }))}
                      selectedOption={input.value.toString()}
                      onChange={(value) => input.onChange(parseInt(value, 10))}
                    />
                  )}
                </Field>
              </div>
            )}
            {values.busTrackerType === 'IMEI' && (
              <div className={styles['input-container']}>
                <GpsDevicesEditor />
              </div>
            )}
          </form>
        )}
      </Form>
    </Modal>
  );
};
