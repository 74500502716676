// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, useForm, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';

import { config } from 'client/config';
import {
  defaultProductCurrencySelector,
  defaultProductLanguageSelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import {
  createProduct,
  fetchPassthroughCandidateProducts,
  fetchProducts,
  updateProduct,
} from 'client/actions/products';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { Button, Input, Select, TextArea } from 'client/components/Form';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { PerParticipantPriceInput } from 'client/components/NewProductEditor/PerParticipantPriceInput/PerParticipantPriceInput';
import { PerBookingPriceInput } from 'client/components/NewProductEditor/PerBookingPriceInput/PerBookingPriceInput';
import { PerGroupPriceInput } from 'client/components/NewProductEditor/PerGroupPriceInput/PerGroupPriceInput';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { ProductTutorialHelpPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductTutorialHelpPopUp';
import { ProductEditBasicPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductEditBasicPopUp';
import { getArrayMutators } from 'client/libraries/util/form';
import { getValidators } from 'shared/libraries/validate/validator';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';
import type {
  Pricing,
  UnitPricing,
  Recurrence,
  StartTime,
} from 'shared/models/swagger';

import { convertMediaUrlsToProductMediaItems } from './util';

const removeUnitPackageMappings = (units: UnitPricing[]) => {
  for (const unit of units) {
    if (unit.guest_type != null) {
      unit.guest_type.package_component_guest_type_mappings = [];
    }
  }
};

const removeStartTimePackageMappings = (startTimes: StartTime[]) => {
  for (const startTime of startTimes) {
    startTime.package_component_time_slots = [];
  }
};

type PerBookingUnit = {
  unit: string,
  title: string,
  ageBandMinimum: number,
  ageBandMaximum: number,
};

type Price = {
  method: 'PER_PARTICIPANT' | 'PER_BOOKING',
  unit: string,
  ageBandMinimum: number,
  ageBandMaximum: number,
  price: string,
  perBookingUnits?: PerBookingUnit[],
};

type PerGroupPrice = {
  method: 'PER_GROUP',
  title: string,
  groupUnits: string[],
  price: string,
};

type BasicFormValues = {
  priceMethod: 'PER_PARTICIPANT' | 'PER_BOOKING',
  productCode: string,
  productName: string,
  internalProductName: string,
  productDescription: string,
  mediaUrls: string[],
  prices: Price[],
  perGroupPrices: PerGroupPrice[],
  sourceLanguage: 'JA_JP' | 'EN_US',
  passthroughProductId: string,
  packageProductIds: string[],
  shouldShowPricingOnBwCalendar: boolean,
};

// focusable input for react-final-form focus-on-error
const HiddenInput = ({ name }: { name: string }) => {
  return (
    <div
      style={{
        width: 0,
        overflow: 'hidden',
      }}
    >
      <input name={name} style={{ opacity: 0 }} />
    </div>
  );
};

const ProductTypeInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const showPassthrough = Boolean(
    activeUserOrganization?.corresponding_organization_id
  );

  const allProducts = useSelector(summariesWithBookmarksSelector);
  const passthroughProductCandidates = useSelector(
    (state: ReduxState) => state.products.passthroughCandidates
  );

  const editingProduct = React.useContext(EditingProductContext);

  const form = useForm();
  const productTypeField = useField('productType');

  const { atLeastOneProductRequired, required } = getValidators(t);

  React.useEffect(() => {
    if (productTypeField.input.value === 'PASSTHROUGH') {
      dispatch(
        fetchPassthroughCandidateProducts({
          agent_id: activeUserOrganization?.corresponding_organization_id ?? '',
        })
      );
    } else if (productTypeField.input.value === 'PACKAGE') {
      dispatch(fetchProducts(activeUserOrganization?.id ?? ''));
    }
  }, [productTypeField.input.value]);

  let typeOptions = [
    {
      value: 'NORMAL',
      label: t('Normal'),
    },
    {
      value: 'PACKAGE',
      label: t('Package'),
    },
  ];

  if (showPassthrough) {
    typeOptions.push({
      value: 'PASSTHROUGH',
      label: t('Passthrough'),
    });
  }
  const packageProductOptions = allProducts.map((product) => ({
    value: product.id,
    text: getVerboseDisplayProductName(product),
  }));

  const passthroughCandidateOptions = passthroughProductCandidates.map(
    (product) => ({
      value: product.id ?? '',
      text: product.product_name ?? '',
    })
  );

  return (
    <div>
      <EnumRadioButtonGroup
        name="productType"
        options={typeOptions}
        onChange={() => {
          form.change('packageProductIds', ['']);
          form.change('passthroughProductId', '');
        }}
        disabled={Boolean(editingProduct?.id)}
      />
      {productTypeField.input.value === 'PACKAGE' && (
        <div className={styles['page-productsRegist__priceMain']}>
          <div>{t('Package Sub-Products')}</div>
          <FieldArray
            name="packageProductIds"
            validate={atLeastOneProductRequired}
          >
            {({ fields, meta: { error, touched } }) => (
              <>
                {fields.length === 0 && (
                  <AddIcon onClick={() => fields.insertAt(0, '')} />
                )}
                <div className={styles['c-table-list']}>
                  <table>
                    {fields.map((name, idx) => (
                      <Field name={name}>
                        {({ input }) => (
                          <tr>
                            <td>
                              <Select
                                options={[
                                  ...packageProductOptions,
                                  ...(config.enablePartnership
                                    ? passthroughCandidateOptions
                                    : []),
                                ].filter(
                                  (option) =>
                                    option.value !== editingProduct?.id
                                )}
                                maxWidth={800}
                                value={input.value}
                                onChange={(event, { value }) =>
                                  input.onChange(value)
                                }
                              />
                            </td>
                            <td className={styles['c-table-list__btns']}>
                              <div className={baseStyles['base-flex']}>
                                <AddIcon
                                  onClick={() => fields.insertAt(idx + 1, '')}
                                />
                                <DeleteIcon
                                  onClick={() => fields.remove(idx)}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </Field>
                    ))}
                  </table>
                </div>
                {error && touched && (
                  <p className={baseStyles['base-form-box__err']}>{error}</p>
                )}
              </>
            )}
          </FieldArray>
          <HiddenInput name="packageProductIds" />
        </div>
      )}
      {productTypeField.input.value === 'PASSTHROUGH' && (
        <div className={styles['page-productsRegist__priceMain']}>
          <div>{t('Passthrough Base Product')}</div>
          <Field name="passthroughProductId" validate={required}>
            {({ input, meta: { error, touched } }) => (
              <Select
                search
                value={input.value}
                onChange={(e, { value }) => input.onChange(value)}
                options={passthroughCandidateOptions}
                error={touched && error}
              />
            )}
          </Field>
        </div>
      )}
    </div>
  );
};

const focusOnError = createDecorator();

type Props = {
  onNextClick: (productId: string) => void,
  copy?: boolean,
  sourceProductId?: string | null,
};

export const BasicStep = ({ onNextClick, copy, sourceProductId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { required } = getValidators(t);

  const [showPopUp, setShowPopUp] = React.useState<boolean>(true);
  const [showHelpPopUp, setShowHelpPopUp] = React.useState<boolean>(false);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const defaultTimezone = useSelector(defaultProductTimezoneSelector);

  const editingProduct = React.useContext(EditingProductContext);

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  React.useEffect(() => {
    if (!showPopUp) {
      setShowHelpPopUp(true);
    }
  }, [showPopUp]);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const sourceLanguageUppercaseIso =
    editingProduct?.source_language ?? defaultLanguage;
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];

  // Note : don't clear data when back from after step
  const copyCreateRequired = copy && sourceProductId === editingProduct?.id;

  const isTutorialActive = location.pathname.includes('/home/tutorial');

  const getDefaultPrices = (languageLowercaseIso: string) => {
    return [
      {
        method: 'PER_PARTICIPANT',
        unit: t('Adult', { lng: languageLowercaseIso }),
        ageBandMinimum: 13,
        ageBandMaximum: 0,
        price: '100',
      },
      {
        method: 'PER_PARTICIPANT',
        unit: t('Child', { lng: languageLowercaseIso }),
        ageBandMinimum: 4,
        ageBandMaximum: 12,
        price: '70',
      },
      {
        method: 'PER_PARTICIPANT',
        unit: t('Infant', { lng: languageLowercaseIso }),
        ageBandMinimum: 0,
        ageBandMaximum: 3,
        price: '0',
      },
    ];
  };

  const initialValues = React.useMemo(() => {
    const defaultPriceMethod = 'PER_PARTICIPANT';

    const priceMethod =
      (editingProduct?.pricing &&
        editingProduct?.pricing.length > 0 &&
        editingProduct?.pricing.slice(-1)[0].units && // Note: get last setting for product copy.
        editingProduct?.pricing.slice(-1)[0].units.length > 0 &&
        editingProduct?.pricing.slice(-1)[0].units[0].method) ||
      defaultPriceMethod;

    const prices =
      editingProduct?.pricing && editingProduct?.pricing.length > 0
        ? (editingProduct?.pricing.slice(-1)[0].units || []).map((unit) => ({
            method: unit.method,
            unit: unit.guest_type?.key,
            price: unit.gross && unit.gross?.substring(3),
            ageBandMinimum: unit.guest_type?.minimum_age,
            ageBandMaximum: unit.guest_type?.maximum_age || 0,
            perBookingUnits: unit.per_booking_guest_types?.map(
              (perBookingGuestType) => ({
                unit: perBookingGuestType?.key,
                title: perBookingGuestType?.title,
                ageBandMinimum: perBookingGuestType?.minimum_age,
                ageBandMaximum: perBookingGuestType?.maximum_age || 0,
              })
            ),
          }))
        : getDefaultPrices(sourceLanguageLowercaseIso);

    const perGroupPrices =
      editingProduct?.pricing && editingProduct?.pricing.length > 0
        ? (editingProduct?.pricing.slice(-1)[0].units || [])
            .filter((unit) => unit.method === 'PER_GROUP')
            .map((unit) => ({
              method: unit.method,
              title: unit.title,
              groupUnits: unit.group_guest_types?.map((type) => {
                return type.key;
              }),
              price: unit.gross && unit.gross?.substring(3),
            }))
        : [];

    let productType = 'NORMAL';
    let packageProductIds =
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? [];
    let passthroughProductId =
      editingProduct?.shared_allotment_references
        ?.passthrough_base_product_id ?? '';

    if (packageProductIds.length > 0) {
      productType = 'PACKAGE';
    }
    if (passthroughProductId) {
      productType = 'PASSTHROUGH';
    }

    let productCode = editingProduct?.supplier_reference;
    let internalProductName = editingProduct?.internal_product_name;
    if (copyCreateRequired) {
      productCode = '';
      internalProductName = '';
    }
    if (isTutorialActive && copyCreateRequired) {
      productCode = `[COPY] ${editingProduct?.supplier_reference ?? ''}`;
      internalProductName = `[COPY] ${
        editingProduct?.internal_product_name ?? ''
      }`;
    }

    return {
      productCode: productCode,
      productName:
        (copyCreateRequired ? '[COPY] ' : '') +
        (editingProduct?.product_name || ''),
      internalProductName: internalProductName,
      productDescription: editingProduct?.description,
      shouldShowPricingOnBwCalendar:
        editingProduct?.should_show_pricing_on_bw_calendar?.value ?? false,
      mediaUrls: (editingProduct?.media || []).map(
        (mediaItem) => mediaItem.url
      ),
      prices,
      perGroupPrices,
      priceMethod,
      sourceLanguage: sourceLanguageUppercaseIso,
      productType,
      packageProductIds,
      passthroughProductId,
    };
  }, [
    editingProduct,
    sourceLanguageUppercaseIso,
    sourceLanguageLowercaseIso,
    copyCreateRequired,
  ]);

  return (
    <>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Step 1 - Basic Info')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <Form
            onSubmit={async (values: BasicFormValues) => {
              try {
                if (editingProduct && !copyCreateRequired) {
                  await dispatch(
                    updateProduct(editingProduct.id, {
                      shared_allotment_references: {
                        package_component_product_ids:
                          values.packageProductIds?.filter((id) => Boolean(id)),
                        passthrough_base_product_id:
                          values.passthroughProductId,
                      },
                      supplier_reference: values.productCode,
                      product_name: values.productName,
                      internal_product_name: values.internalProductName,
                      description: values.productDescription,
                      should_show_pricing_on_bw_calendar: {
                        value: values.shouldShowPricingOnBwCalendar,
                      },
                      media: (convertMediaUrlsToProductMediaItems(
                        values.mediaUrls || []
                      ): any),
                      pricing: [
                        {
                          days_of_week: [
                            'SUN',
                            'MON',
                            'TUE',
                            'WED',
                            'THU',
                            'FRI',
                            'SAT',
                          ],
                          units: [
                            ...(values.prices || []).map((price) => {
                              let guestType;
                              if (price.unit) {
                                guestType = {
                                  title: price.unit,
                                  key: price.unit,
                                  minimum_age: price.ageBandMinimum,
                                  maximum_age: price.ageBandMaximum,
                                };
                              }

                              return {
                                method: price.method,
                                guest_type: guestType,
                                per_booking_guest_types:
                                  price.perBookingUnits?.map(
                                    (perBookingUnit) => ({
                                      key: perBookingUnit.unit,
                                      title: perBookingUnit.title,
                                      minimum_age:
                                        perBookingUnit.ageBandMinimum,
                                      maximum_age:
                                        perBookingUnit.ageBandMaximum,
                                    })
                                  ),
                                gross: `${defaultCurrency}${price.price}`,
                                net: `${defaultCurrency}${price.price}`,
                              };
                            }),
                            ...(values.perGroupPrices ?? []).map((price) => ({
                              method: 'PER_GROUP',
                              title: price.title,
                              group_guest_types: (price.groupUnits ?? []).map(
                                (groupUnit) => ({
                                  title: groupUnit,
                                  key: groupUnit,
                                })
                              ),
                              gross: `${defaultCurrency}${price.price ?? 0}`,
                              net: `${defaultCurrency}${price.price ?? 0}`,
                            })),
                          ],
                        },
                      ],
                    })
                  );
                  await onNextClick(editingProduct.id);
                } else {
                  const result = await dispatch(
                    createProduct({
                      shared_allotment_references: {
                        package_component_product_ids:
                          values.packageProductIds?.filter((id) => Boolean(id)),
                        passthrough_base_product_id:
                          values.passthroughProductId,
                      },
                      is_tutorial_product: false,
                      start_timezone: defaultTimezone,
                      supplier_reference: values.productCode,
                      internal_product_name: values.internalProductName,
                      source_language: values.sourceLanguage,
                      product_name: values.productName,
                      description: values.productDescription,
                      should_show_pricing_on_bw_calendar: {
                        value: values.shouldShowPricingOnBwCalendar,
                      },
                      media: convertMediaUrlsToProductMediaItems(
                        values.mediaUrls || []
                      ),
                      pricing: [
                        {
                          days_of_week: [
                            'SUN',
                            'MON',
                            'TUE',
                            'WED',
                            'THU',
                            'FRI',
                            'SAT',
                          ],
                          units: [
                            ...(values.prices || []).map((price) => {
                              let guestType;
                              if (price.unit) {
                                guestType = {
                                  title: price.unit,
                                  key: price.unit,
                                  minimum_age: price.ageBandMinimum,
                                  maximum_age: price.ageBandMaximum,
                                };
                              }

                              return {
                                method: price.method,
                                guest_type: guestType,
                                gross: `${defaultCurrency}${price.price}`,
                                net: `${defaultCurrency}${price.price}`,
                                per_booking_guest_types:
                                  price.perBookingUnits?.map(
                                    (perBookingUnit) => ({
                                      key: perBookingUnit.unit,
                                      title: perBookingUnit.title,
                                      minimum_age:
                                        perBookingUnit.ageBandMinimum,
                                      maximum_age:
                                        perBookingUnit.ageBandMaximum,
                                    })
                                  ),
                              };
                            }),
                            ...(values.perGroupPrices ?? []).map((price) => ({
                              method: 'PER_GROUP',
                              title: price.title,
                              group_guest_types: (price.groupUnits ?? []).map(
                                (groupUnit) => ({
                                  title: groupUnit,
                                  key: groupUnit,
                                })
                              ),
                              gross: `${defaultCurrency}${price.price ?? 0}`,
                              net: `${defaultCurrency}${price.price ?? 0}`,
                            })),
                          ],
                        },
                      ],
                    })
                  );
                  // Note: createProduct() supports a subset of all products field
                  //       so we need to call updateProduct() to copy all fields
                  if (copyCreateRequired && result) {
                    const productPatch = ({
                      ...editingProduct,
                      ...{
                        add_ons: editingProduct?.add_ons?.map((add_on) => {
                          return {
                            ...add_on,
                            ...{
                              pricing: add_on.pricing?.map((price) => {
                                return {
                                  ...price,
                                  net: price.gross,
                                };
                              }),
                            },
                          };
                        }),
                        transportations: editingProduct?.transportations?.map(
                          (transportation) => {
                            return {
                              ...transportation,
                              ...{
                                pricing: transportation.pricing?.map(
                                  (price) => {
                                    return {
                                      ...price,
                                      net: price.gross,
                                    };
                                  }
                                ),
                              },
                            };
                          }
                        ),
                      },
                      ...{
                        id: result.id,
                        pricing: result.pricing,
                        product_name: result.product_name,
                        internal_product_name: result.internal_product_name,
                        description: result.description,
                        source_language: result.source_language,
                        supplier_id: result.supplier_id,
                        supplier_reference: result.supplier_reference,
                        emergency_contact: result.emergency_contact,
                        start_timezone: result.start_timezone,
                        gateway_name: result.gateway_name,
                        booking_widget_settings: result.booking_widget_settings,
                        media: result.media,
                        shared_allotment_references:
                          result.shared_allotment_references,
                      },
                    }: any);
                    productPatch?.pricing?.forEach((priceSchedule: Pricing) => {
                      removeUnitPackageMappings(priceSchedule.units || []);
                    });
                    productPatch?.recurrence?.forEach(
                      (recurrenceRule: Recurrence) =>
                        removeStartTimePackageMappings(
                          recurrenceRule.start_times || []
                        )
                    );

                    await dispatch(updateProduct(result.id, productPatch));
                  }
                  await onNextClick(result?.id || '');
                }
              } catch (err) {
                if (err == 'Conflict') {
                  return {
                    [FORM_ERROR]: t(
                      'The product code is already in use. Please enter a different code.'
                    ),
                  };
                }
                return {
                  [FORM_ERROR]: t('Save Failed'),
                };
              }
            }}
            decorators={[focusOnError]}
            initialValues={initialValues}
            mutators={getArrayMutators()}
          >
            {({
              handleSubmit,
              submitError,
              submitting,
              values,
              form: { change },
            }) => (
              <form onSubmit={handleSubmit}>
                <p className={styles['page-tutorial__ttl']}>
                  {t('Set up basic product information')}
                </p>

                <div className={styles['c-table-list']}>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('Product Code')}</th>
                        <td>
                          <Field name="productCode" validate={required}>
                            {({ input, meta: { error, touched } }) =>
                              editingProduct && !copyCreateRequired ? (
                                input.value
                              ) : (
                                <Input
                                  {...input}
                                  type="text"
                                  maxWidth={800}
                                  maxLength={50}
                                  error={touched && error}
                                />
                              )
                            }
                          </Field>
                        </td>
                      </tr>
                      {!isTutorialActive && (
                        <tr>
                          <th>{t('Language')}</th>
                          <td>
                            {editingProduct != null ? (
                              values?.sourceLanguage === 'EN_US' ? (
                                t('English')
                              ) : (
                                t('Japanese')
                              )
                            ) : (
                              <EnumRadioButtonGroup
                                name="sourceLanguage"
                                options={[
                                  {
                                    value: 'JA_JP',
                                    label: t('Japanese'),
                                  },
                                  {
                                    value: 'EN_US',
                                    label: t('English'),
                                  },
                                ]}
                                onChange={(newValue) => {
                                  const newSourceLanguage: any = newValue;
                                  change(
                                    'prices',
                                    getDefaultPrices(
                                      uppercaseIsoToLowercaseIso[
                                        newSourceLanguage
                                      ]
                                    )
                                  );
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                      {!isTutorialActive && (
                        <tr>
                          <th>{t('Type')}</th>
                          <td>
                            <ProductTypeInput />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>{t('Internal Product Name')}</th>
                        <td>
                          <Field name="internalProductName" validate={required}>
                            {({ input, meta: { error, touched } }) => (
                              <Input
                                {...input}
                                type="text"
                                maxWidth={800}
                                maxLength={50}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Product Name')}</th>
                        <td>
                          <Field name="productName" validate={required}>
                            {({ input, meta: { error, touched } }) => (
                              <Input
                                {...input}
                                type="text"
                                maxWidth={800}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Product Description')}</th>
                        <td>
                          <Field name="productDescription">
                            {({ input, meta: { error, touched } }) => (
                              <TextArea
                                {...input}
                                maxWidth={800}
                                showCharacterCount
                                error={touched && error}
                              ></TextArea>
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Images/Videos')}</th>
                        <td>
                          <div>
                            <Field name="mediaUrls">
                              {({ input }) => (
                                <ImageVideoAudioInput
                                  fileUrls={input.value || []}
                                  onChange={(newValue) =>
                                    input.onChange(newValue)
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Prices')}</th>
                        <td>
                          <ul
                            className={clsx(
                              baseStyles['base-flex'],
                              styles['page-productsRegist__priceSelect']
                            )}
                          >
                            <li>
                              <div className={baseStyles['base-form-radio']}>
                                <label>
                                  <input
                                    type="radio"
                                    name="price"
                                    onChange={() => {
                                      change('priceMethod', 'PER_PARTICIPANT');
                                      change(
                                        'prices',
                                        getDefaultPrices(
                                          (values?.sourceLanguage: any)
                                        )
                                      );
                                    }}
                                    checked={
                                      values?.priceMethod === 'PER_PARTICIPANT'
                                    }
                                  />
                                  <p></p>
                                  {t('Set Up Per-Participant Prices')}
                                </label>
                              </div>
                              <div className={styles['tooltip-box']}>
                                <Tooltip
                                  text={t(
                                    'Price is set per unit (1 person, etc.). Example: adults, children, toddlers, visitors, etc.'
                                  )}
                                  mobileStyle={{
                                    left: '-270px',
                                  }}
                                />
                              </div>
                            </li>
                            <li>
                              <div className={baseStyles['base-form-radio']}>
                                <label>
                                  <input
                                    type="radio"
                                    name="price"
                                    onChange={() => {
                                      change('priceMethod', 'PER_BOOKING');
                                      change('prices', [
                                        {
                                          method: 'PER_BOOKING',
                                          price: '100',
                                        },
                                      ]);
                                    }}
                                    checked={
                                      values?.priceMethod === 'PER_BOOKING'
                                    }
                                  />
                                  <p></p>
                                  {t('Set Up Per-Booking Prices')}
                                </label>
                              </div>
                              <div className={styles['tooltip-box']}>
                                <Tooltip
                                  text={t(
                                    'Price is set once per booking regardless of the number of people or units. Example: 1 unit, 1 group, 1 time'
                                  )}
                                  mobileStyle={{
                                    left: '-270px',
                                  }}
                                />
                              </div>
                            </li>
                          </ul>
                          {values?.priceMethod === 'PER_PARTICIPANT' && (
                            <>
                              <PerParticipantPriceInput
                                name="prices"
                                language={
                                  uppercaseIsoToLowercaseIso[
                                    values?.sourceLanguage
                                  ]
                                }
                                currency={defaultCurrency}
                                showCustom={copy}
                              />
                              {copy && (
                                <PerGroupPriceInput
                                  name={'perGroupPrices'}
                                  currency={defaultCurrency}
                                  showNet={false}
                                />
                              )}
                            </>
                          )}
                          {values?.priceMethod === 'PER_BOOKING' && (
                            <PerBookingPriceInput
                              name="prices"
                              currency={defaultCurrency}
                            />
                          )}

                          <p className={styles['c-table-list__note']}>
                            {t(
                              '* If prices differ for certain days of the week or time periods, you can make these changes later.'
                            )}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  {submitError && (
                    <p className={baseStyles['base-form-box__err']}>
                      {submitError}
                    </p>
                  )}
                  <Button
                    type="submit"
                    size="small"
                    style="blue"
                    loading={submitting}
                  >
                    {t('Next')}
                  </Button>
                </div>
                {isTutorialActive && showHelpPopUp && (
                  <ProductTutorialHelpPopUp onClose={setShowHelpPopUp} />
                )}
              </form>
            )}
          </Form>
        </div>
        {isTutorialActive && showPopUp && (
          <ProductEditBasicPopUp popUpDisable={() => setShowPopUp(false)} />
        )}
      </div>
    </>
  );
};
