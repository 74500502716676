import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
  OptionalIntegerInput,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Add } from 'client/components/Icons/Add';
import { Delete } from 'client/components/Icons/Delete';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { productOptionsSelector } from 'client/reducers/products';
import { Editor } from 'client/components/Editor/Editor';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { config } from 'client/config';

import { CouponEditor } from './CouponEditor';
import { StampRallyEditor } from './StampRallyEditor';
import { GoogleMapView } from './GoogleMapView';
import {
  Pin,
  FormValues,
  PinInteractionType,
  PinRestaurantType,
} from './formValues';
import styles from './DigitalMapPinEditModal.module.css';
import { StructuredInfoEditor } from './StructuredInfoEditor';
import { ScheduleEditor } from './ScheduleEditor';
import { PinBusRouteEditor } from './PinBusRouteEditor';

interface Props {
  editingIdx: number;
  onClose: () => void;
}

export const DigitalMapPinEditModal = ({ editingIdx, onClose }: Props) => {
  const { t } = useTranslation();
  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);

  const restaurants = useSelector((state: ReduxState) => state.restaurants.all);
  const { id } = useParams<{ id: string }>();
  const form = useForm<FormValues>();
  const values = form.getState().values;

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productOptions = useSelector(productOptionsSelector);

  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );

  const categoryOptions = React.useMemo(() => {
    return (
      existingDigitalMap?.pin_categories?.map((c) => ({
        text: c.label ?? '',
        value: c.label ?? '',
      })) ?? []
    );
  }, [existingDigitalMap?.pin_categories]);

  const pin = _.get(values, `pins.${editingIdx}`) as Pin;

  return (
    <Modal open={true} onClose={onClose} title={t('Edit Pin')} width="wide">
      <Modal.Content>
        <Field name={`pins.${editingIdx}.title`}>
          {({ input }) => <Input label={t('Title')} {...input} />}
        </Field>
        <Field name={`pins.${editingIdx}.description`}>
          {({ input }) => <TextArea label={t('Description')} {...input} />}
        </Field>
        {categoryOptions.length > 0 && (
          <Box mt={2} mb={2}>
            <Field name={`pins.${editingIdx}.category`}>
              {({ input }) => (
                <Select
                  search
                  label={t('Category')}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                  options={categoryOptions}
                />
              )}
            </Field>
          </Box>
        )}
        {pin.category === 'TRANSPORT' && (
          <PinBusRouteEditor name={`pins.${editingIdx}.busRouteIds`} />
        )}
        {existingDigitalMap?.map_floor_count &&
          existingDigitalMap.map_floor_count > 1 && (
            <Box mt={2} mb={2}>
              <Field name={`pins.${editingIdx}.floorNumber`}>
                {({ input }) => (
                  <Select
                    label={t('Floor')}
                    options={Array.from(
                      { length: existingDigitalMap?.map_floor_count ?? 1 },
                      (_, i) => ({
                        value: (i + 1).toString(),
                        text: `${i + 1}`,
                      })
                    )}
                    value={input.value}
                    onChange={(e, { value }) =>
                      input.onChange(parseInt(value, 10))
                    }
                  />
                )}
              </Field>
            </Box>
          )}
        <FieldWrapper label={t('Time')} />
        <EnumRadioButtonGroup
          name={`pins.${editingIdx}.timeDataType`}
          options={[
            {
              value: 'NONE',
              label: t('None'),
            },
            {
              value: 'SHOWTIMES',
              label: t('Showtimes'),
            },
            {
              value: 'TIME_RANGE',
              label: t('Open Hours'),
            },
          ]}
        />
        {pin.timeDataType === 'SHOWTIMES' && (
          <ScheduleEditor
            name={`pins.${editingIdx}.showtimeSchedules`}
            timeDataType="SHOWTIMES"
          />
        )}
        {pin.timeDataType === 'TIME_RANGE' && (
          <ScheduleEditor
            name={`pins.${editingIdx}.openHourSchedules`}
            timeDataType="TIME_RANGE"
          />
        )}

        <Box mt={2}>
          <FieldWrapper label={t('Photos/Videos')} />
          <Field name={`pins.${editingIdx}.mediaUrls`}>
            {({ input }) => (
              <ImageVideoAudioInput
                fileTypes={['image/jpeg', 'image/png', 'video/mp4']}
                fileUrls={input.value || []}
                onChange={(newValue) => input.onChange(newValue)}
              />
            )}
          </Field>
        </Box>
        <Box mt={2}>
          <FieldArray name={`pins.${editingIdx}.tags`}>
            {({ fields }) => (
              <>
                <FieldWrapper label={t('Tags')} />
                <div>
                  <Add onClick={() => (fields as any).insertAt(0, '')} />
                </div>
                {fields.map((name, idx) => (
                  <Box
                    alignItems="center"
                    display="flex"
                    mt={1}
                    gap={1}
                    key={name}
                  >
                    <Field name={name}>
                      {({ input }) => (
                        <Input value={input.value} onChange={input.onChange} />
                      )}
                    </Field>
                    <Add
                      onClick={() => (fields as any).insertAt(idx + 1, '')}
                    />
                    <Delete onClick={() => fields.remove(idx)} />
                  </Box>
                ))}
              </>
            )}
          </FieldArray>
        </Box>

        <Box mt={2}>
          <StructuredInfoEditor
            name={`pins.${editingIdx}.structuredInfoItems`}
          />
        </Box>
        <Box mt={2}>
          <Field name={`pins.${editingIdx}.useThumbnailImagePinMarker`}>
            {({ input }) => (
              <ToggleButton
                label={t('Use Thumbnail Image as Pin Marker')}
                checked={input.value}
                onChange={() => {
                  input.onChange(!input.value);
                }}
              />
            )}
          </Field>
          {!pin.useThumbnailImagePinMarker && (
            <>
              <Box mt={2}>
                <Field name={`pins.${editingIdx}.iconUrl`}>
                  {({ input, meta: { touched, error } }) => (
                    <FieldWrapper label={t('Pin Marker Icon (jpg, png)')}>
                      <ImageVideoAudioInput
                        fileUrls={input.value ? [input.value] : []}
                        onChange={(newValue) =>
                          newValue.length > 0
                            ? input.onChange(newValue[0])
                            : input.onChange('')
                        }
                        maxFileCount={1}
                        disableYoutubeVideos
                        error={touched && error}
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </Box>
              {!pin.useThumbnailImagePinMarker && (
                <Box mt={2}>
                  <Field name={`pins.${editingIdx}.iconFit`}>
                    {({ input }) => (
                      <Select
                        label={t('Icon Fit')}
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                        options={[
                          {
                            text: t('Cover'),
                            value: 'COVER',
                          },
                          {
                            text: t('Contain'),
                            value: 'CONTAIN',
                          },
                        ]}
                      />
                    )}
                  </Field>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box mt={2}>
          <FieldWrapper label={t('Action Button Type')} />
          <EnumRadioButtonGroup
            name={`pins.${editingIdx}.pinInteractionType`}
            options={[
              {
                value: PinInteractionType.NONE,
                label: t('None'),
              },
              {
                value: PinInteractionType.BOOKABLE_PRODUCT,
                label: t('Book Products'),
              },
              ...(config.enableMobileOrder || config.enableMapOpenTableSettings
                ? [
                    {
                      value: PinInteractionType.RESTAURANT,
                      label: t('Restaurant'),
                    },
                  ]
                : []),
              {
                value: PinInteractionType.CUSTOM,
                label: t('Custom'),
              },
            ]}
          />
        </Box>
        {pin.pinInteractionType === PinInteractionType.BOOKABLE_PRODUCT && (
          <Field name={`pins.${editingIdx}.associatedProductIds`}>
            {({ input }) => {
              return (
                <Box mt={2}>
                  <FieldWrapper label={t('Bookable Products')}>
                    <MultiSelect
                      search
                      selectedValues={input.value}
                      onChange={({ value }) => input.onChange(value)}
                      options={productOptions}
                    />
                  </FieldWrapper>
                </Box>
              );
            }}
          </Field>
        )}
        {pin.pinInteractionType === PinInteractionType.RESTAURANT && (
          <Box mt={2}>
            <FieldWrapper label={t('Restaurant Type')}>
              <EnumRadioButtonGroup
                name={`pins.${editingIdx}.pinRestaurantType`}
                options={[
                  ...(config.enableMobileOrder
                    ? [
                        {
                          value: PinRestaurantType.ONLINE_ORDER,
                          label: t('Online Order'),
                        },
                      ]
                    : []),
                  ...(config.enableMapOpenTableSettings
                    ? [
                        {
                          value: PinRestaurantType.OPEN_TABLE,
                          label: t('Open Table'),
                        },
                      ]
                    : []),
                ]}
              />
            </FieldWrapper>
          </Box>
        )}
        {config.enableMobileOrder &&
          pin.pinInteractionType === PinInteractionType.RESTAURANT &&
          pin.pinRestaurantType === PinRestaurantType.ONLINE_ORDER && (
            <Field name={`pins.${editingIdx}.onlineOrderRestaurantId`}>
              {({ input }) => {
                return (
                  <Box mt={2}>
                    <FieldWrapper label={t('Online Order Restaurant')}>
                      <Select
                        search
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                        options={[
                          {
                            value: '',
                            text: t('None'),
                          },
                          ...restaurants.map((r) => ({
                            text: r.name ?? '',
                            value: r.id ?? '',
                          })),
                        ]}
                      />
                    </FieldWrapper>
                  </Box>
                );
              }}
            </Field>
          )}
        {config.enableMapOpenTableSettings &&
          pin.pinInteractionType === PinInteractionType.RESTAURANT &&
          pin.pinRestaurantType === PinRestaurantType.OPEN_TABLE && (
            <Field name={`pins.${editingIdx}.openTableRestaurantId`}>
              {({ input }) => {
                return (
                  <Box mt={2}>
                    <Input
                      value={input.value}
                      onChange={input.onChange}
                      label={t('OpenTable Restaurant ID (rid)')}
                    />
                  </Box>
                );
              }}
            </Field>
          )}
        {pin.pinInteractionType === PinInteractionType.CUSTOM && (
          <>
            <Box mt={2}>
              <Field name={`pins.${editingIdx}.genericActionButton.buttonText`}>
                {({ input }) => (
                  <Input
                    label={t('Custom Action Button Text')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </Box>
            <Box mt={2}>
              <Field
                name={`pins.${editingIdx}.genericActionButton.destinationUrl`}
              >
                {({ input }) => (
                  <Input
                    label={t('Custom Action Button Destination URL')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </Box>
            <Box mt={2}>
              <FieldWrapper label={t('Custom Action Destination Display Type')}>
                <EnumRadioButtonGroup
                  name={`pins.${editingIdx}.genericActionButton.destinationDisplay`}
                  options={[
                    { value: 'IFRAME', label: t('IFrame') },
                    { value: 'SAME_TAB', label: t('Same Tab') },
                    { value: 'NEW_TAB', label: t('New Tab') },
                  ]}
                />
              </FieldWrapper>
            </Box>
          </>
        )}
        <CouponEditor name={`pins.${editingIdx}.couponIds`} />
        <StampRallyEditor name={`pins.${editingIdx}`} />
        <Box mt={2}>
          <Field name={`pins.${editingIdx}.activationDistance`}>
            {({ input }) => (
              <OptionalIntegerInput
                label={t('Coupon/Stamp activation distance (m)')}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          </Field>
          <Box mt={1}>
            {t(
              'We recommend setting a longer distance (20 meters or more) since GPS locations from mobile devices are often imprecise.'
            )}
          </Box>
        </Box>
        <Box mt={2}>
          <Field name={`pins.${editingIdx}.showFreeFormDescription`}>
            {({ input }) => (
              <ToggleButton
                label={t('Show EditorJS Description Section')}
                checked={input.value}
                onChange={() => {
                  input.onChange(!input.value);
                }}
              />
            )}
          </Field>
          {pin.showFreeFormDescription && (
            <Box mt={2}>
              <Field name={`pins.${editingIdx}.freeFormDescription`}>
                {({ input }) => (
                  <>
                    <FieldWrapper label={t('EditorJS Description Section')} />

                    <div className={styles['editor']}>
                      <Editor
                        imageS3Prefix={`editor/supplier/${
                          activeUserOrganization?.id || ''
                        }`}
                        data={input.value}
                        onChange={(data: any, loading) => {
                          setEditorJsLoading(loading);
                          input.onChange(data);
                        }}
                        allowVideoUploads={true}
                        allowAudioUploads={true}
                      />
                    </div>
                  </>
                )}
              </Field>
            </Box>
          )}
        </Box>
        <Field name={`pins.${editingIdx}.location`}>
          {({ input }) => (
            <Box mt={4}>
              <Box mb={2}>
                <FieldWrapper label={t('Location (click map to change)')} />
                {`(${input.value.latitude}, ${input.value.longitude})`}
              </Box>
              {values?.useOverlayImage && (
                <Field name="showOverlayOnMap">
                  {({ input }) => (
                    <Box mb={2}>
                      <ToggleButton
                        label={t('Show Overlay')}
                        checked={input.value}
                        onChange={() => {
                          input.onChange(!input.value);
                        }}
                      />
                    </Box>
                  )}
                </Field>
              )}
              <GoogleMapView
                onClick={(lat, lng) =>
                  input.onChange({
                    latitude: lat,
                    longitude: lng,
                  })
                }
                pins={
                  input.value.latitude && input.value.longitude
                    ? [
                        {
                          lat: input.value.latitude,
                          lng: input.value.longitude,
                        },
                      ]
                    : []
                }
                overlay={
                  pin.floorNumber && values.overlays?.length
                    ? values.overlays[pin.floorNumber - 1]
                    : undefined
                }
                showOverlay={values.showOverlayOnMap}
                initialCenter={values.mapCenter}
                zoom={values.mapZoom ? values.mapZoom - 2 : 18}
                heading={values.mapRotation}
              />
            </Box>
          )}
        </Field>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit disabled={editorJsLoading} onClick={() => onClose()}>
          {t('OK')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
