import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  confirmSystemFeeInvoice,
  fetchSystemFeeInvoices,
} from 'client/actions/systemFeeInvoices';
import { fetchPartnershipSystemFeeInvoices } from 'client/actions/partnershipSystemFeeInvoices';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { Box } from 'client/components/Box/Box';
import { Tabs } from 'client/pages/v3/SystemFee/SystemFeeReservations/Tabs/Tabs';
import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { CustomTable } from 'client/pages/v3/SystemFee/SystemFeeReservations/CustomTable';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import baseStyles from 'client/v3-base.module.css';
import { SystemFeeInvoiceSummary } from 'shared/models/swagger';

import styles from './SystemFeeReservations.module.css';

type TabType = 'MONTHLY_FEES' | 'UPCOMING';

export const ActivityPlanSystemFeeReservationsBody = () => {
  const { partnershipMode } = React.useContext(PartnershipModeContext);

  if (partnershipMode) {
    return <PartnershipSystemFee />;
  }

  return <NormalSystemFee />;
};

interface ConfirmCellProps {
  invoice: SystemFeeInvoiceSummary;
}

const ConfirmCell = ({ invoice }: ConfirmCellProps) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const loading = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.loading
  );
  const monthYear = moment(invoice.month)
    .locale(locale)
    .format(monthYearFormat);
  const activeUser = useSelector(activeUserSelector);

  if (invoice.status === 'SYSTEM_FEE_READY_FOR_CONFIRMATION') {
    return (
      <>
        <Button
          text={t('Confirmation Required')}
          color="primary"
          size="md"
          style={{
            fontWeight: 'var(--text-semibold)',
          }}
          onClick={() => setShowConfirmModal(true)}
          disabled={
            !hasCustomUserRoleWritePermissions(
              activeUser,
              'SYSTEM_FEE.WEB_BOOKINGS'
            )
          }
        />
        {showConfirmModal && (
          <MessageModal
            loading={loading}
            insertRoot
            open={true}
            onClose={() => setShowConfirmModal(false)}
            title={t('Confirm booking fee')}
            message={
              invoice.amount_due_by_nutmeg_final
                ? t(
                    'Nutmeg will make a transfer of {{billingAmount}} to your registered bank account. Please click OK to confirm the booking fee.',
                    {
                      billingAmount: formattedCurrencyAmount(
                        invoice.amount_due_by_nutmeg_final
                      ),
                      month: monthYear,
                    }
                  )
                : t(
                    'Nutmeg will send you an invoice of {{billingAmount}} to be paid by credit card for the reservations fee of {{month}}. Please click OK to confirm the fee.',
                    {
                      billingAmount: formattedCurrencyAmount(
                        invoice.amount_due_by_supplier_final ?? ''
                      ),
                      month: monthYear,
                    }
                  )
            }
            onSubmit={async () => {
              await dispatch(confirmSystemFeeInvoice(invoice.id ?? ''));
              setShowConfirmModal(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <a
      className={clsx(
        baseStyles['base-btn'],
        baseStyles['small'],
        baseStyles['gray']
      )}
      style={{
        width: '100px',
        padding: '2px',
        textAlign: 'center',
        height: 'fit-content',
      }}
      href={invoice.pdf_download_url}
      download
    >
      {t('Download')}
    </a>
  );
};

export type ColumnType<T> = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'shortMiddle' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
  dataText?: string;
};

const useColumns = (
  includeConfirmColumn: boolean
): ColumnType<SystemFeeInvoiceSummary>[] => {
  const { t } = useTranslation();
  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const org = useSelector(activeUserOrganizationSelector);

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return [
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Invoice Month')}
          </Box>
        </Box>
      ),
      th: true,
      accessor: (row) =>
        moment(row.month).locale(locale).format(monthYearFormat),
      Cell: (cellInfo) => {
        const url = partnershipMode
          ? `/partnership/suppliersystemfeeinvoices/${cellInfo.original.id}`
          : `/systemfee/reservations-v3/${cellInfo.original.id}`;
        const month = moment(cellInfo.original.month)
          .locale(locale)
          .format(monthYearFormat);

        return <Link to={url}>{month}</Link>;
      },
      textAlign: 'center',
      width: 'shortMiddle',
    },
    ...(includeConfirmColumn
      ? [
          {
            Header: '',
            HeaderElement: (
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Invoice')}
                </Box>
              </Box>
            ),
            dataText: `${t('Invoice')}`,
            width:
              'shortMiddle' as ColumnType<SystemFeeInvoiceSummary>['width'],
            Cell: (cellInfo: { original: SystemFeeInvoiceSummary }) => {
              return (
                <Box width="100%" display="flex" justifyContent="center">
                  <ConfirmCell invoice={cellInfo.original} />
                </Box>
              );
            },
            textAlign:
              'center' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
          },
        ]
      : []),
    {
      Header: '',
      dataText: `${t('PIF')} ${t('Gross')}`,
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Gross')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_gross_amount),
      textAlign: 'right',
      width: 'shortMiddle',
    },
    {
      Header: '',
      dataText: `${t('PIF')} ${t('Cancel Fee')}`,
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Cancel Fee')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_cancellation_fee_amount),
      textAlign: 'right',
      width: 'shortMiddle',
    },
    {
      Header: '',
      dataText: `${t('PIF')} ${t('Credit Card Fee')}`,
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Credit Card Fee')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_credit_card_fee_amount),
      textAlign: 'right',
      width: 'shortMiddle',
    },
    //...(config.enablePartnership
    //  ? [
    //      {
    //        Header: '',
    //        HeaderElement: (
    //          <Box>
    //            <Box width="100%" display="flex" justifyContent="center">
    //              {t('PIF')}
    //            </Box>
    //            <Box width="100%" display="flex" justifyContent="center">
    //              {t('Commission')}
    //            </Box>
    //          </Box>
    //        ),
    //        accessor: (row: SystemFeeInvoiceSummary) =>
    //          getFormattedAmount(row.pif_commission_amount),
    //        textAlign:
    //          'right' as ColumnType<SystemFeeInvoiceSummary>['textAlign'],
    //        width:
    //          'shortMiddle' as ColumnType<SystemFeeInvoiceSummary>['width'],
    //      },
    //    ]
    //  : []),
    {
      Header: '',
      dataText: `${t('POB')} ${t('Gross')}`,
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('POB')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Gross')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pob_gross_amount),
      textAlign: 'right',
      width: 'shortMiddle',
    },
    {
      Header: '',
      dataText: `${t('POB')} ${t('Cancel Fee')}`,
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('POB')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Cancel Fee')}
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pob_cancellation_fee_amount),
      textAlign: 'right',
      width: 'shortMiddle',
    },
    {
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Adjusted Amount')}
          </Box>
        </Box>
      ),
      Header: '',
      dataText: `${t('Adjusted Amount')}`,
      accessor: (row) => getFormattedAmount(row.adjusted_amount),
      textAlign: 'right',
      width: 'shortMiddle',
    },
    {
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Billing Amount')}
          </Box>
        </Box>
      ),
      Header: '',
      dataText: `${t('Billing Amount')}`,
      Cell: (cellInfo) => {
        if (cellInfo.original.amount_due_by_nutmeg_final) {
          return getFormattedAmount(
            cellInfo.original.amount_due_by_nutmeg_final
          );
        } else {
          return (
            <div className={styles['red']}>
              {getFormattedAmount(
                cellInfo.original.amount_due_by_supplier_final
              )}
            </div>
          );
        }
      },
      textAlign: 'right',
      width: 'shortMiddle',
    },
    {
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Due By')}
          </Box>
        </Box>
      ),
      Header: '',
      dataText: `${t('Due By')}`,
      Cell: (cellInfo) => {
        if (cellInfo.original.amount_due_by_nutmeg_final) {
          return 'Nutmeg';
        } else if (cellInfo.original.amount_due_by_supplier_final) {
          return <div className={styles['red']}>{org?.name ?? ''}</div>;
        }

        return '-';
      },
      textAlign: 'center',
      width: 'shortMiddle',
    },
    {
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Reservations')}
          </Box>
        </Box>
      ),
      Header: '',
      dataText: `${t('Reservations')}`,
      Cell: (cellInfo) => {
        const url = partnershipMode
          ? `/partnership/suppliersystemfeeinvoices/${cellInfo.original.id}`
          : `/systemfee/reservations-v3/${cellInfo.original.id}`;

        return <Link to={url}>{t('List')}</Link>;
      },
      textAlign: 'center',
      width: 'shortMiddle',
    },
  ];
};

interface ContentProps {
  systemFeeInvoices: SystemFeeInvoiceSummary[];
  includeConfirmColumnForPastInvoices: boolean;
}

const NormalSystemFee = () => {
  const dispatch = useDispatch();
  const systemFeeInvoices = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.summaries
  );
  const org = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (org) {
      dispatch(fetchSystemFeeInvoices());
      dispatch(fetchContractedOrganizations());
    }
  }, [org]);

  return (
    <Content
      systemFeeInvoices={systemFeeInvoices}
      includeConfirmColumnForPastInvoices={true}
    />
  );
};

const PartnershipSystemFee = () => {
  const dispatch = useDispatch();
  const systemFeeInvoices = useSelector(
    (state: ReduxState) => state.partnershipSystemFeeInvoices.summaries
  );
  const org = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (org) {
      dispatch(fetchPartnershipSystemFeeInvoices());
      dispatch(fetchContractedOrganizations());
    }
  }, [org]);

  return (
    <Content
      systemFeeInvoices={systemFeeInvoices}
      includeConfirmColumnForPastInvoices={false}
    />
  );
};

const Content = ({
  systemFeeInvoices,
  includeConfirmColumnForPastInvoices,
}: ContentProps) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState<TabType>('MONTHLY_FEES');

  const pastColumns = useColumns(includeConfirmColumnForPastInvoices);
  const futureColumns = useColumns(false);

  const panes = React.useMemo(() => {
    const pastInvoices = _.orderBy(
      systemFeeInvoices.filter(
        (invoice) =>
          invoice.status === 'SYSTEM_FEE_READY_FOR_CONFIRMATION' ||
          invoice.status === 'SYSTEM_FEE_CONFIRMED'
      ),
      'month',
      ['desc']
    );
    const futureInvoices = _.orderBy(
      systemFeeInvoices.filter(
        (invoice) => invoice.status === 'SYSTEM_FEE_PENDING'
      ),
      'month',
      ['asc']
    );
    return [
      {
        header: t('Monthly Fees'),
        content: () =>
          pastInvoices.length === 0 ? (
            <div
              className={baseStyles['base-form-box__err']}
              style={{ padding: '64px 24px', textAlign: 'center' }}
            >
              {t('No System Fee Invoices Found')}
            </div>
          ) : (
            <CustomTable
              items={pastInvoices}
              columns={pastColumns}
              usePaging={true}
            />
          ),
      },
      {
        header: t('Upcoming'),
        content: () =>
          futureInvoices.length === 0 ? (
            <div
              className={baseStyles['base-form-box__err']}
              style={{ padding: '64px 24px', textAlign: 'center' }}
            >
              {t('No System Fee Invoices Found')}
            </div>
          ) : (
            <CustomTable
              items={futureInvoices}
              columns={futureColumns}
              usePaging={true}
            />
          ),
      },
    ];
  }, [t, systemFeeInvoices, pastColumns, futureColumns]);

  return (
    <>
      <div className={baseStyles['l-main__body']}>
        <section className={styles['g-section']}>
          <div
            className={clsx(styles['p-inquiry-tabs'], styles['js-tab-scope'])}
            style={{ marginBottom: '70px' }}
          >
            <div className={styles['p-inquiry-tabs__head']}>
              <div className={styles['p-inquiry-tabs__tab-scroll']}>
                <ul className={styles['p-inquiry-tabs__tab-list']}>
                  <li
                    className={clsx(
                      styles['p-inquiry-tabs__tab'],
                      activeTab === 'MONTHLY_FEES'
                        ? styles['components_is-active__B15DT']
                        : ''
                    )}
                    style={{ display: 'block' }}
                    onClick={() => {
                      setActiveTab('MONTHLY_FEES');
                    }}
                  >
                    <a>{t('Monthly Fees')}</a>
                  </li>
                  <li
                    className={clsx(
                      styles['p-inquiry-tabs__tab'],
                      activeTab === 'UPCOMING'
                        ? styles['components_is-active__B15DT']
                        : ''
                    )}
                    style={{ display: 'block' }}
                    onClick={() => {
                      setActiveTab('UPCOMING');
                    }}
                  >
                    <a>{t('Upcoming')}</a>
                  </li>
                </ul>
              </div>
            </div>
            <Tabs
              panes={panes}
              activeIndex={activeTab == 'MONTHLY_FEES' ? 0 : 1}
            />
          </div>
        </section>
      </div>
    </>
  );
};
