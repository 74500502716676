import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import baseStyles from 'client/v3-base.module.css';

import styles from './Dropdown.module.css';

const itemType = 'selectable-item';
type Option = {
  text: string;
  key: string;
  value: string;
};

type MultiDropdownProps = {
  label?: string;
  options: Option[];
  onChange: (selectedOptions: string[]) => void;
  selectedOptions: string[];
  searchable?: boolean;
  placeholder?: string;
  error?: string;

  // When renderOption is provided, it will be used to render the option instead of the default text
  renderOption?: (option: Option) => React.ReactNode;
};

type ItemProps = {
  value: string;
  index: number;
  title: string;
  handleCheckboxChange: (value: string) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
};
interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const MultiDropdown = ({
  label,
  options,
  onChange,
  selectedOptions,
  searchable = false,
  placeholder,
  error,
  renderOption,
}: MultiDropdownProps) => {
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showOptions &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        // Reset selection & search text on leaving dropdown
        setShowOptions(false);
        setIsClicked(false);
        setSearchText('');
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);
    window.document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, [showOptions]);

  const changeItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = selectedOptions[dragIndex];
    const newSelectedItems = [...selectedOptions];
    newSelectedItems.splice(dragIndex, 1);
    newSelectedItems.splice(hoverIndex, 0, dragItem);
    onChange(newSelectedItems);
  };

  // Notify the calling component of selected options change
  const handleCheckboxChange = (value: string) => {
    const isOptionSelected = selectedOptions.includes(value);
    const newSelectedOptions = isOptionSelected
      ? selectedOptions.filter((selectedOption) => selectedOption !== value)
      : [...selectedOptions, value];
    onChange(newSelectedOptions);
  };

  // Filter options
  const filteredOptions = options.filter((o) => {
    if (o.text === '') {
      return false;
    }

    if (searchable && searchText) {
      return replaceFullWidthCharactersWithHalfWidth(
        o.text.toLowerCase()
      ).includes(searchText.toLowerCase());
    }

    return true;
  });

  if (selectedOptions[0] === '') {
    selectedOptions = [];
  }

  return (
    <fieldset
      className={clsx(
        styles['c-dropdown'],
        isHovered && styles['is-hover'],
        isClicked && styles['is-active'],
        (isClicked || (selectedOptions || []).length > 0) && styles['is-input'],
        error && styles['is-error']
      )}
      ref={fieldSetRef}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setShowOptions(true);
        setIsClicked(true);
      }}
    >
      <div className={styles['c-dropdown__body']}>
        <label>
          {label && (
            <legend className={styles['c-dropdown__body__title']}>
              {label}
            </legend>
          )}
          <div className={styles['c-dropdown__body__selected']}>
            <ul style={{ display: 'block', width: '100%' }}>
              {(selectedOptions || []).length > 0 &&
                selectedOptions.map((selectedOption, index) => (
                  <DraggableList
                    key={selectedOption}
                    index={index}
                    title={
                      options.find((option) => option.value === selectedOption)
                        ?.text || ''
                    }
                    value={selectedOption}
                    handleCheckboxChange={handleCheckboxChange}
                    moveItem={changeItem}
                  />
                ))}
            </ul>
            <input
              ref={inputRef}
              className={
                searchable
                  ? styles['c-dropdown__body__selected__input__canSearch']
                  : styles['c-dropdown__body__selected__input']
              }
              type="text"
              value={searchText}
              onChange={(event) => {
                if (searchable) {
                  setSearchText(event.target.value);
                }
              }}
              placeholder={
                (selectedOptions || []).length > 0 ? '' : placeholder
              }
              style={{ width: '100%' }}
            />
          </div>
        </label>
      </div>
      {/* Do not show options if it's empty */}
      {(filteredOptions || []).length > 0 && (
        <ul
          className={clsx(
            styles['c-dropdown__menu'],
            showOptions && styles['is-active']
          )}
          style={{ position: 'relative' }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className={styles['c-dropdown__menu__item']}
              onClick={() => {
                handleCheckboxChange(option.value);

                // Focus cursor to input and reset search text after selecting an option
                if (inputRef.current) {
                  inputRef.current.focus();
                }
                setSearchText('');
              }}
            >
              <input
                type="checkbox"
                name={`dropdown-${option.value}`}
                checked={
                  selectedOptions &&
                  selectedOptions.some(
                    (selectedOption) => selectedOption === option.value
                  )
                }
                onChange={(event) => {
                  event.stopPropagation();
                  handleCheckboxChange(option.value);
                }}
              />
              <i></i>
              <p>{renderOption ? renderOption(option) : option.text}</p>
            </li>
          ))}
        </ul>
      )}
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </fieldset>
  );
};

const DraggableList = ({
  index,
  title,
  value,
  handleCheckboxChange,
  moveItem,
}: ItemProps) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const [, drop] = useDrop<DragItem>({
    accept: itemType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect =
        ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      item.index = hoverIndex;
      setTimeout(() => {
        moveItem?.(dragIndex, hoverIndex);
      }, 0);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: value,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <li
      ref={ref}
      key={value}
      id={value}
      style={{
        opacity,
      }}
    >
      <div className={styles['p-topPage-acBox__header__ttl__ic']}>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div className={styles['p-topPage__dropdown__title']}>{title}</div>
      <i
        onClick={(event) => {
          event.stopPropagation(); // Prevent click from bubbling to parent elements
          handleCheckboxChange(value);
        }}
      ></i>
    </li>
  );
};
