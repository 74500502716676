import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { Modal } from 'client/components/Modal/Modal';
import * as Swagger from 'shared/models/swagger';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { TextField } from 'client/components/v3/Form/TextField';

type FormValues = {
  isThirdPartyBookingEnabled: boolean;
  thirdPartyBookingType: 'FAREHARBOR' | 'BOOKING_LINK';
  fareharborShortname: string;
  fareharborProductId: string;
  thirdPartyBookingLinkUrl: string;
};

const getInitialValues = (product: Swagger.Product | null): FormValues => {
  return {
    isThirdPartyBookingEnabled:
      Boolean(
        product?.third_party_booking_settings?.third_party_booking_type
      ) &&
      product?.third_party_booking_settings?.third_party_booking_type !==
        'NONE',
    thirdPartyBookingType:
      product?.third_party_booking_settings?.third_party_booking_type &&
      product?.third_party_booking_settings?.third_party_booking_type !== 'NONE'
        ? product?.third_party_booking_settings?.third_party_booking_type
        : 'FAREHARBOR',
    fareharborShortname:
      product?.third_party_booking_settings?.fareharbor_shortname ?? '',
    fareharborProductId:
      product?.third_party_booking_settings?.fareharbor_product_id ?? '',
    thirdPartyBookingLinkUrl:
      product?.third_party_booking_settings?.booking_link_url ?? '',
  };
};

const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return values.isThirdPartyBookingEnabled
    ? {
        third_party_booking_settings: {
          third_party_booking_type: values.thirdPartyBookingType,
          fareharbor_shortname: values.fareharborShortname,
          fareharbor_product_id: values.fareharborProductId,
          booking_link_url: values.thirdPartyBookingLinkUrl,
        },
      }
    : {
        third_party_booking_settings: {
          third_party_booking_type: 'NONE',
        },
      };
};

type Props = {
  onClose: () => void;
};

const FormContent = () => {
  const { t } = useTranslation();
  const { values } = useFormState<FormValues>();

  return (
    <>
      <Field type="checkbox" name="isThirdPartyBookingEnabled">
        {({ input }) => (
          <Modal.Box>
            <Toggle
              {...input}
              label={t(
                'Enable Third-Party Bookings from BW Product Details Page'
              )}
            />
          </Modal.Box>
        )}
      </Field>

      {values.isThirdPartyBookingEnabled && (
        <Modal.Box>
          <EnumRadioButtonGroup
            name="thirdPartyBookingType"
            options={[
              { value: 'FAREHARBOR', label: t('FareHarbor') },
              { value: 'BOOKING_LINK', label: t('Booking Link') },
            ]}
          />
        </Modal.Box>
      )}
      {values.isThirdPartyBookingEnabled &&
        values.thirdPartyBookingType === 'FAREHARBOR' && (
          <Modal.Box>
            <Field name="fareharborShortname">
              {({ input }) => (
                <TextField {...input} label={t('FareHarbor Shortname')} />
              )}
            </Field>
            <Field name="fareharborProductId">
              {({ input }) => (
                <TextField {...input} label={t('FareHarbor Product ID')} />
              )}
            </Field>
          </Modal.Box>
        )}
      {values.isThirdPartyBookingEnabled &&
        values.thirdPartyBookingType === 'BOOKING_LINK' && (
          <Modal.Box>
            <Field name="thirdPartyBookingLinkUrl">
              {({ input }) => (
                <TextField {...input} label={t('Booking Link URL')} />
              )}
            </Field>
          </Modal.Box>
        )}
    </>
  );
};

export const ProductThirdPartyBookingSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Third-Party Booking Settings')}
      initialValues={getInitialValues(product)}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <FormContent />
    </ProductEditorModal>
  );
};
