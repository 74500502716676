import moment from 'moment-timezone';
import _ from 'lodash';

import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import type { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

import { DateRange } from './promotionShape';

export type Weekday = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
export interface ProductShape {
  id: string;
  name: string;
  description: string;
  currency: string;
  mediaURLs: {
    url: string;
    type: 'IMAGE' | 'VIDEO';
  }[];
  tags: string[];
  operatingWeekdays: Weekday[];
  durationInHours: string;
  startTimes: StartTime[];
  priceSchedules: PriceSchedule[];
  promotionSummaries: PromotionSummary[];
  highlights: string[];
  addOns: ServiceShape[];
  transportations: ServiceShape[];
  inclusions: string[];
  exclusions: string[];
  restrictions: string[];
  requirements: string[];
  whatToBring: string[];
  otherInfo: string[];
  minimumParticipants: number;
  schedule: ScheduleEventShape[];
  pickupLocations: LocationShape[];
  dropoffLocations: LocationShape[];
  checkinLocations: LocationShape[];
  checkoutLocations: LocationShape[];
  bestDiscountGross: string;
  bestDiscountPercent: number;
  totalBooked: number;
  cancellationPolicies: CancellationPolicy[];
  instantBookingDeadline: BookingDeadline | null;
  requestBookingDeadline: BookingDeadline | null;
  agentGuestBookingAllowedDaysBefore: number | null;
}
export interface PriceSchedule {
  startDate: string;
  endDate: string;
  weekdays: Weekday[];
  prices: Price[];
  original: Swagger.Pricing;
}
export interface Price {
  unitTitle: string;
  amount: string;
  minimumAge?: number | null;
  maximumAge?: number | null;
  amountAfterChargeOrDiscount: string;
  deltaType: 'CHARGE' | 'DISCOUNT' | '';
  deltaAmount: string;
  deltaPercent: string;
}
export interface ServiceShape {
  name: string;
  description: string;
  type: 'FREE' | 'PAID';
  pricesSummary: Price[];
}
export interface ScheduleEventShape {
  name: string;
  description: string;
  startTimeRelative?: string;
  endTimeRelative?: string;
}
export interface LocationShape {
  name: string;
  googlePlaceID: string;
  description: string;
  timeRelative?: string;
  dedicatedStartTimeInfoItems: DedicatedStartTimeInfoItem[];
}
export interface DedicatedStartTimeInfoItem {
  timeSlotKey: string;
  timeRelative: string;
}
export interface StartTime {
  hhMm: string;
  startDate: string;
  timeSlotKey: string;
  description: string;
}
export interface PromotionSummary {
  id: string;
  participationDateRanges: DateRange[];
  participationDaysOfWeek: Swagger.DayOfWeek[];
  bookedDateLocalFrom: string;
  bookedDateLocalTo: string;
  type: Swagger.PromotionType;
  earlyBirdDeadline: Swagger.RelativeDateTime | null;
  lastMinuteStart: Swagger.RelativeDateTime | null;
  groupDiscount: Swagger.GroupDiscount | null;
  familyDiscount: Swagger.FamilyDiscount | null;
  prices: Price[];
}
export type RelativeDeadline =
  | {
      deadlineType: 'HOUR';
      hoursBefore: number;
    }
  | {
      deadlineType: 'DAY';
      daysBefore: number;
      timeOfDay: string;
    }
  | {
      deadlineType: 'MINUTE';
      minutesBefore: number;
    }
  | {
      deadlineType: 'ETERNAL';
    };
type CancellationFee =
  | {
      feeType: 'FIXED';
      feeFixed: string;
    }
  | {
      feeType: 'PERCENT';
      feePercent: string;
    };
export type CancellationPolicy = RelativeDeadline & CancellationFee;
export type BookingDeadline = RelativeDeadline;
export const printPriceTitle = (price: Price, t: TranslateFuncType): string => {
  if (!price.unitTitle) {
    return t('Per-Booking');
  }

  return price.minimumAge && price.maximumAge
    ? t('{{guestTitle}} ({{guestMinAge}}-{{guestMaxAge}})', {
        guestTitle: price.unitTitle,
        guestMinAge: price.minimumAge,
        guestMaxAge: price.maximumAge,
      })
    : price.minimumAge
    ? t('{{guestTitle}} ({{guestMinAge}}+)', {
        guestTitle: price.unitTitle,
        guestMinAge: price.minimumAge,
      })
    : price.maximumAge
    ? t('{{guestTitle}} ({{guestMaxAge}} & under)', {
        guestTitle: price.unitTitle,
        guestMaxAge: price.maximumAge,
      })
    : price.unitTitle;
};
export const toProductShapePriceSchedule = (
  priceSchedule: Swagger.Pricing
): PriceSchedule => {
  return {
    startDate: priceSchedule.start_date_local ?? '',
    endDate: priceSchedule.end_date_local ?? '',
    weekdays: priceSchedule.days_of_week ?? [],
    prices: getPrices(priceSchedule.units),
    original: priceSchedule,
  };
};
export const toProductShape = (product: Swagger.Product): ProductShape => {
  const mediaURLs: {
    url: string;
    type: 'IMAGE' | 'VIDEO';
  }[] = [];

  for (const mediaItem of product.media || []) {
    if (mediaItem.type === 'IMAGE' || mediaItem.type === 'VIDEO') {
      mediaURLs.push({
        url: mediaItem.url,
        type: mediaItem.type,
      });
    }
  }

  const priceSchedules: PriceSchedule[] = (product.pricing ?? [])
    .filter(
      (priceSchedule) =>
        !priceSchedule.end_date_local ||
        moment(priceSchedule.end_date_local).isAfter(moment())
    )
    .map((priceSchedule) => toProductShapePriceSchedule(priceSchedule));

  let promotionSummaries: PromotionSummary[] = [];
  for (const priceSchedule of priceSchedules) {
    const promotions = findActiveAndFuturePromotions(
      priceSchedule.original.promotions ?? []
    );
    for (const promotion of promotions) {
      promotionSummaries.push(
        toPromotionSummary(promotion, priceSchedule.original.units)
      );
    }
  }

  promotionSummaries = _.uniqBy(promotionSummaries, 'id');

  const cancellationPolicies =
    product?.cancellation_policies != null &&
    product.cancellation_policies.length > 0
      ? product.cancellation_policies
          .filter(
            (cancellationPolicy) =>
              !isDefaultCancellationPolicy(cancellationPolicy)
          )
          .map((cancellationPolicy) =>
            convertToCancellationPolicy(cancellationPolicy)
          )
      : [];
  let instantBookingDeadline: BookingDeadline | null = null;
  let requestBookingDeadline: BookingDeadline | null = null;
  const productHasBookingDeadlines =
    product?.booking_deadlines != null && product.booking_deadlines.length > 0;
  const productInstantBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'INSTANT'
  );
  const productRequestBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'REQUEST'
  );

  if (productHasBookingDeadlines) {
    if (productInstantBookingDeadline) {
      instantBookingDeadline = convertToBookingDeadline(
        productInstantBookingDeadline
      );
    }

    if (productRequestBookingDeadline) {
      requestBookingDeadline = convertToBookingDeadline(
        productRequestBookingDeadline
      );
    }
  }

  const hasPerParticipantPricing =
    product?.pricing == null ||
    product?.pricing.some((priceRule) => {
      if (priceRule.units == null) {
        return false;
      }

      return priceRule.units.some((unit) => unit.method === 'PER_PARTICIPANT');
    });
  const isPackage =
    (product?.shared_allotment_references?.package_component_product_ids ?? [])
      .length > 0;
  const isSharedAllotment = Boolean(
    product?.shared_allotment_references?.parent_product_id
  );
  const isPassthrough = Boolean(
    product?.shared_allotment_references?.passthrough_base_product_id
  );
  const showMinimumParticipants =
    hasPerParticipantPricing &&
    !isPackage &&
    !isSharedAllotment &&
    !isPassthrough;
  return {
    id: product.id || '',
    name: product.product_name || '',
    description: product.description || '',
    currency: getProductCurrency(product) ?? '',
    mediaURLs,
    tags: product.product_tags || [],
    operatingWeekdays: product.operating_days_of_week || [],
    durationInHours: getDurationInHoursFromRecurrence(product.recurrence || []),
    priceSchedules,
    promotionSummaries,
    highlights: product.highlights || [],
    addOns: (product.add_ons || []).map(toServiceShape),
    transportations: (product.transportations || []).map(toServiceShape),
    inclusions: product.inclusions || [],
    exclusions: product.exclusions || [],
    restrictions: product.restrictions || [],
    requirements: product.requirements || [],
    whatToBring: product.what_to_bring || [],
    otherInfo: product.other_notes || [],
    minimumParticipants: showMinimumParticipants
      ? (product.minimum_participant_count &&
          product.minimum_participant_count.value) ??
        0
      : 0,
    schedule: (product.itinerary || []).map(toScheduleEventShape),
    startTimes: getStartTimesFromRecurrence(product.recurrence || []),
    pickupLocations: (product.pickup || []).map(toLocationShape),
    dropoffLocations: (product.dropoff || []).map(toLocationShape),
    checkinLocations: (product.checkin || []).map(toLocationShape),
    checkoutLocations: (product.checkout || []).map(toLocationShape),
    bestDiscountGross: product.best_discount_gross || '',
    bestDiscountPercent: product.best_discount_percent || 0,
    totalBooked: product.total_booked || 0,
    cancellationPolicies,
    instantBookingDeadline,
    requestBookingDeadline,
    agentGuestBookingAllowedDaysBefore:
      product?.agent_guest_booking_period_settings
        ?.booking_allowed_days_before_participation ?? null,
  };
};
export const getPrices = (
  units: Swagger.UnitPricing[],
  promotion?: Swagger.ProductPromotion
): Price[] => {
  return units.map((unit) => {
    const adjustedUnitPrice =
      promotion &&
      promotion.adjusted_unit_prices &&
      promotion.adjusted_unit_prices.find(
        (p) =>
          p.unit_price_method === unit.method &&
          (p.unit_price_method !== 'PER_PARTICIPANT' ||
            p.guest_type_key === (unit.guest_type && unit.guest_type.key))
      );
    let amountAfterChargeOrDiscount = unit.gross ?? '';
    let deltaType: 'CHARGE' | 'DISCOUNT' | '' = '';
    let deltaAmount = '';
    let deltaPercent = '';

    if (adjustedUnitPrice) {
      amountAfterChargeOrDiscount = adjustedUnitPrice.adjusted_gross ?? '';
      deltaType = adjustedUnitPrice.delta_type ?? 'DISCOUNT';
      deltaAmount = adjustedUnitPrice.delta_fixed_gross ?? '';
      deltaPercent = adjustedUnitPrice.delta_percent
        ? `${adjustedUnitPrice.delta_percent}%`
        : '';
    }

    switch (unit.method) {
      case 'PER_PARTICIPANT':
        return {
          unitTitle: (unit.guest_type && unit.guest_type.title) || '',
          minimumAge: unit.guest_type?.minimum_age ?? null,
          maximumAge: unit.guest_type?.maximum_age ?? null,
          amount: unit.gross,
          amountAfterChargeOrDiscount,
          deltaType,
          deltaAmount,
          deltaPercent,
        };

      case 'PER_GROUP':
        return {
          unitTitle: getGroupPriceTitle(unit),
          amount: unit.gross,
          amountAfterChargeOrDiscount,
          deltaType,
          deltaAmount,
          deltaPercent,
        };

      case 'PER_BOOKING':
        return {
          unitTitle: '',
          amount: unit.gross,
          amountAfterChargeOrDiscount,
          deltaType,
          deltaAmount,
          deltaPercent,
        };

      default:
        return {
          unitTitle: unit.title ?? '',
          amount: unit.gross,
          amountAfterChargeOrDiscount,
          deltaType,
          deltaAmount,
          deltaPercent,
        };
    }
  });
};
export const convertToBookingDeadline = (
  productBookingDeadline: Swagger.BookingDeadline
): BookingDeadline => {
  return convertToRelativeDeadline(productBookingDeadline?.relative_date);
};

export const convertToRelativeDeadline = (
  deadline: Swagger.RelativeDateTime
): RelativeDeadline => {
  return deadline.type === 'ETERNAL'
    ? {
        deadlineType: 'ETERNAL',
      }
    : deadline.type === 'HOUR'
    ? {
        deadlineType: 'HOUR',
        hoursBefore: deadline.count ?? 0,
      }
    : deadline.type === 'MINUTE'
    ? {
        deadlineType: 'MINUTE',
        minutesBefore: deadline.count ?? 0,
      }
    : {
        deadlineType: 'DAY',
        daysBefore: deadline.count ?? 0,
        timeOfDay: deadline.time_local ?? '0:00',
      };
};

export const convertToSwaggerRelativeDateTime = (
  deadline: RelativeDeadline
): Swagger.RelativeDateTime => {
  return deadline.deadlineType === 'ETERNAL'
    ? {
        type: 'ETERNAL',
      }
    : deadline.deadlineType === 'HOUR'
    ? {
        type: 'HOUR',
        count: deadline.hoursBefore,
      }
    : deadline.deadlineType === 'MINUTE'
    ? {
        type: 'MINUTE',
        count: deadline.minutesBefore,
      }
    : {
        type: 'DAY',
        count: deadline.daysBefore,
        time_local: deadline.timeOfDay,
      };
};

export const convertToCancellationPolicy = (
  productCancellationPolicy: Swagger.CancellationPolicy
): CancellationPolicy => {
  const chargeFixed = productCancellationPolicy.charge_fixed;
  const feeFixed =
    chargeFixed && chargeFixed.length > 3
      ? chargeFixed.substring(3)
      : undefined;
  return productCancellationPolicy.relative_date.type === 'ETERNAL'
    ? {
        deadlineType: 'ETERNAL',
        ...(feeFixed
          ? {
              feeType: 'FIXED',
              feeFixed,
            }
          : {
              feeType: 'PERCENT',
              feePercent: `${productCancellationPolicy.charge_percent ?? 0}`,
            }),
      }
    : productCancellationPolicy.relative_date.type === 'HOUR'
    ? {
        deadlineType: 'HOUR',
        hoursBefore: productCancellationPolicy.relative_date?.count ?? 0,
        ...(feeFixed
          ? {
              feeType: 'FIXED',
              feeFixed,
            }
          : {
              feeType: 'PERCENT',
              feePercent: `${productCancellationPolicy.charge_percent ?? 0}`,
            }),
      }
    : {
        deadlineType: 'DAY',
        daysBefore: productCancellationPolicy.relative_date?.count ?? 0,
        timeOfDay: productCancellationPolicy.relative_date.time_local || '0:00',
        ...(feeFixed
          ? {
              feeType: 'FIXED',
              feeFixed,
            }
          : {
              feeType: 'PERCENT',
              feePercent: `${productCancellationPolicy.charge_percent ?? 0}`,
            }),
      };
};
export const isDefaultCancellationPolicy = (
  cancellationPolicy: Swagger.CancellationPolicy
): boolean => {
  return (
    cancellationPolicy.relative_date.type === 'HOUR' &&
    !cancellationPolicy.relative_date.count &&
    !cancellationPolicy.charge_fixed &&
    cancellationPolicy.charge_percent === 100
  );
};

const getGroupPriceTitle = (unit: Swagger.UnitPricing): string => {
  const guestTypeCounts: Record<string, number> = {};

  for (const guestType of unit.group_guest_types ?? []) {
    guestTypeCounts[guestType.title] =
      (guestTypeCounts[guestType.title] || 0) + 1;
  }

  const unitBreakdownText = Object.keys(guestTypeCounts)
    .map(
      (guestTypeTitle: string) =>
        `${guestTypeTitle} x ${guestTypeCounts[guestTypeTitle]}`
    )
    .join(',');
  return `${unit.title ?? ''} (${unitBreakdownText})`;
};

const getDurationInHours = (durString: string): string => {
  const match = /(-?[0-9]+):([0-9]+)/.exec(durString);

  if (!match) {
    return '0';
  }

  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);
  const isNegative = Math.sign(hours) === -1 || Object.is(hours, -0);
  const hoursAsNumber = isNegative
    ? -hours - minutes / 60.0
    : hours + minutes / 60.0;
  return Number.isInteger(hoursAsNumber)
    ? `${hoursAsNumber}`
    : hoursAsNumber.toFixed(1);
};

const toServiceShape = (service: Swagger.Service): ServiceShape => {
  return {
    name: service.title || '',
    description: service.description || '',
    type: service.service_type === 'FREE' ? 'FREE' : 'PAID',
    pricesSummary: service.pricing ? getPrices(service.pricing) : [],
  };
};

const toScheduleEventShape = (
  itineraryEvent: Swagger.ItineraryEvent
): ScheduleEventShape => {
  return {
    startTimeRelative: itineraryEvent.start_time_relative ?? '',
    endTimeRelative: itineraryEvent.end_time_relative ?? '',
    name: itineraryEvent.title || '',
    description: itineraryEvent.description || '',
  };
};

const toLocationShape = (
  locationWithTime: Swagger.LocationWithTime
): LocationShape => {
  return {
    name: locationWithTime.location_name || '',
    googlePlaceID: locationWithTime.google_place_id || '',
    description: locationWithTime.location_description || '',
    timeRelative: locationWithTime.time_relative || '',
    dedicatedStartTimeInfoItems: (
      locationWithTime.dedicated_start_time_info || []
    ).map((dedicatedStartTimeInfo) => ({
      timeSlotKey: dedicatedStartTimeInfo.time_slot_key || '',
      timeRelative:
        (dedicatedStartTimeInfo.time_relative &&
          dedicatedStartTimeInfo.time_relative) ||
        '',
    })),
  };
};

const findActiveAndFuturePromotions = (
  promotions: Swagger.ProductPromotion[]
): Swagger.ProductPromotion[] => {
  const now = moment();

  return promotions.filter((promotion) => {
    let latestStartDateLocalTo = '';
    for (const dateRange of promotion.participation_date_ranges || []) {
      if (
        dateRange.end_date_local &&
        dateRange.end_date_local > latestStartDateLocalTo
      ) {
        latestStartDateLocalTo = dateRange.end_date_local;
      }
    }

    return (
      (!promotion.booked_date_local_to ||
        moment(promotion.booked_date_local_to).isAfter(now)) &&
      (!latestStartDateLocalTo || moment(latestStartDateLocalTo).isAfter(now))
    );
  });
};

export const getStartTimesFromRecurrence = (
  recurrence: Swagger.Recurrence[]
): StartTime[] => {
  const startTimesByHhMm: Record<string, StartTime> = {};
  const today = moment().format('YYYY-MM-DD');

  for (const rule of recurrence) {
    const allEndDates = [
      rule.end_date_local,
      ...(rule.additional_date_ranges ?? []).map(
        (dateRange) => dateRange.end_date_local
      ),
    ];

    if (!allEndDates.some((endDate) => (endDate ?? '') >= today)) {
      // Skip recurrence rules that have already passed
      continue;
    }

    if (!rule.start_times) {
      continue;
    }

    let earliestStartDate = '';
    const allStartDates = [
      rule.start_date_local,
      ...(rule.additional_date_ranges ?? []).map(
        (dateRange) => dateRange.start_date_local
      ),
    ];

    if (allStartDates.length > 0) {
      earliestStartDate = allStartDates[0] ?? '';

      for (const date of allStartDates) {
        if (date && date < earliestStartDate) {
          earliestStartDate = date;
        }
      }
    }

    for (const ruleStartTime of rule.start_times) {
      if (!ruleStartTime.start_time_local) {
        continue;
      }

      const startTimeLocal = moment(
        ruleStartTime.start_time_local,
        'H:mm'
      ).format('H:mm');

      const existingStartTime = startTimesByHhMm[startTimeLocal];

      if (
        existingStartTime &&
        existingStartTime.startDate < earliestStartDate
      ) {
        continue;
      }

      startTimesByHhMm[startTimeLocal] = {
        hhMm: startTimeLocal,
        startDate: earliestStartDate,
        timeSlotKey: ruleStartTime.time_slot_key || '',
        description: ruleStartTime.description || '',
      };
    }
  }

  const startTimes = Object.values(startTimesByHhMm);
  startTimes.sort((startTime1, startTime2) => {
    if (
      today < startTime2.startDate &&
      startTime1.startDate < startTime2.startDate
    ) {
      return -1;
    }
    if (
      today < startTime1.startDate &&
      startTime1.startDate > startTime2.startDate
    ) {
      return 1;
    }
    return (
      moment(startTime1.hhMm, 'h:mm').valueOf() -
      moment(startTime2.hhMm, 'h:mm').valueOf()
    );
  });

  return startTimes;
};

export const getDurationInHoursFromRecurrence = (
  recurrence: Swagger.Recurrence[]
): string => {
  const today = moment().format('YYYY-MM-DD');

  const activeRule = recurrence.find((rule) => {
    return (
      (rule.start_date_local <= today && rule.end_date_local >= today) ||
      rule.additional_date_ranges?.some(
        (dateRange) =>
          (dateRange.start_date_local ?? '') <= today &&
          (dateRange.end_date_local ?? '') >= today
      )
    );
  });

  if (activeRule && activeRule.start_times?.length) {
    return getDurationInHours(activeRule.start_times[0].duration);
  }

  const futureRule = recurrence.find((rule) => {
    return (
      rule.end_date_local >= today ||
      rule.additional_date_ranges?.some(
        (dateRange) => (dateRange.end_date_local ?? '') >= today
      )
    );
  });

  if (futureRule && futureRule.start_times?.length) {
    return getDurationInHours(futureRule.start_times[0].duration);
  }

  return '';
};

export const toPromotionSummary = (
  promotion: Swagger.ProductPromotion,
  units: Swagger.UnitPricing[]
): PromotionSummary => ({
  id: promotion.id || '',
  participationDateRanges: (promotion.participation_date_ranges || [])?.map(
    (dateRange) => ({
      startDate: dateRange.start_date_local || '',
      endDate: dateRange.end_date_local || '',
    })
  ),
  participationDaysOfWeek: promotion.participation_days_of_week ?? [],
  bookedDateLocalFrom: promotion.booked_date_local_from || '',
  bookedDateLocalTo: promotion.booked_date_local_to ?? '',
  type: promotion.type ?? 'SIMPLE_DISCOUNT',
  earlyBirdDeadline: promotion.early_bird_deadline ?? null,
  lastMinuteStart: promotion.last_minute_start ?? null,
  groupDiscount: promotion.group_discount ?? null,
  familyDiscount: promotion.family_discount ?? null,
  prices: getPrices(units, promotion) || [],
});
export const compareRelativeDeadlines = (
  relativeDeadline1: RelativeDeadline,
  relativeDeadline2: RelativeDeadline
): number => {
  if (
    relativeDeadline1.deadlineType === 'ETERNAL' ||
    relativeDeadline2.deadlineType === 'ETERNAL'
  ) {
    if (relativeDeadline1.deadlineType !== 'ETERNAL') {
      return -1;
    }
    if (relativeDeadline2.deadlineType !== 'ETERNAL') {
      return 1;
    }

    return 0;
  }
  const relativeDeadline1DaysBefore =
    relativeDeadline1.deadlineType === 'MINUTE'
      ? relativeDeadline1.minutesBefore / 24 / 60
      : relativeDeadline1.deadlineType === 'HOUR'
      ? relativeDeadline1.hoursBefore / 24
      : relativeDeadline1.daysBefore;
  const relativeDeadline2DaysBefore =
    relativeDeadline2.deadlineType === 'MINUTE'
      ? relativeDeadline2.minutesBefore / 24 / 60
      : relativeDeadline2.deadlineType === 'HOUR'
      ? relativeDeadline2.hoursBefore / 24
      : relativeDeadline2.daysBefore;
  const relativeDeadline1TimeOfDay =
    relativeDeadline1.deadlineType === 'HOUR' ||
    relativeDeadline1.deadlineType === 'MINUTE'
      ? '0:00'
      : relativeDeadline1.timeOfDay;
  const relativeDeadline2TimeOfDay =
    relativeDeadline2.deadlineType === 'HOUR' ||
    relativeDeadline2.deadlineType === 'MINUTE'
      ? '0:00'
      : relativeDeadline2.timeOfDay;

  if (relativeDeadline1DaysBefore > relativeDeadline2DaysBefore) {
    return -1;
  }

  if (relativeDeadline1DaysBefore < relativeDeadline2DaysBefore) {
    return 1;
  }

  if (
    moment(relativeDeadline1TimeOfDay, 'H:mm').isBefore(
      moment(relativeDeadline2TimeOfDay, 'H:mm')
    )
  ) {
    return -1;
  }

  if (
    moment(relativeDeadline1TimeOfDay, 'H:mm').isAfter(
      moment(relativeDeadline2TimeOfDay, 'H:mm')
    )
  ) {
    return 1;
  }

  return 0;
};
export const getRelativeDeadlineDescription = (
  relativeDeadline: RelativeDeadline,
  t: TranslateFuncType
): string => {
  if (relativeDeadline.deadlineType === 'ETERNAL') {
    return t('After confirmation');
  }
  if (relativeDeadline.deadlineType === 'DAY') {
    return t('{{count}} days before to {{timeOfDay}}', {
      count: relativeDeadline?.daysBefore,
      timeOfDay: relativeDeadline?.timeOfDay,
    });
  } else if (relativeDeadline.deadlineType === 'HOUR') {
    return t('{{count}} hours before', {
      count: relativeDeadline?.hoursBefore,
    });
  }

  return '';
};
