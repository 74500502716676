import { Content } from '@nutmeglabs/nutmeg-sitecontrol-ui';

import {
  DayOfWeek,
  SiteControlWidget,
  SiteControlWidgetParams,
} from 'shared/models/swagger';

import { appendUtmParametersForTracking, safeConvertToNumber } from './util';

export type Query = {
  parameter?: string;
  value?: string;
};

export type Target = {
  path?: string;
  queries?: Query[];
  hash?: string;
};

export type Schedule = {
  type: 'DATE' | 'TIME' | 'DAYS_OF_WEEK';
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  daysOfWeek?: DayOfWeek[];
};

export type PositioningAxis = {
  xAxis?: number;
  yAxis?: number;
};

export type AudienceTargetingOnVisit = {
  type?:
    | 'LANDING_SOURCE'
    | 'SESSION'
    | 'PAGE_VIEW'
    | 'NUMBER_OF_VISIT'
    | 'PREVIOUS_PAGE';
  referralPaths?: string[];
  dimensionType?: 'COUNT_GTE' | 'COUNT_LT' | 'COUNT_BETWEEN';
  dimensionComparisonValue?: {
    lowerBound?: number;
    upperBound?: number;
  };
};

export type FormValues = {
  name: string;
  baseUrl: string;
  content: Content;
  status: 'DRAFT' | 'ACTIVE';
  includes: Target[];
  excludes: Target[];
  timing: {
    type: 'IMMEDIATELY' | 'SCROLL_DEPTH' | 'TIME_ON_PAGE' | 'TIME_ON_WEBSITE';
    scrollPercentage?: number;
    delayTime?: number;
  };
  stopDisplayTiming?: {
    type: 'FOREVER' | 'X_DAYS';
    numberOfDays?: number;
  };
  schedules?: Schedule[];
  enableBackgroundClick?: boolean;
  positioning?: {
    pc?: PositioningAxis;
    mobile?: PositioningAxis;
  };
  visitAudienceTargets?: AudienceTargetingOnVisit[];
};

export const convertFormValuesToSwagger = (
  values: FormValues,
  widgetId = ''
): SiteControlWidgetParams => {
  const scrollPercentage = values.timing.scrollPercentage
    ? safeConvertToNumber(values.timing.scrollPercentage)
    : undefined;
  const delayTime = values.timing.delayTime
    ? safeConvertToNumber(values.timing.delayTime)
    : undefined;
  const numberOfDays = values.stopDisplayTiming?.numberOfDays
    ? safeConvertToNumber(values.stopDisplayTiming?.numberOfDays)
    : undefined;

  // Check if content is of type 'CARD' and append UTM parameter to button URL
  if (widgetId) {
    if (values.content.type === 'CARD' && values.content.card) {
      if (values.content.card.button.url) {
        values.content.card.button.url = appendUtmParametersForTracking(
          values.content.card.button.url,
          widgetId
        );
      }
    }

    // Check if content is of type 'VERTICAL' and append UTM parameter to button URL
    if (values.content.type === 'VERTICAL' && values.content.vertical) {
      if (values.content.vertical.button.url) {
        values.content.vertical.button.url = appendUtmParametersForTracking(
          values.content.vertical.button.url,
          widgetId
        );
      }
    }

    // Check if content is of type 'MULTI_BUTTONS' and append UTM parameter to button URLs
    if (
      values.content.type === 'MULTI_BUTTONS' &&
      values.content.multiButtons
    ) {
      values.content.multiButtons.buttons =
        values.content.multiButtons.buttons.map((button) => ({
          ...button,
          url: button.url
            ? appendUtmParametersForTracking(button.url, widgetId)
            : undefined,
        }));
    }
  }

  return {
    status: values.status,
    name: values.name,
    base_url:
      values.baseUrl && values.baseUrl.endsWith('/')
        ? values.baseUrl.slice(0, -1)
        : values.baseUrl,
    content_json: JSON.stringify(values.content),
    includes: values.includes,
    excludes: values.excludes,
    timing: {
      type: values.timing.type,
      scroll_percentage: scrollPercentage,
      delay_time: delayTime,
    },
    stop_display_timing: {
      type: values.stopDisplayTiming?.type,
      number_of_days: numberOfDays,
    },
    schedules:
      values.schedules && values.schedules.length > 0
        ? values.schedules.map((schedule) => {
            return {
              type: schedule.type,
              ...(schedule.startDate !== undefined &&
                schedule.startDate !== null && {
                  start_date: schedule.startDate,
                }),
              ...(schedule.endDate !== undefined &&
                schedule.endDate !== null && {
                  end_date: schedule.endDate,
                }),
              ...(schedule.startTime !== undefined && {
                start_time: schedule.startTime,
              }),
              ...(schedule.endTime !== undefined && {
                end_time: schedule.endTime,
              }),
              ...(schedule.daysOfWeek !== undefined && {
                days_of_week: schedule.daysOfWeek,
              }),
            };
          })
        : undefined,
    enable_background_click: values.enableBackgroundClick,
    positioning: {
      pc: {
        x_axis: Number(values.positioning?.pc?.xAxis),
        y_axis: Number(values.positioning?.pc?.yAxis),
      },
      mobile: {
        y_axis: Number(values.positioning?.mobile?.yAxis),
      },
    },
    visit_audience_targets:
      values.visitAudienceTargets && values.visitAudienceTargets.length > 0
        ? values.visitAudienceTargets.map((visitAudienceTarget) => {
            return {
              type: visitAudienceTarget.type,
              ...(visitAudienceTarget.referralPaths !== undefined && {
                referral_paths: visitAudienceTarget.referralPaths,
              }),
              ...(visitAudienceTarget.dimensionType !== undefined && {
                dimension_type: visitAudienceTarget.dimensionType,
              }),
              ...(visitAudienceTarget.dimensionComparisonValue !==
                undefined && {
                dimension_comparison_value: {
                  lower_bound: {
                    value:
                      visitAudienceTarget.dimensionComparisonValue.lowerBound,
                    do_not_omit_empty: true,
                  },
                  upper_bound: {
                    value:
                      visitAudienceTarget.dimensionComparisonValue.upperBound,
                    do_not_omit_empty: true,
                  },
                },
              }),
            };
          })
        : undefined,
  };
};

export const convertSwaggerToFormValues = (
  siteControlWidget: SiteControlWidget
): FormValues => {
  return {
    status: siteControlWidget.status ?? 'DRAFT',
    name: siteControlWidget.name ?? '',
    baseUrl: siteControlWidget.base_url ?? '',
    content: siteControlWidget.content_json
      ? JSON.parse(siteControlWidget.content_json)
      : {},
    includes: siteControlWidget.includes ?? [],
    excludes: siteControlWidget.excludes ?? [],
    timing: {
      type: siteControlWidget.timing?.type ?? 'IMMEDIATELY',
      scrollPercentage: siteControlWidget.timing?.scroll_percentage,
      delayTime: siteControlWidget.timing?.delay_time,
    },
    stopDisplayTiming: siteControlWidget.stop_display_timing
      ? {
          type: siteControlWidget.stop_display_timing?.type ?? 'FOREVER',
          numberOfDays: siteControlWidget.stop_display_timing?.number_of_days,
        }
      : undefined,
    schedules:
      siteControlWidget.schedules && siteControlWidget.schedules.length > 0
        ? siteControlWidget.schedules.map((schedule) => {
            return {
              type: schedule.type ?? 'DATE',
              ...(schedule.type === 'DATE' &&
                schedule.start_date !== undefined && {
                  startDate: schedule.start_date,
                }),
              ...(schedule.type === 'DATE' &&
                schedule.end_date !== undefined && {
                  endDate: schedule.end_date,
                }),
              ...(schedule.type === 'TIME' &&
                schedule.start_time !== undefined && {
                  startTime: schedule.start_time,
                }),
              ...(schedule.type === 'TIME' &&
                schedule.end_time !== undefined && {
                  endTime: schedule.end_time,
                }),
              ...(schedule.type === 'DAYS_OF_WEEK' &&
                schedule.days_of_week !== undefined && {
                  daysOfWeek: schedule.days_of_week,
                }),
            };
          })
        : undefined,
    enableBackgroundClick: siteControlWidget.enable_background_click,
    // Add default values as 0 if positioning is not defined yet or clearables are actually 0
    positioning: {
      pc: {
        xAxis: siteControlWidget.positioning?.pc?.x_axis ?? 0,
        yAxis: siteControlWidget.positioning?.pc?.y_axis ?? 0,
      },
      mobile: {
        yAxis: siteControlWidget.positioning?.mobile?.y_axis ?? 0,
      },
    },
    visitAudienceTargets:
      siteControlWidget.visit_audience_targets &&
      siteControlWidget.visit_audience_targets.length > 0
        ? siteControlWidget.visit_audience_targets.map(
            (visitAudienceTarget) => {
              return {
                type: visitAudienceTarget.type ?? 'LANDING_SOURCE',
                referralPaths: visitAudienceTarget.referral_paths ?? [],
                dimensionType: visitAudienceTarget.dimension_type,
                dimensionComparisonValue: {
                  ...(visitAudienceTarget.dimension_type === 'COUNT_GTE' ||
                  visitAudienceTarget.dimension_type === 'COUNT_BETWEEN'
                    ? {
                        lowerBound:
                          visitAudienceTarget.dimension_comparison_value
                            ?.lower_bound?.value,
                      }
                    : {}),
                  ...(visitAudienceTarget.dimension_type === 'COUNT_LT' ||
                  visitAudienceTarget.dimension_type === 'COUNT_BETWEEN'
                    ? {
                        upperBound:
                          visitAudienceTarget.dimension_comparison_value
                            ?.upper_bound?.value,
                      }
                    : {}),
                },
              };
            }
          )
        : undefined,
  };
};
