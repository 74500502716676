import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useContext,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';

import type { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import { PaymentTypeEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PaymentTypeEditor';
import {
  defaultProductCurrencySelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorForm } from 'client/pages/v3/Product/ProductEdit/ProductEditorForm';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import {
  ProductFormValues,
  convertFormValuesToProductPatch,
  getInitialValues,
} from 'client/pages/v3/Product/ProductEdit/ProductEditContents/FormValues';
import { NtaReservationFormMessage } from 'client/components/ESaas/Nta/NtaReservationFormMessage/NtaReservationFormMessage';
import { isNta } from 'client/components/ESaas/Nta/utils';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { Badge } from 'client/components/v3/Common/Badge';
import {
  getProductSalesStatus,
  getSalesStatusText,
} from 'client/libraries/util/getProductSalesStatus';
import { getBadgeColorForProductStatus } from 'client/libraries/util/getBadgeColorForProductStatus';
import { getProductEditorDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import baseStyles from 'client/v3-base.module.css';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import { PricesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PricesEditor';
import { AvailabilityAllotmentEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/AvailabilityAllotmentEditor';
import { BookingDeadlinesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/BookingDeadlinesInput';
import { CancellationPoliciesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/CancellationPoliciesInput';
import { HighlightsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/HighlightsEditor';
import { TagsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/TagsEditor';
import { InternalTagsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/InternalTagsEditor';
import { DetailInfoEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/DetailInfoEditor';
import { ItineraryEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ItineraryEditor';
import { ServicesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ServicesEditor';
import { ReservationFormEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ReservationFormEditor';
import { MinimumParticipantsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/MinimumParticipantsEditor';
import { CheckinPickupLocationsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/CheckinPickupLocationsEditor';
import { TransportRouteEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/TransportRouteEditor';
import { BasicInfoEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/BasicInfoEditor';
import { operationAllowed } from 'shared/models/access';
import { BookingWidgetSelectableParticipantRulesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/BookingWidgetSelectableParticipantRulesEditor';
import { EmailSettingsInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/EmailSettingsInput';

type Props = {
  showMinimumParticipants: boolean;
  setCurrentSectionId: Dispatch<SetStateAction<string>>;
};

export const ProductEditor = ({
  showMinimumParticipants,
  setCurrentSectionId,
}: Props) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const loading = useSelector((state: ReduxState) => state.products.loading);

  const editingProduct = useContext(EditingProductContext);
  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const defaultTimezone = useSelector(defaultProductTimezoneSelector);

  const initialValues = useMemo(
    () =>
      getInitialValues(
        editingProduct,
        defaultCurrency,
        defaultTimezone,
        t,
        true,
        activeUserOrganization
      ),
    [
      editingProduct,
      defaultCurrency,
      defaultTimezone,
      t,
      loading,
      activeUserOrganization,
    ]
  );

  const convertToProductPatch = useCallback(
    (values: ProductFormValues) =>
      convertFormValuesToProductPatch(
        values,
        defaultCurrency,
        isPackage,
        editingProduct,
        t,
        true
      ),
    [defaultCurrency, isPackage, editingProduct, t, false]
  );

  const hasHighlights = (editingProduct?.highlights || []).length > 0;
  const hasDetailInfo =
    (editingProduct?.inclusions ?? []).length > 0 ||
    (editingProduct?.exclusions ?? []).length > 0 ||
    (editingProduct?.requirements ?? []).length > 0 ||
    (editingProduct?.restrictions ?? []).length > 0 ||
    (editingProduct?.what_to_bring ?? []).length > 0 ||
    (editingProduct?.other_notes ?? []).length > 0;
  const hasItinerary = (editingProduct?.itinerary ?? []).length > 0;
  const hasAddOns = (editingProduct?.add_ons ?? []).length > 0;
  const hasTransportation = (editingProduct?.transportations ?? []).length > 0;
  const hasCheckinPickup =
    (editingProduct?.checkin ?? []).length > 0 ||
    (editingProduct?.checkout ?? []).length > 0 ||
    (editingProduct?.pickup ?? []).length > 0 ||
    (editingProduct?.dropoff ?? []).length > 0;
  const hasForm =
    (
      editingProduct?.reservation_form_fields?.filter(
        (formField) =>
          formField.key !== 'given_name' && formField.key !== 'family_name'
      ) ?? []
    ).length > 0;
  const hasMinPaxOrCountInventorySettings =
    (editingProduct?.minimum_participant_count?.value ?? 0) > 0 ||
    editingProduct?.allotment_settings?.inventory_consumption_rules?.some(
      (rule) => rule.should_not_count_inventory
    );
  const hasProductTags = (editingProduct?.product_tags ?? []).length > 0;
  const hasTransportRoute = (editingProduct?.transport_route ?? []).length > 0;
  const hasInternalProductTags =
    (editingProduct?.internal_product_tags ?? []).length > 0;

  const isNtaHotel =
    isNta(activeUserOrganization) &&
    (editingProduct?.html_texts || []).find((htmlText) => {
      return htmlText.key === 'product-type' && htmlText.text === 'hotel';
    });

  // Guard to ensure product is not null
  if (!editingProduct || loading) {
    return <Loading />;
  }
  const productSalesStatus = getProductSalesStatus(editingProduct);

  return (
    <ProductEditorForm
      id="editProductForm"
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertToProductPatch}
      subscription={{
        submitError: true,
        submitting: true,
      }}
    >
      {({ submitError }) => (
        <>
          <Snackbar
            text={t('Save Successful')}
            color="success"
            shouldShow={success}
          />
          <Snackbar
            text={t('Save Failed')}
            color="error"
            shouldShow={submitError}
          />

          {/* TODO: add which fields are error here based on submit error */}
          {/* <div className="p-products__msg">
            <div className="p-products__msg__ttl">
              <i className="c-icon-outline-alerts-alert-circle"></i>
              <p>保存するには以下のエラーを解消してください</p>
            </div>
            <ul className="p-products__msg__list">
              <li>
                商品 <a>管理用商品名</a>：必須項目を入力してください
              </li>
              <li>
                商品 <a>商品名</a>：必須項目を入力してください
              </li>
            </ul>
          </div> */}

          <section className={styles['g-section']}>
            <div className={styles['p-products__info']}>
              {/* Find the first image in media list and use it for display pic, if there is none do no display */}
              {editingProduct?.media &&
                editingProduct?.media.length > 0 &&
                editingProduct?.media.find((item) => item.type === 'IMAGE') && (
                  <div className={styles['p-products__info__pic']}>
                    <img
                      src={
                        editingProduct?.media.find(
                          (item) => item.type === 'IMAGE'
                        )?.url
                      }
                    />
                  </div>
                )}
              <div className={styles['p-products__info_body']}>
                <div className={styles['p-products__info_body__label status1']}>
                  <Badge
                    label={getSalesStatusText(productSalesStatus, t)}
                    color={getBadgeColorForProductStatus(productSalesStatus)}
                    size="md"
                  />
                </div>
                <p className={styles['p-products__info_body__ttl']}>
                  {getProductEditorDisplayProductName(editingProduct)}
                </p>
              </div>
            </div>
          </section>

          {/* Basic editor */}
          <section
            id="basic"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Basic Information')}
            </p>
            <CollapsibleSection
              id="products"
              title={t('Products')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <BasicInfoEditor />
            </CollapsibleSection>

            <CollapsibleSection
              id="prices"
              title={t('Prices')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <PricesEditor />
            </CollapsibleSection>
          </section>

          {/* Reservation parameters editor */}
          <section
            id="reservationParameters"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Reservation Parameters')}
            </p>
            <CollapsibleSection
              id="participationRules"
              title={t('Participation Rules')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <AvailabilityAllotmentEditor />
            </CollapsibleSection>
            {showMinimumParticipants && (
              <CollapsibleSection
                id="mininumParticipantsAndPerUnitSettings"
                title={t('Minimum Participants & Per-Unit Settings')}
                subtitle={t(
                  'If minimum participants are set, reservations will be received as request-only until this number of people is reached'
                )}
                initialOpen={hasMinPaxOrCountInventorySettings}
                setCurrentSectionId={setCurrentSectionId}
              >
                <MinimumParticipantsEditor />
              </CollapsibleSection>
            )}
            {operationAllowed(activeUser, 'write', 'productContents') && (
              <CollapsibleSection
                id="selectableParticipantRules"
                title={t('Booking Website Selectable Participant Rules')}
                setCurrentSectionId={setCurrentSectionId}
              >
                <BookingWidgetSelectableParticipantRulesEditor />
              </CollapsibleSection>
            )}
            <CollapsibleSection
              id="bookingPeriod"
              title={t('Booking Period')}
              subtitle={t(
                '* Times and settings for accepting online reservations. All local time.'
              )}
              setCurrentSectionId={setCurrentSectionId}
            >
              <BookingDeadlinesInput />
            </CollapsibleSection>
            {operationAllowed(activeUser, 'write', 'productContents') && (
              <CollapsibleSection
                id="paymentType"
                title={t('Booking Website Payment Types')}
                setCurrentSectionId={setCurrentSectionId}
              >
                <PaymentTypeEditor />
              </CollapsibleSection>
            )}
            <CollapsibleSection
              id="cancellationPolicies"
              title={t('Cancellation Policies')}
              subtitle={t(
                '* Decide when cancellation fees will be charged. New rules can be added with the "+" mark.'
              )}
              setCurrentSectionId={setCurrentSectionId}
            >
              <CancellationPoliciesInput />
            </CollapsibleSection>
          </section>

          {/* Details editor */}
          <section
            id="detail"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Detail Information')}
            </p>
            <CollapsibleSection
              id="highlights"
              title={t('Highlights')}
              subtitle={t('Enter the highlights and summary of the experience')}
              initialOpen={hasHighlights}
              setCurrentSectionId={setCurrentSectionId}
            >
              <HighlightsEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="detailedInfoVoucher"
              title={t('Detailed Info (shown on voucher)')}
              subtitle={t(
                'Enter detailed info for the experience (info is shown automatically on reservation confirmation email)'
              )}
              initialOpen={hasDetailInfo}
              setCurrentSectionId={setCurrentSectionId}
            >
              <DetailInfoEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="experienceItinerary"
              title={t('Experience Itinerary')}
              subtitle={t('Explain the flow of the experience')}
              initialOpen={hasItinerary}
              setCurrentSectionId={setCurrentSectionId}
            >
              <ItineraryEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="addOns"
              title={t('Add-Ons')}
              subtitle={t(
                'Describe additional options that can be selected on the booking form'
              )}
              initialOpen={hasAddOns}
              setCurrentSectionId={setCurrentSectionId}
            >
              <ServicesEditor name="addOns" addButtonLabel={t('Add Add-on')} />
            </CollapsibleSection>
            <CollapsibleSection
              id="transportation"
              title={t('Transportation')}
              subtitle={t(
                'Register if there is pickup (not required for checkin-only)'
              )}
              initialOpen={hasTransportation}
              setCurrentSectionId={setCurrentSectionId}
            >
              <ServicesEditor
                name="transportations"
                addButtonLabel={t('Add Transportation')}
              />
            </CollapsibleSection>
            <CollapsibleSection
              id="checkinPickupLocations"
              title={t('Checkin/Pickup Locations')}
              subtitle={t(
                'Enter location and time details in advance to make it easy for customers to understand'
              )}
              initialOpen={hasCheckinPickup}
              setCurrentSectionId={setCurrentSectionId}
            >
              <CheckinPickupLocationsEditor />
            </CollapsibleSection>
            {!isNtaHotel && (
              <CollapsibleSection
                id="reservationForm"
                title={t('Reservation Form')}
                subtitle={t(
                  'Customize information required from customers at the time of booking'
                )}
                initialOpen={hasForm}
                setCurrentSectionId={setCurrentSectionId}
              >
                <ReservationFormEditor />
              </CollapsibleSection>
            )}
            {isNtaHotel && (
              <CollapsibleSection
                id="reservationForm"
                title={t('Reservation Form')}
                initialOpen={hasForm}
                setCurrentSectionId={setCurrentSectionId}
              >
                <NtaReservationFormMessage />
              </CollapsibleSection>
            )}
            <CollapsibleSection
              id="productTags"
              title={t('Product Tags')}
              subtitle={t(
                'Use tags for displaying detailed conditions of the experience and help customers narrow down their selection'
              )}
              initialOpen={hasProductTags}
              setCurrentSectionId={setCurrentSectionId}
            >
              <TagsEditor />
            </CollapsibleSection>
          </section>

          {/* Internal management editor */}
          <section
            id="internalManagement"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Internal Management')}
            </p>
            <CollapsibleSection
              id="transportRoutes"
              title={t('Transport Routes')}
              initialOpen={hasTransportRoute}
              setCurrentSectionId={setCurrentSectionId}
            >
              <TransportRouteEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="internalProductTags"
              title={t('Internal Use Product Tags')}
              subtitle={t('Set tags for internal use on product list page')}
              initialOpen={hasInternalProductTags}
              setCurrentSectionId={setCurrentSectionId}
            >
              <InternalTagsEditor />
            </CollapsibleSection>

            <CollapsibleSection
              id="emailSettings"
              title={t('Email Settings')}
              setCurrentSectionId={setCurrentSectionId}
            >
              <EmailSettingsInput />
            </CollapsibleSection>
          </section>
        </>
      )}
    </ProductEditorForm>
  );
};
