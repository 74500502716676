import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { SubscriptionsEditor } from 'client/pages/Settings/SubscriptionsEditor';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { PaymentMethodEditorModal } from 'client/pages/Settings/PaymentMethodEditorModal';
import baseStyles from 'client/base.module.css';

import { BankAccountEditor } from './BankAccountEditor';

export const SystemFeeSettings = () => {
  const { t } = useTranslation();
  const [paymentMethodSuccess, setPaymentMethodSuccess] =
    React.useState<boolean>(false);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const apiKey =
    activeUserOrganization?.default_subscriptions_payment_currency === 'JPY'
      ? config.stripeConfig.publicJPYApiKey
      : config.stripeConfig.publicUSDApiKey;

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="SYSTEM_FEE_SETTINGS" />
      )}
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__body']}>
          <FormTableBox style={{ width: '100%' }} header={t('Payments')}>
            <table>
              <tbody>
                <tr>
                  <th>{t('Credit Card')}</th>
                  <td>
                    <Box mb={2}>
                      {t(
                        'Credit card to be used for monthly payments of system fees and subscription fees'
                      )}
                    </Box>
                    {activeUserOrganization?.payment_profile_card_info && (
                      <Box ml={2} mb={4}>
                        <Box display="flex">
                          <Box mr={2}>
                            {activeUserOrganization?.payment_profile_card_info
                              ?.card_brand ?? ''}
                          </Box>
                          <Box ml={2} mr={2}>
                            XXXXXXXXXXXX
                            {activeUserOrganization?.payment_profile_card_info
                              ?.last_four_digits ?? ''}
                          </Box>
                          <Box ml={2} mr={2}>
                            {t('Exp: ')}
                            {
                              activeUserOrganization?.payment_profile_card_info
                                ?.exp_month
                            }{' '}
                            /{' '}
                            {activeUserOrganization?.payment_profile_card_info
                              ?.exp_year ?? ''}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <StripeProvider key={apiKey} apiKey={apiKey}>
                      <Elements>
                        <PaymentMethodEditorModal
                          onOpen={() => setPaymentMethodSuccess(false)}
                          onSuccess={() => setPaymentMethodSuccess(true)}
                        />
                      </Elements>
                    </StripeProvider>
                    {paymentMethodSuccess && (
                      <Message success header={t('Update Succeeded')} />
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t('Bank Account')}</th>
                  <td>
                    <BankAccountEditor />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormTableBox>
          <FormTableBox style={{ width: '100%' }} header={t('Subscriptions')}>
            <SubscriptionsEditor />
          </FormTableBox>
        </div>
      </div>
    </>
  );
};
