import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import * as Swagger from 'shared/models/swagger';

import type { Price, PerGroupPrice, Schedule } from '../types';

export type PriceSchedule = Schedule & {
  title: string;
  method: 'PER_PARTICIPANT' | 'PER_BOOKING';
  prices: Price[];
  // Per-participant schedules may also have per-group prices
  perGroupPrices: PerGroupPrice[];
  applySurcharge: boolean;
  surchargeTitle?: string;
  surchargeRate?: string;
  applyMinimumPrice: boolean;
  minimumGross?: string;
  minimumNet?: string;
};
export type BasicFormValues = {
  productCode: string;
  productName: string;
  internalProductName: string;
  productDescription: string;
  mediaUrls: string[];
  priceSchedules: PriceSchedule[];
  shouldShowPricingOnBwCalendar: boolean;
};
export const getInitialPriceMethod = (
  units: Swagger.UnitPricing[]
): 'PER_BOOKING' | 'PER_PARTICIPANT' => {
  return units.find((unit) => unit.method === 'PER_PARTICIPANT')
    ? 'PER_PARTICIPANT'
    : 'PER_BOOKING';
};
export const getInitialPrices = (
  units: Swagger.UnitPricing[],
  method: 'PER_BOOKING' | 'PER_PARTICIPANT'
): Price[] => {
  return (units || [])
    .filter((unit) => unit.method === method)
    .map((unit) => ({
      method,
      unit: unit.guest_type?.key ?? '',
      groupUnits: (unit.group_guest_types ?? []).map(
        (guestType) => guestType.key
      ),
      perBookingUnits: unit.per_booking_guest_types?.map(
        (perBookingGuestType) => ({
          unit: perBookingGuestType.key ?? '',
          title: perBookingGuestType.title ?? '',
          ageBandMinimum: perBookingGuestType?.minimum_age ?? 0,
          ageBandMaximum: perBookingGuestType?.maximum_age ?? 0,
        })
      ),
      price: unit.gross && unit.gross?.substring(3),
      netPrice: unit.net && unit.net?.substring(3),
      perAgentNetPrices:
        unit.per_agent_net?.map((agentNet) => ({
          agentId: agentNet.agent_id ?? '',
          netFixed: agentNet.net_fixed,
        })) ?? [],
      ageBandMinimum: unit.guest_type?.minimum_age ?? 0,
      ageBandMaximum: unit.guest_type?.maximum_age ?? 0,
      packageComponentUnitMappings:
        unit.guest_type?.package_component_guest_type_mappings?.map(
          (mapping) => ({
            componentProductId: mapping.product_id ?? '',
            componentProductUnit: mapping.guest_type_key ?? '',
          })
        ),
      weight: unit?.guest_type?.minimum_participant_parameters
        ? unit?.guest_type?.minimum_participant_parameters.weight || 0
        : 1,
      referenceInSupplierSystem: unit.guest_type?.third_party_reference,
      referencesInAgentSystems: (unit.guest_type?.agent_references ?? []).map(
        (ref) => ({
          agentId: ref.agent_id ?? '',
          reference: ref.agent_reference ?? '',
        })
      ),
    }));
};
export const getInitialPerGroupPrices = (
  units: Swagger.UnitPricing[]
): PerGroupPrice[] => {
  return (units || [])
    .filter((unit) => unit.method === 'PER_GROUP')
    .map((unit) => ({
      title: unit.title ?? '',
      groupUnits: (unit.group_guest_types ?? []).map(
        (guestType) => guestType.key
      ),
      price: unit.gross && unit.gross?.substring(3),
      netPrice: unit.net && unit.net?.substring(3),
      perAgentNetPrices:
        unit.per_agent_net?.map((agentNet) => ({
          agentId: agentNet.agent_id ?? '',
          netFixed: agentNet.net_fixed,
        })) ?? [],
    }));
};
export const getInitialPriceSchedules = (
  editingProduct: Swagger.Product | null
): PriceSchedule[] => {
  const priceSchedules: PriceSchedule[] = [];

  for (const productPriceRule of editingProduct?.pricing ?? []) {
    const method = getInitialPriceMethod(productPriceRule.units || []);
    priceSchedules.push({
      title: productPriceRule.title ?? '',
      dateRange: {
        startDate: productPriceRule.start_date_local ?? '',
        endDate: productPriceRule.end_date_local ?? '',
      },
      weekdays: productPriceRule.days_of_week ?? [],
      closedDates: [],
      method,
      prices: getInitialPrices(productPriceRule.units || [], method),
      perGroupPrices: getInitialPerGroupPrices(productPriceRule.units || []),
      applySurcharge: Boolean(
        productPriceRule.surcharge?.rate || productPriceRule.surcharge?.title
      ),
      surchargeRate: productPriceRule.surcharge?.rate,
      surchargeTitle: productPriceRule.surcharge?.title,
      applyMinimumPrice: Boolean(
        productPriceRule.minimum_price_gross ||
          productPriceRule.minimum_price_net
      ),
      minimumGross:
        productPriceRule.minimum_price_gross &&
        productPriceRule.minimum_price_gross?.substring(3),
      minimumNet:
        productPriceRule.minimum_price_net &&
        productPriceRule.minimum_price_net?.substring(3),
    });
  }

  return priceSchedules;
};
export const getInitialValues = (
  editingProduct: Swagger.Product | null
): BasicFormValues => {
  return {
    productCode: editingProduct?.supplier_reference ?? '',
    productName: editingProduct?.product_name ?? '',
    internalProductName: editingProduct?.internal_product_name ?? '',
    productDescription: editingProduct?.description ?? '',
    mediaUrls: (editingProduct?.media || []).map((mediaItem) => mediaItem.url),
    priceSchedules: getInitialPriceSchedules(editingProduct),
    shouldShowPricingOnBwCalendar:
      editingProduct?.should_show_pricing_on_bw_calendar?.value ?? false,
  };
};
export const convertFormValuesToPricing = (
  values: PriceSchedule[],
  defaultCurrency: string
): Swagger.Pricing$Patch[] => {
  return values.map<Swagger.Pricing$Patch>((priceSchedule) => ({
    start_date_local: priceSchedule.dateRange.startDate,
    end_date_local: priceSchedule.dateRange.endDate,
    surcharge:
      priceSchedule.applySurcharge &&
      (priceSchedule.surchargeTitle || priceSchedule.surchargeRate)
        ? {
            title: priceSchedule.surchargeTitle,
            rate: priceSchedule.surchargeRate,
          }
        : undefined,
    minimum_price_gross:
      priceSchedule.applyMinimumPrice && priceSchedule.minimumGross
        ? `${defaultCurrency}${priceSchedule.minimumGross}`
        : '',
    minimum_price_net:
      priceSchedule.applyMinimumPrice && priceSchedule.minimumNet
        ? `${defaultCurrency}${priceSchedule.minimumNet}`
        : '',
    title: priceSchedule.title,
    days_of_week: priceSchedule.weekdays,
    units: [
      ...priceSchedule.prices.map((price) => {
        let guestType: Swagger.GuestType | undefined;

        if (price.unit) {
          guestType = {
            title: price.unit,
            key: price.unit,
            minimum_age: price.ageBandMinimum,
            maximum_age: price.ageBandMaximum,
            package_component_guest_type_mappings:
              price.packageComponentUnitMappings?.map((mapping) => ({
                product_id: mapping.componentProductId,
                guest_type_key: mapping.componentProductUnit,
              })),
            minimum_participant_parameters:
              price.weight != null
                ? {
                    weight: price.weight,
                  }
                : undefined,
            agent_references: (price.referencesInAgentSystems ?? []).map(
              (ref) => ({
                agent_id: ref.agentId,
                agent_reference: ref.reference,
              })
            ),
            third_party_reference: price.referenceInSupplierSystem,
          };
        }

        return {
          method: priceSchedule.method,
          guest_type: guestType,
          per_booking_guest_types: price.perBookingUnits?.map(
            (perBookingUnit) => ({
              title: perBookingUnit.unit,
              key: perBookingUnit.unit,
              minimum_age: perBookingUnit.ageBandMinimum,
              maximum_age: perBookingUnit.ageBandMaximum,
            })
          ),
          gross: `${defaultCurrency}${price.price ?? 0}`,
          net: `${defaultCurrency}${price.netPrice ?? 0}`,
          per_agent_net: price.perAgentNetPrices?.map((agentNet) => ({
            agent_id: agentNet.agentId,
            net_fixed: agentNet.netFixed,
          })),
        };
      }),
      ...(priceSchedule.perGroupPrices ?? []).map((price) => ({
        method: 'PER_GROUP' as Swagger.UnitPricing['method'],
        title: price.title,
        group_guest_types: (price.groupUnits ?? []).map((groupUnit) => ({
          title: groupUnit,
          key: groupUnit,
        })),
        gross: `${defaultCurrency}${price.price ?? 0}`,
        net: `${defaultCurrency}${price.netPrice ?? 0}`,
        per_agent_net: price.perAgentNetPrices?.map((agentNet) => ({
          agent_id: agentNet.agentId,
          net_fixed: agentNet.netFixed,
        })),
      })),
    ],
  }));
};
export const convertFormValuesToProductPatch = (
  values: BasicFormValues,
  defaultCurrency: string
): Swagger.Product$Patch => {
  return {
    supplier_reference: values.productCode,
    product_name: values.productName,
    internal_product_name: values.internalProductName,
    description: values.productDescription,
    media: convertMediaUrlsToProductMediaItems(values.mediaUrls || []) as any,
    pricing: convertFormValuesToPricing(values.priceSchedules, defaultCurrency),
    should_show_pricing_on_bw_calendar: {
      value: values.shouldShowPricingOnBwCalendar,
    },
  };
};
