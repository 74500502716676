import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { forwardRef, useState, useContext } from 'react';
import moment from 'moment';

import type {
  Reservation,
  Account,
  Product,
  ProductInstance,
} from 'shared/models/swagger';
import type { LanguageISO } from 'shared/libraries/i18n';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupDescription,
  TutorialPopupHeader,
} from 'client/components/TutorialPopup/TutorialPopup';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { ReservationPinModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/MainInformation/ReservationPinModal';
import { ReservationMainInformationUpdateModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/MainInformation/ReservationMainInformationUpdateModal';
import { LabelWithHelpText } from 'client/components/LabelWithHelpText';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { calculateResellNet } from 'client/libraries/util/calculateResellNet';
import {
  getCurrentStatus,
  getGuestTypesUsedInProductInstance,
  isTerminalReservationStatus,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { getGuestName } from 'client/libraries/util/getGuestName';
import { operationAllowed } from 'shared/models/access';
import { PaymentMethod } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/MainInformation/PaymentMethod';
import { Button } from 'client/components/v3/Common/Button';
import { Badge } from 'client/components/v3/Common/Badge';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { getBadgeColorForReservationStatus } from 'client/libraries/util/getBadgeColorForReservationStatus';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { i18n } from 'client/libraries/i18n';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  product: Product;
  productInstance: ProductInstance;
  locale: LanguageISO;
  activeUser: Account | null;
  readOnly?: boolean;
};

type RouteParams = {
  id: string;
};

export const MainInformation = forwardRef<any, Props>((props: Props) => {
  const { reservation, product, productInstance, activeUser, readOnly } = props;

  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const [showModal, setShowModal] = useState(false);
  const [openReservationPinModal, setOpenReservationPinModal] =
    useState<boolean>(false);
  const { partnershipMode } = useContext(PartnershipModeContext);

  if (!reservation || !product || !productInstance) {
    return null;
  }

  // Addon
  const addOnItems: string[] = [];
  (reservation.add_ons || []).forEach((addOnKey) => {
    const addOn =
      product.add_ons && product.add_ons.find((a) => a.key === addOnKey);
    if (addOn) {
      // mapped per-reservation addon
      addOnItems.push(addOn.title);
    } else {
      // unmapped per-reservation addon
      addOnItems.push(addOnKey);
    }
  });
  // mapped per-guest addons
  (product.add_ons || []).forEach((addOn) => {
    addOn.pricing &&
      addOn.pricing.forEach((pr) => {
        const guestType = pr.guest_type;
        if (pr.method === 'PER_PARTICIPANT' && guestType) {
          const count = (reservation.guests || []).filter(
            (g) =>
              (guestType.key === g.guest_type_key ||
                // Match on guest type title because agent reverse mapping may cause
                // the reservation guest type key to not match the corresponding product
                // guest type key.
                guestType.title === g.guest_type_title) &&
              (g.add_ons || []).indexOf(addOn.key ?? '') !== -1
          ).length;

          if (count > 0) {
            addOnItems.push(`${addOn.title} - ${guestType.title} (x${count})`);
          }
        }
      });
  });

  let setAdditionalBuyCount = 0;
  const individualAdditionalBuyCount: { [index: string]: number } = {};
  (reservation?.e_ticket_additional_redemption_counts || []).forEach(
    (count) => {
      if (count.type === 'SET') {
        setAdditionalBuyCount += 1;
      } else if (count.type === 'INDIVIDUAL') {
        const key = `${t('Additional Ticket')}(${count.redemption_count}${
          i18n.language === 'ja' ? '枚' : ''
        })`;
        if (!individualAdditionalBuyCount[key]) {
          individualAdditionalBuyCount[key] = 0;
        }
        individualAdditionalBuyCount[key] += 1;
      }
    }
  );

  if (setAdditionalBuyCount) {
    addOnItems.push(
      `${t('Additional Ticket(Set)')} (x${setAdditionalBuyCount})`
    );
  }

  Object.keys(individualAdditionalBuyCount).forEach((key) => {
    addOnItems.push(`${key} (x${individualAdditionalBuyCount[key]})`);
  });

  // unmapped per-guest addons
  (reservation.guests || []).forEach((guest) => {
    const unmapped_addons = (guest.add_ons || []).filter(
      (per_guest_addon_key) =>
        !(product.add_ons || [])
          .filter(
            (product_addon) =>
              product_addon.pricing &&
              product_addon.pricing
                .map((pr) => pr.method)
                .includes('PER_PARTICIPANT')
          )
          .map((product_addon) => product_addon.key)
          .includes(per_guest_addon_key)
    );
    unmapped_addons.forEach((unmapped_addon) => {
      addOnItems.push(unmapped_addon);
    });
  });
  const addOnSummary = addOnItems.join(', ');

  // Transportation
  let transportationSummary = '';
  const transKey = reservation.transportation;
  if (transKey) {
    const trans = (product.transportations || []).find(
      (trans) => trans.key === transKey
    );
    if (trans) {
      if (trans.service_type === 'FREE') {
        transportationSummary = trans.title;
      } else {
        const perBookingPricing =
          trans.pricing &&
          trans.pricing.find((pr) => pr.method === 'PER_BOOKING');
        if (perBookingPricing) {
          transportationSummary = trans.title;
        } else {
          const transItems: string[] = [];
          trans.pricing &&
            trans.pricing.forEach((pr) => {
              const guestType = pr.guest_type;
              const guestCount = (reservation.guests || []).filter(
                (g) => guestType && g.guest_type_key === guestType.key
              ).length;
              if (guestType && guestCount > 0) {
                transItems.push(
                  `${trans.title} - ${guestType.title} (x${guestCount})`
                );
              }
            });

          transportationSummary = transItems.join(', ');
        }
      }
    } else {
      transportationSummary = transKey;
    }
  }
  const isCheckinCheckoutOnly = reservationIsCheckinCheckoutOnly(reservation);
  if (!transportationSummary) {
    transportationSummary = isCheckinCheckoutOnly
      ? t('Checkin/Checkout Only')
      : t('Pickup/Dropoff Included');
  }

  // Guest
  const guestCounts = [];
  const guestCountMap: { [key: string]: number } = {};
  const productGuestTypes = getGuestTypesUsedInProductInstance(
    productInstance,
    product,
    t
  );
  reservation.guests.forEach((g: any) => {
    const guestTitle =
      g.guest_type_title ||
      (((productGuestTypes.find(
        (guest_type) => guest_type.key === g.guest_type_key
      ) as any)
        ? (
            productGuestTypes.find(
              (guest_type) => guest_type.key === g.guest_type_key
            ) as any
          ).title
        : g.guest_type_key) as string);

    if (guestTitle in guestCountMap) {
      guestCountMap[guestTitle]++;
    } else {
      guestCountMap[guestTitle] = 1;
    }
  });
  for (const guestTitle in guestCountMap) {
    guestCounts.push({
      guestTitle: guestTitle,
      guestCount: guestCountMap[guestTitle],
    });
  }
  const guestSummary = guestCounts
    .map((g) => `${g.guestTitle} (x${g.guestCount})`)
    .join(', ');

  const customerName = getGuestName(reservation);
  const currentStatus = getCurrentStatus(reservation);

  const isResellAgent =
    reservation.contract_type === 'RESELL' &&
    activeUser?.organization_type === 'AGENT';
  let netTotal = '';
  if (reservation.billing_info && reservation.billing_info.amount_net) {
    netTotal = formattedCurrencyAmount(reservation.billing_info.amount_net);
    if (isResellAgent && reservation.billing_info?.amount_gross) {
      netTotal = calculateResellNet(
        reservation.billing_info.amount_gross,
        reservation.billing_info.amount_net ?? ''
      );
    }
  }

  const participatesAt = moment.tz(
    reservation.start_date_time_utc,
    reservation.start_timezone ?? ''
  );

  const userIsPassthroughOrg =
    (reservation.agent_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'AGENT') ||
    (reservation.supplier_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'SUPPLIER');

  const userCanEditSupplierReference =
    !userIsPassthroughOrg &&
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationSupplierReference') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !isTerminalReservationStatus(currentStatus) &&
    !partnershipMode;

  const showPinButton = (
    reservation: Reservation,
    activeUser: Account | null
  ) => {
    if (
      [
        'WITHDRAWN_BY_AGENT',
        'DECLINED_BY_SUPPLIER',
        'CANCELED_BY_AGENT',
        'CANCELED_BY_GUEST',
        'CANCELED_BY_SUPPLIER',
      ].includes(reservation.status)
    ) {
      return false;
    }

    if (partnershipMode) {
      return false;
    }

    if (activeUser?.organization_type === 'AGENT') {
      return false;
    }

    return true;
  };

  const getCustomerNameDisplay = (name: string) => {
    return (
      <>
        {name.split(' ').map((s, index) => (
          <span
            key={index}
            style={{
              display: 'inline-block',
              marginRight: '2px',
            }}
          >
            {s}{' '}
          </span>
        ))}
      </>
    );
  };

  const customerId = (reservation?.field_responses ?? []).find(
    (fieldResponse) => fieldResponse.key === 'customer_id'
  )?.response;

  return (
    <section id="main" className={styles['g-section']}>
      <TutorialPopup
        name="main"
        content={
          <TutorialPopupContent>
            <TutorialPopupHeader text={t('Main Information')} />
            <TutorialPopupDescription
              text={t(
                'This is the section where you can check the main information of the reservation. Information is always displayed for each item so that you can check the latest reservation status.'
              )}
            />
          </TutorialPopupContent>
        }
      />

      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Main Information')}
          </p>
          <div className={styles['p-reservationsDetail__actions']}>
            {hasCustomUserRoleWritePermissions(
              activeUser,
              'RESERVATION.LIST'
            ) && (
              <>
                {showPinButton(reservation, activeUser) && (
                  <>
                    <ReservationPinModal
                      open={openReservationPinModal}
                      onClose={() => setOpenReservationPinModal(false)}
                      reservation={reservation}
                    />
                    <Tooltip
                      text={`${t('Pinned until')}: ${moment(
                        reservation.pin_info?.due_date_time_utc
                      ).format('YYYY/MM/DD HH:mm')}\n${
                        reservation.pin_info?.memo
                      }`}
                      width={300}
                      mobileStyle={{
                        left: '100px',
                      }}
                      shouldShow={
                        // Explicitly set T/F since is_pinned can be undefined
                        reservation?.pin_info?.is_pinned ? true : false
                      }
                    >
                      <Button
                        text={
                          reservation?.pin_info?.is_pinned
                            ? t('Pinned')
                            : t('Pin', {
                                context: 'reservationUISection',
                              })
                        }
                        uiType="bg"
                        size="md"
                        color="gray"
                        onClick={() => setOpenReservationPinModal(true)}
                        iconBeforeText={
                          reservation?.pin_info?.is_pinned ? (
                            <i className="c-icon-solid-general-pin-02"></i>
                          ) : (
                            <i className="c-icon-outline-general-pin-02"></i>
                          )
                        }
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className={styles['p-primary']}>
            <div className={styles['p-primary__header']}>
              <div className={styles['p-primary__header__ttl']}>
                <Badge
                  label={t(reservation.status)}
                  color={getBadgeColorForReservationStatus(reservation.status)}
                />
                <p className={styles['p-primary__header__ttl__id']}># {id}</p>
              </div>
            </div>
            <div className={styles['p-primary__body']}>
              <table className={tableStyles['c-tableSmall']}>
                <tbody>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Application Number')}
                    </th>
                    <td>{reservation.agent_reference}</td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Confirmation Number')}
                    </th>
                    <td>
                      <div
                        className={
                          styles['p-primary__body__confirmationNumber']
                        }
                      >
                        <div>{reservation.supplier_reference}</div>
                        <div>
                          {userCanEditSupplierReference && (
                            <Button
                              text={t('Edit')}
                              uiType="bg"
                              size="sm"
                              color="white"
                              iconBeforeText={
                                <i className="c-icon-outline-general-edit-05"></i>
                              }
                              onClick={() => setShowModal(true)}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Participation Date')}
                    </th>
                    <td>
                      {reservation.is_dynamic_package_reservation ? (
                        <ul>
                          {reservation?.package_component_reservation_summaries?.map(
                            (packageComponentReservationSummary, index) => (
                              <li key={index}>
                                {moment
                                  .tz(
                                    packageComponentReservationSummary?.start_date_time_utc ??
                                      '',
                                    reservation?.start_timezone ?? ''
                                  )
                                  .format('lll')}
                                (
                                {
                                  packageComponentReservationSummary?.product_name
                                }
                                )
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <>{participatesAt.format('lll')}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Product Name')}
                    </th>
                    <td>
                      <p>{reservation?.internal_product_name}</p>
                      <p>({reservation?.product_name})</p>
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Add-ons')}
                    </th>
                    <td>{addOnSummary}</td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Transportation')}
                    </th>
                    <td>{transportationSummary}</td>
                  </tr>
                </tbody>
              </table>
              <table className={tableStyles['c-tableSmall']}>
                <tbody>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Customer')}
                    </th>
                    <td>
                      {customerId ? (
                        <Link to={`/customers/${customerId}`}>
                          {getCustomerNameDisplay(customerName)}
                        </Link>
                      ) : (
                        <>{getCustomerNameDisplay(customerName)}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>{t('Guest')}</th>
                    <td>{guestSummary}</td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Landing Source')}
                    </th>
                    <td>
                      {reservation?.landing_sid ||
                        reservation?.landing_referrer}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Booking source')}
                    </th>
                    <td>
                      {operationAllowed(
                        activeUser,
                        'read',
                        'reservationBookingSource'
                      ) &&
                        reservation.booking_source &&
                        (() => {
                          const bookingSource = reservation.booking_source;
                          const bookingSourceType = bookingSource?.source_type;
                          if (!bookingSourceType) {
                            return null;
                          }

                          const isResell =
                            reservation.contract_type === 'RESELL';
                          let bookingAgent = '';
                          if (bookingSource?.agent_name) {
                            if (
                              reservation.agent_name &&
                              reservation.agent_name !==
                                bookingSource.agent_name
                            ) {
                              // Prefix booking agent with contract agent name
                              // Ex: "H.I.S. Australia (HOPS)"
                              bookingAgent = `${reservation.agent_name} (${bookingSource.agent_name})`;
                            } else {
                              bookingAgent = bookingSource.agent_name;
                            }
                          }

                          if (bookingSourceType === 'AGENT') {
                            return (
                              <>
                                {(bookingAgent || '') +
                                  ` (${isResell ? t('resell') : t('agent')})`}
                              </>
                            );
                          }

                          if (bookingSourceType === 'GROUP') {
                            return <>{bookingSource?.group_name || ''}</>;
                          }

                          return <>{t(bookingSourceType)}</>;
                        })()}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Payment Type')}
                    </th>
                    <td>
                      {operationAllowed(
                        activeUser,
                        'read',
                        'reservationPaymentType'
                      ) &&
                        reservation.payment_type &&
                        (reservation.payment_type
                          ? t(reservation.payment_type)
                          : t('Unspecified'))}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Payment Method')}
                    </th>
                    <td>
                      {operationAllowed(
                        activeUser,
                        'read',
                        'reservationPaymentType'
                      ) && <PaymentMethod reservation={reservation} />}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>{t('Gross')}</th>
                    <td>
                      {reservation.billing_info?.amount_gross ? (
                        formattedCurrencyAmount(
                          reservation.billing_info.amount_gross
                        )
                      ) : (
                        <LabelWithHelpText
                          text={t('Not confirmed')}
                          helpText={t(
                            'Details are displayed on confirmed reservations and cancelled reservations with cancel fees. It may take up to 1 minute, please reload your browser if details are not displayed.'
                          )}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>{t('Net')}</th>
                    <td>
                      {reservation.billing_info ? (
                        netTotal
                      ) : (
                        <LabelWithHelpText
                          text={t('Not confirmed')}
                          helpText={t(
                            'Details are displayed on confirmed reservations and cancelled reservations with cancel fees. It may take up to 1 minute, please reload your browser if details are not displayed.'
                          )}
                        />
                      )}
                    </td>
                  </tr>
                  {(reservation.status === 'CANCELED_BY_SUPPLIER' ||
                    reservation.status === 'CANCELED_BY_AGENT' ||
                    reservation.status == 'CANCELED_BY_GUEST') && (
                    <>
                      <tr>
                        <th className={baseStyles['u-width-176']}>
                          {t('Cancellation Fee Gross')}
                        </th>
                        <td>
                          {reservation.billing_info
                            ?.amount_cancellation_fee_gross
                            ? formattedCurrencyAmount(
                                reservation.billing_info
                                  ?.amount_cancellation_fee_gross
                              )
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th className={baseStyles['u-width-176']}>
                          {t('Cancellation Fee Net')}
                        </th>
                        <td>
                          {reservation.billing_info?.amount_cancellation_fee_net
                            ? formattedCurrencyAmount(
                                reservation.billing_info
                                  ?.amount_cancellation_fee_net
                              )
                            : '-'}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ReservationMainInformationUpdateModal
          reservation={reservation}
          onClose={() => setShowModal(false)}
        />
      )}
    </section>
  );
});
