import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';

import type { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import { fetchProducts } from 'client/actions/products';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import { getBookingWidgetEmbeddedCalendarUrl } from 'client/libraries/util/getBookingWidgetEmbeddedCalendarUrl';
import { getBookingWidgetMultiProductCalendarUrl } from 'client/libraries/util/getBookingWidgetMultiProductCalendarUrl';
import { getBookingWidgetProductGroupCalendarUrl } from 'client/libraries/util/getBookingWidgetProductGroupCalendarUrl';
import { TextField } from 'client/components/v3/Form/TextField';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { ProductGroupCalendarList } from 'client/pages/v3/BookingWidget/WebSiteIntegration/Tab/EmbedCalendar/ProductGroupCalendarList';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

import { BookingWidgetEmbeddedSingleProductCalendar } from './EmbedCalendar/BookingWidgetEmbeddedSingleProductCalendar';
import { BookingWidgetEmbeddedMultiProductCalendar } from './EmbedCalendar/BookingWidgetEmbeddedMultiProductCalendar';
import { BookingWidgetEmbeddedProductGroupCalendar } from './EmbedCalendar/BookingWidgetEmbeddedProductGroupCalendar';
import { getBookNowButtonEmbedCode } from './util';

export const EmbedCalendarTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const organization = useSelector(activeUserOrganizationSelector);

  const defaultColor =
    organization?.booking_widget_design_params?.primary_color ?? '#0094cc';

  const [buttonColor, setButtonColor] = React.useState<string>(defaultColor);
  const [buttonText, setButtonText] = React.useState<string>(t('Book Now'));
  const [selectedProductId, setSelectedProductId] = React.useState<string>(
    productOptions?.[0]?.key || ''
  );
  const [widgetLanguage, setWidgetLanguage] = React.useState<string>('ja');
  const [calendarType, setCalendarType] = React.useState<
    | 'SINGLE_PRODUCT_CALENDAR'
    | 'MULTI_PRODUCT_CALENDAR'
    | 'PRODUCT_GROUP_CALENDAR'
  >('SINGLE_PRODUCT_CALENDAR');
  const [embedType, setEmbedType] = React.useState<
    'CALENDAR' | 'BOOK_NOW_BUTTON'
  >('CALENDAR');
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);

  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [locale, organization]);

  const languageOptions = (
    (organization && organization.supported_languages) ||
    []
  ).map((language) => ({
    text: getLanguageName(uppercaseIsoToLowercaseIso[language], t),
    value: uppercaseIsoToLowercaseIso[language],
    key: uppercaseIsoToLowercaseIso[language],
  }));

  React.useEffect(() => {
    if (!(window as any).openCalendarModal) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed-book-now/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);

  const getCalendarUrl = () => {
    let calendarUrl = '';
    if (
      calendarType === 'SINGLE_PRODUCT_CALENDAR' &&
      embedType === 'BOOK_NOW_BUTTON' &&
      selectedProductId
    ) {
      calendarUrl = `${getBookingWidgetEmbeddedCalendarUrl(
        bookingWidgetApiKey,
        selectedProductId,
        'BASIC_WITH_PRICES',
        widgetLanguage
      )}&isModal=true`;
    }

    if (
      calendarType === 'MULTI_PRODUCT_CALENDAR' &&
      embedType === 'BOOK_NOW_BUTTON'
    ) {
      calendarUrl = `${getBookingWidgetMultiProductCalendarUrl(
        bookingWidgetApiKey,
        widgetLanguage
      )}&isModal=true`;
    }

    if (
      calendarType === 'PRODUCT_GROUP_CALENDAR' &&
      embedType === 'BOOK_NOW_BUTTON'
    ) {
      calendarUrl = `${getBookingWidgetProductGroupCalendarUrl(
        bookingWidgetApiKey,
        widgetLanguage
      )}&isModal=true`;
    }

    return calendarUrl;
  };

  const bookingWidgetApiKey =
    (organization && organization.booking_widget_api_key) ?? '';

  if (!bookingWidgetApiKey) {
    return (
      <Message warning>
        {t(
          'Booking widget not currently configured. Please contact sales@nutmeglabs.com if interested.'
        )}
      </Message>
    );
  }

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '24px',
    height: '48px',
    background: buttonColor,
    color: '#fff',
    cursor: 'pointer',
    border: 'none',
    paddingLeft: '32px',
    paddingRight: '32px',
  };

  const cssStyleString = [
    `font-size: ${buttonStyle.fontSize}`,
    `font-weight: ${buttonStyle.fontWeight}`,
    `border-radius: ${buttonStyle.borderRadius}`,
    `height: ${buttonStyle.height}`,
    `font-weight: ${buttonStyle.fontWeight}`,
    `background: ${buttonStyle.background}`,
    `color: ${buttonStyle.color}`,
    `cursor: ${buttonStyle.cursor}`,
    `border: ${buttonStyle.border}`,
    `padding-left: ${buttonStyle.paddingLeft}`,
    `padding-right: ${buttonStyle.paddingRight}`,
  ].join('; ');

  return (
    <div
      className={clsx(
        styles['p-inquiry-tabs__tab-panel'],
        styles['components_is-active__B15DT']
      )}
      style={{ display: 'block' }}
    >
      {copySuccess && (
        <Snackbar
          color="success"
          text={t('Copied')}
          shouldShow={copySuccess}
          onClose={() => setCopySuccess(false)}
        />
      )}
      <div className={styles['p-inquiry-tabs__content']}>
        <ul className={styles['p-inquiry-list']}>
          <li className={styles['p-inquiry-list__item']}>
            <div className={styles['p-inquiry-list__item__ttl']}>
              <div className={styles['p-inquiry-list__item__ttl__txt']}>
                <div>{t('Select language')}</div>
              </div>
            </div>
            <div className={styles['p-inquiry-list__item__body']}>
              <div className={styles['p-inquiry-list__item__body__item']}>
                {languageOptions.length > 0 ? (
                  <SingleDropdown
                    selectedOption={widgetLanguage}
                    options={languageOptions}
                    onChange={(value) => {
                      setWidgetLanguage(value);
                    }}
                  />
                ) : (
                  <Message warning>
                    {t(
                      'No supported languages are configured for your organization. Please contact sales@nutmeglabs.com.'
                    )}
                  </Message>
                )}
              </div>
            </div>
          </li>
          <li className={styles['p-inquiry-list__item']}>
            <div className={styles['p-inquiry-list__item__ttl']}>
              <div className={styles['p-inquiry-list__item__ttl__txt']}>
                <div>{t('Calendar Type')}</div>
              </div>
            </div>
            <div className={styles['p-inquiry-list__item__body']}>
              <div className={styles['p-inquiry-list__item__body__item']}>
                <SingleDropdown
                  selectedOption={calendarType}
                  options={[
                    {
                      value: 'SINGLE_PRODUCT_CALENDAR',
                      text: t('Single Product Calendar'),
                    },
                    {
                      value: 'MULTI_PRODUCT_CALENDAR',
                      text: t('All Products Calendar'),
                    },
                    {
                      value: 'PRODUCT_GROUP_CALENDAR',
                      text: t('Product Group'),
                    },
                  ]}
                  onChange={(value) => {
                    setCalendarType(value as any);
                  }}
                />
              </div>
              {calendarType === 'PRODUCT_GROUP_CALENDAR' && (
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <ProductGroupCalendarList />
                </div>
              )}
              {calendarType === 'SINGLE_PRODUCT_CALENDAR' && (
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <SingleDropdown
                    label={t('Select a product')}
                    selectedOption={selectedProductId}
                    options={productOptions}
                    onChange={(value) => {
                      setSelectedProductId(value);
                    }}
                  />
                </div>
              )}
            </div>
          </li>
          <li className={styles['p-inquiry-list__item']}>
            <div className={styles['p-inquiry-list__item__ttl']}>
              <div className={styles['p-inquiry-list__item__ttl__txt']}>
                <div>{t('Embed Type')}</div>
              </div>
            </div>
            <div className={styles['p-inquiry-list__item__body']}>
              <div className={styles['p-inquiry-list__item__body__item']}>
                <SingleDropdown
                  selectedOption={embedType}
                  options={[
                    {
                      value: 'CALENDAR',
                      text: t('Calendar'),
                    },
                    {
                      value: 'BOOK_NOW_BUTTON',
                      text: t('"Book Now" Button'),
                    },
                  ]}
                  onChange={(value) => {
                    setEmbedType(value as any);
                  }}
                />
              </div>
              {calendarType === 'SINGLE_PRODUCT_CALENDAR' &&
                embedType === 'CALENDAR' &&
                selectedProductId && (
                  <BookingWidgetEmbeddedSingleProductCalendar
                    bookingWidgetApiKey={bookingWidgetApiKey}
                    selectedProductId={selectedProductId}
                    widgetLanguage={widgetLanguage}
                  />
                )}
              {calendarType === 'MULTI_PRODUCT_CALENDAR' &&
                embedType === 'CALENDAR' && (
                  <BookingWidgetEmbeddedMultiProductCalendar
                    bookingWidgetApiKey={bookingWidgetApiKey}
                    widgetLanguage={widgetLanguage}
                  />
                )}
              {calendarType === 'PRODUCT_GROUP_CALENDAR' &&
                embedType === 'CALENDAR' && (
                  <BookingWidgetEmbeddedProductGroupCalendar
                    bookingWidgetApiKey={bookingWidgetApiKey}
                    widgetLanguage={widgetLanguage}
                  />
                )}
              {embedType === 'BOOK_NOW_BUTTON' && (
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <div className={styles['p-bookingWidgetCustomize-box']}>
                    <div
                      className={styles['p-bookingWidgetCustomize-box__list']}
                    >
                      <div
                        className={
                          styles['p-bookingWidgetCustomize-box__list__item']
                        }
                      >
                        <dl
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list'
                            ]
                          }
                        >
                          <dt
                            className={
                              styles[
                                'p-bookingWidgetCustomize-products__section__list__term'
                              ]
                            }
                          >
                            <span>{t('Button Text')}</span>
                          </dt>
                          <dd
                            className={
                              styles[
                                'p-bookingWidgetCustomize-products__section__list__desc'
                              ]
                            }
                          >
                            <TextField
                              value={buttonText}
                              onChange={(value) => {
                                setButtonText(value);
                              }}
                            />
                          </dd>
                        </dl>
                      </div>
                      <div
                        className={
                          styles['p-bookingWidgetCustomize-box__list__item']
                        }
                      >
                        <dl
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list'
                            ]
                          }
                        >
                          <dt
                            className={
                              styles[
                                'p-bookingWidgetCustomize-products__section__list__term'
                              ]
                            }
                          >
                            <span>{t('Button Color')}</span>
                          </dt>
                          <dd
                            className={
                              styles[
                                'p-bookingWidgetCustomize-products__section__list__desc'
                              ]
                            }
                          >
                            <div style={{ width: '142px' }}>
                              <ColorPicker
                                placement="bottomRight"
                                color={buttonColor}
                                onChange={({ color }: any) =>
                                  setButtonColor(color)
                                }
                                className={styles['color_section']}
                              >
                                <a
                                  className={clsx(
                                    'react-colorpicker-trigger',
                                    styles[
                                      'p-bookingWidgetCustomize-products-list__color'
                                    ]
                                  )}
                                >
                                  <div
                                    className={clsx(
                                      styles[
                                        'p-bookingWidgetCustomize-products-list__color-box'
                                      ]
                                    )}
                                    style={{
                                      backgroundColor: buttonColor,
                                    }}
                                  ></div>
                                  <span>
                                    <i></i>
                                    {t('Change')}
                                  </span>
                                </a>
                              </ColorPicker>
                            </div>
                          </dd>
                        </dl>
                      </div>
                      <div
                        className={
                          styles['p-bookingWidgetCustomize-box__list__item']
                        }
                      >
                        <dl
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list'
                            ]
                          }
                        >
                          <dd
                            className={
                              styles[
                                'p-bookingWidgetCustomize-products__section__list__desc'
                              ]
                            }
                          >
                            <div
                              className={
                                styles['p-bookingWidgetCustomize-box-wh']
                              }
                            >
                              <button
                                style={buttonStyle}
                                type="button"
                                onClick={() => {
                                  const calendarUrl = getCalendarUrl();
                                  (window as any).openCalendarModal(
                                    calendarUrl,
                                    calendarType
                                  );
                                }}
                              >
                                {buttonText}
                              </button>
                            </div>
                          </dd>
                        </dl>
                      </div>
                      <div
                        className={
                          styles['p-bookingWidgetCustomize-box__list__item']
                        }
                      >
                        <Button
                          uiType="bg"
                          size="icon"
                          color="white"
                          text={t('Copy embed code')}
                          iconBeforeText={
                            <i className="c-icon-outline-general-copy-02"></i>
                          }
                          onClick={() => {
                            const calendarUrl = getCalendarUrl();
                            const url = getBookNowButtonEmbedCode(
                              cssStyleString,
                              calendarUrl,
                              calendarType,
                              buttonText
                            );
                            navigator.clipboard.writeText(url);
                            setCopySuccess(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
