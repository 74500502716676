import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState, useForm } from 'react-final-form';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

//import { getArrayMutators } from 'client/libraries/util/form';
import { Button } from 'client/components/v3/Common/Button';
import { customPagesSelector } from 'client/reducers/customPages';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { getLanguageName } from 'client/libraries/i18n';
import { bookingWidgetPMPSupportedLanguagesSelector } from 'client/reducers/user';
import type {
  CustomizeFormValues,
  FooterLink,
} from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

const itemType = 'selectable-item';

export const FooterEditor = () => {
  const { t } = useTranslation();
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    label: getLanguageName(lang, t),
  }));
  const parentFormState = useFormState<CustomizeFormValues>();
  const parentForm = useForm<CustomizeFormValues>();

  const parentFormSaveFooterLink = (newFooterLink: FooterLink) => {
    const existingFooterLinks = parentFormState.values?.footerLinks?.[
      language
    ] as FooterLink[];
    parentForm.change(
      `footerLinks.${language}` as any,
      existingFooterLinks?.some(
        (footerLink) => footerLink.key === newFooterLink.key
      )
        ? existingFooterLinks?.map((existingFooterLink) =>
            existingFooterLink.key === newFooterLink.key
              ? newFooterLink
              : existingFooterLink
          )
        : [...(existingFooterLinks ?? []), newFooterLink]
    );
  };

  const language = parentFormState.values?.footerLinkLanguage || 'JA_JP';

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const existingFooterLinks = parentFormState.values?.footerLinks?.[
      language
    ] as FooterLink[];
    const dragItem = existingFooterLinks[dragIndex];
    const newSelectedItems = [...existingFooterLinks];
    newSelectedItems.splice(dragIndex, 1);
    newSelectedItems.splice(hoverIndex, 0, dragItem);
    parentForm.change(`footerLinks.${language}` as any, newSelectedItems);
  };
  return (
    <>
      <div
        className={
          styles['p-bookingWidgetCustomize-products__section__body__item']
        }
      >
        <dl
          className={styles['p-bookingWidgetCustomize-products__section__list']}
        >
          <dt
            className={
              styles['p-bookingWidgetCustomize-products__section__list__term']
            }
          >
            <span>{t('Footer')}</span>
            <span>{t('Content Language')}</span>
          </dt>
          <EnumRadioButtonGroup
            name="footerLinkLanguage"
            options={languageOptions}
            defaultChecked={language}
          />
        </dl>
      </div>
      <div
        className={
          styles['p-bookingWidgetCustomize-products__section__body__item']
        }
      >
        <div className={styles['p-bookingWidgetCustomize-box']}>
          <div className={styles['p-bookingWidgetCustomize-box__list']}>
            <FieldArray name={`footerLinks.${language}`}>
              {({ fields }) => (
                <>
                  <div
                    className={
                      styles['p-bookingWidgetCustomize-box__list__item']
                    }
                  >
                    <ul
                      className={styles['p-bookingWidgetCustomize-list-trash']}
                    >
                      {fields.map((name, idx) => {
                        return (
                          <FooterItem
                            index={idx}
                            id={`${name}-foooterlinks-${idx}`}
                            key={`${name}-foooterlinks-${idx}`}
                            name={name}
                            moveItem={moveItem}
                            reMoveItem={() => {
                              const existingFooterLinks = parentFormState.values
                                ?.footerLinks?.[language] as FooterLink[];
                              const newSelectedItems = [...existingFooterLinks];
                              newSelectedItems.splice(idx, 1);
                              parentForm.change(
                                `footerLinks.${language}` as any,
                                newSelectedItems
                              );
                            }}
                          />
                        );
                      })}
                    </ul>
                  </div>
                  <div
                    className={
                      styles['p-bookingWidgetCustomize-box__list__item']
                    }
                  >
                    <a
                      className={
                        styles['p-bookingWidgetCustomize-box__table__add']
                      }
                      onClick={() => {
                        const initialValue: FooterLink = {
                          text: '',
                          url: '',
                          customPagePath: '/about',
                          key: uuidv4(),
                          linkType: 'CUSTOM_PAGE_PATH',
                        };
                        parentFormSaveFooterLink(initialValue);
                      }}
                    >
                      <i className="c-icon-outline-general-plus-circle"></i>
                      {t('Add Footer')}
                    </a>
                  </div>
                </>
              )}
            </FieldArray>
          </div>
        </div>
      </div>
      {parentForm.getState().values?.footerLinkLanguage === 'JA_JP' && (
        <div
          className={
            styles['p-bookingWidgetCustomize-products__section__body__item']
          }
        >
          <Field name="showFooterJapaneseTermsLink" type="checkbox">
            {({ input }) => {
              return (
                <Toggle
                  checked={input.checked ?? false}
                  onChange={() => input.onChange(!input.checked)}
                  label={
                    <div>
                      <div>
                        {t(
                          'Show the default "特定商取引法" link on Japanese website.'
                        )}
                      </div>
                      <div>
                        {t(
                          'Note: It is required to show 特定商取引法 page by Japanese law. If you want to hide the default link, please add a link to equivalent page of your organization or create a page using Homepage feature.'
                        )}
                      </div>
                    </div>
                  }
                />
              );
            }}
          </Field>
        </div>
      )}
    </>
  );
};

type FooterItemProps = {
  name: string;
  id: string;
  index: number;
  moveItem?: (dragIndex: number, hoverIndex: number) => void;
  reMoveItem?: () => void;
};
interface DragItem {
  index: number;
  id: string;
  type: string;
}
const FooterItem = ({
  name,
  id,
  index,
  moveItem,
  reMoveItem,
}: FooterItemProps) => {
  const { t } = useTranslation();
  const form = useForm();
  const formState = useFormState();
  const { required } = getValidators(t);
  const customPages = useSelector(customPagesSelector);

  const initialValuesFromForm = _.get(formState.values, name);

  const linkTypeOptions = [
    {
      key: 'CUSTOM_PAGE_PATH',
      value: 'CUSTOM_PAGE_PATH',
      label: t('Select a page from Homepages'),
    },
    {
      key: 'URL',
      value: 'URL',
      label: t('Enter URL directly'),
    },
  ];
  const customPagePathOptions = [
    {
      text: t('About Us'),
      key: '/about',
      value: '/about',
    },
    {
      text: t('FAQ'),
      key: '/faq',
      value: '/faq',
    },
    {
      text: t('COVID-19 Guidelines'),
      key: '/covid19',
      value: '/covid19',
    },
    ...customPages.map((customPage) => ({
      text: customPage.title,
      key: `/article/${customPage.path}`,
      value: `/article/${customPage.path}`,
    })),
  ];

  const ref = React.useRef<HTMLLIElement | null>(null);
  const [, drop] = useDrop<DragItem>({
    accept: itemType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect =
        ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem?.(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: id,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <li
      ref={ref}
      style={{ opacity }}
      id={id}
      className={styles['p-bookingWidgetCustomize-list-trash__item']}
    >
      <div className={styles['p-bookingWidgetCustomize-list-trash__box']}>
        <div className={styles['p-bookingWidgetCustomize-box-wh']}>
          <div className={styles['p-bookingWidgetCustomize-box-wh__header']}>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__header__ic']}
            >
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box-wh__body']}>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__body__item']}
            >
              <div className={styles['p-bookingWidgetCustomize-footer__title']}>
                {t('Title')}
              </div>
              <Field name={`${name}.text`} validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <TextField error={touched && error} {...input} />
                )}
              </Field>
            </div>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__body__item']}
            >
              <dl className={styles['p-bookingWidgetCustomize-box-wh__list']}>
                <dt
                  className={
                    styles['p-bookingWidgetCustomize-box-wh__list__term']
                  }
                >
                  <span>{t('Page to open')}</span>
                </dt>
                <EnumRadioButtonGroup
                  name={`${name}.linkType`}
                  options={linkTypeOptions}
                  onChange={(value) => {
                    if (value === 'CUSTOM_PAGE_PATH') {
                      form.change('customPagePath', '/about');
                      form.change('url', '');
                    } else {
                      form.change('customPagePath', '');
                      form.change('url', '');
                    }
                  }}
                />
              </dl>
            </div>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__body__item']}
            >
              {initialValuesFromForm?.linkType === 'CUSTOM_PAGE_PATH' && (
                <Field name={`${name}.customPagePath`}>
                  {({ input, meta: { touched, error } }) => (
                    <SingleDropdown
                      options={customPagePathOptions}
                      error={touched && error}
                      selectedOption={input.value}
                      onChange={(value) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
              {initialValuesFromForm?.linkType === 'URL' && (
                <Field name={`${name}.url`}>
                  {({ input, meta: { touched, error } }) => (
                    <TextField error={touched && error} {...input} />
                  )}
                </Field>
              )}
            </div>
          </div>
        </div>
      </div>
      <Button
        size="icon"
        color="tertiarygray"
        iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
        onClick={() => reMoveItem?.()}
      />
    </li>
  );
};
