import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';
import { Section } from '@nutmeglabs/falcon-ui';
import clsx from 'clsx';

import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { Editor } from 'client/components/Editor/Editor';
import { FalconUIContextProvider } from 'client/components/FalconUIContextProvider/FalconUIContextProvider';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { updateCustomPage, createCustomPage } from 'client/actions/customPage';
import { getBookingWidgetPmpEssentialPageUrlBase } from 'client/pages/EssentialPages/utils';
import { CustomSectionsEditor } from 'client/pages/EssentialPages/CustomPages/CustomSectionsEditor';
import { productOptionsSelector } from 'client/reducers/products';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { customPagesSelector } from 'client/reducers/customPages';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SingleImageInput } from 'client/components/v3/SingleImageInput/SingleImageInput';
import { getArrayMutators } from 'client/libraries/util/form';
import type {
  CustomPageType,
  CustomPagePatch,
  NewCustomPage,
  SourceLanguage,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';
import baseStyles from 'client/v3-base.module.css';

type LocationState = {
  contentLanguage: SourceLanguage;
  type: CustomPageType;
  id?: string;
};

type FormValues = {
  visibility: boolean;
  title: string;
  path: string;
  body: any;
  sections: Section[];
  keyVisual: string;
  description: string;
  tag: string[];
  relatedProduct: string[];
};

const focusOnError: any = createDecorator();

export const EditCustomPageBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollTo = React.useContext(ScrollToContext);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const customPages = useSelector(customPagesSelector);
  const productOptions = useSelector(productOptionsSelector);
  const propsLocation = location && (location.state as LocationState);

  const [selfPath, setSelfPath] = React.useState<string | null>(null);
  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);

  const id = propsLocation?.id || '';
  const type = propsLocation.type;
  const contentLanguage = propsLocation.contentLanguage;
  const editCustomPage = customPages.find((customPage) => customPage.id === id);

  React.useEffect(() => {
    if (editCustomPage) {
      setSelfPath(editCustomPage.path);
    }
  }, [editCustomPage]);

  const initialValues = React.useMemo((): FormValues => {
    return editCustomPage
      ? {
          ...editCustomPage,
          visibility: editCustomPage.status === 'ACTIVE',
          body: JSON.parse(editCustomPage?.body || '{}'),
          title: editCustomPage.title,
          sections: !editCustomPage.sections
            ? [
                {
                  type: 'EDITORJS',
                  editorJsContent: editCustomPage?.body || '{}',
                  title: editCustomPage.title,
                  titleColor: '#000000',
                  backgroundColor: '#f9f9f9',
                },
              ]
            : JSON.parse(editCustomPage.sections || '[]'),
          keyVisual: editCustomPage.key_visual || '',
          description: editCustomPage.description || '',
          tag: editCustomPage.tag || [],
          relatedProduct: editCustomPage.related_product || [],
        }
      : {
          visibility: true,
          path: '',
          title: '',
          body: null,
          sections: [],
          keyVisual: '',
          description: '',
          tag: [],
          relatedProduct: [],
        };
  }, [activeUserOrganization, customPages]);

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  return (
    <FalconUIContextProvider language={contentLanguage}>
      <Form
        onSubmit={async (values: FormValues) => {
          const customPage = {
            title: values.title,
            path: values.path,
            body: JSON.stringify(values.body),
            sections: JSON.stringify(values.sections),
            status: values.visibility ? 'ACTIVE' : 'INACTIVE',
            key_visual: values.keyVisual,
            description: values.description,
            tag: values.tag,
            related_product: values.relatedProduct,
          };
          setSelfPath(values.path);

          try {
            if (editCustomPage) {
              await dispatch(
                updateCustomPage(
                  editCustomPage.id,
                  customPage as CustomPagePatch
                )
              );
            } else {
              await dispatch(
                createCustomPage({
                  ...(customPage as NewCustomPage),
                  type: type,
                  content_language: contentLanguage,
                })
              );
            }

            scrollTo(0, 0);
          } catch (error) {
            if (editCustomPage) {
              setSelfPath(editCustomPage.path);
            }

            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        validate={(values) => {
          const errors: Record<string, string> = {};

          if (!values.title) {
            errors.title = t('Required');
          }

          if (selfPath !== values.path) {
            const duplicate = (customPages || []).find(
              (customPage) =>
                customPage.path === values.path && customPage.type === type
            );

            if (duplicate) {
              errors.path = t('Path Not Available');
            }
          }

          if (!values.path) {
            errors.path = t('Required');
          }

          return errors;
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
      >
        {({
          handleSubmit,
          submitting,
          submitSucceeded,
          submitError,
          modifiedSinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div
              className={clsx(
                styles['p-inquiry-tabs__tab-panel'],
                styles['components_is-active__B15DT']
              )}
              style={{ display: 'block' }}
            >
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Snackbar
                  color={'success'}
                  text={t('Save Successful')}
                  shouldShow={submitSucceeded}
                />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Snackbar
                  color={'error'}
                  text={t('Save Failed')}
                  shouldShow={submitError}
                />
              )}
              <div className={styles['p-inquiry-tabs__content']}>
                <ul className={styles['p-inquiry-list']}>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Type')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <EnumRadioButtonGroup
                          name="type"
                          options={[
                            {
                              value: 'NORMAL',
                              label: t('Custom Page'),
                            },
                            {
                              value: 'NEWS',
                              label: t('NEWS'),
                            },
                            {
                              value: 'OTHER_PAGE',
                              label: t('OTHER_PAGE'),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Content Language')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        {getLanguageName(
                          uppercaseIsoToLowercaseIso[contentLanguage],
                          t
                        )}
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Visibility')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="visibility">
                          {({ input }) => (
                            <Toggle
                              label={t('Show this page on booking site')}
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('URL')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <div style={{ display: 'inline-block' }}>
                          <p
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {`${getBookingWidgetPmpEssentialPageUrlBase(
                              activeUserOrganization,
                              contentLanguage
                            )}${'/article'}/`}
                          </p>
                          <Field name="path">
                            {({ input, meta }) => (
                              <>
                                <TextField {...input} />
                                {meta.error && meta.touched && (
                                  <p className={baseStyles['u-error-msg']}>
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Title')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="title">
                          {({ input, meta }) => (
                            <TextField
                              error={meta.error && meta.touched && meta.error}
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Key Visual')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="keyVisual">
                          {({ input }) => (
                            <SingleImageInput
                              fileTypes={['image/jpeg', 'image/png']}
                              onUploadFinished={(filename) =>
                                input.onChange(filename)
                              }
                              initialValue={input.value}
                              saveFilePath={`keyvisual/supplier/${activeUserOrganization?.id}`}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Description')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="description">
                          {({ input, meta }) => (
                            <TextField
                              style={{ height: '70px' }}
                              error={meta.error && meta.touched && meta.error}
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  {hasSubscription(
                    activeUserOrganization,
                    'feature-custom-top-page'
                  ) &&
                  !isSubscriptionCancelled(
                    activeUserOrganization,
                    'feature-custom-top-page'
                  ) ? (
                    <li className={styles['p-inquiry-list__item']}>
                      <div className={styles['p-inquiry-list__item__ttl']}>
                        <div
                          className={styles['p-inquiry-list__item__ttl__txt']}
                        >
                          <div>{t('Sections')}</div>
                        </div>
                      </div>
                      <div className={styles['p-inquiry-list__item__body']}>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                          style={{ display: 'block' }}
                        >
                          <CustomSectionsEditor language={contentLanguage} />
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li className={styles['p-inquiry-list__item']}>
                      <div className={styles['p-inquiry-list__item__ttl']}>
                        <div
                          className={styles['p-inquiry-list__item__ttl__txt']}
                        >
                          <div>{t('Body')}</div>
                        </div>
                      </div>
                      <div className={styles['p-inquiry-list__item__body']}>
                        <div
                          className={styles['p-inquiry-list__item__body__item']}
                          style={{ display: 'block' }}
                        >
                          <Field name="body">
                            {({ input }) => (
                              <Editor
                                imageS3Prefix={`editor/supplier/${
                                  activeUserOrganization?.id || ''
                                }`}
                                data={input.value}
                                onChange={(data: any, loading) => {
                                  setEditorJsLoading(loading);
                                  input.onChange(data);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </li>
                  )}

                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Tag')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <FieldArray name={`tag`}>
                          {({ fields }) => {
                            const count = fields.length ?? 0;
                            return (
                              <div>
                                {fields.map((name, formIdx) => (
                                  <div
                                    className={styles['company__item']}
                                    style={{ display: 'flex' }}
                                    key={formIdx}
                                  >
                                    <div
                                      className={styles['company__item__box']}
                                    >
                                      <div
                                        style={{
                                          display: 'block',
                                        }}
                                      >
                                        <Field name={name} key={formIdx}>
                                          {({
                                            input,
                                            meta: { error, touched },
                                          }) => (
                                            <>
                                              <div
                                                style={{
                                                  margin: '10px 10px 10px',
                                                }}
                                              >
                                                <p>
                                                  {' '}
                                                  <TextField
                                                    {...input}
                                                    label={t('Tag')}
                                                    error={touched && error}
                                                  />{' '}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <Button
                                      size="icon"
                                      color="tertiarygray"
                                      iconBeforeText={
                                        <i className="c-icon-outline-general-trash-03"></i>
                                      }
                                      onClick={() => fields.remove(formIdx)}
                                    />
                                  </div>
                                ))}
                                <a
                                  className={styles['add__button']}
                                  onClick={() => {
                                    if (count === 0) {
                                      (fields as any).insertAt(0, '');
                                    } else {
                                      (fields as any).insertAt(count + 1, '');
                                    }
                                  }}
                                >
                                  <i className="c-icon-outline-general-plus-circle"></i>
                                  {t('Add')}
                                </a>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>
                  </li>

                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Related Product')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="relatedProduct">
                          {({ input }) => (
                            <MultiDropdown
                              options={productOptions}
                              onChange={(value) => input.onChange(value)}
                              selectedOptions={input.value}
                              searchable={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={clsx(styles['p-products__fixed'], styles['is-active'])}
            >
              <div className={styles['p-products__fixed__actions']}>
                <Button
                  disabled={submitting || editorJsLoading}
                  loading={submitting || editorJsLoading}
                  text={t('Save')}
                  type="submit"
                  color="primary"
                  size="md"
                  form="submit"
                  style={{
                    width: '60px',
                    fontWeight: 'var(--text-semibold)',
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Form>
    </FalconUIContextProvider>
  );
};
