import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import jpPhoneLocalization from 'react-phone-input-2/lang/jp.json';
import { useCallback, useMemo, useState } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper/FieldWrapper';
import { sendInquirySMS } from 'client/actions/inquiries';
import { MessageModal } from 'client/components/v3/MessageModal/MessageModal';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import 'react-phone-input-2/lib/style.css';
import { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import { Modal } from './Modal';

type FormValues = {
  message: string;
  phone: string;
};
type Props = {
  reservation: Reservation | undefined;
  onClose: () => void;
  open: boolean;
};
export const SendSMSModal = ({ onClose, open, reservation }: Props) => {
  const { t } = useTranslation();
  const [showInsertMessageTemplate, setShowInsertMessageTemplate] =
    useState(false);
  const [selectedMessageTemplateName, setSelectedMessageTemplateName] =
    useState('');
  const [step, setStep] = useState<'EDIT' | 'CONFIRM'>('EDIT');
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const initialValues = useMemo((): FormValues => {
    let reservationPhone =
      reservation?.field_responses?.find((response) => response.key === 'phone')
        ?.response ||
      reservation?.field_responses?.find(
        (response) => response.key === 'international_phone'
      )?.response;

    if (reservationPhone) {
      reservationPhone = `${
        !reservationPhone.startsWith('+') ? '+' : ''
      }${reservationPhone.replace(/^\+0-9/g, '')}`;
    }

    return {
      phone: reservationPhone ?? '',
      message: '',
    };
  }, [reservation]);

  const { required } = getValidators(t);
  const validatePhone = useCallback(
    (phone?: any) => {
      if (!phone) return t('Required');

      if (!matchesFormat(phone, 'friendly_phone')) return t('Invalid Phone');

      if (
        ['+81', '+1', '+61'].every(
          (countryCode) => !phone.startsWith(countryCode)
        )
      ) {
        return t('Invalid Phone');
      }

      return undefined;
    },
    [t]
  );

  const org = useSelector(activeUserOrganizationSelector);
  const hasMessageTemplates =
    (org?.inquiry_settings?.inquiry_message_templates ?? []).length > 0;
  const messageTemplateOptions = [
    {
      value: 'none',
      text: '',
    },
    ...(org?.inquiry_settings?.inquiry_message_templates ?? []).map(
      (template) => ({
        value: template.name ?? '',
        text: template.name ?? '',
      })
    ),
  ];

  const dispatch = useDispatch();

  const countryCodeOptions =
    activeUser?.locale === 'ja' ? ['jp', 'us', 'au'] : ['us', 'jp', 'au'];
  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        switch (step) {
          case 'EDIT':
            setStep('CONFIRM');
            break;
          case 'CONFIRM':
            await dispatch(
              sendInquirySMS({
                reservation_id: reservation?.id,
                phone: values.phone,
                body: values.message,
              })
            );
            await onClose();
            break;
        }
      }}
    >
      {({ handleSubmit, submitting, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={t('Send SMS')}
            open={open}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button text={t('Cancel')} color="white" onClick={onClose} />
                <Button text={t('Send')} type="submit" />
              </>
            }
            style={{ width: '568px' }}
          >
            <div className={styles['p-secondaryContent']}>
              {step === 'CONFIRM' && (
                <MessageModal
                  onClose={() => setStep('EDIT')}
                  title={t('Send SMS')}
                  message={t('Are you sure you wish to send this SMS?')}
                  onSubmit={handleSubmit}
                  insertRoot
                  loading={submitting}
                />
              )}
              <div className={styles['p-secondaryContent__box__form']}>
                <div className={styles['p-secondaryContent__box__form__item']}>
                  <Field name="phone" validate={validatePhone}>
                    {({ input, meta: { touched, error } }) => (
                      <FieldWrapper
                        label={t('Phone')}
                        required={true}
                        error={touched && error}
                      >
                        <PhoneInput
                          placeholder={
                            locale === 'ja' ? '81 9012345678' : '1 800 123 4567'
                          }
                          masks={{ jp: '...........' }}
                          onlyCountries={countryCodeOptions}
                          localization={
                            locale === 'ja' ? jpPhoneLocalization : undefined
                          }
                          value={input.value?.replace('+', '')}
                          onChange={(
                            value: string,
                            data: { dialCode: string }
                          ) => {
                            if (data && !value?.startsWith(data.dialCode)) {
                              input.onChange(`+${data.dialCode}${value}`);
                            } else if (value && !value?.startsWith('+')) {
                              input.onChange(`+${value}`);
                            } else {
                              input.onChange(value);
                            }
                          }}
                          searchPlaceholder={t('Search')}
                          searchNotFound={t('No Results')}
                          isValid={() => {
                            return !touched || !error;
                          }}
                          enableSearch
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </div>
                {hasMessageTemplates && (
                  <div
                    className={styles['p-secondaryContent__box__form__item']}
                  >
                    <div style={{ marginBottom: '6px' }}>
                      <Toggle
                        label={t('Insert Message Template')}
                        checked={showInsertMessageTemplate}
                        onChange={() =>
                          setShowInsertMessageTemplate(
                            !showInsertMessageTemplate
                          )
                        }
                      />
                    </div>
                    {showInsertMessageTemplate && (
                      <div
                        className={
                          styles['p-contactEmail__item__templateToggle']
                        }
                      >
                        <SingleDropdown
                          options={messageTemplateOptions}
                          selectedOption={selectedMessageTemplateName}
                          onChange={async (value) => {
                            await setSelectedMessageTemplateName(value);
                            const selectedMessageTemplate =
                              org?.inquiry_settings?.inquiry_message_templates?.find(
                                (template) => template.name === value
                              );
                            form.change(
                              'message',
                              selectedMessageTemplate?.message
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className={styles['p-secondaryContent__box__form__item']}>
                  <Field name="message" validate={required}>
                    {({ input, meta: { touched, error } }) => (
                      <TextArea
                        label={t('Message')}
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                        error={touched && error}
                        showCharacterCount={true}
                        height={80}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['p-secondaryContent__box__form__item']}>
                  <div>
                    {t(
                      '* 1 message cost will be for approximately 60 characters.'
                    )}
                  </div>
                  <div>
                    {t(
                      '* Long messages may be split into multiple messages and charged for multiple messages'
                    )}
                  </div>
                  <div>
                    {t(
                      '* When including URLs in messages, we recommend shortening the URLs with a service like '
                    )}
                    <a
                      className={baseStyles['base-link']}
                      href="https://bitly.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      bitly.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
