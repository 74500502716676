import { useTranslation } from 'react-i18next';

import { GenericDraggableList } from 'client/components/v3/GenericDraggableList/GenericDraggableList';

import styles from '../../ETicketEdit.module.css';

import { StubOptionItem } from './StubOptionItem';

interface Props {
  stubKey: string;
  value: string[];
  onChange: (newItems: string[]) => void;
}

export const StubOptionsForm = ({ stubKey, value, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['p-box__table3']}>
      <GenericDraggableList<string>
        items={value}
        itemType={'stub-options-' + stubKey}
        moveItem={(newItems) => onChange(newItems)}
        addItem={() => {
          const newItems = [...value, ''];
          onChange(newItems);
        }}
        addItemText={t('Add Ticket Usage Option')}
        renderItem={(item) => {
          return (
            <StubOptionItem
              value={item}
              onChange={(newItem) => {
                const newItems = value.map((i: string) => {
                  if (i === item) {
                    return newItem;
                  }
                  return i;
                });
                onChange(newItems);
              }}
              onRemoveClick={() => {
                const newItems = value.filter((i: string) => i !== item);
                onChange(newItems);
              }}
            />
          );
        }}
      />
    </div>
  );
};
