import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_BUS_ROUTES_SUCCESS,
  FETCH_BUS_ROUTES_FAILURE,
  FETCH_BUS_ROUTES_REQUEST,
  FETCH_BUS_ROUTE_SUCCESS,
  FETCH_BUS_ROUTE_FAILURE,
  FETCH_BUS_ROUTE_REQUEST,
  DELETE_BUS_ROUTE_FAILURE,
  DELETE_BUS_ROUTE_REQUEST,
  DELETE_BUS_ROUTE_SUCCESS,
  CREATE_BUS_ROUTE_REQUEST,
  UPDATE_BUS_ROUTE_FAILURE,
  UPDATE_BUS_ROUTE_SUCCESS,
  UPDATE_BUS_ROUTE_REQUEST,
  CREATE_BUS_ROUTE_FAILURE,
  CREATE_BUS_ROUTE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { BusRouteParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchBusRoutesRequest = () => ({
  type: FETCH_BUS_ROUTES_REQUEST,
});

const fetchBusRoutesSuccess = (response: any) => ({
  type: FETCH_BUS_ROUTES_SUCCESS,
  response,
});

const fetchBusRoutesFailure = (error: any) => ({
  type: FETCH_BUS_ROUTES_FAILURE,
  error,
});

let fetchBusRoutesCancel: () => void | undefined;
export const fetchBusRoutes =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchBusRoutesCancel?.();
    dispatch(fetchBusRoutesRequest());
    axios
      .get('/api/busroutes', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchBusRoutesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchBusRoutesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchBusRoutesFailure('canceled'));
        dispatch(fetchBusRoutesFailure(error));
      });
  };

const fetchBusRouteRequest = () => ({
  type: FETCH_BUS_ROUTE_REQUEST,
});

const fetchBusRouteSuccess = (response: any) => ({
  type: FETCH_BUS_ROUTE_SUCCESS,
  response,
});

const fetchBusRouteFailure = (error: any) => ({
  type: FETCH_BUS_ROUTE_FAILURE,
  error,
});

export const fetchBusRoute =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchBusRouteRequest());
    axios
      .get(`/api/busroutes/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchBusRouteSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchBusRouteFailure('canceled'));
        else dispatch(fetchBusRouteFailure(error));
      });
  };

const deleteBusRouteRequest = (id: string) => ({
  type: DELETE_BUS_ROUTE_REQUEST,
  id,
});

const deleteBusRouteSuccess = (response: any, id: string) => ({
  type: DELETE_BUS_ROUTE_SUCCESS,
  response,
  id,
});

const deleteBusRouteFailure = (error: any) => ({
  type: DELETE_BUS_ROUTE_FAILURE,
  error,
});

export const deleteBusRoute =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteBusRouteRequest(id));
    axios
      .delete(`/api/busroutes/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteBusRouteSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteBusRouteFailure(error)));
  };

const createBusRouteRequest = (newBusRoute: BusRouteParams) => ({
  type: CREATE_BUS_ROUTE_REQUEST,
  newBusRoute,
});

const createBusRouteSuccess = (response: any) => ({
  type: CREATE_BUS_ROUTE_SUCCESS,
  response,
});

const createBusRouteFailure = (error: any) => ({
  type: CREATE_BUS_ROUTE_FAILURE,
  error,
});

export const createBusRoute =
  (newBusRoute: BusRouteParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createBusRouteRequest(newBusRoute));
    return axios
      .post('/api/busroutes', newBusRoute, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createBusRouteSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createBusRouteFailure(err.message));
      });
  };

const updateBusRouteRequest = (id: string, patch: BusRouteParams) => ({
  type: UPDATE_BUS_ROUTE_REQUEST,
  id,
  patch,
});

const updateBusRouteSuccess = (response: any) => ({
  type: UPDATE_BUS_ROUTE_SUCCESS,
  response,
});

const updateBusRouteFailure = (error: any) => ({
  type: UPDATE_BUS_ROUTE_FAILURE,
  error,
});

export const updateBusRoute =
  (id: string, patch: BusRouteParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateBusRouteRequest(id, patch));
    return axios
      .patch(`/api/busroutes/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateBusRouteSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateBusRouteFailure(err.message));
      });
  };
