import * as React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from '../../ETicketEdit.module.css';

import { CheckinEndRelativeDateTime } from './CheckinEndRelativeDateTime';
import { ETicketDurationInput } from './ETicketDurationInput';
import { ExpireLimitInput } from './ExpireLimitInput';
import { ETicketSettingsDisplayBox } from './ETicketSettingsDisplayBox';

export const ScheduleEditor = () => {
  const { t } = useTranslation();
  const form = useForm();

  const values = useFormState().values;

  React.useEffect(() => {
    if (values && form) {
      if (values?.checkinStartTimeType === 'RELATIVE') {
        form.change('shouldUseCheckinStartTimeRelative', true);
      } else {
        form.change('shouldUseCheckinStartTimeRelative', false);
      }

      if (values?.checkinEndTimeType === 'RELATIVE') {
        form.change('shouldUseCheckinEndTimeRelative', true);
      } else {
        form.change('shouldUseCheckinEndTimeRelative', false);
      }
    }
  }, [values, form]);

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Redemption start time')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <EnumRadioButtonGroup
            name="checkinStartTimeType"
            liStyle={{ width: '250px', marginRight: '7px' }}
            options={[
              {
                value: 'NORMAL',
                label: t('Valid on the day of participation'),
              },
              {
                value: 'RELATIVE',
                label: t('Available after the specified time'),
              },
            ]}
            disabled={values.isFreeStartDateTime}
          />

          {values.shouldUseCheckinStartTimeRelative && (
            <Box mt={2}>
              <Box>
                <Field name="checkinStartTimeRelative">
                  {({ input }) => <ETicketDurationInput required {...input} />}
                </Field>
              </Box>
              <Box mt={2}>
                <Box mb={2}>
                  <div>
                    {t('Precautions before the start of ticket validity')}
                  </div>
                </Box>
                <Box mb={2}>
                  <Field name="noteForRedemptionModalBeforeCheckinStartTime">
                    {({ input }) => <TextField {...input} />}
                  </Field>
                </Box>
              </Box>
            </Box>
          )}
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Redemption Deadline')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <EnumRadioButtonGroup
            name="checkinEndTimeType"
            liStyle={{ width: '250px', marginRight: '7px' }}
            options={[
              {
                value: 'NORMAL',
                label: t('Valid only on the day of participation'),
              },
              {
                value: 'RELATIVE',
                label: t('Available until the specified time'),
              },
            ]}
            disabled={values.isFreeStartDateTime}
          />

          {values.shouldUseCheckinEndTimeRelative && (
            <>
              <Box mb={2}>
                <div>{t('Deadline for the first ticket redemption')}</div>
                <CheckinEndRelativeDateTime name="checkinEndRelativeDateTime" />
              </Box>
            </>
          )}
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('E-ticket Expiration')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <ExpireLimitInput
            name="expirationDate"
            activateImmediateExpiration={true}
          />
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Display Type')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <EnumRadioButtonGroup
            name="displayType"
            liStyle={{ width: '250px', marginRight: '7px' }}
            options={[
              {
                value: 'COUNTDOWN',
                label: t('Count down'),
              },
              {
                value: 'CLOCK',
                label: t('Time'),
              },
            ]}
            disabled={values?.expirationDate?.deadlineType === 'ETERNAL'}
          />
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Deadlines')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <ETicketSettingsDisplayBox />
        </div>
      </li>
    </ul>
  );
};
