import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  createDigitalMap,
  deleteDigitalMap,
  fetchDigitalMaps,
} from 'client/actions/digitalMaps';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { ReduxState } from 'client/reducers';
import { DigitalMap } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import linkIcon from 'client/images/ic_link.svg';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { convertSourceLanguageToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';

import styles from './DigitalMapList.module.css';

const EditCopyDeleteCell = ({ digitalMap }: { digitalMap: DigitalMap }) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector((state: ReduxState) => state.digitalMaps.loading);
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);

  return (
    <Box display="flex">
      {hasCustomUserRoleWritePermissions(activeUser, 'MAPS.LIST') && (
        <>
          <Link to={`/maps/${digitalMap.id}/edit`}>
            <Edit />
          </Link>
          <Copy
            onClick={async () => {
              await dispatch(
                createDigitalMap({
                  ...digitalMap,
                  pins: digitalMap.pins?.map((pin) => ({
                    ...pin,
                    key: uuidv4(),
                  })),
                  name: `${digitalMap.name} [COPY]`,
                })
              );
              const lastCreatedDigitalMap =
                store.getState().digitalMaps.lastCreatedDigitalMap;
              if (lastCreatedDigitalMap) {
                history.push(`/maps/${lastCreatedDigitalMap.id}/edit`);
              }
            }}
          />
          <Box ml={1}>
            <Delete onClick={() => setShowDeleteModal(true)} />
            {showDeleteModal && (
              <DeleteConfirmModal
                loading={loading}
                header={t('Delete Map')}
                content={t('Are you sure you want to delete map?')}
                onConfirm={async () => {
                  await dispatch(deleteDigitalMap(digitalMap.id as string));
                }}
                onClose={() => setShowDeleteModal(false)}
                open={true}
                insertRoot
              />
            )}
          </Box>
        </>
      )}
      <Box ml={1}>
        <a
          target="_blank"
          href={
            digitalMap?.seo_params?.language
              ? `${getBookingWidgetApiKeyUrl(
                  activeUserOrganization?.booking_widget_api_key ?? ''
                )}/maps/${
                  digitalMap.id
                }?lng=${convertSourceLanguageToBookingWidgetUrlLangCode(
                  digitalMap.seo_params.language
                )}`
              : `${getBookingWidgetApiKeyUrl(
                  activeUserOrganization?.booking_widget_api_key ?? ''
                )}/maps/${digitalMap.id}`
          }
          rel="noreferrer"
        >
          <img className={styles['link-icon']} src={linkIcon} width="25px" />
        </a>
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<DigitalMap>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (template) => <EditCopyDeleteCell digitalMap={template} />,
      width: 150,
    },
    {
      Header: t('Name'),
      id: 'name',
      accessor: (item) => item.name,
    },
  ];
};

export const DigitalMapList = () => {
  const dispatch = useDispatch();
  const columns = useColumns();
  const { t } = useTranslation();

  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    dispatch(fetchDigitalMaps());
    dispatch(fetchProducts());
  }, [activeUserOrganization?.id]);

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          {hasCustomUserRoleWritePermissions(activeUser, 'MAPS.LIST') && (
            <Link to="/maps/new">
              <Button style="green" size="middle">
                {t('Create New Map')}
              </Button>
            </Link>
          )}
        </Box>
      </Box>
      <PagedGenericTable allItems={digitalMaps} columns={columns} />
    </div>
  );
};
