import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import clsx from 'clsx';

import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { TextField } from 'client/components/v3/Form/TextField';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';

export const AdditionalBuyPriceForm = () => {
  const { t } = useTranslation();

  const editingProduct = useContext(EditingProductContext);

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const currency = defaultProductCurrency ?? defaultSupplierCurrency;

  return (
    <div className={styles['p-box']}>
      <div className={styles['p-box__table']}>
        <ul
          className={clsx(
            styles['p-box__table__header'],
            baseStyles['u-spHidden']
          )}
        >
          <li
            className={clsx(
              styles['p-box__table__item'],
              baseStyles['u-width-240']
            )}
          >
            <p className={styles['p-box__table__item__ttl']}>
              {t('Amount (tax included {{currency}})', {
                currency: currency,
              })}
            </p>
          </li>
          <li
            className={clsx(
              styles['p-box__table__item'],
              baseStyles['u-width-240']
            )}
          >
            <p className={styles['p-box__table__item__ttl']}>
              {t('Net Amount ({{currency}})', {
                currency: currency,
              })}
            </p>
          </li>
        </ul>

        <ul className={clsx(styles['p-box__table__body'])}>
          <li
            className={clsx(
              styles['p-box__table__item'],
              styles['p-box__table__item__alignTop']
            )}
            data-title={t('Amount (tax included {{currency}})', {
              currency: currency,
            })}
          >
            <p
              className={clsx(
                styles['p-box__table__item__ttl'],
                baseStyles['u-pcHidden']
              )}
            >
              {t('Amount (tax included {{currency}})', {
                currency: currency,
              })}
            </p>
            <div className={styles['p-box__item__currency']}>
              <Field name={`individualGross`}>
                {({ input }) => (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      if (!currencyInputAllowed(currency, value)) {
                        return;
                      }

                      input.onChange(value);
                    }}
                  />
                )}
              </Field>
            </div>
          </li>
          <li
            className={clsx(
              styles['p-box__table__item'],
              styles['p-box__table__item__alignTop']
            )}
            data-title={t('Net Amount ({{currency}})', {
              currency: currency,
            })}
          >
            <p
              className={clsx(
                styles['p-box__table__item__ttl'],
                baseStyles['u-pcHidden']
              )}
            >
              {t('Net Amount ({{currency}})', {
                currency: currency,
              })}
            </p>
            <div className={styles['p-box__item__currency']}>
              <Field name={`individualNet`}>
                {({ input }) => (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      if (!currencyInputAllowed(currency, value)) {
                        return;
                      }

                      input.onChange(value);
                    }}
                  />
                )}
              </Field>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
