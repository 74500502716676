import { useSelector } from 'react-redux';

import { summariesSelector } from 'client/reducers/products';
import { SingleDropdown } from 'client/pages/v3/Product/ProductPromotions/SingleDropdown';

interface Props {
  productIds: string[];
  value: string;
  onChange: (productId: string) => void;
}

export const ProductSelector = ({ productIds, value, onChange }: Props) => {
  const productSummaries = useSelector(summariesSelector);
  return (
    <SingleDropdown
      selectedOption={value}
      options={productSummaries
        .filter((product) => productIds.includes(product.id))
        .map((product) => ({
          value: product.id,
          text: product.product_name || '',
        }))}
      onChange={onChange}
    />
  );
};
