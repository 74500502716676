import * as React from 'react';
import clsx from 'clsx';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/v3/Common/Button';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { PcSidebarMenu } from 'client/pages/v3/Product/ProductEdit/Menu/PcSidebarMenu';
import { Section } from 'client/pages/v3/Product/ProductEdit/Menu/menuType';
import { SpMenu } from 'client/pages/v3/Product/ProductEdit/Menu/SpMenu';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import v3BaseStyles from 'client/v3-base.module.css';

import styles from './ETicketEdit.module.css';
import { ETicketEditor } from './ETicketEditor';
import { ETicketEditHeader } from './ETicketEditHeader/ETicketEditHeader';

type Params = {
  id: string;
};

export const ETicketEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentSectionId, setCurrentSectionId] = React.useState('products');
  const { id: editingProductId = '' } = useParams<Params>();
  const loading = useSelector((state: ReduxState) => state.products.loading);

  const editingProduct =
    useSelector(
      (state: ReduxState) => state.products.byID[editingProductId || '']
    ) ?? null;

  React.useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [editingProductId]);

  const sections: Section[] = [
    {
      title: t('Ticket Settings'),
      id: 'ticket',
      hasPermission: true,
      subsections: [
        {
          title: t('Basic Settings'),
          id: 'basics',
          hasPermission: true,
        },
        {
          title: t('Start and Expiry Dates'),
          id: 'startTime',
          hasPermission: true,
        },
        {
          title: t('Display Items'),
          id: 'custom',
          hasPermission: true,
        },
      ],
    },
  ];

  return (
    <V3Page>
      <Dimmer active={!editingProduct} page={true} inverted>
        <Loader>{t('Loading')}</Loader>
      </Dimmer>
      <EditingProductContext.Provider value={editingProduct ?? null}>
        <ETicketEditHeader product={editingProduct} />
        <div
          className={clsx(
            v3BaseStyles['l-main__body'],
            styles['p-productEditor']
          )}
        >
          <div className={v3BaseStyles['l-main__body__flex']}>
            <div className={v3BaseStyles['l-main__body__flex__left']}>
              <SpMenu sections={sections} currentSectionId={currentSectionId} />
              <ETicketEditor setCurrentSectionId={setCurrentSectionId} />
              <div
                className={clsx(
                  styles['p-products__fixed'],
                  styles['is-active']
                )}
              >
                <div className={styles['p-products__fixed__main']}></div>
                <div className={styles['p-products__fixed__actions']}>
                  <Button
                    text={t('Save')}
                    type="submit"
                    color="primary"
                    size="md"
                    // Associate button with form id
                    form="editProductForm"
                    loading={loading}
                    iconBeforeText={
                      <i className="c-icon-outline-general-save-01"></i>
                    }
                    style={{ minWidth: '100px' }}
                  />
                </div>
              </div>
            </div>

            <div
              className={clsx(
                v3BaseStyles['l-main__body__flex__right'],
                v3BaseStyles['sticky']
              )}
            >
              <PcSidebarMenu
                sections={sections}
                currentSectionId={currentSectionId}
              />
            </div>
          </div>
        </div>
      </EditingProductContext.Provider>
    </V3Page>
  );
};
