import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { currency } from 'shared/libraries/currency';
import { config } from 'client/config';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import baseStyles from 'client/base.module.css';
import type { ReduxState } from 'client/reducers';
import { fetchReservationByID } from 'client/actions/reservations';
import type { Reservation } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import { activeUserSelector } from 'client/reducers/user';
import { calculateResellNet } from 'client/libraries/util/calculateResellNet';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';

import styles from './ManageReservationModal.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  onPrintETicketClick: () => void;
  onClearAssignmentClick: () => void;
  onDeleteRedemptionRecordsClick: () => void;
  loading?: boolean;
  equipmentTicketPrinted?: boolean;
  reservationSummaryShape?: ReservationSummaryShape;
  reservationLoading?: boolean;
}

export const ManageReservationModal = ({
  open,
  onClose,
  onPrintETicketClick,
  onClearAssignmentClick,
  onDeleteRedemptionRecordsClick,
  loading,
  equipmentTicketPrinted,
  reservationSummaryShape,
  reservationLoading,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const byID = useSelector((state: ReduxState) => state.reservations.byID);
  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);
  const activeUser = useSelector(activeUserSelector);

  const reservation = byID[reservationSummaryShape?.id ?? ''];

  const equipmentBlockReferences = equipmentAssignments
    ?.filter((e) => e.reservation_id === reservationSummaryShape?.id ?? '')
    ?.map((e) => e.equipment_block_reference)
    ?.sort()
    ?.join(',');

  React.useEffect(() => {
    if (reservationSummaryShape && !reservation) {
      dispatch(fetchReservationByID(reservationSummaryShape.id));
    }
  }, [reservationSummaryShape, reservation]);

  return (
    <Modal open={open} onClose={onClose} title={t('Manage Reservation')}>
      <Modal.Content>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <FieldWrapper label={t('Reservation Info')}>
            <Box mb={2}>
              <div className={clsx(styles['c-table-nowrap'])}>
                <table>
                  <tbody>
                    <tr>
                      <th className={clsx(styles['center-align'])}>
                        {t('Application Number')}
                      </th>
                      <th className={clsx(styles['center-align'])}>
                        {t('Customer')}
                      </th>
                      <th className={clsx(styles['center-align'])}>
                        {t('Unit')}
                      </th>
                    </tr>
                    <tr>
                      <td className={clsx(styles['center-align'])}>
                        {reservationSummaryShape?.agent_reference}
                      </td>
                      <td className={clsx(styles['center-align'])}>
                        {reservationSummaryShape?.guest_display_name}
                      </td>
                      <td className={clsx(styles['right-align'])}>
                        {reservationSummaryShape?.guest_description}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>

            <Box mb={2}>
              <div className={clsx(styles['c-table-nowrap'])}>
                <table>
                  <tbody>
                    <tr>
                      <th
                        className={clsx(
                          styles['right-align'],
                          baseStyles['base-t-96']
                        )}
                      >
                        {t('Product')}
                      </th>
                      <td>{reservationSummaryShape?.product_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>
          </FieldWrapper>

          <FieldWrapper label={t('Selected Seats')}>
            <Box mb={2}>
              <div className={clsx(styles['c-table-nowrap'])}>
                <table>
                  <tbody>
                    <tr>
                      <th
                        className={clsx(
                          styles['right-align'],
                          baseStyles['base-t-96']
                        )}
                      >
                        {t('Seats')}
                      </th>
                      <td>{equipmentBlockReferences}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>
          </FieldWrapper>

          <FieldWrapper label={t('Amount')}>
            {reservation && <BillingInfo reservation={reservation} />}
          </FieldWrapper>

          <Box mb={2} mt={4}>
            <Button style="blue" size="middle" onClick={onPrintETicketClick}>
              {equipmentTicketPrinted
                ? t('Re-print Ticket')
                : t('Print Ticket')}
            </Button>
          </Box>
          {hasCustomUserRoleWritePermissions(
            activeUser,
            'SEAT_MANAGEMENT.ASSIGNMENTS'
          ) && (
            <>
              <Box mb={2}>
                <Button
                  style="red"
                  size="middle"
                  onClick={onClearAssignmentClick}
                  disabled={reservationLoading}
                  loading={reservationLoading}
                >
                  {t('Clear Assignment')}
                </Button>
              </Box>
              <Box mb={2}>
                <Button
                  style="red"
                  size="middle"
                  onClick={onDeleteRedemptionRecordsClick}
                  disabled={reservationLoading}
                  loading={reservationLoading}
                >
                  {config.enableUndoEqauipmentTicketPrint
                    ? t('Undo Redemption & Ticketing')
                    : t('Delete Redemption Records')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose} loading={loading}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const BillingInfo = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);
  const isResellAgent =
    reservation.contract_type === 'RESELL' &&
    activeUser?.organization_type === 'AGENT';

  let netTotal = t('Unknown');

  if (reservation.billing_info?.amount_net) {
    netTotal = reservation.billing_info.amount_net;
    if (isResellAgent && reservation.billing_info.amount_gross) {
      netTotal = calculateResellNet(
        reservation.billing_info.amount_gross,
        reservation.billing_info.amount_net
      );
    }
  }

  let grossTotal = reservation.billing_info?.amount_gross;
  if (
    reservation.status === 'CANCELED_BY_AGENT' ||
    reservation.status === 'CANCELED_BY_GUEST' ||
    reservation.status === 'CANCELED_BY_SUPPLIER'
  ) {
    grossTotal = reservation.billing_info?.amount_cancellation_fee_gross;
    netTotal = reservation.billing_info?.amount_cancellation_fee_net ?? '';
  }

  return (
    <div className={clsx(componentStyles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th className={clsx(baseStyles['base-t-128'])}>{t('Title')}</th>
            <th className={clsx(baseStyles['base-t-128'])}>{t('Gross')}</th>
            <th className={clsx(baseStyles['base-t-128'])}>{t('Net')}</th>
            <th className={clsx(baseStyles['base-t-128'])}>{t('Count')}</th>
            <th className={clsx(componentStyles['c-table-nowrap__cell'])}>
              {t('Notes')}
            </th>
          </tr>
          {reservation.billing_info?.line_items?.map((item, idx) => {
            let net = item.amount_net;
            let calNet = '';
            let calGross = '';
            const count = item.count ? item.count : 0;

            if (isResellAgent && item.amount_gross && item.amount_net) {
              net = calculateResellNet(item.amount_gross, item.amount_net);
            }

            if (net) {
              calNet = currency(net).multiply(count).format();
            }
            if (item.amount_gross) {
              calGross = currency(item.amount_gross).multiply(count).format();
            }

            return (
              <tr key={idx}>
                <td>
                  {item.title === 'fare adjustment' ? t('Adjust') : item.title}
                </td>
                <td>
                  <p className={clsx(componentStyles['c-table-nowrap__bold'])}>
                    {calGross}
                  </p>
                  <p className={clsx(componentStyles['c-table-nowrap_small'])}>
                    {currency(item.amount_gross || '').format()} x {item.count}
                  </p>
                </td>
                <td>
                  <p className={clsx(componentStyles['c-table-nowrap__bold'])}>
                    {calNet}
                  </p>
                  <p className={clsx(componentStyles['c-table-nowrap__small'])}>
                    {currency(net || '').format()} x {item.count}
                  </p>
                </td>
                <td>{item.count !== undefined ? `x${item.count}` : 'x0'}</td>
                <td>{item.notes}</td>
              </tr>
            );
          })}
          {reservation.billing_info?.variable_markup_amount &&
            currency(reservation.billing_info?.variable_markup_amount)
              .intValue !== 0 && (
              <tr>
                <td>{t('Taxes and Fees')}</td>
                <td>
                  <p className={clsx(componentStyles['c-table-nowrap__bold'])}>
                    {currency(
                      reservation.billing_info?.variable_markup_amount ?? ''
                    ).format()}
                  </p>
                </td>
                <td>-</td>
                <td>x1</td>
                <td />
              </tr>
            )}

          <tr>
            <td>{t('Total')}</td>
            <td>
              <p className={clsx(componentStyles['c-table-nowrap__bold'])}>
                {formattedCurrencyAmount(grossTotal ?? '')}
              </p>
            </td>
            <td>
              <p className={clsx(componentStyles['c-table-nowrap__bold'])}>
                {formattedCurrencyAmount(netTotal ?? '')}
              </p>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
