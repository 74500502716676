import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_BUS_ROUTE_FAILURE,
  CREATE_BUS_ROUTE_REQUEST,
  CREATE_BUS_ROUTE_SUCCESS,
  DELETE_BUS_ROUTE_FAILURE,
  DELETE_BUS_ROUTE_REQUEST,
  DELETE_BUS_ROUTE_SUCCESS,
  FETCH_BUS_ROUTES_FAILURE,
  FETCH_BUS_ROUTES_REQUEST,
  FETCH_BUS_ROUTES_SUCCESS,
  FETCH_BUS_ROUTE_FAILURE,
  FETCH_BUS_ROUTE_REQUEST,
  FETCH_BUS_ROUTE_SUCCESS,
  UPDATE_BUS_ROUTE_FAILURE,
  UPDATE_BUS_ROUTE_REQUEST,
  UPDATE_BUS_ROUTE_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  BusRoute,
  CreateBusRouteResponse,
  ListBusRoutesResponse,
  UpdateBusRouteResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_BUS_ROUTES_FAILURE:
    case FETCH_BUS_ROUTE_FAILURE:
    case CREATE_BUS_ROUTE_FAILURE:
    case UPDATE_BUS_ROUTE_FAILURE:
    case DELETE_BUS_ROUTE_FAILURE:
      return action.error;

    case FETCH_BUS_ROUTES_SUCCESS:
    case FETCH_BUS_ROUTE_SUCCESS:
    case CREATE_BUS_ROUTE_SUCCESS:
    case UPDATE_BUS_ROUTE_SUCCESS:
    case DELETE_BUS_ROUTE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_BUS_ROUTES_REQUEST:
    case FETCH_BUS_ROUTE_REQUEST:
    case CREATE_BUS_ROUTE_REQUEST:
    case UPDATE_BUS_ROUTE_REQUEST:
    case DELETE_BUS_ROUTE_REQUEST:
      return true;

    case FETCH_BUS_ROUTES_FAILURE:
    case FETCH_BUS_ROUTE_FAILURE:
    case CREATE_BUS_ROUTE_FAILURE:
    case UPDATE_BUS_ROUTE_FAILURE:
    case DELETE_BUS_ROUTE_FAILURE:
    case FETCH_BUS_ROUTES_SUCCESS:
    case FETCH_BUS_ROUTE_SUCCESS:
    case CREATE_BUS_ROUTE_SUCCESS:
    case UPDATE_BUS_ROUTE_SUCCESS:
    case DELETE_BUS_ROUTE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: BusRoute[] = [], action: any): BusRoute[] => {
  switch (action.type) {
    case FETCH_BUS_ROUTES_SUCCESS: {
      const response = action.response as ListBusRoutesResponse;
      return response.bus_routes ?? [];
    }

    case FETCH_BUS_ROUTE_SUCCESS: {
      const response = action.response as BusRoute;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_BUS_ROUTE_SUCCESS: {
      const response = action.response as CreateBusRouteResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_BUS_ROUTE_SUCCESS: {
      const response = action.response as UpdateBusRouteResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_BUS_ROUTE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const busRoutes = combineReducers({
  error,
  loading,
  all,
});
