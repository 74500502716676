import { FieldArray } from 'react-final-form-arrays';
import {
  Field,
  FieldRenderProps,
  useForm,
  useFormState,
} from 'react-final-form';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { OptionalIntegerInput } from 'client/components/v3/Form/OptionalIntegerInput';

import styles from './SiteControlWidgetEditor.module.css';
import { FormValues } from './formValues';

export const VisitAudienceTargetingEditor = () => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const values = formState.values;
  const form = useForm();

  const visitTypeOptions = [
    {
      text: t('Landing source'),
      value: 'LANDING_SOURCE',
    },
    // {
    //   text: t('Session'),
    //   value: 'SESSION',
    // },
    // {
    //   text: t('Page view'),
    //   value: 'PAGE_VIEW',
    // },
    {
      text: t('Number of visit'),
      value: 'NUMBER_OF_VISIT',
    },
    // {
    //   text: t('Previous page'),
    //   value: 'PREVIOUS_PAGE',
    // },
  ];
  const referralPathOptions = [
    {
      text: 'Google',
      value: 'google',
    },
    {
      text: 'Facebook',
      value: 'facebook',
    },
    {
      text: 'Instagram',
      value: 'instagram',
    },
    {
      text: 'X(Twitter)',
      value: 'twitter',
    },
    {
      text: 'Bing',
      value: 'bing',
    },
    {
      text: 'Yahoo',
      value: 'yahoo',
    },
  ];
  const dimensionTypeOptions = [
    {
      text: t('X or more'),
      value: 'COUNT_GTE',
    },
    {
      text: t('Less than X'),
      value: 'COUNT_LT',
    },
    {
      text: t('Between X and Y'),
      value: 'COUNT_BETWEEN',
    },
  ];

  const requiredForNumber = useCallback(
    (value?: number): string | undefined => {
      if (value === undefined || value === null) return t('Required');
      return undefined;
    },
    [t]
  );

  return (
    <FieldArray name="visitAudienceTargets">
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((fieldName, idx) => {
              return (
                <React.Fragment key={fieldName}>
                  <div className={styles['p-schedule__item']}>
                    <div className={styles['p-schedule__item__condition']}>
                      <div className={styles['p-schedule__item__type']}>
                        <Field name={`${fieldName}.type`}>
                          {({ input }: FieldRenderProps<string>) => (
                            <SingleDropdown
                              options={visitTypeOptions}
                              selectedOption={input.value ?? 'LANDING_SOURCE'}
                              onChange={(value) => {
                                input.onChange(value);
                                if (value === 'LANDING_SOURCE') {
                                  form.change(
                                    `${fieldName}.dimensionType`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.dimensionComparisonValue`,
                                    undefined
                                  );
                                } else if (value === 'NUMBER_OF_VISIT') {
                                  form.change(
                                    `${fieldName}.referralPaths`,
                                    undefined
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      {values.visitAudienceTargets &&
                        values.visitAudienceTargets?.length > 0 &&
                        values.visitAudienceTargets?.[idx].type ===
                          'LANDING_SOURCE' && (
                          <div
                            className={
                              styles['p-schedule__item__conditionDetails']
                            }
                          >
                            <Field name={`${fieldName}.referralPaths`}>
                              {({ input }) => (
                                <MultiDropdown
                                  selectedOptions={input.value}
                                  onChange={(newValue) =>
                                    input.onChange(newValue)
                                  }
                                  options={referralPathOptions}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      {values.visitAudienceTargets &&
                        values.visitAudienceTargets?.length > 0 &&
                        values.visitAudienceTargets?.[idx].type ===
                          'NUMBER_OF_VISIT' && (
                          <div
                            className={
                              styles['p-schedule__item__conditionDetails']
                            }
                          >
                            <Field name={`${fieldName}.dimensionType`}>
                              {({ input }) => (
                                <SingleDropdown
                                  selectedOption={input.value}
                                  onChange={(value) => {
                                    input.onChange(value);
                                    if (value === 'COUNT_GTE') {
                                      form.change(
                                        `${fieldName}.dimensionComparisonValue.upperBound`,
                                        undefined
                                      );
                                    } else if (value === 'COUNT_LT') {
                                      form.change(
                                        `${fieldName}.dimensionComparisonValue.lowerBound`,
                                        undefined
                                      );
                                    }
                                  }}
                                  options={dimensionTypeOptions}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      {values.visitAudienceTargets?.[idx].dimensionType ===
                        'COUNT_GTE' && (
                        <Field
                          name={`${fieldName}.dimensionComparisonValue.lowerBound`}
                          validate={requiredForNumber}
                        >
                          {({
                            input,
                            meta: { error, touched },
                          }: FieldRenderProps<number>) => (
                            <OptionalIntegerInput
                              value={input.value}
                              onChange={(value) => input.onChange(value)}
                              error={touched && error ? error : undefined}
                              disallowNegative={true}
                            />
                          )}
                        </Field>
                      )}
                      {values.visitAudienceTargets?.[idx].dimensionType ===
                        'COUNT_LT' && (
                        <Field
                          name={`${fieldName}.dimensionComparisonValue.upperBound`}
                          validate={requiredForNumber}
                        >
                          {({
                            input,
                            meta: { error, touched },
                          }: FieldRenderProps<number>) => (
                            <OptionalIntegerInput
                              value={input.value}
                              onChange={(value) => input.onChange(value)}
                              error={touched && error ? error : undefined}
                              disallowNegative={true}
                            />
                          )}
                        </Field>
                      )}
                      {values.visitAudienceTargets?.[idx].dimensionType ===
                        'COUNT_BETWEEN' && (
                        <div
                          className={
                            styles['p-schedule__item__conditionDetails']
                          }
                        >
                          <Field
                            name={`${fieldName}.dimensionComparisonValue.lowerBound`}
                            validate={requiredForNumber}
                          >
                            {({
                              input,
                              meta: { error, touched },
                            }: FieldRenderProps<number>) => (
                              <OptionalIntegerInput
                                value={input.value}
                                onChange={(value) => input.onChange(value)}
                                error={touched && error ? error : undefined}
                                disallowNegative={true}
                              />
                            )}
                          </Field>
                          <p className={styles['p-schedule__date__p']}>~</p>
                          <Field
                            name={`${fieldName}.dimensionComparisonValue.upperBound`}
                            validate={requiredForNumber}
                          >
                            {({
                              input,
                              meta: { error, touched },
                            }: FieldRenderProps<number>) => (
                              <OptionalIntegerInput
                                value={input.value}
                                onChange={(value) => input.onChange(value)}
                                error={touched && error ? error : undefined}
                                disallowNegative={true}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                    </div>
                    <div className={styles['p-button__deleteCondition']}>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => {
                          fields.remove(idx);
                        }}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>

                  {fields.length &&
                    fields.length > 1 &&
                    idx !== fields.length - 1 && (
                      <div className={styles['p-schedule__and']}>
                        {t('AND')}
                      </div>
                    )}
                </React.Fragment>
              );
            })}
          <div>
            <a
              className={styles['p-button__addCondition']}
              style={{
                height: fields.length === 0 ? '46px' : undefined,
              }}
              onClick={async () => {
                // Init empty audience target visit upon addition
                let index = 0;
                if (fields.length && fields.length > 0) {
                  index = fields.length;
                }
                (fields as any).insertAt(index, { type: 'LANDING_SOURCE' });
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add Condition')}
            </a>
          </div>
        </>
      )}
    </FieldArray>
  );
};
