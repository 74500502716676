import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { parseDuration } from 'client/libraries/util/util';

import styles from './ETicketDurationInput.module.css';

type Props = {
  value: string;
  onChange: (arg0: string) => void;
  required?: boolean;
  positive?: boolean;
  dateText?: string;
  timeText?: string;
  tailText?: string;
};

const getDuration = (days: string, hours: string, minutes: string): string => {
  const d = parseInt(days);
  const h = parseInt(hours);
  const m = parseInt(minutes);
  return `${d * 24 + h}:${m < 10 ? `0${m}` : m}`;
};

export const ETicketDurationInput = ({
  required,
  value,
  onChange,
  positive,
  dateText,
  timeText,
  tailText,
}: Props) => {
  const { t } = useTranslation();

  let { hours: rawHours, minutes: rawMinutes } = parseDuration(value);

  if (rawHours < 0) {
    rawHours = -rawHours;
  }

  if (rawMinutes < 0) {
    rawMinutes = -rawMinutes;
  }

  const days = value ? `${Math.floor(rawHours / 24)}` : '';
  const hours = value ? `${rawHours % 24}` : '';
  const minutes = value ? `${rawMinutes}` : '';
  return (
    <div>
      <Box>
        <Box mb={2}>
          <div>{dateText ?? t('Redemption date')}</div>
        </Box>
        <label className={clsx(styles['day-select'])}>
          <SingleDropdown
            options={[
              ...(!required
                ? [
                    {
                      value: '',
                      text: '-',
                    },
                  ]
                : []),
              ..._.times(11).map((d) => ({
                value: `${d}`,
                text:
                  d === 0
                    ? t('On the participation date')
                    : positive
                    ? t('{{count}} days after', { count: d })
                    : t('{{count}} days before', { count: d }),
              })),
            ]}
            selectedOption={days}
            onChange={(newDays) => {
              if (newDays === '') {
                onChange('');
              } else {
                onChange(
                  `${positive ? '' : '-'}${getDuration(
                    newDays,
                    hours || '0',
                    minutes || '0'
                  )}`
                );
              }
            }}
          />
        </label>
      </Box>

      <Box mt={2}>
        <Box mb={2}>
          <div>{timeText ?? t('Redemption start time')}</div>
        </Box>
        <Box display="flex" alignItems="center">
          <label className={clsx(styles['hour-select'])}>
            <SingleDropdown
              selectedOption={hours}
              onChange={(newHours) => {
                if (newHours === '') {
                  onChange('');
                } else {
                  onChange(
                    `${positive ? '' : '-'}${getDuration(
                      days || '0',
                      newHours,
                      minutes || '0'
                    )}`
                  );
                }
              }}
              options={[
                ...(!required
                  ? [
                      {
                        value: '',
                        text: '-',
                      },
                    ]
                  : []),
                ..._.times(24).map((hours) => ({
                  value: `${hours}`,
                  text: t('{{count}}h', {
                    count: hours,
                  }),
                })),
              ]}
            />
          </label>
          <label className={clsx(styles['minute-select'])}>
            <SingleDropdown
              selectedOption={minutes}
              onChange={(newMinutes) => {
                if (newMinutes === '') {
                  onChange('');
                } else {
                  onChange(
                    `${positive ? '' : '-'}${getDuration(
                      days || '0',
                      hours || '0',
                      newMinutes
                    )}`
                  );
                }
              }}
              options={[
                ...(!required
                  ? [
                      {
                        value: '',
                        text: '-',
                      },
                    ]
                  : []),
                ..._.times(60).map((min) => ({
                  value: `${min}`,
                  text: t('{{count}}m', {
                    count: min,
                  }),
                })),
              ]}
            />
          </label>
          <Box ml={2}>{tailText ?? t('before the start time')}</Box>
        </Box>
      </Box>
    </div>
  );
};
