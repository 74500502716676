import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { ReservationStatus } from 'shared/models/swagger';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';

import styles from './SystemFeeInvoiceDetails.module.css';
import { FilterReservationsRequest, getPaymentTypeOptions } from './util';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  filterCondition: FilterReservationsRequest;
  setFilterCondition: (arg0: FilterReservationsRequest) => void;
};
export const FilterBlock = ({
  onReset,
  onSearch,
  filterCondition,
  setFilterCondition,
}: Props) => {
  const { t } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);
  const products = useSelector(summariesSortedByBookmarkedSelector);

  const showDepositDate = org?.stripe_accounts?.some(
    (acct) => acct.account_type === 'JAPAN_EXPRESS'
  );

  const productOptions = products.map((p) => {
    return {
      value: p.id,
      text: getDisplayProductName(p),
    };
  });
  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const paymentTypeOptions = getPaymentTypeOptions(t);

  const changeProductId = (value: string[]) => {
    setFilterCondition({ ...filterCondition, productIds: value });
  };

  const changeStatus = (value: string[]) => {
    setFilterCondition({
      ...filterCondition,
      reservationStatuses: value as ReservationStatus[],
    });
  };

  const changePaymentTypes = (value: string[]) => {
    setFilterCondition({
      ...filterCondition,
      paymentTypes: value as string[],
    });
  };

  const transferDateChangeHandler = (dates: [string | null, string | null]) => {
    const [startDate, endDate] = dates;
    setFilterCondition({
      ...filterCondition,
      transferDateFrom: startDate || '',
      transferDateTo: endDate || '',
    });
  };

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <MultiDropdown
          searchable={true}
          options={productOptions}
          selectedOptions={filterCondition.productIds}
          onChange={changeProductId as any}
          label={t('Product')}
        />
      </div>
      <div className={styles['p-sysfee__filter__block']}>
        <div className={styles['p-box__input']} style={{ marginRight: '20px' }}>
          <MultiDropdown
            searchable={true}
            options={statusOptions}
            selectedOptions={filterCondition.reservationStatuses}
            onChange={changeStatus as any}
            label={t('Booking Status')}
          />
        </div>
        <div className={styles['p-sysfee__filter__block__paymenttype']}>
          <div
            style={{
              marginRight: '16px',
              color: 'var(--gray500)',
              fontSize: 'var(--text-sm-size);',
              lineHeight: 'var(--text-xs-height)',
            }}
          >
            {t('Payment Type')}
          </div>
          {paymentTypeOptions.map((option, idx) => (
            <div key={idx} style={{ marginRight: '10px' }}>
              <Checkbox
                key={option.value}
                label={option.text}
                size="sm"
                checked={filterCondition.paymentTypes.includes(option.value)}
                onChange={() => {
                  filterCondition.paymentTypes.includes(option.value)
                    ? changePaymentTypes(
                        filterCondition.paymentTypes.filter(
                          (p) => p !== option.value
                        )
                      )
                    : changePaymentTypes([
                        ...filterCondition.paymentTypes,
                        option.value,
                      ]);
                }}
              />
            </div>
          ))}
        </div>
        {showDepositDate && (
          <div className={styles['p-box__input']} style={{ marginRight: '0' }}>
            <DateRangeInput
              label={t('Deposit Date')}
              dateFrom={filterCondition.transferDateFrom}
              dateTo={filterCondition.transferDateTo}
              onChange={transferDateChangeHandler}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginRight: '25px' }}>
          <Button
            text={t('Clear')}
            color="white"
            size="sm"
            onClick={() => onReset()}
          />
        </div>
        <Button
          text={t('Filter')}
          color="primary"
          size="sm"
          onClick={() => onSearch()}
        />
      </div>
    </>
  );
};
