import * as React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';

import styles from '../../ETicketEdit.module.css';
import { FieldResponsesForm } from '../../Components/FieldResponsesForm/FieldResponsesForm';

import { PresetRedemptionCountsForm } from './PresetRedemptionCountsForm';
import { StubsForm } from './StubsForm';
import { AdditionalBuyForm } from './AdditionalBuyForm';

export const BasicEditor = () => {
  const { t } = useTranslation();
  const form = useForm();

  const values = useFormState().values;

  React.useEffect(() => {
    if (values && form) {
      if (values?.eTicketType === 'FREEPASS') {
        form.change('isFreeStartDateTime', true);
        form.change('checkinStartTimeType', 'NORMAL');
        form.change('checkinEndTimeType', 'NORMAL');
        form.change('shouldUseFootPrint', true);
      } else {
        form.change('isFreeStartDateTime', false);
      }

      if (values?.consumptionType === 'SPLIT') {
        form.change('shouldUseCountGuestsWithGuestType', true);
        form.change('shouldUseRedemptionCount', false);
      } else if (values?.consumptionType === 'MULTI-RIDE') {
        form.change('shouldUseCountGuestsWithGuestType', false);
        form.change('shouldUseRedemptionCount', true);
      } else {
        form.change('shouldUseCountGuestsWithGuestType', false);
        form.change('shouldUseRedemptionCount', false);
      }

      if (values?.checkinStartTimeType === 'RELATIVE') {
        form.change('shouldUseCheckinStartTimeRelative', true);
      } else {
        form.change('shouldUseCheckinStartTimeRelative', false);
      }
    }
  }, [values, form]);

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('E-ticket')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="shouldUseETicket" type="checkbox">
            {({ input: shouldUseETicketInput }) => (
              <>
                <Toggle
                  label={t('Use E-ticket')}
                  checked={shouldUseETicketInput.checked}
                  onChange={() => {
                    shouldUseETicketInput.onChange(
                      !shouldUseETicketInput.checked
                    );
                    form.change('shouldUseEasyCheckin', false);
                    form.change('shouldCountGuests', false);
                  }}
                />
              </>
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Type')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <EnumRadioButtonGroup
            name="eTicketType"
            liStyle={{ width: '250px', marginRight: '7px' }}
            options={[
              {
                value: 'NORMAL',
                label: t(
                  'A regular ticket that specifies the participation date'
                ),
              },
              {
                value: 'FREEPASS',
                label: t(
                  'A free pass that does not specify the participation date'
                ),
              },
            ]}
          />
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Redeem Ticket')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Box mb={2}>
            <EnumRadioButtonGroup
              name="consumptionType"
              liStyle={{ width: '180px', marginRight: '7px' }}
              options={[
                {
                  value: 'NORMAL',
                  label: t('Redeem ticket for all participants at once'),
                },
                {
                  value: 'SPLIT',
                  label: t(
                    'Divide the number of participants and redeem separately'
                  ),
                },
                {
                  value: 'MULTI-RIDE',
                  label: t('Multi-ride ticket'),
                },
              ]}
            />
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            {values.shouldUseRedemptionCount && !values.showStubSettings && (
              <>
                <Box mr={2} width="200px">
                  <Field name="maxRedemptionCount">
                    {({ input }) => (
                      <TextField
                        type="number"
                        label={t('Number of uses')}
                        value={String(input.value)}
                        onChange={(value) => {
                          input.onChange(Number(value));
                        }}
                      />
                    )}
                  </Field>
                </Box>
              </>
            )}
          </Box>
          {values.shouldUseRedemptionCount && !values.showStubSettings && (
            <Field name="useQrScanOnly" type="checkbox">
              {({ input }) => (
                <>
                  <Toggle
                    label={t(
                      'Make it available only when the QR code is scanned'
                    )}
                    checked={input.checked}
                    onChange={() => {
                      input.onChange(!input.checked);
                    }}
                  />
                </>
              )}
            </Field>
          )}
          {values.shouldUseRedemptionCount && !values.showStubSettings && (
            <PresetRedemptionCountsForm />
          )}
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Ticket Usage')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="showStubSettings" type="checkbox">
            {({ input }) => (
              <>
                <Toggle
                  label={t('Use redemption usage')}
                  checked={input.checked}
                  onChange={() => {
                    input.onChange(!input.checked);
                  }}
                />
              </>
            )}
          </Field>

          {values.showStubSettings && <StubsForm />}
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Items to Display for Active Tickets')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Box mb={2}>
            <Field name="shouldUseFootPrint" type="checkbox">
              {({ input }) => (
                <>
                  <Toggle
                    label={t('Use [Show] button')}
                    checked={input.checked}
                    onChange={() => {
                      input.onChange(!input.checked);
                      form.change(
                        'shouldShowCustomizedDisplayItemsOnFootprintedModal',
                        false
                      );
                    }}
                    disabled={values.isFreeStartDateTime}
                  />
                </>
              )}
            </Field>
          </Box>
          <Field
            name="shouldShowCustomizedDisplayItemsOnFootprintedModal"
            type="checkbox"
          >
            {({ input }) => (
              <>
                <Toggle
                  label={t('Show the field response on ETicket')}
                  disabled={!values.shouldUseFootPrint}
                  checked={input.checked}
                  onChange={() => {
                    input.onChange(!input.checked);
                  }}
                />
              </>
            )}
          </Field>

          {values.shouldShowCustomizedDisplayItemsOnFootprintedModal && (
            <FieldResponsesForm
              name="footprintedModalDisplayItems"
              includeConfirmationNumber={true}
              addItemText={t('Add Reservation Form to Display')}
            />
          )}
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Terms of Service URL')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="termsOfServiceUrl">
            {({ input }) => <TextField {...input} />}
          </Field>
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Ticket Usage Precautions')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="noteForRedemptionModal">
            {({ input }) => <TextField {...input} />}
          </Field>
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Bar Code')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="barCode">
            {({ input }) => <TextField {...input} />}
          </Field>
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Additional Ticket Purchase')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="shouldUseAdditionalBuy" type="checkbox">
            {({ input }) => (
              <>
                <Toggle
                  label={t('Allow purchasing additional ticket')}
                  disabled={!values.shouldUseRedemptionCount}
                  checked={input.checked}
                  onChange={() => {
                    input.onChange(!input.checked);
                  }}
                />
              </>
            )}
          </Field>

          {values.shouldUseAdditionalBuy && <AdditionalBuyForm />}
        </div>
      </li>
    </ul>
  );
};
