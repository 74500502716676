// @flow

import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  List,
  Modal,
  Message,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { TranslatorProps } from 'react-i18next';

import {
  createOrganization,
  updateOrganization,
} from 'client/actions/organizations';
import { idToNameSelector } from 'client/reducers/organizations';
import { getLanguageName } from 'client/libraries/i18n';
import { ThirdPartyReferenceInput } from 'client/pages/Manage/BranchesTab/ThirdPartyReferenceInput.js';
import { LogoInput } from 'client/pages/Manage/BranchesTab/LogoInput';
import { IconInput } from 'client/pages/Manage/BranchesTab/IconInput';
import { OperatingHoursInput } from 'client/pages/Manage/BranchesTab/OperatingHoursInput';
import { SupplierGuestPaymentSettingsInput } from 'client/pages/Manage/BranchesTab/SupplierGuestPaymentSettingsInput';
import { MultiselectListInput } from 'client/components/MultiselectListInput';
import type { ReduxState } from 'client/reducers';
import type {
  Organization$Patch,
  Organization$Input,
  Organization,
} from 'shared/models/swagger';

const ERROR_ID_SEED_ALREADY_IN_USE = 'ERROR_ID_SEED_ALREADY_IN_USE';
const ERROR_EMERGENCY_CONTACT_EMPTY = 'ERROR_EMERGENCY_CONTACT_EMPTY';
const ERROR_ORGANIZATION_NAME_EMPTY = 'ERROR_ORGANIZATION_NAME_EMPTY';
const ERROR_ORGANIZATION_NAME_REPEATED = 'ERROR_ORIGANIZATION_NAME_REPEATED';

const getOrgOptions = (
  organizations: Organization[]
): { key: string, value: string, text: string }[] => {
  return organizations
    .sort((org1, org2) => {
      var org1Name = (org1.name || '').toUpperCase();
      var org2Name = (org2.name || '').toUpperCase();
      if (org1Name < org2Name) {
        return -1;
      }
      if (org1Name > org2Name) {
        return 1;
      }

      return 0;
    })
    .map((org) => ({
      key: org.id,
      value: org.id,
      text: org.name || '',
    }));
};

type OwnProps = {
  branch: Organization,
  trigger: React.ComponentType<{}>,
  header?: string,
  agentIdSeedsMap: { [key: string]: string[] },
  supplierIdSeedsMap: { [key: string]: string[] },
  idToNameMap: { [key: string]: { [key: string]: string } },
};

/* eslint-disable no-use-before-define */
type Props = {|
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
|};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  branch: Organization,
  takenById: string,
  takenByName: string,
  formError: string,
  showBookingWidgetSettings: boolean,
};

class EditBranchModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      branch: props.branch,
      takenById: '',
      takenByName: '',
      formError: '',
      showBookingWidgetSettings: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.branch !== this.props.branch) {
      this.setState({
        branch: this.props.branch,
      });
    }
  }

  render() {
    const { trigger: Trigger, header, organizations, t } = this.props;
    const { branch, showBookingWidgetSettings } = this.state;

    const supplier_guest_payment_settings =
      branch.supplier_guest_payment_settings || {};

    const correspondingAgentOptions = getOrgOptions(
      organizations.filter((org) => org.type === 'AGENT')
    );
    const correspondingSupplierOptions = getOrgOptions(
      organizations.filter((org) => org.type === 'SUPPLIER')
    );

    return (
      <React.Fragment>
        <Modal
          trigger={
            <span
              onClick={() =>
                this.setState({
                  showModal: true,
                })
              }
            >
              <Trigger />
            </span>
          }
          open={this.state.showModal}
          onClose={() =>
            this.setState({
              showModal: false,
            })
          }
          className="fix-semantic-modal"
        >
          <Header icon="edit" content={header} />
          <Modal.Content>
            <Modal.Description>
              <List>
                {branch.id && (
                  <List.Item>{t('ID') + `: ${branch.id}`}</List.Item>
                )}
              </List>
              <Form error={!!this.state.formError}>
                <Form.Input
                  required
                  label={t('Name')}
                  value={branch.name || ''}
                  onChange={(e, { value }) =>
                    this.setState({
                      branch: {
                        ...branch,
                        name: value,
                      },
                    })
                  }
                />
                {!branch.id && (
                  <>
                    <Form.Select
                      required
                      label={t('Type')}
                      value={branch.type}
                      options={['AGENT', 'SUPPLIER'].map((t) => ({
                        key: t,
                        text: t,
                        value: t,
                      }))}
                      onChange={(e, { value }) => {
                        this.setState({
                          branch: {
                            ...branch,
                            type: value,
                          },
                          formError: '',
                        });
                      }}
                    />
                    <Form.Input
                      required
                      label={t('Office Email')}
                      value={branch.office_email || ''}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            office_email: value,
                          },
                        })
                      }
                    />
                  </>
                )}
                <ThirdPartyReferenceInput
                  label={t('Third Party References')}
                  value={
                    branch.third_party_references &&
                    branch.third_party_references.map((ref) => ({
                      id: ref.id ? ref.id : 'AGENT',
                      type: branch.type === 'AGENT' ? 'SUPPLIER' : 'AGENT',
                      reference: ref.reference ? ref.reference : '',
                    }))
                  }
                  update={(updater) => {
                    const updated_references = updater(
                      branch.third_party_references
                        ? branch.third_party_references.map((ref) => ({
                            id: ref.id ? ref.id : 'AGENT',
                            type:
                              !ref.id ||
                              (!this.props.idToNameMap['AGENT'][ref.id] &&
                                this.props.idToNameMap['AGENT'][ref.id] !== '')
                                ? 'SUPPLIER'
                                : 'AGENT',
                            reference: ref.reference ? ref.reference : '',
                          }))
                        : []
                    );
                    const formatted_references = updated_references.map(
                      (ref) => ({
                        id: ref.id,
                        reference: ref.reference ? ref.reference : '',
                      })
                    );
                    this.setState({
                      branch: {
                        ...branch,
                        third_party_references: formatted_references,
                      },
                    });
                  }}
                  type={branch.type}
                  idToNameMap={this.props.idToNameMap}
                />
                {branch.type === 'SUPPLIER' && (
                  <>
                    <div className="field">
                      <label>{t('Timezone')}</label>
                      {branch.default_timezone}
                    </div>
                    <div className="field">
                      <label>{t('Currency')}</label>
                      {branch.default_currency}
                    </div>
                    <div className="field">
                      <label>{t('Language')}</label>
                      {getLanguageName((branch.source_language: any), t)}
                    </div>
                    <div className="field">
                      <label>{t('JPY Payment GW')}</label>
                      {(() => {
                        if (branch.id) {
                          return branch?.reservation_payment_gateway_settings
                            ?.payment_gateway === 'GMO'
                            ? 'GMO'
                            : 'Stripe';
                        } else {
                          if (
                            branch.default_timezone == 'Asia/Tokyo' &&
                            branch.default_currency == 'JPY'
                          ) {
                            return 'GMO';
                          } else {
                            return 'Stripe';
                          }
                        }
                      })()}
                    </div>
                  </>
                )}
                {this.props.branch.id && branch.type === 'AGENT' && (
                  <>
                    <Form.Input
                      label={t('Google Analytics Tag')}
                      value={
                        branch.booking_widget_settings?.google_analytics_tag ||
                        ''
                      }
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            booking_widget_settings: {
                              ...branch.booking_widget_settings,
                              google_analytics_tag: value,
                            },
                          },
                        })
                      }
                    />
                    <Form.Field>
                      <label>{t('Logo')}</label>
                      <LogoInput
                        filename={branch.logo_url || ''}
                        organizationID={branch.id}
                        organizationType={'agent'}
                        onChange={(logo_url) =>
                          this.setState({
                            branch: {
                              ...branch,
                              logo_url,
                            },
                          })
                        }
                      />
                    </Form.Field>
                    <Form.Input
                      label={t('Office Mailing Address')}
                      value={branch.office_mailing_address || ''}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            office_mailing_address: value,
                          },
                        })
                      }
                    />
                    <Form.Input
                      label={t('Office Email')}
                      value={branch.office_email || ''}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            office_email: value,
                          },
                        })
                      }
                    />
                    <Form.Select
                      search
                      disabled={this.props.branch.corresponding_organization_id}
                      label={t('Corresponding Supplier (passthrough)')}
                      value={branch.corresponding_organization_id || ''}
                      options={correspondingSupplierOptions}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            corresponding_organization_id: value,
                          },
                        })
                      }
                    />
                  </>
                )}
                {this.props.branch.id && branch.type === 'SUPPLIER' && (
                  <>
                    <Form.Input
                      required
                      label={t('Emergency Contact')}
                      value={branch.emergency_contact || ''}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            emergency_contact: value,
                          },
                        })
                      }
                    />
                    <Form.Select
                      search
                      disabled={this.props.branch.corresponding_organization_id}
                      label={t('Corresponding Agent (passthrough)')}
                      value={branch.corresponding_organization_id || ''}
                      options={correspondingAgentOptions}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            corresponding_organization_id: value,
                          },
                        })
                      }
                    />
                    <Form.Input
                      label={t('Actim API KEY')}
                      value={branch.actim_api_key || ''}
                      onChange={(e, { value }) =>
                        this.setState({
                          branch: {
                            ...branch,
                            actim_api_key: value,
                          },
                        })
                      }
                    />
                    <Form.Checkbox
                      toggle
                      label={t('Show booking widget settings')}
                      value={showBookingWidgetSettings}
                      onChange={() =>
                        this.setState({
                          showBookingWidgetSettings: !showBookingWidgetSettings,
                        })
                      }
                    />
                    {showBookingWidgetSettings && (
                      <>
                        <Form.Input
                          label={t('Office Phone')}
                          value={branch.office_phone || ''}
                          onChange={(e, { value }) =>
                            this.setState({
                              branch: {
                                ...branch,
                                office_phone: value,
                              },
                            })
                          }
                        />
                        <Form.Input
                          label={t('Office Mailing Address')}
                          value={branch.office_mailing_address || ''}
                          onChange={(e, { value }) =>
                            this.setState({
                              branch: {
                                ...branch,
                                office_mailing_address: value,
                              },
                            })
                          }
                        />
                        <Form.Input
                          label={t('Office Email')}
                          value={branch.office_email || ''}
                          onChange={(e, { value }) =>
                            this.setState({
                              branch: {
                                ...branch,
                                office_email: value,
                              },
                            })
                          }
                        />
                        <Divider />
                        <OperatingHoursInput
                          onChange={(operating_hours_rule) =>
                            this.setState({
                              branch: {
                                ...branch,
                                operating_hours_rules: [operating_hours_rule],
                              },
                            })
                          }
                          value={
                            (branch.operating_hours_rules &&
                              branch.operating_hours_rules[0]) ||
                            {}
                          }
                        />
                        <Divider />
                        <SupplierGuestPaymentSettingsInput
                          value={supplier_guest_payment_settings}
                          onChange={(supplier_guest_payment_settings) => {
                            this.setState({
                              branch: {
                                ...branch,
                                supplier_guest_payment_settings,
                              },
                            });
                          }}
                        />
                        <MultiselectListInput
                          label={t('Supported languages')}
                          options={[
                            { value: 'EN_US', text: t('English') },
                            { value: 'JA_JP', text: t('Japanese') },
                            { value: 'KO_KR', text: t('Korean') },
                            { value: 'ZH_CN', text: t('Chinese (Simplified)') },
                            {
                              value: 'ZH_TW',
                              text: t('Chinese (Traditional)'),
                            },
                          ]}
                          value={(branch.supported_languages: any) || []}
                          onChange={(supported_languages: any) =>
                            this.setState({
                              branch: {
                                ...branch,
                                supported_languages,
                              },
                            })
                          }
                        />
                        <Form.Input
                          label={t('Google Analytics Tag')}
                          value={
                            branch.booking_widget_settings
                              ?.google_analytics_tag || ''
                          }
                          onChange={(e, { value }) =>
                            this.setState({
                              branch: {
                                ...branch,
                                booking_widget_settings: {
                                  google_analytics_tag: value,
                                },
                              },
                            })
                          }
                        />
                        <Form.Field>
                          <label>{t('Logo')}</label>
                          <LogoInput
                            filename={branch.logo_url || ''}
                            organizationID={branch.id}
                            organizationType={'supplier'}
                            onChange={(logo_url) =>
                              this.setState({
                                branch: {
                                  ...branch,
                                  logo_url,
                                },
                              })
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>{t('Icon')}</label>
                          <IconInput
                            organizationID={branch.id}
                            organizationType={'supplier'}
                          />
                        </Form.Field>
                      </>
                    )}
                  </>
                )}

                {this.state.formError === ERROR_ID_SEED_ALREADY_IN_USE && (
                  <Message
                    error
                    header={t(
                      'Error: Office email already in use by another {{branchType}}',
                      {
                        branchType: this.state.branch.type.toLowerCase(),
                      }
                    )}
                    content={t(
                      'Please change the type or office email of this organization'
                    )}
                    list={[
                      `${this.state.branch.type} ID: ${this.state.takenById}`,
                      `${this.state.branch.type} NAME: ${this.state.takenByName}`,
                    ]}
                  />
                )}

                {this.state.formError === ERROR_EMERGENCY_CONTACT_EMPTY && (
                  <Message
                    error
                    header={t(
                      'Error: Cannot remove an existing emergency contact number.'
                    )}
                    content={t('Please enter in an emergency contact number.')}
                  />
                )}

                {this.state.formError === ERROR_ORGANIZATION_NAME_EMPTY && (
                  <Message
                    error
                    header={t('Error: Organization name field cannot be blank')}
                    content={t('Please fill in the blank organization name')}
                  />
                )}

                {this.state.formError === ERROR_ORGANIZATION_NAME_REPEATED && (
                  <Message
                    error
                    header={t(
                      'Error: Cannot give more than one reference for a third party organization'
                    )}
                    content={t('Please remove the conflicting references')}
                  />
                )}
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({
                  branch: this.props.branch,
                  formError: '',
                });
              }}
              disabled={this.state.branch === this.props.branch}
            >
              {t('Discard Changes')}
            </Button>
            <Button
              primary
              disabled={
                !this.state.branch.name ||
                !this.state.branch.type ||
                !this.state.branch.office_email
              }
              onClick={() => {
                let error = false;
                if (!branch.id) {
                  let selectedIdSeed = this.state.branch.office_email;
                  let selectedOrganizationType = this.state.branch.type;
                  if (selectedIdSeed && selectedOrganizationType) {
                    if (selectedOrganizationType === 'AGENT') {
                      console.log(this.props.agentIdSeedsMap);

                      let agentIdSeedExists =
                        this.props.agentIdSeedsMap[selectedIdSeed];
                      error = !!agentIdSeedExists;
                      if (agentIdSeedExists) {
                        this.setState({
                          takenById: agentIdSeedExists[0],
                          takenByName: agentIdSeedExists[1],
                        });
                      }
                    } else {
                      let supplierIdSeedExists =
                        this.props.supplierIdSeedsMap[selectedIdSeed];
                      error = !!supplierIdSeedExists;
                      if (supplierIdSeedExists) {
                        this.setState({
                          takenById: supplierIdSeedExists[0],
                          takenByName: supplierIdSeedExists[1],
                        });
                      }
                    }
                  }

                  if (error) {
                    this.setState({
                      formError: ERROR_ID_SEED_ALREADY_IN_USE,
                    });
                    return;
                  }
                }

                if (
                  this.props.branch.emergency_contact &&
                  (!this.state.branch.emergency_contact ||
                    !this.state.branch.emergency_contact.trim())
                ) {
                  this.setState({
                    formError: ERROR_EMERGENCY_CONTACT_EMPTY,
                  });
                  return;
                }

                const refs = this.state.branch.third_party_references;

                if (refs) {
                  // Check for any blank organizations
                  error = refs.some(
                    (ref) =>
                      !ref.id ||
                      ref.id === 'AGENT' ||
                      ref.id === 'SUPPLIER' ||
                      !this.props.idToNameMap[
                        branch.type === 'AGENT' ? 'SUPPLIER' : 'AGENT'
                      ][ref.id]
                  );

                  if (error) {
                    this.setState({
                      formError: ERROR_ORGANIZATION_NAME_EMPTY,
                    });
                    return;
                  }

                  // Check for any duplicate organizations
                  error = refs.some(
                    (ref) =>
                      refs.filter((otherRef) => otherRef.id === ref.id).length >
                      1
                  );

                  if (error) {
                    this.setState({
                      formError: ERROR_ORGANIZATION_NAME_REPEATED,
                    });
                    return;
                  }
                }

                this.setState({
                  formError: '',
                });

                if (this.props.branch.id) {
                  this.props.updateOrganization(
                    this.props.branch.id,
                    this.props.branch.type,
                    (this.state.branch: any)
                  );
                } else {
                  this.props.createOrganization((this.state.branch: any));
                }
                this.setState({
                  showModal: false,
                });
              }}
            >
              {t('Save')}
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): Object => {
  const locale = state.language.selected.iso;

  // TODO: rewrite this using activeUser

  let branch = ownProps.branch
    ? ownProps.branch
    : {
        type: 'AGENT',
        ...(locale.includes('ja')
          ? {
              default_currency: 'JPY',
              default_timezone: 'Asia/Tokyo',
              source_language: 'JA_JP',
            }
          : {
              default_currency: 'USD',
              default_timezone: 'Pacific/Honolulu',
              source_language: 'EN_US',
            }),
      };

  return {
    locale,
    branch,
    organizations: state.organizations.all,
    idToNameMap: idToNameSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  createOrganization: (organization: Organization$Input) =>
    dispatch(createOrganization(organization)),
  updateOrganization: (
    id: string,
    type: 'AGENT' | 'SUPPLIER',
    organization: Organization$Patch
  ) => dispatch(updateOrganization(id, type, organization)),
});

export const EditBranchModal = compose(
  connect<Props, OwnProps, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(EditBranchModalComponent);
