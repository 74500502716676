import * as React from 'react';

import {
  GenericTable,
  ColumnType,
} from 'client/components/v3/GenericTable/GenericTable';

export type { ColumnType };

interface Props<T> {
  allItems: T[];
  columns: ColumnType<T>[];
  hideScrollButtons?: boolean;
  headerBar?: React.ReactNode;
  loading?: boolean;
  makeTableContentOverflow?: boolean;
}

export const PagedGenericTable = <T,>({
  allItems,
  columns,
  hideScrollButtons,
  headerBar,
  loading,
  makeTableContentOverflow,
}: Props<T>) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);

  const pageItems = allItems.slice(
    (currentPage - 1) * rowCount,
    currentPage * rowCount
  );

  return (
    <GenericTable<T>
      items={pageItems}
      columns={columns}
      rowCount={rowCount}
      onRowCountChange={setRowCount}
      currentPage={currentPage}
      onCurrentPageChange={setCurrentPage}
      totalCount={allItems.length}
      hideScrollButtons={hideScrollButtons}
      headerBar={headerBar}
      loading={loading}
      makeTableContentOverflow={makeTableContentOverflow}
    />
  );
};
