import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';

import { TextField } from 'client/components/v3/Form/TextField';
import { Box } from 'client/components/Box/Box';
import { DragHandle } from 'client/components/Icons/DragHandle';

import styles from './SortableItineraryItem.module.css';

interface Props {
  id: string;
  value: string;
  onChange: (value: string) => void;
  onDelete: () => void;
}

export const SortableItineraryItem = ({
  id,
  value,
  onChange,
  onDelete,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    width: '100%',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box display="flex" alignItems="center" gap={2}>
        <div
          {...attributes}
          {...listeners}
          style={{ cursor: 'grab', display: 'flex', alignItems: 'center' }}
        >
          <DragHandle />
        </div>
        <Box display="flex" width="100%">
          <TextField
            value={value}
            onChange={(newValue) => onChange(newValue)}
            placeholder="https://"
          />
        </Box>
        {value && (
          <a
            className="c-button-link-sm-color"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            <i className="c-icon-outline-general-link-external-02" />
          </a>
        )}
        <a
          className={clsx(styles.button, styles.delete)}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <i className="c-icon-outline-general-trash-03" />
        </a>
      </Box>
    </div>
  );
};
