import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { addSubscription } from 'client/actions/subscriptions';
import { Modal } from 'client/components/v3/Form/Modal';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { FieldWrapper, Radio } from 'client/components/Form';
import { Button } from 'client/components/v3/Common/Button';
import { ModalLoader } from 'client/components/ModalLoader';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { ReduxState } from 'client/reducers';
import type { TranslateFuncType } from 'client/components/Translate';
import { subdomainIsAvailable } from 'client/actions/subdomains';
import type {
  SubscriptionKey,
  SubscriptionInterval,
  SourceLanguage,
} from 'shared/models/swagger';

import { getSubscriptionPriceDescription } from './util';
import styles from './AddSubscriptionModal.module.css';

const getHeader = (
  subscriptionKey: SubscriptionKey,
  t: TranslateFuncType
): string => {
  switch (subscriptionKey) {
    case 'feature-review':
      return t('Subscribe: Review Feature');

    case 'feature-email-customization':
      return t('Subscribe: Email Customization Feature');

    case 'feature-digital-waiver':
      return t('Subscribe: Digital Waiver Feature');

    case 'standard-plan':
      return t('Subscribe: Standard Plan');

    case 'feature-guide-account':
      return t('Subscribe: Shift Management');

    case 'feature-qr-checkin':
      return t('Subscribe: QR Checkin Feature');

    case 'feature-guest-my-page':
      return t('Subscribe: My Page for Guest Feature');

    case 'feature-image-download':
      return t('Subscribe: Image Download Feature');

    case 'feature-digital-guidance':
      return t('Subscribe: Digital Guidance Feature');

    case 'feature-inquiry':
      return t('Subscribe: Inquiry Feature');

    case 'feature-e-ticket':
      return t('Subscribe: E-ticket Feature');

    case 'feature-resource-management':
      return t('Subscribe: Resource Management Feature');

    case 'feature-vehicle-crew-resource-management':
      return t('Subscribe: Vehicle, Crew, Resource Management Feature');

    case 'feature-survey':
      return t('Subscribe: Survey Feature');

    case 'feature-customer-ledger':
      return t('Subscribe: Customer Membership & Newsletter Feature');

    case 'feature-newsletter':
      return t('Subscribe: Newsletter Feature');

    case 'feature-seat-management':
      return t('Subscribe: Seat Management Feature');

    case 'feature-custom-top-page':
      return t('Subscribe: Custom TOP Page Feature');

    case 'feature-channel-manager':
      return t('Subscribe: Channel Manager Feature');

    case 'feature-annual-pass':
      return t('Subscribe: Annual Pass');

    case 'feature-journey-analytics':
      return t('Subscribe: Journey Analytics Feature');

    case 'feature-marketing-automation':
      return t('Subscribe: Marketing Automation Feature');

    case 'feature-digital-map':
      return t('Subscribe: Map Feature');

    case 'feature-group-ticketing':
      return t('Subscribe: Group Ticketing Feature');

    case 'feature-site-hacker':
      return t('Subscribe: Site Hacker Feature');

    default:
      throw new Error('unknown subscription key');
  }
};

type Props = {
  subscriptionKey: SubscriptionKey;
  onFailure?: () => void;
  isManualSubscription?: boolean;
  onClose: () => void;
};
export const AddSubscriptionModal = ({
  onFailure,
  subscriptionKey,
  isManualSubscription,
  onClose,
}: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [paymentTerm, setPaymentTerm] =
    React.useState<SubscriptionInterval>('month');
  const [pmpLanguage, setPmpLanguage] = React.useState<SourceLanguage>('EN_US');
  const [pmpSubdomain, setPmpSubdomain] = React.useState<string>('');
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const subdomainCheckLoading = useSelector(
    (state: ReduxState) => state.subdomains.loading
  );
  const pmpSubdomainIsAvailable = useSelector(
    (state: ReduxState) => state.subdomains.isAvailable
  );
  const hasExistingPMPSettings =
    (activeUserOrganization?.private_marketplaces?.length || 0) > 0;
  const dispatch = useDispatch();
  const store = useStore();
  React.useEffect(() => {
    if (pmpSubdomain) {
      dispatch(subdomainIsAvailable(pmpSubdomain));
    }
  }, [pmpSubdomain]);
  const handlePaymentTermChange = React.useCallback(
    (event, { value }) => setPaymentTerm(value),
    []
  );
  const handlePmpLanguageChange = React.useCallback(
    (event, { value }) => setPmpLanguage(value),
    []
  );
  const handleAddSubscription = React.useCallback(async () => {
    setLoading(true);
    await dispatch(
      addSubscription({
        key: subscriptionKey,
        interval: paymentTerm,
        pmpSubdomain,
        pmpLanguage,
        isManualSubscription,
      })
    );
    setLoading(false);

    if (store.getState().subscriptions.error) {
      setError(store.getState().subscriptions.error);
      onFailure?.();
    } else {
      setError('');
      onClose();
    }
  }, [
    subscriptionKey,
    paymentTerm,
    pmpSubdomain,
    pmpLanguage,
    isManualSubscription,
  ]);
  const currency =
    (activeUserOrganization?.default_subscriptions_payment_currency ??
      'JPY') as 'JPY' | 'USD';
  let baseSubdomain = '.book.ntmg.com';

  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost:3000/.test(window.location.origin)
  ) {
    baseSubdomain = '.book.dev.ntmg.com';
  } else if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    baseSubdomain = '.book.stg.ntmg.com';
  }

  return (
    <Modal
      open={true}
      onClose={() => {
        setError('');
        onClose();
      }}
      title={getHeader(subscriptionKey, t)}
      rightActionChildren={
        <>
          <Button
            text={t('Subscribe')}
            loading={loading}
            onClick={handleAddSubscription}
            color="primary"
            size="md"
            disabled={
              !hasExistingPMPSettings &&
              (subdomainCheckLoading ||
                (subscriptionKey === 'standard-plan' &&
                  (!pmpSubdomain || !pmpSubdomainIsAvailable)))
            }
            style={{
              fontWeight: 'var(--text-semibold)',
            }}
          />
        </>
      }
      style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
    >
      <>
        <FieldWrapper label={t('Payment Term')}>
          <Radio
            label={
              isManualSubscription
                ? t('No Charge (manual subscription)')
                : getSubscriptionPriceDescription(subscriptionKey, currency)
            }
            name="paymentTerm"
            value="month"
            checked={paymentTerm === 'month'}
            onChange={handlePaymentTermChange}
          />
          {/*
          <Radio
           label={t('Yearly ({{price}}/year)', {
             price: formattedCurrencyAmount(subscriptionPrices.year[currency])
           })}
           name="paymentTerm"
           value="year"
           checked={paymentTerm === 'year'}
           onChange={handlePaymentTermChange}
          />
          */}
        </FieldWrapper>
        {subscriptionKey === 'standard-plan' && hasExistingPMPSettings && (
          <Box>
            <Box mt={2} mb={2}>
              {t(
                'Private Marketplace was previously configured. Please contact support@nutmeglabs.com if you wish to change these settings.'
              )}
            </Box>
            <Box mt={2} mb={2}>
              <FieldWrapper label={t('Private Marketplace Website URL')}>
                {`https://${
                  activeUserOrganization?.private_marketplaces?.[0].domain ??
                  '<error>'
                }`}
              </FieldWrapper>
            </Box>
            <Box mt={2} mb={2}>
              <FieldWrapper label={t('Private Marketplace Language')}>
                {getLanguageName(
                  uppercaseIsoToLowercaseIso[
                    activeUserOrganization?.private_marketplaces?.[0]
                      .language ?? 'JA_JP'
                  ],
                  t
                )}
              </FieldWrapper>
            </Box>
          </Box>
        )}
        {subscriptionKey === 'standard-plan' && !hasExistingPMPSettings && (
          <>
            <Box mt={2}>
              <div>
                <div className={styles['c-form__box__header']}>
                  {t('Private Marketplace Website URL')}
                </div>
                <div>
                  <div className={styles['p-regist__url']}>
                    <div className={styles['p-regist__url__main']}>
                      <p>https://</p>
                      <input
                        type="text"
                        className={styles['c-inputtext']}
                        placeholder="sample"
                        onChange={(e) => setPmpSubdomain(e.target.value)}
                        value={pmpSubdomain}
                      />
                      <p>{baseSubdomain}</p>
                    </div>
                    <div className={styles['p-regist__url__check']}>
                      {pmpSubdomain &&
                        (subdomainCheckLoading ? (
                          <ModalLoader />
                        ) : pmpSubdomainIsAvailable ? (
                          <p className={styles['ok']}>
                            <span></span>
                            {t('URL Available')}
                          </p>
                        ) : (
                          <p className={styles['ng']}>
                            <span></span>
                            {t('URL Not Available')}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            <Box mt={2}>
              <FieldWrapper label={t('Private Marketplace Language')}>
                <Radio
                  label={t('English')}
                  name="pmpLanguage"
                  value="EN_US"
                  checked={pmpLanguage === 'EN_US'}
                  onChange={handlePmpLanguageChange}
                />
                <Radio
                  label={t('Japanese')}
                  name="pmpLanguage"
                  value="JA_JP"
                  checked={pmpLanguage === 'JA_JP'}
                  onChange={handlePmpLanguageChange}
                />
              </FieldWrapper>
            </Box>
          </>
        )}
        {error && (
          <Message
            error
            header={t('Subscription Failed')}
            content={t('Subscription Failed. Please try again later.')}
          />
        )}
      </>
    </Modal>
  );
};
