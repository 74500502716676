import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { config } from 'client/config';
import { customPagesSelector } from 'client/reducers/customPages';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { Radio } from 'client/components/v3/Form/Radio';
import type { ReduxState } from 'client/reducers';
import {
  batchUpdateCustomPages,
  fetchCustomPages,
} from 'client/actions/customPage';
import { DraggableList } from 'client/pages/v3/BookingWidget/EssentialPages/Tab/DraggableList';
import { Button } from 'client/components/v3/Common/Button';
import { DeleteCustomPageModal } from 'client/pages/v3/BookingWidget/EssentialPages/EditCustomPage/DeleteCustomPageModal';
import { EditCustomPageModal } from 'client/pages/v3/BookingWidget/EssentialPages/EditCustomPage/EditCustomPageModal';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';
import type {
  CustomPage,
  SourceLanguage,
  CustomPageStatus,
} from 'shared/models/swagger';

export const CustomPagesTab = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const customPages = useSelector(customPagesSelector);
  const submitError = useSelector(
    (state: ReduxState) => state.customPages.error
  );

  const [contentLanguage, setContentLanguage] =
    React.useState<SourceLanguage | null>(null);
  const [supportLanguages, setSupportLanguages] = React.useState<
    SourceLanguage[]
  >([]);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<
    {
      key: string;
      text: string;
      status: CustomPageStatus;
    }[]
  >([]);
  const [newsItems, setNewsItems] = React.useState<
    {
      key: string;
      text: string;
      status: CustomPageStatus;
    }[]
  >([]);
  const [editCustomPage, setEditCustomPage] = React.useState<CustomPage | null>(
    null
  );
  const [type, setType] = React.useState<'NORMAL' | 'NEWS'>('NORMAL');
  const [orderUpdated, setOrderUpdated] = React.useState<boolean>(false);
  const [deleteCustomPage, setDeleteCustomPage] =
    React.useState<CustomPage | null>(null);

  React.useEffect(() => {
    setSupportLanguages(
      getBookingWidgetPmpSupportLanguages(activeUserOrganization)
    );
  }, [activeUserOrganization]);

  React.useEffect(() => {
    if (supportLanguages.length > 0) {
      setContentLanguage(supportLanguages[0]);
    }
  }, [supportLanguages]);

  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));

  const selectableContentLanguageOptions = languageOptions.filter((option) => {
    return supportLanguages.includes(option.value);
  });

  React.useEffect(() => {
    dispatch(fetchCustomPages());
  }, [activeUserOrganization]);

  React.useEffect(() => {
    const items = (customPages || [])
      .filter((customPage) => {
        return customPage.content_language === contentLanguage;
      })
      .filter((customPage) => {
        return customPage.type === 'NORMAL';
      })
      .sort((a, b) => {
        return a.sort_order - b.sort_order;
      })
      .map((customPage) => {
        return {
          key: customPage.id,
          text: customPage.title,
          status: customPage.status,
        };
      });
    setItems(items);

    const newsItems = (customPages || [])
      .filter((customPage) => {
        return customPage.content_language === contentLanguage;
      })
      .filter((customPage) => {
        return customPage.type === 'NEWS';
      })
      .sort((a, b) => {
        return a.sort_order - b.sort_order;
      })
      .map((customPage) => {
        return {
          key: customPage.id,
          text: customPage.title,
          status: customPage.status,
        };
      });
    setNewsItems(newsItems);
  }, [customPages, contentLanguage]);

  React.useEffect(() => {
    if (orderUpdated) {
      setOrderUpdated(false);
      //setOrderUpdateSucceeded(true);
    }
  }, [customPages]);
  React.useEffect(() => {
    if (submitError) {
      setOrderUpdated(false);
    }
  }, [submitError]);

  const onRemove = (key: string) => {
    //const target = items[index];
    const customPage = customPages.find((customPage) => customPage.id === key);

    if (customPage) {
      setDeleteCustomPage(customPage);
      setShowDeleteModal(true);
    }
  };

  const onEdit = (key: string) => {
    const customPage = customPages.find((customPage) => customPage.id === key);

    if (customPage) {
      setEditCustomPage(customPage);
      setShowEditModal(true);
    }
  };

  const onOrderChange = (
    items: {
      key: string;
      text: string;
      status: CustomPageStatus;
    }[]
  ) => {
    setItems(items);
    setOrderUpdated(false);
  };

  const onOrderSave = async () => {
    const newCustomPages = items.map((item, idx) => {
      return {
        id: item.key,
        sort_order: idx + 1,
      };
    });
    const newNewsPages = newsItems.map((item, idx) => {
      return {
        id: item.key,
        sort_order: idx + 1,
      };
    });

    if (newCustomPages) {
      await dispatch(batchUpdateCustomPages(newCustomPages));
    }
    if (newNewsPages) {
      await dispatch(batchUpdateCustomPages(newNewsPages));
    }
    setOrderUpdated(true);
  };

  if (
    config.enableHPFeatureImprovements &&
    contentLanguage !== null &&
    showEditModal
  ) {
    return (
      <Redirect
        push
        to={{
          pathname: `/bookingWidget/homepages-v3/editcustompage`,
          state: {
            contentLanguage: contentLanguage,
            type: editCustomPage?.type,
            id: editCustomPage?.id || '',
          },
        }}
      />
    );
  }

  return (
    <>
      <div
        className={clsx(
          styles['p-inquiry-tabs__tab-panel'],
          styles['components_is-active__B15DT']
        )}
        style={{ display: 'block' }}
      >
        <div className={styles['p-inquiry-tabs__content']}>
          <ul className={styles['p-inquiry-list']}>
            <li className={styles['p-inquiry-list__item']}>
              <div
                className={styles['p-inquiry-list__item__ttl']}
                style={{ width: 'auto' }}
              >
                <div className={styles['p-inquiry-list__item__ttl__txt']}>
                  <div>{t('Content Language')}</div>
                </div>
              </div>
              <div className={styles['p-inquiry-list__item__body']}>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <div className={styles['p-reservations__dropdown']}>
                    <div style={{ display: 'flex' }}>
                      {selectableContentLanguageOptions.map((language, idx) => (
                        <div key={idx} style={{ marginRight: '15px' }}>
                          <Radio
                            label={t(language.text)}
                            onChange={() => {
                              setContentLanguage(language.value);
                            }}
                            checked={contentLanguage === language.value}
                            size="sm"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              className={styles['p-inquiry-list__item']}
              style={{ display: 'block' }}
            >
              <div
                className={styles['p-inquiry-list__item__ttl']}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                  marginRight: '0',
                }}
              >
                <div
                  className={styles['p-inquiry-list__item__title']}
                  style={{ width: '100%', display: 'block' }}
                >
                  <div style={{ width: '100%' }}>{t('Info')}</div>
                </div>
              </div>
              <div style={{ marginBottom: '10px' }}>
                {t(
                  'Create a page to display under the "Info" menu on the booking site.'
                )}
              </div>
              <div
                className={styles['p-inquiry-list__item__body']}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <DraggableList
                    options={items}
                    moveItem={(newItems) => {
                      onOrderChange(newItems);
                    }}
                    removeItem={(key) => {
                      onRemove(key);
                    }}
                    editItem={(key) => {
                      onEdit(key);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: '100%', marginLeft: 'auto' }}>
                <a
                  className={styles['add__button']}
                  onClick={() => {
                    setType('NORMAL');
                    setEditCustomPage(null);
                    setShowEditModal(true);
                  }}
                  style={{ marginTop: '0', marginLeft: 'auto' }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Info Page')}
                </a>
              </div>
              <div
                className={styles['p-inquiry-list__item__ttl']}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                  marginRight: '0',
                  paddingTop: '10px',
                }}
              >
                <div
                  className={styles['p-inquiry-list__item__title']}
                  style={{ width: '100%', display: 'block' }}
                >
                  <div style={{ width: '100%' }}>{t('NEWS')}</div>
                </div>
              </div>
              <div style={{ marginBottom: '10px' }}>
                {t('Create a page to display as "NEWS" on the booking site.')}
              </div>
              <div
                className={styles['p-inquiry-list__item__body']}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <DraggableList
                    options={newsItems}
                    moveItem={(newItems) => {
                      onOrderChange(newItems);
                    }}
                    removeItem={(key) => {
                      onRemove(key);
                    }}
                    editItem={(key) => {
                      onEdit(key);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: '100%', marginLeft: 'auto' }}>
                <a
                  className={styles['add__button']}
                  onClick={() => {
                    setType('NEWS');
                    setEditCustomPage(null);
                    setShowEditModal(true);
                  }}
                  style={{ marginTop: '0', marginLeft: 'auto' }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add NEWS Page')}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {!config.enableHPFeatureImprovements &&
        contentLanguage !== null &&
        showEditModal && (
          <EditCustomPageModal
            title={
              editCustomPage
                ? t('Edit Custom Page')
                : t('Create New Custom Page')
            }
            open={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
            editCustomPage={editCustomPage}
            type={
              editCustomPage
                ? (editCustomPage?.type as 'NORMAL' | 'NEWS')
                : type
            }
            contentLanguage={contentLanguage}
            customPages={customPages}
          />
        )}
      {showDeleteModal && (
        <DeleteCustomPageModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          customPage={deleteCustomPage}
        />
      )}
      <div className={clsx(styles['p-products__fixed'], styles['is-active'])}>
        <div className={styles['p-products__fixed__actions']}>
          <Button
            text={t('Save')}
            type="submit"
            color="primary"
            size="md"
            style={{
              width: '60px',
              fontWeight: 'var(--text-semibold)',
            }}
            onClick={() => onOrderSave()}
          />
        </div>
      </div>
    </>
  );
};
