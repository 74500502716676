import { Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import { FieldResponseItemType } from 'client/pages/ProductDetails/ProductContentsHeader/QRCheckinSettingsModal/QRCheckinSettingsFormValues';
import { GenericDraggableList } from 'client/components/v3/GenericDraggableList/GenericDraggableList';

import styles from '../../ETicketEdit.module.css';

import { FieldResponseItem } from './FieldResponseItem';

interface Props {
  name: string;
  includeConfirmationNumber?: boolean;
  includeAddOns?: boolean;
  excludeHotelInfomationCheckinCheckout?: boolean;
  formFieldType?: 'PER_BOOKING' | 'PER_PARTICIPANT';
  addItemText?: string;
}

export const FieldResponsesForm = ({
  name,
  formFieldType,
  includeConfirmationNumber,
  includeAddOns,
  excludeHotelInfomationCheckinCheckout,
  addItemText,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['p-box__table3']}>
      <Field name={name}>
        {({ input }) => {
          return (
            <>
              <GenericDraggableList<FieldResponseItemType>
                items={input.value}
                itemType="footprintedModalDisplayItems"
                moveItem={(newItems) => input.onChange(newItems)}
                addItem={() => {
                  const newItems = [
                    ...input.value,
                    {
                      _key: uuid(),
                      text: '',
                      key: '',
                    },
                  ];
                  input.onChange(newItems);
                }}
                addItemText={addItemText || t('Add Display Item')}
                renderItem={(item) => {
                  return (
                    <FieldResponseItem
                      formFieldType={formFieldType}
                      includeConfirmationNumber={includeConfirmationNumber}
                      includeAddOns={includeAddOns}
                      excludeHotelInfomationCheckinCheckout={
                        excludeHotelInfomationCheckinCheckout
                      }
                      item={item}
                      onChange={(newItem) => {
                        const newItems = input.value.map(
                          (i: FieldResponseItemType) => {
                            if (i._key === item._key) {
                              return newItem;
                            }
                            return i;
                          }
                        );
                        input.onChange(newItems);
                      }}
                      onRemoveClick={() => {
                        const newItems = input.value.filter(
                          (i: FieldResponseItemType) => i._key !== item._key
                        );
                        input.onChange(newItems);
                      }}
                    />
                  );
                }}
              />
            </>
          );
        }}
      </Field>
    </div>
  );
};
